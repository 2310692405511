<!-- info-other apps-->
<div #otherapps style="margin:1.0rem;">

    <!-- language de -->
    <ng-container *ngIf="languageCode==='de'">
        <h1>Weitere Apps von Trip4you</h1>

        <h3>Weitere Apps</h3>
        <div style="margin:0.5em;">
            <span style="font-size: large;"><b>Trip4You - Travel information</b><br>
                App zum Suchen von Reiseinformationen </span><br>
            <p style="margin-left:2.0em; margin-top:0.5rem;">
                Trip4You-Reiseinformationen sind Teil der Reiseplaner-App.<br>
                Sie können es ohne Reiseplanung verwenden, um Reiseinformationen über ein Land oder eine Region zu
                erhalten.
            </p>
            <a mat-raised-button color="primary" href="https://trip4you.net/explore" target="_blank"
                style="margin-left:2.0em;">Try Trip4You - Travel information</a>
        </div>
        <div style="margin:0.5em;">
            <span style="font-size: large;"><b>Trip4You - Travel planner</b><br>
                App zum Planen von individuellen Reisen</span><br>
            <p style="margin-left:2.0em; margin-top:0.5rem;">
                Mit dem Reiseplaner Trip4You können Sie alle Ihre Reisen mit allen gewünschten Detailinformationen
                planen. <br>
                Zu Ihrer Reise können Sie die Orte, die Sie besuchen möchten, die Transfers von einem Ort zum anderen
                und vieles mehr hinzufügen
                Weitere Informationen.<br>
                Für jeden Ort, den Sie hinzugefügt haben, können Sie die Dinge speichern, die Sie sehen und unternehmen
                möchten. Zusätzlich können sie Unterkünfte
                und Restaurants, Infos und Links zu den Orten hinzufügen.
            </p>
            <a mat-raised-button color="primary" href="https://trip4you.net" target="_blank"
                style="margin-left:2.0em;">Zu Trip4You - Travel planner</a>
        </div>
        <div style="margin:0.5em;">
            <span style="font-size: large;"><b>Tandem4You</b><br>
                App für die Suche nach Sprachpartnern</span><br>
            <p style="margin-left:2.0em; margin-top:0.5rem;">
                Tandem4You ist eine App zur Suche von Sprachpartnern und Brieffreunden auf der ganzen Welt.<br>
                Es verfügt über einen integrierten Messenger zur Kontaktaufnahme ohne Angabe der E-Mail-Adresse.<br>
                Auch Tandem4You ist eine kostenlose App.
            </p>
            <a mat-raised-button color="primary" href="https://tandem4you.net" target="_blank"
                style="margin-left:1.0em;">Try
                Tandem4You - Sprachpartnersuche</a>
        </div>
    </ng-container>

    <!-- language en -->
    <ng-container *ngIf="languageCode!=='de'">

        <h3>There are more apps available</h3>
        <div style="margin:0.5em;">
            <span style="font-size: large;"><b>Trip4You - Travel information</b><br>
                App for travel information</span><br>
            <p style="margin-left:2.0em; margin-top:0.5rem;">
                Trip4You travel information is part of the trip planner app.<br>
                You can use it without planning a trip to get travel information about a country or region.
            </p>
            <a mat-raised-button color="primary" href="https://trip4you.net/explore" target="_blank"
                style="margin-left:2.0em;">Try Trip4You - Travel information</a>
        </div>
        <div style="margin:0.5em;">
            <span style="font-size: large;"><b>Trip4You - Travel planner</b><br>
                App for individual trip planning</span><br>
            <p style="margin-left:2.0em; margin-top:0.5rem;">
                With Trip4You travel planner you can plan all your trips with all detailed information you like. <br>
                To your trip you can add the places you want to visit, the transfers from one place to another and much
                additional information.<br>
                For each place you've added, you can save the things you want to see and do. Additionally you can add
                your accommodations
                and restaurants, information and links to the places.
            </p>
            <a mat-raised-button color="primary" href="https://trip4you.net" target="_blank"
                style="margin-left:2.0em;">Try
                Trip4You - Travel planner</a>
        </div>
        <div style="margin:0.5em;">
            <span style="font-size: large;"><b>Tandem4You</b><br>
                App for language partner search</span><br>
            <p style="margin-left:2.0em; margin-top:0.5rem;">
                Tandem4You is an app for the search of language partners and penpals all over the world.<br>
                It has an integrated messenger for contacting without offering the e-mail address.<br>
                It is also a free app.
            </p>
            <a mat-raised-button color="primary" href="https://tandem4you.net" target="_blank"
                style="margin-left:1.0em;">Try
                Tandem4You - language partners</a>
        </div>

    </ng-container>

</div>