import { MapPlace, MapPlaceCategory, MapRouteCategory } from "src/dto.generated/api";

import { SavedPlaceCategory, SavedRouteCategory } from "src/app/models/saved-item-categories";
import { LineStyle } from "src/app/models/line-style";
import { PRoute } from "src/app/models/p-route";
import { PointStyle } from "src/app/models/point-style";
import { MapPlaceL } from "src/app/models/mapplace";


export function createDefaultSavedRouteCategoryRemember(userLanguage: string) {
    const newCat = {} as SavedRouteCategory;
    newCat.name = "Places to remember";
    if (userLanguage === "de") { newCat.name = "Gemerkte Orte"; }
    newCat.type = "$remember";
    newCat.visible = true;
    newCat.fixed = true;
    newCat.open = true;
    newCat.lineStyle = {} as LineStyle;
    newCat.lineStyle.lineColor = "#ff0000";
    newCat.routeItems = new Array<PRoute>();
    return newCat;
}

export function createNewRouteCategory(categoryName: string) {
    const newCategory = {} as SavedRouteCategory;
    newCategory.name = categoryName;
    newCategory.type = "$user";
    newCategory.routeItems = new Array<PRoute>();
    newCategory.lineStyle = {} as LineStyle;
    newCategory.lineStyle.lineColor = "#808080";
    return newCategory;
}

export function convertLRouteCatToSRouteCat(localCat: SavedRouteCategory) {
    // console.log("Maps:convertLRouteCatToSRouteCat-localCat", localCat);
    const sCat = {} as MapRouteCategory;
    sCat.id = localCat.categoryId;
    sCat.type = localCat.type;
    sCat.name = localCat.name;
    sCat.description = localCat.description;
    sCat.routeFormatVersion = 1;
    sCat.routeFormat = JSON.stringify(localCat.lineStyle);
    sCat.order = localCat.order;
    sCat.visible = localCat.visible;
    return sCat;
}

export function convertSRouteCatToLRouteCat(serverCat: MapRouteCategory) {
    // console.log("Maps:convertSRouteCatToLRouteCat-serverCat", serverCat);
    const lCat = {} as SavedRouteCategory;
    lCat.categoryId = serverCat.id;
    lCat.type = serverCat.type;
    lCat.name = serverCat.name;
    if (!lCat.name) { lCat.name = lCat.type; }
    lCat.description = serverCat.description;
    lCat.routeItems = new Array<PRoute>();
    if (serverCat.routeFormatVersion === 1) {
        lCat.lineStyle = JSON.parse(serverCat.routeFormat);
    }
    lCat.visible = false;
    lCat.open = false;
    if (lCat.type === "$remember") {
        lCat.open = true;
        lCat.fixed = true;
        lCat.lineStyle = {} as LineStyle;
        lCat.lineStyle.lineColor = "#ff0000";
    }
    if (!lCat.lineStyle) {
        lCat.lineStyle = {} as LineStyle;
        lCat.lineStyle.lineColor = "gray";
    }
    lCat.order = serverCat.order;
    lCat.visible = serverCat.visible;
    return lCat;
}


export function getColorFromIndex(index: number) {
    const colors = new Array<string>();
    colors.push("#ff000080");  // red
    colors.push("#00ff0080");  // green
    colors.push("#0000ff80");  // blue
    colors.push("#ff00ff80");  // magenta
    colors.push("#00ffff80");  // cyan
    colors.push("#ffff0080");  // yellow
    const count = 6;
    const x = Math.floor(index / count);
    const index2 = index - x * count;
    return colors[index2];
}


