import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { RockItAuthenticationService } from "./rockit-authentication.service";
import { RockitSocialLoginPopupComponent } from "./rockit-social-login-popup/rockit-social-login-popup.component";
import { RockItSocialLoginComponent } from "./rockit-social-login/rockit-social-login.component";
import { RockitSocialLogoutPopupComponent } from "./rockit-social-logout-popup/rockit-social-logout-popup.component";

@NgModule({
    declarations: [
        RockItSocialLoginComponent,
        RockitSocialLoginPopupComponent,
        RockitSocialLogoutPopupComponent
    ],
    imports: [
        CommonModule,
        // MatDialogModule,
        // MatButtonModule,
    ],
    providers: [
        RockItAuthenticationService
    ],
    exports: [
        RockItSocialLoginComponent,
    ]
})
export class ShellModule { }
