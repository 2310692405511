<div *ngIf="true" style="padding:0.5rem;">

    <!-- load-gpx-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="back arrow"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onGpxRouteBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.GPXROUTE.DLG.TITLE' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="map symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onGpxRouteBackClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">
    <!-- load gpx-file -->
    <div style="padding:0.5rem;">
        <span>{{'MAPS.GPXROUTE.DLG.SEL-GPX-FILE_TITLE' | translate}}</span><br>
        <input type="file" #gpxFileInput (change)="onFileChange($event)"
            style="cursor:pointer; margin:0.5rem; font-size:large;" title="load GPX-file/KML-file" accept=".gpx,.kml">
    </div>
    <!-- gpx-functions -->
    <ng-container *ngIf="gpxFileLoaded && activeGpxTrack">
        <div style="margin-top:1.0rem; padding:0.5rem;">{{this.activeGpxTrack.name}} </div>
        <hr>
        <div style="padding:0.5rem;">
            <button mat-raised-button color="primary" style="width:12.0rem;" (click)=" onZoomToGpxRouteClick()">
                {{'MAPS.GPXROUTE.DLG.ZOOM-TO-ROUTE_BUTT_TEXT' | translate}}</button><br>
        </div>
        <div *ngIf="activeGpxTrack.trackPointsType !== ''" style="padding:0.5rem;">
            <button mat-raised-button color="primary" style="width:12.0rem;" (click)="onShowGpxRouteDetailsClick()">
                {{'MAPS.GPXROUTE.DLG.SHOW-DETAILS_BUTT_TEXT' | translate}}</button>
        </div>
        <div style="padding:0.5rem;">
            <button mat-raised-button color="primary" style="width:12.0rem;" (click)="onClearGpxRouteClick()">
                {{'MAPS.GPXROUTE.DLG.CLEAR-ROUTE_BUTT_TEXT' | translate}}</button>
        </div>
    </ng-container>

</div>