import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./global-components/page-not-found/page-not-found.component";
import { HttpLoaderFactory } from "./module-maps/maps.module";
import { ClientSettingsService } from "./services/client-settings.service";
import { GlobalService } from "./services/global.service";
import { RockItApiConfig } from "./shell/rockit-api-config";
import { ShellModule } from "./shell/shell.module";
import { StartPageComponent } from "./shell/start-page/start-page.component";

const apiConfig = new RockItApiConfig(environment.apiEndpoint);

// console.log("Environment is: ", apiConfig);

export function provideApiConfig() {
  return apiConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    StartPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    // BackButtonDisableModule.forRoot({
    //   preserveScrollPosition: true
    // }),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production }),
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    MatButtonModule,
    MatToolbarModule,

    ShellModule,
  ],
  exports: [],
  providers: [
    {
      provide: RockItApiConfig,
      useFactory: provideApiConfig
    },
    Location,
    ClientSettingsService,
    GlobalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
