import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";

import { User } from "src/dto.generated/api";

@Component({
  selector: "app-map-selection-component",
  templateUrl: "./map-selection.component.html",
  styleUrls: ["./map-selection.component.scss"]
})
export class MapSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() mapSource: string;
  @Input() ovlmapSource: string;
  @Input() loggedInUser: User;

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() selectedMapChange: EventEmitter<string> = new EventEmitter();
  @Output() selectedOverlayMapChange: EventEmitter<string> = new EventEmitter();

  public showBingMaps = false;
  public showTestMaps = false;
  public showOvlmapHikingRoutes = false;
  public showOvlmapCyclingRoutes = false;
  public showOvlmapMtbRoutes = false;

  public nomapTest: string;

  constructor() { }

  public async ngOnInit() {
    if (this.loggedInUser) {
      this.showBingMaps = this.loggedInUser.mapPriv >= 1;
      this.showTestMaps = this.loggedInUser.id === 1;
    }
    if (this.ovlmapSource === "hiking-red") { this.showOvlmapHikingRoutes = true; }
    if (this.ovlmapSource === "cycling-red") { this.showOvlmapCyclingRoutes = true; }
    if (this.ovlmapSource === "mtb-red") { this.showOvlmapMtbRoutes = true; }

  }
  public ngAfterViewInit() {
    // console.log("MapSElection:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onSelectMapBackClick() {
    this.back.emit();
  }

  public onSelectMap(mapType: string) {
    // console.log("MapSelection:onSelectMap-mapType", mapType);
    this.selectedMapChange.emit(mapType);
  }

  public onOverlayMapHikingRoutesCLick() {
    // console.log("MapSelection:onOverlayMapHikingRoutesCLick-showOvlmapHikingRoutes", this.showOvlmapHikingRoutes);
    let mapType = "hiking-red";
    this.showOvlmapHikingRoutes = !this.showOvlmapHikingRoutes;
    if (!this.showOvlmapHikingRoutes) { mapType = ""; }
    this.showOvlmapCyclingRoutes = false;
    this.showOvlmapMtbRoutes = false;
    this.selectedOverlayMapChange.emit(mapType);
  }
  public onOverlayMapCyclingRoutesCLick() {
    // console.log("MapSelection:onOverlayMapCyclingRoutesCLick-showOvlmapCyclingRoutes", this.showOvlmapCyclingRoutes);
    let mapType = "cycling-red";
    this.showOvlmapCyclingRoutes = !this.showOvlmapCyclingRoutes;
    if (!this.showOvlmapCyclingRoutes) { mapType = ""; }
    this.showOvlmapHikingRoutes = false;
    this.showOvlmapMtbRoutes = false;
    this.selectedOverlayMapChange.emit(mapType);
  }
  public onOverlayMapMtbRoutesCLick() {
    // console.log("MapSelection:onOverlayMapMTBRoutesCLick-showOvlmapCyclingRoutes", this.showOvlmapMTBRoutes);
    let mapType = "mtb-red";
    this.showOvlmapMtbRoutes = !this.showOvlmapMtbRoutes;
    if (!this.showOvlmapMtbRoutes) { mapType = ""; }
    this.showOvlmapCyclingRoutes = false;
    this.showOvlmapHikingRoutes = false;
    this.selectedOverlayMapChange.emit(mapType);
  }

  // public onOverlayMapHikingRoutesChange() {
  //   // console.log("MapSelection:onOverlayMapHikingRoutesChange-showOvlmapWaymarker", this.showOvlmapHikingRoutes);
  //   let mapType = "hiking-routes";
  //   if (!this.showOvlmapHikingRoutes) { mapType = ""; }
  //   this.selectedOverlayMapChange.emit(mapType);
  // }
  // public onOverlayMapCyclingRoutesChange() {
  //   // console.log("MapSelection:onOverlayMapCyclingRoutesChange-showOvlmapWaymarker", this.showOvlmapHikingRoutes);
  //   let mapType = "cycling-routes";
  //   if (!this.showOvlmapCyclingRoutes) { mapType = ""; }
  //   this.selectedOverlayMapChange.emit(mapType);
  // }

}
