<!-- terms-of-use-info -->
<div #termsofuseId style="margin:1.0rem;">

  <!-- language de -->
  <ng-container *ngIf="languageCode==='de'">

    <h1>Nutzungsbedingungen</h1>

    <p>
      Dieses Dokument beschreibt die Regeln für die Nutzung dieser App. Indem Du dich bei Trip4You-Maps anmeldest oder
      dessen Dienste nutzt, stimmst Du den folgenden Bedingungen und Nutzungsregeln zu.
    </p>

    <h3>Wie kannst Du Trip4You-Maps nutzen?</h3>
    <p>
      Du kannst alle verfügbaren Funktionen von Trip4You-Maps kostenlos nutzen. <br>
    </p>

    <h3>Was darfst du nicht?</h3>
    <p>
      Du darfst diese App nicht in einer Weise verwenden, die der App oder ihren Benutzern Schaden zufügt oder
      verursachen könnte.<br>
      Trip4You-Maps behält sich das Recht vor, Dein Konto zu löschen oder zu deaktivieren, wenn Du gegen die
      Nutzungsbedingungen verstößt.
    </p>

    <h3>Deine Rechte</h3>
    <p>
      Du kannst alle Deine persönlichen Daten jederzeit löschen.<br>
      Wenn Sie Ihr Konto vollständig entfernen und auch Ihre Anmeldedaten (Name und E-Mail) löschen möchten, müssen Sie
      uns eine E-Mail an travelplanner4you@outlook.com senden
    </p>

    <h3>Unsere Rechte</h3>
    <p>
      Wir können die Nutzungsbedingungen von Zeit zu Zeit ändern.<br>
      Wir können die Nutzung dieser Anwendung für eine Person einschränken, wenn wir eine unsachgemäße oder schädliche
      Nutzung feststellen.
    </p>

    <h3>Haftungsausschluss</h3>
    <p>
      Wir haften nicht für Fehlfunktionen oder Servivice-Ausfälle von Trip4You-Maps.<br>
      Wir haften weder für Fehler in der Routenplanung, noch für Fehler in den OSM-Daten, auf denen die Routenplanung
      passiert.<br>
    </p>

  </ng-container>

  <!-- language en -->
  <ng-container *ngIf="languageCode!=='de'">

    <h1>Terms of use</h1>
    <p>
      This document describes the rules for using this app. By signing up to Trip4You-Maps and using its services you
      agree to the following terms and rules of usage.
    </p>

    <h3>How can you use Trip4You-Maps?</h3>
    <p>
      You can use all functions of Trip4You-Maps for free.<br>
    </p>

    <h3>What are you not allowed to do?</h3>
    <p>
      You must not use this app in any way that causes, or may cause any damage to the app or its users.<br>
      Trip4You-Maps reserves the right to delete or deactivate your account if you break the terms of use.
    </p>

    <h3>Your rights</h3>
    <p>
      You can delete all your personal information at any time.<br>
      If you want to remove your account completely and delete also your login data (name and email), you must send us
      an email to travelplanner4you@outlook.com
    </p>

    <h3>Our rights</h3>
    <p>
      We can change the terms of use from time to time.<br>
      We can restrict usage of this application for a person when we notice an improper or harmful usage.
    </p>

    <h3>Disclaimer</h3>
    <p>
      We are not liable for malfunctions or service failures of Trip4You Maps.<br>
      We are not liable for errors in route planning, nor for errors in the OSM data on which the route planning takes
      place.<br>
    </p>

  </ng-container>

</div>