<!-- about information-->
<div #aboutId style="margin:1.0rem;">

  <!-- language de -->
  <ng-container *ngIf="languageCode==='de'">
    <h1 style="margin-bottom:0.0rem;">Über Trip4YouMaps</h1>
    <div style="margin-bottom:1.0rem; font-size:large">die Outdoor-App für Biker und Wanderer</div>

    <h3>Wofür ist Trip4You Maps?</h3>
    <div style="font-size:medium;">
      <p>
        Trip4YouMaps ist eine moderne App für Routenplanung, Navigation und Tracking, speziell für Radfahrer und
        Wanderer geeignet.
        Die aktuelle Version {{releaseVersion}} ist vom {{releaseDate}}. <br>
        Mit Trip4You-Maps kann nach Orten, Adressen und anderen Objekten gesucht werden.
        Restaurants, Hotels, Lebensmittelgeschäfte und weitere interessante Punkte können auf der Karte angezeigt
        werden. Zusätzlich zur OSM-Karte stehen weitere Kartenarten zur Verfügung. Über die Basiskarte kann eine Karte
        mit Wanderwegen, Radwegen oder MTB-Wegen eingeblendet werden.
      </p>
    </div>

    <h3><img src="./assets/icons/directions.svg" alt="symbol">Routenplanung</h3>
    <div style="font-size:medium;">
      <p>Für die Routenplanung stehen unterschiedliche Bewegungsprofile wie Fahrrad, Elektrofahrrad, Rennrad, MTB, Zu
        Fuß Gehen, Wandern und Auto zur Verfügung.
        Durch einfaches Einfügen von Zwischenpunkten kann die Route exakt geplant werden. Bis zu 70 Wegpunkte sind
        möglich. Rund-Routen sind leicht zu realisieren.
        Bei jeder Änderung der Wegpunkte wird die Route neu berechnet und sofort angezeigt.
        Wegpunkte können mittels Klick auf die Karte, Adresssuche oder Wahl eines gespeicherten Ortes definiert werden.
        Eine geplante Route kann mit einem Klick umgedreht, mit anderen geteilt, am Gerät gespeichert oder als GPX-Datei
        exportiert werden.<br>
        Der Straßenbelag, die Straßenart oder die Steigung der Route kann mittels unterschiedlichen Farben im
        Höhenprofil und in der Karte dargestellt werden.<br>
        Die Anzeige der Routendetails enthält zusätzlich zu den üblichen Werten wie Länge, Dauer, Steigung und Gefälle
        das Höhenprofil, die Wegbeschaffenheit, die Straßenart und eine Schritt für Schritt Wegbeschreibung.
      </p>
    </div>

    <h3><img src="./assets/icons/directions.svg" alt="symbol">Navigation</h3>
    <div style="font-size:medium;">
      <p>Bei aktivierter Navigation wird die Karte automatisch verschoben, sodaß die aktuelle Position stets im Zentrum
        des Displays liegt. Dabei wird die Karte in die Bewegungsrichtung gedreht.<br>
        Bei Navigation entlang einer geplanten Route wird eine Schritt für Schritt Anweisung bezüglich aktuellem
        Standort angezeigt. Das Verlassen, sowie Wiedererreichen der Route wird in der Anweisung und auch akustisch
        angezeigt. Das automatische Verschieben und Drehen der Karte kann bei Bedarf in den Einstellungen ausgeschaltet
        werden.
      </p>
    </div>

    <!-- search -->
    <h3><img src="./assets/icons/magnify.svg" alt="symbol">Suche</h3>
    <div style="font-size:medium;">
      <p>Mit der Suchfunktion können Orte, Adressen, sowie beliebige Objekte (wie Markierungen, Gebäude usw.) weltweit
        gesucht werden.
        Als Suchbegriff werden ähnlich wie bei Google-Maps Ortsnamen, Adressen oder andere Kartenbezeichnungen
        verwendet.
        Die Suche erfolgt weltweit im Datenbestand von OpenStreetMap.
        Ist die Option 'Standort berücksichtigen' aktiviert, so werden die Suchergebnisse nach Entfernung zum aktuellen
        Standort geordnet.
        Die Suche kann eingeschränkt werden indem der alpha-3 Iso-3166 Ländercode mit Komma getrennt angegeben wird
        (z.B. 'AUT').
        Werden bei einem Suchvorgang mehrere Ergebnisse gefunden, kann man das gewünschte Ergebnis aus einer Liste
        auswählen.
      </p>
    </div>

    <!-- live-tracking -->
    <h3><img src="./assets/icons/access-point-check.svg" width="30px" alt="symbol">Standort teilen und Live-Tracking
    </h3>
    <div style="font-size:medium;">
      <p>Durch klick auf den Standort kannst du deinen Standort teilen oder Live-tracking starten.
        Beim Live-tracking kannst du einen key an Freunde oder Tour-Partner verschicken, die damit deine Route live
        verfolgen können.
        Dies kann deine Sicherheit während einer Tour erhöhen und das Wiederfinden nach einer Trennung erleichtern.
      </p>
    </div>

    <!-- markers -->
    <h3><img src="./assets/icons/flag-variant-outline.svg" alt="symbol">Markierungen</h3>
    <div style="font-size:medium;">
      <p>Auf der Karten können Markierungen (als Fahnen mit Bezeichnung) gesetzt werden.
        Die Markierungen können mit anderen geteilt werden, zum Beispiel um einen Treffpunkt zu vereinbaren.</p>
    </div>

    <!-- save routes and places -->
    <h3><img src="./assets/icons/bookmark-outline.svg" width="24px" alt="symbol">Speichern von Routen und Orten</h3>
    <div style="font-size:medium;">
      <p>Routen und Orte können am Gerät und in der Cloud gepeichert und in selbst erstellten Gruppen verwaltet werden.
        Gespeicherte Routen können jederzeit wieder aktiviert werden.</p>
    </div>

    <h3>Weitere Bedienungshinweise</h3>
    <div style="font-size:medium;">
      <p>
        Weitere Bedienungshinweise und Tipps findest du unter dem Register 'FAQ'.<br>
        Weitere Informationen findest du auch auf OpenStreetMap Wiki.<br>
        Eine Beschreibung der Funktionen von Trip4YouMaps kannst du unter <a
          href="https://wiki.openstreetmap.org/wiki/DE:Trip4YouMaps" target="_blank">Trip4YouMaps auf Wiki OSM</a>
        finden.<br>
        Einen guten Vergleich verschiedener Routenplanungs-Apps für Android findest du unter
        <a href="https://wiki.openstreetmap.org/wiki/DE:Android" target="_blank">Vergleich von Routenplanungs-Apps auf
          Wiki OSM</a>.<br>
      </p>
    </div>

    <h3>Ist die Nutzung gratis?</h3>
    <div style="font-size:medium;">
      <p>
        Die Nutzung von Trip4YouMaps ist komplett gratis. <br>
        Derzeit ist die App ohne Werbung. <br>
        Bei steigenden Serverkosten sind Werbung und/oder Affiliate-Links geplant.
      </p>
    </div>

    <h3>Nutzungsbedingungen, Datenschutz & Cookies?</h3>
    <div style="font-size:medium;">
      <p>
        Es werden keine Benutzerdaten weitergegeben.
        Cookies werden nur technisch bedingt und nur für eine angenehmere Bedienung der App eingesetzt.
        Das Nutzungsverhalten wird teilweise gespeichert, um die Weiterentwicklung von Trip4YouMaps zu planen.
        Mit der Nutzung von Trip4YouMaps erklärst du dich mit den Nutzungsbedingungen einverstanden.
        Einloggen ist für die Nutzung vieler Funktionen derzeit nicht notwendig. Für einige Funktionen, wie das Teilen
        deines Standortes musst du aber eingelogt sein.<br>
        Detailierte Hinweise findest du unter den Registern 'Nutzungsbedingungen' und 'Datenschutz'.
      </p>
    </div>

    <h3>Welche Karten nutzt Trip4YouMaps?</h3>
    <div style="font-size:medium;">
      <p>
        <span>Als Standard-Karte ist 'OpenStreetMap' (OSM) (© OpenStreetMap) aktiviert.</span><br>
        <span>Du kannst auch Karten von 'MapTiler' aktivieren.</span><br>
        <span>Die einblendbaren Wander- Rad- und MTB-Wege sind Karten von 'Waymarked Trails'.</span>
      </p>
    </div>

    <h3>Welchen Routenplaner nutzt Trip4YouMaps?</h3>
    <div style="font-size:medium;">
      <p>
        <span>Als Routenplaner wird ORS (OpenRouteService) der Universität Heidelberg genutzt.</span><br>
        <span></span>
      </p>
    </div>

  </ng-container>

  <!-- language en -->
  <ng-container *ngIf="languageCode!=='de'">
    <h1>About Trip4YouMaps</h1>

    <h3>What is Trip4You Maps for?</h3>
    <div style="font-size:medium;">
      <p>
        Trip4YouMaps is a modern app for route planning, navigation and tracking, specially designed for cyclists and
        suitable for hikers.
        The current version {{releaseVersion}} is from {{releaseDate}}. <br>
        With Trip4You-Maps you can search for places, addresses and other objects of interest.
        Restaurants, hotels, grocery stores and many other points of interest can be displayed on the map.
        In addition to the OSM map, other map types are available.
        A map with hiking trails, cycle paths or MTB trails can be displayed over the base map.
      </p>
    </div>

    <h3><img src="./assets/icons/directions.svg" alt="symbol">Route planning</h3>
    <div style="font-size:medium;">
      <p>
        Different moving profiles are available for route planning, such as bicycle, electric bicycle, racing bike,
        MTB, walking, hiking and car.<br>
        By simply inserting intermediate points, the route can be planned exactly, there are up to 70 waypoints
        possible. Circular routes are easy to implement.
        Each time the waypoints are changed, the route is recalculated and displayed immediately.
        Waypoints can be defined by clicking on the map, searching for an address or selecting a saved location.
        A planned route can be reversed with one click, shared with others, saved on the device or as a GPX file
        be exported.
        Road surface, road type and uphill/downhill gradients of the route can be displayed using different colors
        both in the elevation profile and on the map.
        In addition to the usual values, such as length, ascent and descent, the route details include also the
        elevation profile and a step-by-step description of the route.
      </p>
    </div>

    <h3><img src="./assets/icons/directions.svg" alt="symbol">Navigation</h3>
    <div style="font-size:medium;">
      <p>
        When navigation is activated, the map is automatically shifted so that the current position is always in the
        center of the display. The map is rotated in the direction of movement. When navigating along a planned route,
        step-by-step instructions regarding current location displayed.
        The automatic panning and rotating of the map can be switched off in the settings if required.
      </p>
    </div>

    <!-- search -->
    <h3><img src="./assets/icons/magnify.svg" alt="symbol">Search</h3>
    <div style="font-size:medium;">
      <p>The search function can be used to search for locations, addresses and any map designation anywhere in the
        world.
        Similar to Google Maps, place names, addresses or other map names are used as search terms use.
        The search is carried out worldwide in the OpenStreetMap database.
        The search can be restricted by entering the alpha-3 Iso-3166 country code separated by commas
        (e.g. 'Salzburg, AUT').
        If several results are found during a search process, the desired result must be from a list
        to be selected.
      </p>
    </div>

    <!-- live-tracking -->
    <h3><img src="./assets/icons/access-point-check.svg" width="30px" alt="symbol">Share location and live
      tracking</h3>
    <div style="font-size:medium;">
      <p>By clicking on the location you can share your location or start live tracking.
        With live tracking, you can send a key to friends or tour partners who can use it to follow your route live.
        This can increase your safety during a tour and make it easier to find you again after a breakup.
      </p>
    </div>

    <!-- markers -->
    <h3><img src="./assets/icons/flag-variant-outline.svg" alt="symbol">Markers</h3>
    <div style="font-size:medium;">
      <p>Markers (as flags with names) can be set on the map.
        The markers can be shared with others, for example to arrange a meeting point.</p>
    </div>

    <!-- save routes and places -->
    <h3><img src="./assets/icons/bookmark-outline.svg" width="24px" alt="symbol">Saving routes and locations</h3>
    <div style="font-size:medium;">
      <p>Routes and Locations (places) can be saved on the device and in the cloud. The routes can be managed in
        self-created lists. Saved routes can be reactivated at any time.</p>
    </div>

    <h3>More operating instructions</h3>
    <div style="font-size:medium;">
      <p>
        Further operating instructions and tips can be found under the 'FAQ' tab.<br>
        You can also find more information on the OpenStreetMap Wiki.<br>
        You can find a description of the functions of Trip4YouMaps under
        <a href="https://wiki.openstreetmap.org/wiki/Trip4YouMaps" target="_blank">Trip4YouMaps on OSM Wiki</a><br>
        You can find a good comparison of different route planning apps for Android under
        <a href="https://wiki.openstreetmap.org/wiki/Comparison_of_Android_applications" target="_blank">Comparison of
          Android applications on
          OSM-Wiki</a>.<br>
        You can find a good comparison of different route planning apps for IOS under
        <a href="https://wiki.openstreetmap.org/wiki/Comparison_of_iOS_applications" target="_blank">Comparison of IOS
          applications on OSM-Wiki</a>.
      </p>
    </div>
    <h3>Is the usage free?</h3>
    <div style="font-size:medium;">
      <p>
        Trip4YouMaps is completely free to use. <br>
        Currently the app is ad-free. <br>
        With increasing server costs, advertising and/or affiliate links are planned.
      </p>
    </div>

    <h3>Terms of use, data protection & cookies?</h3>
    <div style="font-size:medium;">
      <p>
        No user data will be passed on.<br>
        Cookies are only used for technical reasons and only to make the app easier to use.
        The usage behavior is partially saved in order to plan the further development of Trip4YouMaps.
        With the use of of Trip4YouMaps you agree to the terms of use.
        To use the most functions it is currently not necessary to log in. Special functions, like share your location
        with friends need to be logged in.
        You can find detailed information under the tabs 'Terms of Use' and 'Privacy Policy'. </p>
    </div>

    <h3>Which map does Trip4YouMaps use?</h3>
    <div style="font-size:medium;">
      <p>
        <span>As the default map we use the 'OpenStreetMap' (OSM) (© OpenStreetMap).</span><br>
        <span>As map-source you can select different maps from 'MapTiler' as well.</span><br>
        <span>The hiking, cycling and MTB trails that can be displayed are maps of 'Waymarked Trails'.</span>
      </p>
    </div>

    <h3>Which route planner does Trip4YouMaps use?</h3>
    <div style="font-size:medium;">
      <p>
        <span>Trip4You-Maps uses the ORS (OpenRouteService) from the University of Heidelberg.</span><br>
        <span></span>
      </p>
    </div>

  </ng-container>

</div>