
// regions
export function getAvailableRegions() {
    // const regionIds = [1340, 1341, 1342, 1343, 1344, 1345, 1346, 1347, 1348, 53, 68, 70, 77, 57, 54, 87, 88, 90];
    const regionIds = [70, 88, 57, 90, 68, 87, 77, 54, 53, 1348, 1347, 1346, 1345, 1344, 1343, 1342, 1341, 1340, 74, 1961, 1960, 78, 2000];
    return regionIds;
}
export function getAvailableRegionsDevelop() {
    // const regionIds = [1421, 1422, 1423, 1424, 1425, 1426, 1427, 1428, 1429, 53, 68, 70, 77, 57, 54, 87, 88, 90];
    const regionIds = [70, 88, 57, 90, 68, 87, 77, 54, 53, 1429, 1428, 1427, 1426, 1425, 1424, 1423, 1422, 1421];
    return regionIds;
}
