<!-- edit waypoints -->

<!-- table -->
<div cdkDropList class="drop-list" (cdkDropListDropped)="onDrop($event)">

  <!-- waypoints -->
  <div *ngFor=" let xWp of this.wayPoints; let i=index" cdkDrag class="drag-box">

    <div class="drag-handle" cdkDragHandle>
      <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
        </path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </div>

    <div *ngIf="!isVisibleSearchDialog || isVisibleSearchDialog && i!==selectedWayPointIndex"
      style="margin-top:0.5rem;">
      <!-- point-marker -->
      <!-- <span *ngIf="i===selectedWayPointIndex" style="margin-left:0.7rem;color:red;font-size:large;">> </span> -->
      <span *ngIf="i<9" style="margin-left:0.7rem; font-size:large;">{{i+1}}: </span>
      <span *ngIf="i>=9" style="margin-left:0.0rem;font-size:large;">{{i+1}}: </span>

      <!-- point-name -->
      <input type="text" style="max-width:300px; height:30px; border-radius:10px; font-size:large;" spellcheck="false"
        placeholder="{{'MAPS.RPL.WPDLG.WP-INPUT_PLH' | translate}}" [(ngModel)]="xWp.name"
        (input)="onWayPointInputChange($event,i)" (click)="onWayPointInputClick(i)"
        (keyup)="onWayPointNameKeyUp($event,i)" />

      <!-- menu -->
      <button mat-mini-fab [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" style="margin-left:0.5rem;"
        color="accent" aria-label="Icon button with wapoint menu" title="{{'MAPS.GEN.MENU_ICON_T' | translate}}">
        <mat-icon svgIcon="menu"></mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button *ngIf="currentLocation" mat-menu-item (click)="onSetWayPointToCurrentPositionClick(i)">
          <img width="20px" src="./assets/icons/crosshairs-gps.svg">
          {{'MAPS.RPL.WPDLG.CURRENT-POS_BUTT_TEXT' | translate}}</button>
        <button mat-menu-item (click)="onSetWayPointMapPositionClick(i)">
          <img width="20px" src="./assets/icons/map-marker.svg">
          {{'MAPS.RPL.WPDLG.MAP-POS_BUTT_TEXT' | translate}}</button>
        <button mat-menu-item (click)="onSetWayPointSearchClick(i)">
          <img width="20px" src="./assets/icons/magnify.svg">
          {{'MAPS.RPL.WPDLG.SEARCH_BUTT_TEXT' | translate}}</button>
        <button *ngIf="savedPlaces.length>0" mat-menu-item (click)="onSelectFromSavedPlacesClick(i)">
          <img width="20px" src="./assets/icons/star-outline.svg">
          {{'MAPS.RPL.WPDLG.SELECT-FROM-FAV_BUTT_TEXT' | translate}}</button>
        <button *ngIf="true" mat-menu-item (click)="onRemoveWayPointClick(i)">
          <img width="20px" src="./assets/icons/delete.svg">
          {{'MAPS.RPL.WPDLG.DEL-WP_BUTT_TEXT' | translate}}</button>
        <button *ngIf="wayPoints.length < 70" mat-menu-item (click)="onAddNewWayPointClick(i)">
          <img width="20px" src="./assets/icons/map-marker-plus.svg">
          {{'MAPS.RPL.WPDLG.ADD-NEW-WP_BUTT_TEXT' | translate}}</button>
      </mat-menu>
    </div>

    <!-- new search-Dialog - small dev-->
    <div *ngIf="isVisibleSearchDialog && i===selectedWayPointIndex" style="margin-top:0.5rem; margin-bottom:0.5rem;">
      <app-search-place1-component [languageCode]="languageCode" [uiType]="'SS'" [searchParameter]="searchParameter"
        [createPointOnMapActive]="isSetMapPositionActive" [currentLocation]="currentLocation" [map]="map"
        [sessionId]="sessionId" [savedPlaces]="savedPlaces" [(foundPlaces)]="foundRefPlaces" (back)="onSearchBack()"
        (selectedPoint)="onSelectSearchResult($event)" (createPointOnMap)="onCreateSearchPointOnMap()">
      </app-search-place1-component>
    </div>

    <!-- select from saved places -->
    <div *ngIf="isVisibleSavedPlaces && i===selectedWayPointIndex">
      <app-saved-place-selection-component [savedPlaces]="savedPlaces" (selectedPlace)="onSelectSavedPlace($event)">
      </app-saved-place-selection-component>
    </div>

    <!-- define point info -->
    <div *ngIf="!xWp.coordLon" style="margin-left:3.0rem;">
      <!-- click on map to set the position -->
      <span *ngIf="!isSetMapPositionActive && !isVisibleSearchDialog" style="color:orange;"
        (click)="onSelectFromMenuClick(i)">{{'MAPS.RPL.WPDLG.WP-SELECT-METHOD_MSG' | translate}}</span>
      <!-- select method of click on map to set the position -->
      <span *ngIf="isSetMapPositionActive && i===selectedWayPointIndex" style="color:orange;"
        (click)="onSelectFromMenuClick(i)">{{'MAPS.RPL.WPDLG.WP-SELECT-METHOD-OR-CLICK_MSG' | translate}}</span>
    </div>
    <div *ngIf="xWp.coordLon" style="margin-left:3.0rem;">
      <!-- click on map to set the position -->
      <span *ngIf="isSetMapPositionActive && i===selectedWayPointIndex"
        style="color:orange;">{{'MAPS.RPL.WPDLG.WP-CLICK_MSG' | translate}}</span>
    </div>

    <div style="margin-top:0.2rem;"></div>
  </div>

  <!-- back-to-start -->
  <mat-slide-toggle *ngIf="!isRoundRoute" color="primary" style="margin-top:0.5rem; margin-left:2.0rem;"
    [(ngModel)]="backToStart" (change)="onBackToStartChange()">{{'MAPS.RPL.DLG.BACK-TO-START' | translate}}
  </mat-slide-toggle>

</div>