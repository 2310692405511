
export function showLocalNotificationShareLocation(component: any, swRegistration: any, swPush: any, userLanguage: string) {

  let title = "Trip4You-Maps has new features";
  let body = "By click on the locator icon you can share your current location or send your friends a key for live tracking.";
  if (userLanguage === "de") {
    title = "Trip4You-Maps hat neue Funktionen";
    body = "Durch klick auf das Standortsymbol kannst du deinen Standort teilen oder deinen Freunden einen key für Live-Tracking senden.";
  }
  let infoTitle = "Read more ...";
  if (userLanguage === "de") { infoTitle = "Mehr darüber ..."; }
  const options = {
    body,
    // here you can add more properties like icon, image, vibrate, etc.
    icon: "/assets/icons_logo/icon-192x192.png",
    actions: [
      {
        action: "info",
        title: infoTitle,
        icon: "/assets/icons/information_variant.png",
        // type: "button",
      },
      {
        action: "ok",
        title: "Ok",
        // icon: "/assets/icons_logo/maskable_icon_x48.png",
        // type: "button",
      },
      {
        action: "later",
        title: "Later",
        // icon: "/assets/icons_logo/maskable_icon_x48.png",
        // type: "button",
      },
    ],
    default:
    {
      action: "later"
    }
  };
  swRegistration.showNotification(title, options);
  swPush.notificationClicks.subscribe(event => {
    if (event.action === "info") {
      console.log("info clicked");
      component.notificationActionShowInfo("share-loc");
    } else if (event.action === "ok") {
      console.log("ok clicked");
      component.notifcationActionOk("share-loc");
    } else if (event.action === "later") {
      console.log("later clicked");
      component.notifcationActionLater("share-loc");
    }
  });
}
