import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef } from "@angular/core";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

import Map from "ol/Map";

import { LngLat } from "src/app/models/lnglat";
import { MapPlaceL } from "src/app/models/mapplace";
import { PRoute } from "src/app/models/p-route";
import { SavedPlaceCategory } from "src/app/models/saved-item-categories";
import { SearchFilter } from "src/app/models/search-parameter";
import { Track, TrackPoint } from "src/app/models/track";
import { convertTrackToGPXData } from "src/app/utils/utils-track";
import { getSteepnessColor, getSteepnessName, getSurfaceColor, getSurfaceName, getWaytypeColor, getWaytypeName } from "../show-details/show-route/show-route-utils";
import { EnumAdminPriv, User } from "src/dto.generated/api";
// import { GlobalService } from "src/app/services/global.service";
import { getRouteDurationCorrectionFactor } from "../maps-utils/utils-route";
import { TourData } from "src/app/models/tour-data";

@Component({
  selector: "app-route-planner-component",
  templateUrl: "./route-planner.component.html",
  styleUrls: ["./route-planner.component.scss"]
})
export class RoutePlannerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() loggedInUser: User;
  @Input() languageCode: string;
  @Input() uiType: string;
  @Input() sessionId: string;
  @Input() map: Map;
  @Input() currentLocation: LngLat;
  @Input() debugLog: string[];


  @Input() currentRoute: PRoute;
  @Input() isCurrentRouteIdPublished: boolean;
  @Input() isCurrentRoutePublic: boolean;
  @Input() geojsonRoute: any; // because from currentRoute it is undefined
  @Input() drawRouteStyle: string;
  @Input() steepnessExtra: any;

  @Input() selectedWayPointIndex: number;      // to select way-point from outside (onMapClick)
  @Input() wayPointPositionOnMap: LngLat;      // clicked and confirmed map position for waypoint

  @Input() searchParameter: SearchFilter;
  @Input() foundPlaces: MapPlaceL[];
  @Input() savedPlaces: SavedPlaceCategory[];

  @Input() isTourStarted: boolean;
  @Input() tourData: TourData;

  @Input() orsPostErrorText: string;


  @Output() back: EventEmitter<void> = new EventEmitter();

  @Output() moveTypeChange: EventEmitter<string> = new EventEmitter(); // -
  @Output() backToStartChange: EventEmitter<boolean> = new EventEmitter(); // -
  @Output() avoidTollwaysChange: EventEmitter<boolean> = new EventEmitter(); // -
  @Output() avoidHighwaysChange: EventEmitter<boolean> = new EventEmitter(); // -

  @Output() createPointOnMap: EventEmitter<void> = new EventEmitter();
  @Output() selectedWayPointForMapChange: EventEmitter<number> = new EventEmitter();

  @Output() redrawWayPoints: EventEmitter<void> = new EventEmitter();
  @Output() recalculateRoute: EventEmitter<boolean> = new EventEmitter();
  @Output() updateRouteToUrl: EventEmitter<boolean> = new EventEmitter();

  @Output() startNavigation: EventEmitter<void> = new EventEmitter();
  @Output() showRouteDetails: EventEmitter<void> = new EventEmitter();
  @Output() zoomToRoute: EventEmitter<void> = new EventEmitter();
  @Output() saveRouteLocaly: EventEmitter<void> = new EventEmitter();
  @Output() saveRouteCloud: EventEmitter<void> = new EventEmitter();
  @Output() publishRoute: EventEmitter<void> = new EventEmitter();
  @Output() publicRouteUpdate: EventEmitter<void> = new EventEmitter();
  @Output() shareRoute: EventEmitter<void> = new EventEmitter();
  @Output() saveRouteAsGpx: EventEmitter<void> = new EventEmitter();


  @Output() foundPlacesChange: EventEmitter<MapPlaceL[]> = new EventEmitter();
  @Output() drawRouteStyleChange: EventEmitter<string> = new EventEmitter();

  @Output() clearRoute: EventEmitter<boolean> = new EventEmitter();
  @Output() reverseRoute: EventEmitter<void> = new EventEmitter();

  public isTestUser: boolean;
  public isUserRouteAdmin: boolean;

  public isMoveTypeBike; boolean;
  public routeWayPoints: MapPlaceL[];
  public isRoundRoute: boolean;
  public roundRouteLength: number;
  public roundRoutePoints: number;
  public roundRouteSeed: number;

  public routeDistance: number;   // m
  public routeDuration: number;   // sec
  public routeDurationHour: number;
  public routeDurationMinute: number;

  public surfaceSummary: any;
  public waytypeSummary: any;
  public steepnessSummary: any;

  public fileUrlRoute: SafeValue;
  public countWayPoints: number;

  public timer: any;

  @ViewChild("saveRouteIcon1") saveRouteButtonIcon1: ElementRef;
  @ViewChild("saveRouteIcon2") saveRouteButtonIcon2: ElementRef;
  @ViewChild("nameInput") nameInput: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    // private globalService: GlobalService,
    private translate: TranslateService,
  ) {
  }

  public async ngOnInit() {
    // console.log("RP:ngOnInit");
    // console.log("RP:ngOnInit-isCurrentRouteIdPublic", this.isCurrentRoutePublic);
    // console.log("RP:ngOnInit-isCurrentRouteIdPublished", this.isCurrentRouteIdPublished);

    // console.log("RP:ngOnInit-loggedInUser", this.loggedInUser);
    if (this.loggedInUser) {
      if (this.loggedInUser.mapPriv >= EnumAdminPriv.PrivLevel2) { this.isUserRouteAdmin = true; }
      // console.log("RP:ngOnInit-isUserRouteAdmin", this.isUserRouteAdmin);
      if (this.loggedInUser.id === 1) { this.isTestUser = true; }
    }
  }

  public ngAfterViewInit() {
    // console.log("RP:ngAfterViewInit");
  }
  public async ngOnChanges(changes: SimpleChanges) {
    // console.log("RP-changes", changes);

    // currentRoute changed
    if (changes.currentRoute) {
      // console.log("RP:onChanges-currentRoute-changed", this.currentRoute);
      this.onCurrentRouteChanged();
    }

    if (changes.geojsonRoute) {
      // console.log("RP:onChanges-geojsonRoute-changed", this.geojsonRoute);
      this.onCurrentRouteChanged();
    }

    // drawRouteStyle changed
    if (changes.drawRouteStyle) {
      // console.log("RP:onChanges-drawRouteStyle-changed", this.drawRouteStyle);
      // steepness-summaries
      if (this.drawRouteStyle === "steepness") {
        const feature = this.geojsonRoute.features[0];
        if (feature.properties.extras.steepness) { this.steepnessSummary = feature.properties.extras.steepness.summary; }
      }
    }
    // selectedWayPointIndex changed
    if (changes.selectedWayPointIndex) {
      // console.log("RP:onChanges-selectedWayPointIndex-changed", this.selectedWayPointIndex);
    }
    // wayPointPositionOnMap changed
    if (changes.wayPointPositionOnMap) {
      // console.log("RP:onChanges-wayPointPositionOnMap-changed", this.wayPointPositionOnMap);
    }
    if (changes.steepnessExtra) {
      // console.log("RP:onChanges-steepnessExtra-changed", this.steepnessExtra);
      this.steepnessSummary = undefined;
      if (this.steepnessExtra) { this.steepnessSummary = this.steepnessExtra.summary; }
    }
  }
  public ngOnDestroy(): void {
  }

  private onCurrentRouteChanged() {
    // console.log("RP:onCurrentRouteChanged-currentRoute", this.currentRoute);
    this.isMoveTypeBike = false;
    if (this.currentRoute.plannerOptions.moveType === "bike") { this.isMoveTypeBike = true; }
    if (this.currentRoute.plannerOptions.moveType === "ebike") {
      this.isMoveTypeBike = true;
      if (this.currentRoute.plannerOptions.avoidSteps === undefined) { this.currentRoute.plannerOptions.avoidSteps = true; }
    }
    if (this.currentRoute.plannerOptions.moveType === "bike-road") { this.isMoveTypeBike = true; }
    if (this.currentRoute.plannerOptions.moveType === "mtb") { this.isMoveTypeBike = true; }
    this.routeWayPoints = this.currentRoute.wayPoints;
    // this.geojsonRoute = this.currentRoute.geojsonRoute;
    // console.log("RP:onCurrentRouteChanged-geojsonRoute", this.geojsonRoute);
    this.countWayPoints = 0;
    for (const wp of this.routeWayPoints) {
      if (wp.coordLon) { this.countWayPoints++; }
    }
    if (this.geojsonRoute) {
      const feature = this.geojsonRoute.features[0];
      this.routeDistance = Math.round(feature.properties.summary.distance);
      this.routeDuration = Math.round(feature.properties.summary.duration);
      const corrFactor = getRouteDurationCorrectionFactor(this.currentRoute.plannerOptions.moveType);
      this.routeDurationHour = Math.floor(this.routeDuration * corrFactor / 3600);
      this.routeDurationMinute = Math.floor(this.routeDuration * corrFactor / 60 - this.routeDurationHour * 60);
      const currentTime = new Date(Date.now());
      // surface-summaries
      this.surfaceSummary = undefined;
      if (feature.properties.extras.surface) { this.surfaceSummary = feature.properties.extras.surface.summary; }
      // waytype-summaries
      this.waytypeSummary = undefined;
      if (feature.properties.extras.waytypes) { this.waytypeSummary = feature.properties.extras.waytypes.summary; }
      // steepness-summaries
      this.steepnessSummary = undefined;
      if (feature.properties.extras.steepness) { this.steepnessSummary = feature.properties.extras.steepness.summary; }
      // round-route-options
      this.isRoundRoute = this.currentRoute.roundRoute;
      this.roundRouteLength = this.currentRoute.plannerOptions.roundRouteLength / 1000;
      this.roundRoutePoints = this.currentRoute.plannerOptions.roundRoutePoints;
      this.roundRouteSeed = this.currentRoute.plannerOptions.roundRouteSeed;
    }
    setTimeout(() => { this.setDefault(); }, 1000);
  }

  private setDefault() {
  }

  public onRoutePlannerBackClick() {
    if (this.uiType === "L") {
      this.selectedWayPointForMapChange.emit(-1);
    }
    this.back.emit();
  }

  public onSetRoutePlannerMoveType(moveType: string) {
    this.isMoveTypeBike = false;
    if (moveType === "bike" || moveType === "ebike" || moveType === "bike-road" || moveType === "mtb") {
      if (this.currentRoute.plannerOptions.roundRouteLength < 20000) {
        this.currentRoute.plannerOptions.roundRouteLength = 50000;
      }
      this.isMoveTypeBike = true;
    }
    if (moveType === "walking" || moveType === "hikinging") {
      if (this.currentRoute.plannerOptions.roundRouteLength > 25000) {
        this.currentRoute.plannerOptions.roundRouteLength = 10000;
      }
    }
    this.moveTypeChange.emit(moveType);
  }
  public onBackToStartChange(backToStart: boolean) {
    this.currentRoute.backToStart = backToStart;
    this.backToStartChange.emit(backToStart);
  }

  public onSelectedWayPointForMapChange(index: number) {
    // console.log("RP:onSelectedWayPointForMapChange-index", index);
    this.selectedWayPointForMapChange.emit(index);
  }

  public onRedrawWayPoints() {
    this.redrawWayPoints.emit();
  }

  public onRecalculateRoute() {
    // console.log("RP:onRecalculateRoute");
    this.recalculateRoute.emit(true);
  }

  public onUpdateUrl() {
    this.updateRouteToUrl.emit();
  }

  public onAvoidStepsChange() {
    this.recalculateRoute.emit(false);
  }
  public onAvoidTollwaysChange() {
    this.recalculateRoute.emit(false);
  }

  public onAvoidHighwaysChange() {
    // this.avoidHighwaysChange.emit(this.currentRoute.plannerOptions.avoidHighways);
    this.recalculateRoute.emit(false);
  }

  public onDrawRouteSurfaceChange(style: string) {
    this.drawRouteStyle = style;
    this.drawRouteStyleChange.emit(style);
  }

  public async onRouteNameKeyUp(event: KeyboardEvent) {
    console.log("RP:onRouteNameKeyUp", event);
    if (event.key === "Enter") {
      this.nameInput.nativeElement.blur();
      this.setRouteLanguageName();
      this.updateRouteToUrl.emit(false);
      return;
    }
    if (event.key === "Escape") {
      return;
    }
  }
  public onRouteNameBlur() {
    console.log("RP:onRouteNameBlur-routeName", this.currentRoute.name);
    this.setRouteLanguageName();
    this.updateRouteToUrl.emit(true);
  }

  public onClearRouteClick() {
    // console.log("RP:onClearRouteClick");
    this.isRoundRoute = false;
    this.isCurrentRoutePublic = false;
    this.clearRoute.emit(true);
  }
  public onReverseRouteClick() {
    this.reverseRoute.emit();
  }
  public onStartNavigationClick() {
    this.startNavigation.emit();
  }
  public onPlanRouteToStartPointTrip4YouMapsClick() {
    let url = "https://maps.trip4you.net/param?ver=2.0";
    const routeIdUrl = "&id=";
    const moveTypeUrl = "&movetype=car";
    let startName = "Current location";
    if (this.languageCode == "de") { startName = "Altueller Standort"; }
    let startUrl = "&start=" + this.currentLocation.lng.toString(7) + "|" + this.currentLocation.lat.toString(7) + "|" + startName;
    const wpUrl = "&wp=";
    const wpTo = this.currentRoute.wayPoints[0];
    const endUrl = "&end=" + wpTo.coordLon.toString(7) + "|" + wpTo.coordLat.toString(7) + "|" + wpTo.name;
    url += routeIdUrl + moveTypeUrl + startUrl + wpUrl + endUrl;
    window.open(url, "_blank");
  }
  public onPlanRouteToStartPointGoogleClick() {
    let uri = "https://www.google.com/maps/dir/?api=1";
    const coordsFrom = "&origin=" + this.currentLocation.lat.toString() + "," + this.currentLocation.lng.toString();
    const wpTo = this.currentRoute.wayPoints[0];
    const coordsTo = "&destination=" + wpTo.coordLat.toString() + "," + wpTo.coordLon.toString();
    uri += coordsFrom + coordsTo;
    const encodedUri = encodeURI(uri);
    // console.log("RP:onPlanRouteToStartPointGoogleClick-uri", encodedUri);
    // this.debugLog.push("RP-url:", encodedUri);
    window.open(encodedUri, "_blank");
  }
  public onShowRouteDetailsClick() {
    this.showRouteDetails.emit();
  }
  public onZoomToRouteClick() {
    this.zoomToRoute.emit();
  }
  public onSaveRouteLocalyClick() {
    const icon = this.saveRouteButtonIcon1;
    // console.log("RP:onSaveRouteLocalyClick-currentRoute", this.currentRoute);
    icon.nativeElement.style.backgroundColor = "lightgreen";
    setTimeout(() => {
      icon.nativeElement.style.backgroundColor = "";
    }, 1000);
    if (!this.currentRoute.name) { this.createDefaultRouteName(); }
    this.setRouteLanguageName();
    this.saveRouteLocaly.emit();
  }
  public onSaveRouteCloudClick() {
    // console.log("RP:onSaveRouteCloudClick-currentRoute", this.currentRoute);
    const icon = this.saveRouteButtonIcon2;
    icon.nativeElement.style.backgroundColor = "lightgreen";
    setTimeout(() => {
      icon.nativeElement.style.backgroundColor = "";
    }, 1000);
    if (!this.currentRoute.name) { this.createDefaultRouteName(); }
    this.setRouteLanguageName();
    this.saveRouteCloud.emit();
  }
  public onShareRouteClick() {
    this.setRouteLanguageName();
    this.shareRoute.emit();
  }
  public onSaveRouteAsGpxClick() {
    this.downloadRouteAsGpx();
  }
  public onPublishRouteClick() {
    // console.log("RP:onPublishRouteClick-currentRoute", this.currentRoute);
    this.setRouteLanguageName();
    this.publishRoute.emit();
  }
  public onPublicRouteUpdateClick() {
    this.setRouteLanguageName();
    this.publicRouteUpdate.emit();
  }

  private createDefaultRouteName() {
    this.currentRoute.name = "my_route";
    if (this.languageCode === "de") { this.currentRoute.name = "Meine_Route"; }
  }
  private setRouteLanguageName() {
    if (this.languageCode === "de" && !this.currentRoute.nameEn) { this.currentRoute.nameDe = this.currentRoute.name; }
    if (this.languageCode === "en" && !this.currentRoute.nameDe) { this.currentRoute.nameEn = this.currentRoute.name; }
  }

  private async downloadRouteAsGpx() {
    // console.log("RP:downloadRouteAsGpx");
    // const user = await this.tripService.getLoggedInUser();
    // console.log("Maps:downloadRouteAsGpx-user", user);
    // const user = this.loggedInUser;
    const routeTrack = this.convertORSRouteToGPXRoute(this.geojsonRoute);
    // console.log("Maps:downloadRouteAsGpx-track", track);
    routeTrack.name = this.currentRoute.name;
    // if (user) { routeTrack.author = user.name; }
    const data = convertTrackToGPXData(routeTrack);
    // console.log("Maps:downloadRouteAsGpx-data", data);
    const blob = new Blob([data], { type: "application/octet-stream" });
    console.log("Maps:downloadRouteAsGpx-blob", blob);
    this.fileUrlRoute = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  // utils - GPX-Route
  private convertORSRouteToGPXRoute(ORSRoute) {
    const track = {} as Track;
    track.name = "Trip4You-route";
    if (this.currentRoute.name) { track.name = this.currentRoute.name; }
    track.type = ORSRoute.metadata.query.profile;
    track.author = "Trip4You/maps - anonymous user";
    const feature = ORSRoute.features[0];
    track.trackPoints = new Array<TrackPoint>();
    for (const wp of feature.geometry.coordinates) {
      const tp = {} as TrackPoint;
      tp.order = feature.geometry.coordinates.indexOf(wp);
      tp.lng = wp[0];
      tp.lat = wp[1];
      tp.ele = wp[2];
      track.trackPoints.push(tp);
    }
    return track;
  }

  public async onRoundRouteLengthKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter") {
      // console.log("RP:onRoundRouteLengthKeyUp-roundRouteLength", this.roundRouteLength);
      this.currentRoute.plannerOptions.roundRouteLength = this.roundRouteLength * 1000;
      return;
    }
    if (event.key === "Escape") {
      this.roundRouteLength = this.currentRoute.plannerOptions.roundRouteLength / 1000;
      return;
    }
  }
  public async onRoundRoutePointsKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter") {
      // console.log("RP:onRoundRoutePointsKeyUp-roundRoutePoints", this.roundRoutePoints);
      this.currentRoute.plannerOptions.roundRoutePoints = this.roundRoutePoints;
      return;
    }
    if (event.key === "Escape") {
      this.roundRoutePoints = this.currentRoute.plannerOptions.roundRoutePoints;
      return;
    }
  }
  public async onRoundRouteSeedKeyUp(event: KeyboardEvent) {
    if (event.key === "Enter") {
      // console.log("RP:onRoundRouteSeedKeyUp-roundRouteSeed", this.roundRouteSeed);
      this.currentRoute.plannerOptions.roundRouteSeed = this.roundRouteSeed;
      return;
    }
    if (event.key === "Escape") {
      this.roundRouteSeed = this.currentRoute.plannerOptions.roundRouteSeed;
      return;
    }
  }
  public onRecalculateRouteClick() {
    this.currentRoute.plannerOptions.roundRouteLength = this.roundRouteLength * 1000;
    this.currentRoute.plannerOptions.roundRoutePoints = this.roundRoutePoints;
    this.currentRoute.plannerOptions.roundRouteSeed = this.roundRouteSeed;
    this.recalculateRoute.emit(true);
  }

  private sortSteepnessByValue() {
    if (!this.steepnessSummary) { return; }
    this.steepnessSummary = this.steepnessSummary.sort((n1, n2) => {
      if (n1.value > n2.value) { return -1; }
      if (n1.value < n2.value) { return 1; }
    });
  }

  private async createTourDataTimer_old() {
    // console.log("RoutePlanner:createTimer");
    this.timer = setInterval(() => {
      if (this.tourData.tourStartTime) {
        // this.simulateLocationChange();
      }
      if (this.tourData.tourEndTime) {
        clearInterval(this.timer);
      }
    }, 1000);
  }

  private simulateLocationChange() {
    const deltaX = this.getRandomInt(50) * 0.1;
    const deltaY = this.getRandomInt(50) * 0.1;
    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    let timeInMotion = 0;
    const time = 1;
    const velocity = distance / time;
    const minVelocity = 0.5;
    if (velocity > minVelocity) { timeInMotion += time; }
    const ascent = this.getRandomInt(5) * 0.1
    const descent = this.getRandomInt(5) * 0.1
    this.tourData.timeInMotion += timeInMotion;
    this.tourData.timeInMotionHours = Math.floor(this.tourData.timeInMotion / 3600);
    this.tourData.timeInMotionMinutes = Math.floor(this.tourData.timeInMotion / 60) - this.tourData.doneDurationHours * 60;
    this.tourData.doneDistance += distance;
    this.tourData.doneAscents += ascent;
    this.tourData.doneDescents += descent;
    this.tourData.averageVelocity = this.tourData.doneDistance / this.tourData.doneDuration * 3.6;
  }
  public getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }




  public getSurfaceName(id: number) {
    return getSurfaceName(id, this.languageCode);
  }
  public getSurfaceColor(id: number) {
    return getSurfaceColor(id);
  }
  public getWaytypeName(id: number) {
    return getWaytypeName(id, this.languageCode);
  }
  public getWaytypeColor(id: number) {
    return getWaytypeColor(id);
  }
  public getSteepnessName(id: number) {
    return getSteepnessName(id, this.languageCode);
  }
  public getSteepnessColor(id: number) {
    return getSteepnessColor(id);
  }

}
