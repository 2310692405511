<!-- select from saved places -->
<div *ngIf="savedPlaces"
    style="margin:0.5rem; border:3px solid mediumslateblue; border-radius:10px; padding:0.5rem; background:whitesmoke">
    <span style="font-size:large;">{{'MAPS.SRCH.DLG.SEL-FROM-FAV_MSG' | translate}}</span><br>

    <!-- lists -->
    <div *ngFor="let cat of savedPlaces" style="margin-top:0.5rem;">
        <span style="font-size:large">- {{cat.name}}</span>
        <span *ngIf="cat.placeItems.length>0 && !cat.open" (click)="cat.open=!cat.open"><img
                src="./assets/icons/chevron-down.svg"></span>
        <span *ngIf="cat.placeItems.length>0 && cat.open" (click)="cat.open=!cat.open">
            <img src="./assets/icons/chevron-up.svg"></span>
        <!-- places -->
        <ng-container *ngIf="cat.open">
            <div *ngFor="let place of cat.placeItems" (click)="onSelectPlace(place)"
                style="cursor:pointer; margin:0.5rem; margin-right:0.5rem; background-color:cornsilk; padding:0.5rem; font-size:medium; border-radius:12px;">
                <span>{{place.name}}</span>
                <span *ngIf="place.label" style="margin-left:1.0rem;font-size:smaller;">{{place.label}}</span>
            </div>
        </ng-container>
    </div>

</div>