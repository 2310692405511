import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { getUserLanguage } from "src/app/utils/utils";


@Component({
  selector: "app-info-other-apps-component",
  templateUrl: "./info-other-apps.component.html",
  styleUrls: ["./info-other-apps.component.scss"]
})
export class InfoOtherAppsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;

  @Output() close: EventEmitter<void> = new EventEmitter();


  constructor(
    private titleService: Title,
  ) { }

  public async ngOnInit() {
    // console.log("InfoOtherApps:ngOnInit");
    // this.languageCode = getUserLanguage();
    // this.languageCode = "en"; // until other versions available
    // title
    // let title = "More apps of Trip4You";
    // if (this.languageCode === "de") { title = "Weitere Apps von Trip4You"; }
    // title += " | Trip4You-Maps";
    // this.titleService.setTitle(title);
    // meta viewport
    // let metaDesc = document.querySelector("meta[name='viewport']");
    // metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    // let description = "Presentation of other apps of Trip4You";
    // if (this.languageCode === "de") {
    //   description = "Vorstellung weiterer Apps von Trip4You";
    // }
    // metaDesc = document.querySelector("meta[name='description']");
    // metaDesc.setAttribute("content", description);
  }
  public ngAfterViewInit() {
  }
  public ngOnDestroy(): void {
  }

}
