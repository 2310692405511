<h1 mat-dialog-title>{{data.dlgTitle}}</h1>
<hr>

<div *ngIf="data.dlgText || data.dlgText2" mat-dialog-content>
  <p>{{data.dlgText}}</p>
  <p>{{data.dlgText2}}</p>
  <div style="display: flex; flex-direction: column; text-align: center; margin: auto;">
  </div>
  <hr>
</div>

<div mat-dialog-actions align="end">
  <div>
    <button *ngIf="data.buttonYes && data.defaultButton !=='yes'" mat-button
      [mat-dialog-close]="'yes'">{{textYes}}</button>
    <button *ngIf="data.buttonYes && data.defaultButton ==='yes'" mat-button [mat-dialog-close]="'yes'"
      cdkFocusInitial>{{textYes}}</button>
    <button *ngIf="data.buttonNo  && data.defaultButton !=='no'" mat-button
      [mat-dialog-close]="'no'">{{textNo}}</button>
    <button *ngIf="data.buttonNo  && data.defaultButton ==='no'" mat-button [mat-dialog-close]="'no'"
      cdkFocusInitial>{{textNo}}</button>
    <button *ngIf="data.buttonOk" mat-button [mat-dialog-close]="'ok'">{{textOk}}</button>
    <button *ngIf="data.buttonCancel" mat-button [mat-dialog-close]="'cancel'" cdkFocusInitial>{{textCancel}}</button>
    <button *ngIf="data.buttonDel" mat-button [mat-dialog-close]="'delete'"
      [style.color]="data.colorButtonDelete">{{textDelete}}</button>
    <button *ngIf="data.buttonAdd" mat-button [mat-dialog-close]="'add'">{{textAdd}}</button>
    <button *ngIf="data.buttonReplace" mat-button [mat-dialog-close]="'replace'">{{textReplace}}</button>
  </div>
</div>