// tour-categories

import { LineStyle } from "src/app/models/line-style";
import { PointStyle } from "src/app/models/point-style";
import { SavedTourCategory } from "src/app/models/saved-item-categories";
import { MapTour, MapTourCategory } from "src/dto.generated/api";

export function createDefaultSavedTourCategoryRemember(userLanguage: string) {
    const newCat = {} as SavedTourCategory;
    newCat.name = "Tours to remember";
    if (userLanguage === "de") { newCat.name = "Gemerkte Touren"; }
    newCat.type = "$remember";
    newCat.visible = true;
    newCat.fixed = true;
    newCat.open = true;
    newCat.tourLineStyle = {} as LineStyle
    newCat.tourLineStyle.lineColor = "#00ffff";
    newCat.tourLineStyle.lineWidth = 2;
    newCat.tourPointStyle = {} as PointStyle;
    newCat.tourPointStyle.strokeColor = "#00ffff";
    newCat.tourItems = new Array<MapTour>();
    return newCat;
}

export function createNewTourCategory(categoryName: string) {
    const newCategory = {} as SavedTourCategory;
    newCategory.name = categoryName;
    newCategory.type = "$user";
    newCategory.tourLineStyle = {} as LineStyle;
    newCategory.tourLineStyle.lineColor = "#00ffff";
    newCategory.tourItems = new Array<MapTour>();
    return newCategory;
}


export function convertSTourCatToLTourCat(sTourCat: MapTourCategory) {
    const lTourCat = {} as SavedTourCategory;
    lTourCat.categoryId = sTourCat.id;
    lTourCat.type = sTourCat.type;
    lTourCat.name = sTourCat.name;
    lTourCat.fixed = false;
    lTourCat.open = false;
    lTourCat.description = sTourCat.description;
    lTourCat.visible = sTourCat.visible;
    lTourCat.tourLineStyle = undefined;
    lTourCat.tourPointStyle = undefined;
    lTourCat.tourItems = new Array<MapTour>();
    return lTourCat;
}

export function convertLTourCatToSTourCat(lTourCat: SavedTourCategory) {
    const sTourCat = {} as MapTourCategory;
    sTourCat.id = lTourCat.categoryId;
    sTourCat.name = lTourCat.name;
    sTourCat.type = lTourCat.type;
    sTourCat.description = lTourCat.description;
    sTourCat.visible = lTourCat.visible;
    sTourCat.mapTourFormatVersion = 0;
    return sTourCat;
}