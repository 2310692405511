import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import { IScatterData, IScatterPoint, } from "src/app/models/chart-types";
import { getSteepnessColor, getSteepnessName } from "../show-route/show-route-utils";

interface IChartDataSet {
    label: string;
    data: IScatterPoint[];
    showLine: boolean;
    fill: boolean;
    borderWidth: number;
    borderColor: string;
    pointStyle: string;
    radius: number;
}

@Component({
    selector: "app-steepness-profile-chart-component",
    templateUrl: "./steepness-profile-chart.component.html",
    styleUrls: ["./steepness-profile-chart.component.scss"]
})
export class SteepnessProfileChartComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() data: IScatterData;
    @Input() steepnessSections: Array<number>[];
    @Input() languageCode: string;

    public steepnessChart: Chart;
    public chartTitle: string;
    public scaleXTitle: string;
    public scaleYTitle: string;

    private dataSets: IChartDataSet[];

    @ViewChild("container") containerElement: ElementRef;

    constructor(
        private translate: TranslateService,
    ) {
        // get texts
        translate.get("MAPS.STEEPNESS-CHART.TITLE").subscribe((text: string) => { this.chartTitle = text; });
        translate.get("MAPS.STEEPNESS-CHART.SCALE-X_TITLE").subscribe((text: string) => { this.scaleXTitle = text; });
        translate.get("MAPS.STEEPNESS-CHART.SCALE-Y_TITLE").subscribe((text: string) => { this.scaleYTitle = text; });
    }

    public ngOnInit() {
        // console.log("SteepnessProfileChart:ngOnInit-data", this.data);
        // console.log("SteepnessProfileChart:ngOnInit-steepnessSections", this.steepnessSections);

    }

    // public async ngOnChanges(changes: SimpleChanges) {

    //     if (this.steepnessChart) {
    //         this.steepnessChart.destroy();
    //     }

    //     // get array of different types
    //     const types = new Array<number>();
    //     for (const section of this.steepnessSections) {
    //         const id = Number(section[2]);
    //         const found = types.includes(id);
    //         if (!found) { types.push(id); }
    //     }
    //     console.log("SteepnessProfileChart:ngOnInit-types", types);

    //     // create datasets
    //     this.dataSets = new Array<IChartDataSet>();
    //     for (const type of types) {
    //         const dataSet = {} as IChartDataSet;
    //         const data = new Array<IScatterPoint>();
    //         for (const section of this.steepnessSections) {
    //             const id = Number(section[2]);
    //             const iFrom = section[0];
    //             const iTo = section[1];
    //             for (const wp of this.data.points) {
    //                 const index = this.data.points.indexOf(wp);
    //                 if (index >= iFrom && index <= iTo) {
    //                     const point = {} as IScatterPoint;
    //                     point.x = wp.x;
    //                     point.y = wp.y;
    //                     if (id !== type) { point.y = NaN; }
    //                     data.push(point);
    //                 }
    //             }
    //         }
    //         dataSet.borderColor = getSteepnessColor(type);
    //         dataSet.borderWidth = 2;
    //         dataSet.label = this.scaleXTitle
    //         dataSet.data = data;
    //         dataSet.fill = false;
    //         dataSet.label = getSteepnessName(type, this.languageCode);
    //         // dataSet.pointStyle = "circle";
    //         dataSet.radius = 0;
    //         dataSet.showLine = true;
    //         this.dataSets.push(dataSet);
    //     }
    //     console.log("SteepnessProfileChart:ngOnInit-dataSets", this.dataSets);

    //     // get drawing context
    //     const canvas = document.getElementById("steepnessChart") as HTMLCanvasElement;
    //     const ctx = canvas.getContext("2d");
    //     // console.log("SteepnessProfileChart:ngOnInit-ctx", ctx);

    //     // create steepness chart
    //     this.steepnessChart = new Chart(ctx, {
    //         type: "scatter",
    //         data: {
    //             datasets: this.dataSets
    //         },
    //         options: {
    //             elements: {
    //                 point: {
    //                     // pointStyle: "cross",
    //                 },
    //                 line: {
    //                     tension: 0, // disables bezier curves
    //                 }
    //             },
    //             layout: {
    //                 padding: {
    //                     left: 0,
    //                     right: 0,
    //                     top: 0,
    //                     bottom: 0
    //                 }
    //             },
    //             scales: {
    //                 x: {
    //                     type: "time",
    //                     position: "bottom"
    //                 },
    //             }
    //         }
    //     });
    //     // console.log("SteepnessProfileChart:ngOnInit-steepnessChart", this.steepnessChart);

    // }

    public async ngOnChanges(changes: SimpleChanges) {

        if (this.steepnessChart) {
            this.steepnessChart.destroy();
        }

        // get array of different types
        const types = new Array<number>();
        for (const section of this.steepnessSections) {
            const id = Number(section[2]);
            const found = types.includes(id);
            if (!found) { types.push(id); }
        }
        console.log("SteepnessProfileChart:ngOnInit-types", types);

        // create datasets
        this.dataSets = new Array<IChartDataSet>();
        for (const type of types) {
            const dataSet = {} as IChartDataSet;
            const data = new Array<IScatterPoint>();
            for (const section of this.steepnessSections) {
                const id = Number(section[2]);
                const iFrom = section[0];
                const iTo = section[1];
                for (const wp of this.data.points) {
                    const index = this.data.points.indexOf(wp);
                    if (index >= iFrom && index <= iTo) {
                        const point = {} as IScatterPoint;
                        point.x = wp.x;
                        point.y = wp.y;
                        if (id !== type) { point.y = NaN; }
                        data.push(point);
                    }
                }
            }
            dataSet.borderColor = getSteepnessColor(type);
            dataSet.borderWidth = 2;
            dataSet.data = data;
            dataSet.fill = false;
            dataSet.label = getSteepnessName(type, this.languageCode);
            // dataSet.pointStyle = "circle";
            dataSet.radius = 0;
            dataSet.showLine = true;
            this.dataSets.push(dataSet);
        }
        console.log("SteepnessProfileChart:ngOnInit-dataSets", this.dataSets);

        // get drawing context
        const ctx = document.getElementById("steepnessChart") as HTMLCanvasElement;
        // console.log("SteepnessProfileChart:ngOnInit-ctx", ctx);

        // create steepness chart
        this.steepnessChart = new Chart(ctx, {
            type: "scatter",
            data: {
                datasets: this.dataSets
            },
            options: {
                title: {
                    display: true,
                    // text: "Steepness profile",
                    text: this.chartTitle,
                },
                elements: {
                    point: {
                        // pointStyle: "cross",
                    },
                    line: {
                        tension: 0, // disables bezier curves
                    }
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                legend: {
                    // display: true,
                    display: false,
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            // labelString: "Distance [km]",
                            labelString: this.scaleXTitle,
                        },
                        ticks: {
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: true,
                            // labelString: "Elevation [m]",
                            labelString: this.scaleYTitle,
                        },
                        ticks: {
                        }
                    }]
                }
            }
        });
        // console.log("SteepnessProfileChart:ngOnInit-steepnessChart", this.steepnessChart);

    }
    public ngAfterViewInit() {
        // console.log("SteepnessProfileChart:ngAfterViewInit-data", this.data);

    }

    public onClick() {
        // console.log("ElevationChart:onClick-isFullscreen");
        const elem = document.fullscreenElement;
        if (!elem) { this.openFullscreen(); }
        if (elem) { this.exitFullscreen(); }

    }
    private openFullscreen() {
        if (!document.fullscreenEnabled) { return; }
        const elem = this.containerElement.nativeElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    private exitFullscreen() {
        document.exitFullscreen();
    }


}
