import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges } from "@angular/core";
import { EnumActionType, EnumGlobalStatusCode, EnumRouteType, MapPublicRoute, RefRegion, UsageLogMaps, User } from "src/dto.generated/api";
import { DomSanitizer } from "@angular/platform-browser";

import Map from "ol/Map";
import { transform } from "ol/proj";

import { GlobalService } from "src/app/services/global.service";
import { MapSearchRouteFilter } from "src/app/models/search-route-filter";
import { getRouteCategoryName } from "../maps-utils/utils-route";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { environment } from "src/environments/environment";
import { ApiError } from "src/app/models/api-error";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";


@Component({
  selector: "app-search-route-component",
  templateUrl: "./search-route.component.html",
  styleUrls: ["./search-route.component.scss"]
})
export class SearchRouteComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;
  @Input() loggedInUser: User;
  @Input() map: Map;
  @Input() sessionId: string;

  @Input() foundRoutes: MapPublicRoute[];
  @Input() isPresentedRoute: boolean;
  @Input() scrolledRouteIndex: number;

  @Input() searchFilter: MapSearchRouteFilter;
  @Input() regions: RefRegion[];

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() showError: EventEmitter<ApiError> = new EventEmitter();
  @Output() foundPublicRoutes: EventEmitter<MapPublicRoute[]> = new EventEmitter();
  @Output() activatePublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();
  @Output() removePublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();
  @Output() drawPublicRoutes: EventEmitter<void> = new EventEmitter();
  @Output() drawPublicRoute: EventEmitter<MapPublicRoute> = new EventEmitter();

  // general variables
  public isTestUser: boolean;
  public isUserAdmin: boolean;


  public searchStringRouteName: string;
  public searchStringRegionName: string;
  public categoryIds: number[];
  public categoryNames: string[];
  public categoryChecked: boolean[];
  public routeType: string;
  public minRouteLength: number;
  public maxRouteLength: number;
  public stepRouteLength: number;
  public selectedMinRouteLength: number;
  public selectedMaxRouteLength: number;
  public approved: number;

  public searchRegionText: string;
  public foundRefRegions: RefRegion[];
  public selectedRegionId: number;

  public noRouteFound: boolean;

  public loadingPageSize = 5;
  public isLoadingData: boolean;

  public showSearchFilter: boolean;
  public showHelpTextSearch: boolean;
  public showHelpSelectRegion: boolean;
  public inBboxDisabled: boolean;
  public showCategories: boolean;

  // error
  public apiPostErrorText: string;


  @ViewChild("routesHeader") routesHeaderElement: ElementRef;

  constructor(
    private sanitizer: DomSanitizer,
    private globalService: GlobalService,
  ) { }

  public async ngOnInit() {
    if (this.loggedInUser && this.loggedInUser.id === 1) { this.isTestUser = true; }
    // console.log("SearchRoute:ngOnInit-searchFilter", this.searchFilter);
    // console.log("SearchRoute:ngOnInit-regions", this.regions);
    this.initValues();

    // presented route (from url)
    if (this.isPresentedRoute) {
      this.showSearchFilter = false;
    }
  }
  public ngAfterViewInit() {
    // console.log("SearchRoute:ngAfterViewInit");
  }
  public async ngOnChanges(changes: SimpleChanges) {
    // console.log("SearchRoute:onChanges-changes", changes);
    if (this.scrolledRouteIndex >= 0) {
      this.scrollToRoute(this.scrolledRouteIndex);
    }
  }
  public ngOnDestroy(): void {
  }
  public onBackToMapClick() {
    this.saveSearchFilterData();
    this.back.emit();
  }
  public onBackClick() {
    if (this.foundRoutes) {
      this.foundRoutes = undefined;
      return;
    }
    this.onBackToMapClick();
  }

  private initValues() {
    this.approved = -1;
    // SHOW SEARCH-FILTER
    this.showSearchFilter = true;
    if (this.foundRoutes && this.foundRoutes.length > 0) { this.showSearchFilter = false; }

    // selectedRegionId
    this.selectedRegionId = this.searchFilter.regionId;

    // searchText
    this.searchStringRouteName = this.searchFilter.textInName;

    // only in map-extent
    this.detDefaultOnlyInMapExtent();

    // region
    this.selectedRegionId = this.searchFilter.regionId;

    // routeType
    this.routeType = "all";
    if (this.searchFilter.routeType === EnumRouteType.OneWay) { this.routeType = "normal"; }
    if (this.searchFilter.routeType === EnumRouteType.BackToStart || this.searchFilter.routeType === EnumRouteType.RoundRoute) {
      this.routeType = "round";
    }
    // routeLength
    this.initRouteLength();
    // route-categories
    this.initCategories();
  }
  private initRouteLength() {
    this.minRouteLength = 0;
    this.maxRouteLength = 150;
    this.stepRouteLength = 5;
    this.selectedMinRouteLength = 5;
    this.selectedMaxRouteLength = 100;
    let moveType = "car";
    if (this.searchFilter.moveType === "bike") { moveType = "bike"; }
    if (this.searchFilter.moveType === "ebike") { moveType = "bike"; }
    if (this.searchFilter.moveType === "'bike-road") { moveType = "bike"; }
    if (this.searchFilter.moveType === "mtb") { moveType = "bike"; }
    if (this.searchFilter.moveType === "walking") { moveType = "food"; }
    if (this.searchFilter.moveType === "hiking") { moveType = "food"; }
    if (moveType === "bike") {
      this.minRouteLength = 0;
      this.maxRouteLength = 150;
      this.stepRouteLength = 5;
      this.selectedMinRouteLength = 5;
      this.selectedMaxRouteLength = 100;
      if (this.searchFilter.maxRouteLength) { this.selectedMaxRouteLength = this.searchFilter.maxRouteLength; }
    }
    if (moveType === "food") {
      this.minRouteLength = 0;
      this.maxRouteLength = 30;
      this.stepRouteLength = 2;
      this.selectedMinRouteLength = 2;
      this.selectedMaxRouteLength = 15;
      if (this.searchFilter.maxRouteLength) { this.selectedMaxRouteLength = this.searchFilter.maxRouteLength; }
    }
  }
  private initCategories() {
    this.categoryIds = [1, 2, 3, 4, 5, 6, 51, 52, 54, 53, 101, 102, 103, 104, 105, 151, 1001];
    this.categoryNames = new Array<string>();
    this.categoryChecked = new Array<boolean>();
    for (const id of this.categoryIds) {
      const name = getRouteCategoryName(id, this.languageCode);
      this.categoryNames.push(name);
      let check = false;
      if (this.searchFilter.categories) {
        for (const actCat of this.searchFilter.categories) {
          if (actCat === id.toString()) { check = true; }
        }
      }
      this.categoryChecked.push(check);
    }
  }

  public onShowCategoriesClick() {
    this.showCategories = !this.showCategories;
  }

  public onClearTextStringClick() {
    this.searchStringRouteName = undefined;
  }
  public async onSearchTextKeyUp(event: KeyboardEvent) {
    // console.log("SearchRoute:onSearchTextKeyUp", event);
    this.searchFilter.inBbox = false;
    this.detDefaultOnlyInMapExtent();
    if (event.key === "Enter" || !event.key) {
      await this.searchForRoutes();
    }
  }

  public onSelectMoveType(moveType) {
    this.searchFilter.moveType = moveType;
    if (moveType === "all") { this.searchFilter.moveType = undefined; }
    this.initRouteLength();
  }

  public onSelectRouteType(type: string) {
    if (type === "normal") {
      this.searchFilter.routeType = EnumRouteType.OneWay;
    }
    if (type === "round") {
      this.searchFilter.routeType = EnumRouteType.BackToStart;
    }
    if (type === "all") {
      this.searchFilter.routeType = EnumRouteType.Undefined;
    }
  }

  public onCheckClicked(event: Event, index: number) {
    // console.log("SearchRoute:ngOnInit-onCheckClicked", this.categoryChecked);
  }

  public async onSearchClick() {
    await this.searchForRoutes();
    this.foundPublicRoutes.emit(this.foundRoutes);
  }

  private saveSearchFilterData() {
    // search-text
    if (this.searchStringRouteName === undefined) { this.searchStringRouteName = ""; }
    this.searchFilter.textInName = this.searchStringRouteName;
    this.searchFilter.maxRouteLength = this.selectedMaxRouteLength;
    // bbox
    if (this.uiType === "L") { this.setSearchFilterBBoxToMapExtent(); }
    // region
    this.searchFilter.regionId = this.selectedRegionId;
    // categories
    this.setSearchFilterCategories();
  }
  private async searchForRoutes() {
    this.apiPostErrorText = "";
    this.foundRoutes = undefined;
    this.foundPublicRoutes.emit(undefined);
    this.showSearchFilter = false;
    this.noRouteFound = false;
    this.isLoadingData = true;
    this.saveSearchFilterData();
    await this.lazyLoadAllRoutes();
  }
  private async lazyLoadAllRoutes() {
    const result = await this.globalService.searchPublicRoutesByFilter(this.searchFilter, true, this.approved);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "searchPublicRoutesByFilter");
      this.showError.emit(apiErr);
      if (this.languageCode === "de") {
        this.apiPostErrorText = "Internetverbindung prüfen!";
      } else {
        this.apiPostErrorText = "Check your internet connection!";
      }
      this.back.emit();
      return;
    }
    this.foundRoutes = result.routes;
    this.isLoadingData = false;
    if (!this.foundRoutes.length) { this.noRouteFound = true; }
    // console.log("SearchRoutes:lazyLoadAllRoutes-foundRoutes", this.foundRoutes);
    for (const route of this.foundRoutes) {
      this.loadSingleRouteFull(route);
    }
    const info = "filter:" + JSON.stringify(this.searchFilter);
    this.createUsageLog(EnumActionType.search_route, info);
  }
  private async loadSingleRouteFull(route: MapPublicRoute) {
    // console.log("SearchRoutes:loadSingleRouteFull-route", route);
    const result = await this.globalService.getPublicRoute(route.id);
    // console.log("SearchRoutes:loadSingleRouteFull-result", result);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "getPublicRoute");
      this.showError.emit(apiErr);
      this.back.emit();
      return;
    }
    const index = this.foundRoutes.indexOf(route);
    this.foundRoutes[index] = result.route;
  }

  private setSearchFilterBBoxToMapExtent() {
    // console.log("SearchRoute:setSearchFilterBBoxToMapExtent-map", this.map);
    const view = this.map.getView();
    const extent = view.calculateExtent(this.map.getSize());
    // console.log("SearchRoute:setSearchFilterBBoxToMapExtent-extent", extent);
    const coordLL = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
    const coordUR = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
    this.searchFilter.bboxLonFrom = coordLL[0];
    this.searchFilter.bboxLonTo = coordUR[0];
    this.searchFilter.bboxLatFrom = coordLL[1];
    this.searchFilter.bboxLatTo = coordUR[1];
  }

  private setSearchFilterCategories() {
    this.searchFilter.categories = new Array<string>();
    for (const id of this.categoryIds) {
      const index = this.categoryIds.indexOf(id);
      if (this.categoryChecked[index]) {
        this.searchFilter.categories.push(id.toString());
      }
    }
    // console.log("SearchRoute:setSearchFilterCategories-categories", this.searchFilter.categories);
  }

  public onDrawAllRoutesClick() {
    this.drawPublicRoutes.emit();
  }

  public onDrawPublicRoute(route: MapPublicRoute) {
    // console.log("SearchRoute:onDrawPublicRoute-route", route);
    const index = this.foundRoutes.indexOf(route);
    route.id = index + 1;
    this.drawPublicRoute.emit(route);
  }

  public onRemovePublicRoute(route: MapPublicRoute) {
    // console.log("PresentRoute:onRemovePublicRoute-route", route);
    this.removePublicRoute.emit(route);
    const index = this.foundRoutes.indexOf(route);
    this.foundRoutes.splice(index, 1);
  }

  public onActivatePublicRoute(route: MapPublicRoute) {
    // console.log("SearchRoute:onActivatePublicRoute-route", route);
    this.activatePublicRoute.emit(route);
  }

  public onNewSearchClick() {
    this.foundRoutes = undefined;
    this.showSearchFilter = true;
  }

  public onSearchOtherRoutesClick() {
    this.foundRoutes = undefined;
    this.isPresentedRoute = false;
    this.showSearchFilter = true;
  }

  public onHelpTextSearchClick() {
    this.showHelpTextSearch = true;
  }

  public onHelpRegionClick() {
    this.showHelpSelectRegion = !this.showHelpSelectRegion;
  }
  public onClearRegionClick() {
    this.foundRefRegions = undefined;
    this.selectedRegionId = undefined;
    this.searchRegionText = undefined;
    this.detDefaultOnlyInMapExtent();
  }
  public onCheckInBoxChanged(event: MatCheckboxChange) {
    // console.log("SearchRoute:onRegionSelectionChange-event", event);
    this.searchFilter.inBbox = !this.searchFilter.inBbox;
    this.detDefaultOnlyInMapExtent();
  }

  public setApproved(approved: number) {
    this.approved = approved;
  }

  private async createUsageLog(actionType: EnumActionType, info: string) {
    const startedAt = new Date(Date.now());
    const referrer = document.referrer;
    const appVersion = environment.releaseVersion;
    const usageLog = {} as UsageLogMaps;
    usageLog.actionType = actionType;
    usageLog.actionInfo = info;
    usageLog.sessionId = this.sessionId;
    usageLog.userLanguage = this.languageCode;
    usageLog.referrer = referrer;
    usageLog.appVer = appVersion;
    usageLog.at = startedAt;
    const result = await this.globalService.addUsageLogMaps(usageLog);
    if (result.status !== EnumGlobalStatusCode.Success) {
      const apiErr = this.createApiError(result, "addUsageLogMaps");
      this.showError.emit(apiErr);
      return;
    }
  }

  private detDefaultOnlyInMapExtent() {
    // console.log("SearchRoute:detDefaultOnlyInMapExtent-searchFilter", this.searchFilter);
    // console.log("SearchRoute:detDefaultOnlyInMapExtent-selectedReagionId", this.selectedRegionId);
    // this.searchFilter.inBbox = false;
    if (!this.selectedRegionId && !this.searchStringRouteName) {
      this.searchFilter.inBbox = true;
    }
    this.inBboxDisabled = !this.selectedRegionId && !this.searchStringRouteName;
  }

  public scrollToRoute(index: number) {
    // console.log("SearchRoute:onScroll-index", index);
    const elementId = "route" + index.toString();
    // console.log("SearchRoute:onScroll-elementId", elementId);
    const el = document.getElementById(elementId);
    // console.log("SearchRoute:onScroll-el", el);
    el.scrollIntoView({ behavior: "smooth" });
  }

  // region
  public onSelectRegionChange(event: Event) {
    // console.log("SearchRoute:onRegionSelectionChange-event", event);
    this.searchFilter.inBbox = false;
    this.detDefaultOnlyInMapExtent();
  }
  public async onSearchRegionTextKeyUp(event: KeyboardEvent) {
    // console.log("SearchRoute:onSearchRegionTextKeyUp", event);
    if (event.key === "Enter") {
      const refRegion = this.findRefRegionInFoundList(this.searchRegionText);
      // console.log("SearchRoute:onSearchRegionTextKeyUp-searchRegionText", this.searchRegionText);
      // console.log("SearchRoute:onSearchRegionTextKeyUp-refRegion", refRegion);
      if (refRegion) {
        this.selectedRegionId = refRegion.id;
        this.searchFilter.inBbox = false;
        this.detDefaultOnlyInMapExtent();
        await this.searchForRoutes();
      } else {
        this.selectedRegionId = undefined;
        this.searchFilter.inBbox = true;
        this.detDefaultOnlyInMapExtent();
      }
    }
    if (event.key === "Backspace") {
      this.selectedRegionId = undefined;
      this.searchFilter.inBbox = true;
      this.detDefaultOnlyInMapExtent();
    }
    if (!event.key) {
      // const selectedRefRegion = this.getSelectedRefRegion(this.searchRegionText);
      // console.log("SearchRoute:onSearchRegionTextKeyUp-selectedrefRegion", selectedRefRegion);
      // this.searchRegionText = selectedRefRegion.name;
      // this.selectedRegionId = selectedRefRegion.id;
      // this.searchFilter.inBbox = false;
      // this.detDefaultOnlyInMapExtent();
      return;
    }
    if (this.searchRegionText && this.searchRegionText.length > 2) {
      const result = await this.globalService.searchRefRegionListByName(this.searchRegionText);
      if (result.status !== EnumGlobalStatusCode.Success) {
        const apiErr = this.createApiError(result, "searchRefRegionListByName");
        this.showError.emit(apiErr);
        return;
      }
      this.foundRefRegions = result.refRegions;
      const refRegion = this.findRefRegionInFoundList(this.searchRegionText);
      if (refRegion) {
        this.searchRegionText = refRegion.name;
        this.selectedRegionId = refRegion.id;
        this.searchFilter.inBbox = false;
        this.detDefaultOnlyInMapExtent();
      } else {
        this.selectedRegionId = undefined;
        this.searchFilter.inBbox = true;
        this.detDefaultOnlyInMapExtent();
      }
    }
  }
  public onFoundRegionSelected(event: MatAutocompleteSelectedEvent) {
    // console.log("SearchRoute:onFoundRegionSelected-event", event);
    const selectedRefRegion = this.getSelectedRefRegion(this.searchRegionText);
    // console.log("SearchRoute:onSearchRegionTextKeyUp-selectedrefRegion", selectedRefRegion);
    this.searchRegionText = selectedRefRegion.name;
    this.selectedRegionId = selectedRefRegion.id;
    this.searchFilter.inBbox = false;
    this.detDefaultOnlyInMapExtent();
    // console.log("SearchRoute:onSearchRegionTextKeyUp-regionInputElement", this.regionInputElement.nativeElement);
    // this.regionInputElement.nativeElement.blur();
    setTimeout(() => {
      document.getElementById("regionAutocomplete").blur();
      document.getElementById("regionInput").blur();
    });
  }
  private findRefRegionInFoundList(name: string) {
    if (!this.foundRefRegions) { return; }
    return this.foundRefRegions.find(p => p.name.toUpperCase() === name.toUpperCase());
  }
  private getSelectedRefRegion(name: string) {
    for (const xRefRegion of this.foundRefRegions) {
      if (xRefRegion.name === name) {
        return xRefRegion;
      }
    }
  }

  private createApiError(result: any, funcName: string) {
    const apiErr = {} as ApiError;
    apiErr.funcName = funcName;
    apiErr.status = result.status;
    apiErr.errorCode = result.errorCode;
    apiErr.errorMessage = result.errorMessage;
    return apiErr;
  }

}
