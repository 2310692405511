// functions for ol-map
import Map from "ol/Map";
import OSM, { ATTRIBUTION } from "ol/source/OSM";
import { TileDebug } from "ol/source.js";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { Fill, Stroke, Text, Style, Circle, Icon } from "ol/style";
import { Control, ScaleLine, Attribution, Rotate, MousePosition, defaults as defaultControls } from "ol/control";
import View from "ol/View";
import { Color } from "ol/color";
import { fromLonLat, transform } from "ol/proj";
import { DragRotateAndZoom, defaults as defaultInteractions } from "ol/interaction";
import { createStringXY } from "ol/coordinate";

import { MapPlaceL } from "src/app/models/mapplace";
import { LngLat } from "src/app/models/lnglat";
import { getPoiIconSource } from "./utils-pois";
import TileSource from "ol/source/Tile";

export function createBaseLayer() {
    const tileLayer = new TileLayer({
        // name: "base",
    });
    tileLayer.set("name", "base");
    return tileLayer;
}

export function createOverlayLayer() {
    const tileLayer = new TileLayer({
        // name: "ovl",
    });
    tileLayer.set("name", "ovl");
    return tileLayer;
}

export function createGeoLocationLayer() {
    // create Layer for geolocation symbol
    const geoLocationSource = new VectorSource({});
    const geoLocationLayer = new VectorLayer({
        // name: "geo-location",
        source: geoLocationSource,
    });
    geoLocationLayer.set("name", "geo-location");
    return geoLocationLayer;
}

export function createRouteLayer() {
    // create route-layer
    const routePlannerStyle = new Style({
        stroke: new Stroke({
            color: "rgba(0, 255, 255, 1.0)",
            width: 4
        }),
    });
    const routePlannerRouteLayer = new VectorLayer({
        style: routePlannerStyle,
        // name: "route",
    });
    routePlannerRouteLayer.set("name", "route");
    return routePlannerRouteLayer;
}

export function createRouteArrowLayer() {
    // create layer-routeplanner rrows
    const routePlannerArrowSource = new VectorSource({});
    const routePlannerArrowLayer = new VectorLayer({
        source: routePlannerArrowSource,
        // name: "route-arrows",
    });
    routePlannerArrowLayer.set("name", "route-arrows");
    return routePlannerArrowLayer;
}

export function createWayPointLayer() {
    // create layer- routeplanner waypoints
    const routePlannerWayPointsSource = new VectorSource({});
    const routePlannerWayPointsLayer = new VectorLayer({
        source: routePlannerWayPointsSource,
        // name: "way-points",
    });
    routePlannerWayPointsLayer.set("name", "way-points");
    return routePlannerWayPointsLayer;
}

export function createRoutePoisLayer() {
    // create layer-routepois
    const routePoisSource = new VectorSource({});
    const routePoisLayer = new VectorLayer({
        source: routePoisSource,
        // name: "route-pois",
    });
    routePoisLayer.set("name", "route-pois");
    return routePoisLayer;
}

export function createRoutePointerLayer() {
    // create layer-routepointer
    const routePointerStyle = new Style({
        image: new Circle({
            stroke: new Stroke({
                color: "#ff0000",
            }),
            fill: new Fill({
                color: "#ff000040",
            }),
            radius: 8,
        })
    });
    const routePointerSource = new VectorSource({});
    const routePointerLayer = new VectorLayer({
        source: routePointerSource,
        // name: "route-pointer",
        style: routePointerStyle,
    });
    routePointerLayer.set("name", "route-pointer");
    return routePointerLayer;
}

export function createSearchPointLayer() {
    // create layer search-points
    const searchPointsSource = new VectorSource({});
    const searchPointsLayer = new VectorLayer({
        source: searchPointsSource,
        // name: "search-points",
    });
    searchPointsLayer.set("name", "search-points");
    return searchPointsLayer;
}

export function createMarkerLayer() {
    // create layer marker-points
    const markerPointsSource = new VectorSource({});
    const markerPointsLayer = new VectorLayer({
        source: markerPointsSource,
        // name: "marker-points",
    });
    markerPointsLayer.set("name", "marker-points");
    return markerPointsLayer;
}

export function createSavedPlacesLocalyLayer() {
    // create layer saved-places-localy
    const savedPlacesSource = new VectorSource({});
    const savedPlacesLayer = new VectorLayer({
        source: savedPlacesSource,
        // name: "saved-places-l",
    });
    savedPlacesLayer.set("name", "saved-places-l");
    return savedPlacesLayer;
}

export function createSavedPlacesServerLayer() {
    // create layer saved-places-server
    const savedPlacesSource = new VectorSource({});
    const savedPlacesLayer = new VectorLayer({
        source: savedPlacesSource,
        // name: "saved-places-s",
    });
    savedPlacesLayer.set("name", "saved-places-s");
    return savedPlacesLayer;
}

export function createSavedRoutesLocalyLayer() {
    // create layer saved-routes-localy
    const savedRoutesSource = new VectorSource({});
    const savedRoutesLayer = new VectorLayer({
        source: savedRoutesSource,
        // name: "saved-routes-l",
    });
    savedRoutesLayer.set("name", "saved-routes-l");
    return savedRoutesLayer;
}

export function createSavedRoutesServerLayer() {
    // create layer saved-routes-server
    const savedRoutesSource = new VectorSource({});
    const savedRoutesLayer = new VectorLayer({
        source: savedRoutesSource,
        // name: "saved-routes-s",
    });
    savedRoutesLayer.set("name", "saved-routes-s");
    return savedRoutesLayer;
}

export function createPublicRoutesLayer() {
    // create layer public-routes
    const publicRoutesSource = new VectorSource({});
    const publicRoutesLayer = new VectorLayer({
        source: publicRoutesSource,
        // name: "pub-routes",
    });
    publicRoutesLayer.set("name", "pub-routes");
    return publicRoutesLayer;
}

export function createPoisLayer() {
    // create layer OSM-pois (OSM-Elements)
    const poisSource = new VectorSource({});
    const poisLayer = new VectorLayer({
        // name: "pois",
        source: poisSource,
    });
    poisLayer.set("name", "pois");
    return poisLayer;
}

export function createRestoStyle() {
    const restoStyle = [
        new Style({
            image: new Icon({
                src: "./assets/icons-test/Restaurant-14.svg",
                rotation: 0,
            })
        }),
        new Style({
            image: new Circle({
                stroke: new Stroke({
                    color: "#C77400",
                }),
                fill: new Fill({
                    color: "#C7740020",
                }),
                radius: 10,
            })
        }),
    ];
    return restoStyle;
}
export function createHotelStyle() {
    const hotelStyle = [
        new Style({
            image: new Icon({
                src: "./assets/icons-test/Hotel-16.svg",
                rotation: 0,
            })
        }),
        new Style({
            image: new Circle({
                stroke: new Stroke({
                    color: "#0092da",
                }),
                fill: new Fill({
                    color: "#0092da20",
                }),
                radius: 10,
            })
        }),
    ];
    return hotelStyle;
}
export function createSupermarketStyle() {
    const shopStyle = [
        new Style({
            image: new Icon({
                src: "./assets/icons-test/Supermarket-14.svg",
                rotation: 0,
            })
        }),
        new Style({
            image: new Circle({
                stroke: new Stroke({
                    color: "#ac39ac",
                }),
                fill: new Fill({
                    color: "#ac39ac20",
                }),
                radius: 10,
            })
        }),
    ];
    return shopStyle;
}

export function createPoiStyle(cat: string, name: string) {
    const src = getPoiIconSource(cat);

    // default - no icon-source
    if (!src) {
        const style = [
            new Style({
                image: new Circle({
                    stroke: new Stroke({
                        color: "#C77400",
                    }),
                    fill: new Fill({
                        color: "#00FFFF80",
                    }),
                    radius: 12,
                }),
                text: new Text({
                    text: name,
                    offsetX: 0,
                    offsetY: 16,
                }),
            }),
        ];
        return style;
    }

    // icon-source is defined
    const poiStyle = [
        new Style({
            image: new Circle({
                stroke: new Stroke({
                    color: "#C77400",
                }),
                fill: new Fill({
                    color: "#00FFFF80",
                }),
                radius: 12,
            }),
            text: new Text({
                text: name,
                offsetX: 0,
                offsetY: 16,
            }),
        }),
        new Style({
            image: new Icon({
                src,
                rotation: 0,
            })
        }),
    ];
    return poiStyle;
}

export function createGpxRouteLayer() {
    // create layer - gpx-route (green)
    const gpxRouteSource = new VectorSource({});
    const gpxRouteStyle = new Style({
        stroke: new Stroke({
            color: "rgba(0, 100, 0, 1.0)",
            width: 4
        }),
    });
    const gpxRouteLayer = new VectorLayer({
        // name: "gpx-route",
        source: gpxRouteSource,
        style: gpxRouteStyle
    });
    gpxRouteLayer.set("name", "gpx-route");
    return gpxRouteLayer;
}

export function createGpxWaypointLayer() {
    // create layer - gpx-route (green)
    const gpxWaypointSource = new VectorSource({});
    const gpxWaypointLayer = new VectorLayer({
        // name: "gpx-waypoints",
        source: gpxWaypointSource,
    });
    gpxWaypointLayer.set("name", "gpx-waypoints");
    return gpxWaypointLayer;
}

export function createTrackingRouteLayer() {
    // create layer - tracking (route) (yellow)
    const trackingSource = new VectorSource({});
    const trackingStyle = new Style({
        stroke: new Stroke({
            color: "rgba(255, 204, 51, 1.0)",
            width: 2
        }),
    });
    const trackingLayer = new VectorLayer({
        // name: "tracking",
        source: trackingSource,
        style: trackingStyle
    });
    trackingLayer.set("name", "tracking");
    return trackingLayer;
}

export function createLiveTrackingLayer() {
    // create layer - live-tracking (magenta)
    const liveTrackingSource = new VectorSource({});
    const liveTrackingLayer = new VectorLayer({
        // name: "live-tracking",
        source: liveTrackingSource,
    });
    liveTrackingLayer.set("name", "live-tracking");
    return liveTrackingLayer;
}
export function createLiveTrackingEndPointStyle(color: Color, name: string) {
    // create live-tracking-endPoint-style
    const liveTrackingStyle = new Style({
        image: new Circle({
            radius: 8,
            fill: new Fill({
                color,
            })
        }),
        text: new Text({
            text: name,
            offsetX: 0,
            offsetY: 15,
        }),
    });
    return liveTrackingStyle;
}
export function createLiveTrackingPathStyle(color: Color) {
    // create live-tracking-path-style
    const liveTrackingStyle = new Style({
        stroke: new Stroke({
            color,
            width: 2
        }),
    });
    return liveTrackingStyle;
}

export function createTrackingPointLayer() {
    // create layer track-point (red)
    const trackingLocationSource = new VectorSource({});
    const trackingLocationStyle = new Style({
        image: new Circle({
            radius: 4,
            fill: new Fill({
                color: "rgba(255, 204, 51, 1.0)"
            })
        })
    });
    const trackingLocationLayer = new VectorLayer({
        // name: "track-route",
        source: trackingLocationSource,
        style: trackingLocationStyle
    });
    trackingLocationLayer.set("name", "track-route");
    return trackingLocationLayer;
}

export function createMapExtentLayer() {
    const mapExtentSource = new VectorSource({});
    // map-extent-layer
    const mapExtentStyle = new Style({
        stroke: new Stroke({
            color: "rgba(0, 0, 0, 1.0)",
            width: 2
        }),
    });
    const mapExtentLayer = new VectorLayer({
        source: mapExtentSource,
        style: mapExtentStyle,
    });
    mapExtentLayer.set("name", "map-extents");
    return mapExtentLayer;
}


export function createMap(baseLayer: TileLayer<TileDebug>, view: View) {
    // console.log("map-utils-ol-map:createMap");

    // tile-debug-layer
    const tileDebug = new TileLayer({
        source: new TileDebug(),
    });

    // map-layer
    // const layers = [baseLayer, tileDebug];
    const layers = [baseLayer];

    const rotateControl = createRotateControl();

    const mapControls = defaultControls({ zoom: false, rotate: false, attribution: false }).extend([
        // new Rotate(),
        rotateControl,
        new Attribution({ collapsible: true }),
        new ScaleLine(),
    ]);

    const map = new Map({
        interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
        controls: mapControls,
        layers,
        target: "map",
        view
    });
    const controls = map.getControls();
    // console.log("map-utils-ol-map:createMap-mapCotrols", controls);


    // test setting willReadFrequently attribute of ctx of map-canvas
    // const viewport = map.getViewport();
    // const olLayers = viewport.querySelector(".ol-layers");
    // setTimeout(() => {
    //     const olLayer = olLayers.querySelector(".ol-layer");
    //     const layerChildren = olLayer.children;
    //     const canvas = layerChildren[0];
    //     console.log("map-utils-ol-map:createMap-canvas", canvas);
    //     const ctx = canvas.getContext("2d", { willReadFrequently: true });
    //     console.log("map-utils-ol-map:createMap-ctx", ctx);
    //     const attributes = ctx.getContextAttributes();
    //     console.log("map-utils-ol-map:createMap-attributes", attributes);
    // });

    return map;
}

export function createView(longitude: number, latitude: number, zoom: number) {
    // view
    const view = new View({
        center: fromLonLat([longitude, latitude]),
        zoom
    });
    return view;
}

export function createMousePositionControl(uiType: string) {
    // mouse-position-control
    const mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(4),
        projection: "EPSG:4326",
        // comment the following two lines to have the mouse position
        // be placed within the map.
        // className: "custom-mouse-position",
        // target: document.getElementById("mouse-position"),
    });
    const element = (mousePositionControl as any).element;
    if (uiType === "S") {
        // element.style.top = "80px";
        element.style.top = "1px";
        element.style.right = "8px";
    }
    if (uiType === "L") {
        element.style.top = "1px";
        element.style.right = "8px";
    }
    element.style.background = "snow";
    return mousePositionControl;
}
export function createRotateControl() {
    // rotation-control
    const rotateControl = new Rotate({});
    const element = (rotateControl as any).element;
    element.style.top = "25px";
    element.style.right = "8px";
    return rotateControl;
}

export class MainControl extends Control {
    // @param {Object} [optOptions] Control options.
    constructor(optOptions, lang: string) {
        const options = optOptions || {};
        const component = options.component;

        const elementDiv = document.createElement("div");
        elementDiv.className = "ol-unselectable ol-control";
        elementDiv.style.zIndex = "-1";
        elementDiv.style.top = "80px";
        elementDiv.style.left = "8px";

        let text = "";
        const buttonZoomToMyPosition = document.createElement("button");
        text = "Zoom to my position";
        if (lang === "de") { text = "Zoom auf meine Position"; }
        buttonZoomToMyPosition.title = text;
        buttonZoomToMyPosition.setAttribute("style", "width:40px; height:40px;");
        buttonZoomToMyPosition.innerHTML = "<img width='30px' src='./assets/icons/crosshairs-gps.svg' alt='symbol'>";
        buttonZoomToMyPosition.style.backgroundColor = "orangered";
        component.buttonZoomToMyPosition = buttonZoomToMyPosition;
        elementDiv.appendChild(buttonZoomToMyPosition);

        const buttonNavigation = document.createElement("button");
        buttonNavigation.innerHTML = "<img width='30px' src='./assets/icons/navigation.svg' alt='symbol'>";
        text = "Start/Stop navigation";
        if (lang === "de") { text = "Navigation starten/stoppen"; }
        buttonNavigation.title = text;
        buttonNavigation.setAttribute("style", "width:40px; height:40px;");
        buttonNavigation.style.cursor = "pointer";
        component.buttonNavigation = buttonNavigation;
        elementDiv.appendChild(buttonNavigation);

        const buttonZoomToMapData = document.createElement("button");
        buttonZoomToMapData.innerHTML = "<img width='30px'src='./assets/icons/overscan.svg'alt='symbol'>";
        text = "Zoom to map data";
        if (lang === "de") { text = "Zoom auf Kartendaten"; }
        buttonZoomToMapData.title = text;
        buttonZoomToMapData.setAttribute("style", "width:40px; height:40px;");
        buttonZoomToMapData.style.cursor = "pointer";
        component.buttonZoomToMapData = buttonZoomToMapData;
        elementDiv.appendChild(buttonZoomToMapData);

        const buttonSelectMap = document.createElement("button");
        buttonSelectMap.innerHTML = "<img width='30px'src='./assets/icons/layers-triple-outline.svg'alt='symbol'>";
        text = "Select a map";
        if (lang === "de") { text = "Karte wählen"; }
        buttonSelectMap.title = text;
        buttonSelectMap.setAttribute("style", "width:40px; height:40px;");
        buttonSelectMap.style.cursor = "pointer";
        elementDiv.appendChild(buttonSelectMap);

        let buttonLockScreen;
        if (component.isMobileDevice) {
            buttonLockScreen = document.createElement("button");
            buttonLockScreen.innerHTML = "<img width='30px'src='./assets/icons/lock-outline.svg'alt='symbol'>";
            text = "Prevent map clicks";
            if (lang === "de") { text = "Klicks auf Karte verhindern"; }
            buttonLockScreen.title = text;
            buttonLockScreen.setAttribute("style", "width:40px; height:40px;");
            buttonLockScreen.style.cursor = "pointer";
            component.buttonLockScreen = buttonLockScreen;
            elementDiv.appendChild(buttonLockScreen);
        }
        super({
            element: elementDiv,
            target: options.target,
        });

        buttonZoomToMyPosition.addEventListener("click", this.handleZoomToMyPosition.bind(component), false);
        buttonNavigation.addEventListener("click", this.handleNavigation.bind(component), false);
        buttonZoomToMapData.addEventListener("click", this.handleZoomToMapData.bind(component), false);
        buttonSelectMap.addEventListener("click", this.handleSelectMap.bind(component), false);
        if (buttonLockScreen) {
            buttonLockScreen.addEventListener("click", this.handleLockScreen.bind(component), false);
        }
    }

    handleZoomToMyPosition = function fuHandleZoomToMyPosition() {
        // console.log("MainControl:handleZoomToMyPosition-Clicked-this", this);
        this.onZoomToCurrentLocationClick();
    };

    handleNavigation = function handleNavigation() {
        // console.log("MainControl:handleNavigationClicked");
        this.onNavigationClick();
    };

    handleZoomToMapData = function handleZoomToMapData() {
        // console.log("MainControl:handleZoomToMapDataClicked");
        this.zoomToMapData();
    };

    handleSelectMap = function handleSelectMap() {
        // console.log("MainControl:handleSelectMapClicked");
        this.onSelectMapClick();
    };

    handleLockScreen = function handleLockClick() {
        // console.log("MainControl:handleLockClicked");
        this.onLockScreenClick();
    };

}

export class PoisControl extends Control {
    // @param {Object} [optOptions] Control options.
    constructor(optOptions, lang: string) {
        const options = optOptions || {};
        const component = options.component;

        let top = "260px";
        if (component.isMobileDevice) {
            top = "300px";
        }
        const elementDiv = document.createElement("div");
        elementDiv.className = "ol-unselectable ol-control";
        elementDiv.style.zIndex = "-1";
        elementDiv.style.top = top;
        elementDiv.style.left = "8px";

        let text = "";
        const buttonRestos = document.createElement("button");
        // buttonRestos.innerHTML = "<img width='30px' src='./assets/osm-icons/restaurant-24x24.svg' alt='symbol'>";
        buttonRestos.innerHTML = "<img width='30px' src='./assets/icons-test/Restaurant-14.svg' alt='symbol'>";
        text = "Show restaurants";
        if (lang === "de") { text = "Restaurants anzeigen"; }
        buttonRestos.title = text;
        buttonRestos.setAttribute("style", "width:40px; height:40px;");
        buttonRestos.style.cursor = "pointer";
        component.buttonShowRestos = buttonRestos;
        elementDiv.appendChild(buttonRestos);

        const buttonHotels = document.createElement("button");
        // buttonHotels.innerHTML = "<img width='30px' src='./assets/osm-icons/hotel-24x24.svg' alt='symbol'>";
        buttonHotels.innerHTML = "<img width='30px' src='./assets/icons-test/Hotel-16.svg' alt='symbol'>";
        text = "Show accomodations";
        if (lang === "de") { text = "Unterkünfte anzeigen"; }
        buttonHotels.title = text;
        buttonHotels.setAttribute("style", "width:40px; height:40px;");
        buttonHotels.style.cursor = "pointer";
        component.buttonShowHotels = buttonHotels;
        elementDiv.appendChild(buttonHotels);

        const buttonSupermarkets = document.createElement("button");
        // buttonShops.innerHTML = "<img width='30px' src='./assets/osm-icons/supermarket-40x40.svg' alt='symbol'>";
        buttonSupermarkets.innerHTML = "<img width='30px' src='./assets/icons-test/Supermarket-14.svg' alt='symbol'>";
        text = "Show groceries";
        if (lang === "de") { text = "Lebensmittelgeschäfte anzeigen"; }
        buttonSupermarkets.title = text;
        buttonSupermarkets.setAttribute("style", "width:40px; height:40px;");
        buttonSupermarkets.style.cursor = "pointer";
        component.buttonShowSupermarkets = buttonSupermarkets;
        elementDiv.appendChild(buttonSupermarkets);

        const buttonPois = document.createElement("button");
        buttonPois.innerHTML = "<img width='30px' src='./assets/icons/dots-horizontal.svg' alt='symbol'>";
        text = "Show more points of interest";
        if (lang === "de") { text = "Weitere Elemente anzeigen"; }
        buttonPois.title = text;
        buttonPois.setAttribute("style", "width:40px; height:40px;");
        buttonPois.style.cursor = "pointer";
        component.buttonShowPois = buttonPois;
        elementDiv.appendChild(buttonPois);

        super({
            element: elementDiv,
            target: options.target,
        });

        buttonRestos.addEventListener("click", this.handleRestos.bind(component), false);
        buttonHotels.addEventListener("click", this.handleHotels.bind(component), false);
        buttonSupermarkets.addEventListener("click", this.handleSupermarkets.bind(component), false);
        buttonPois.addEventListener("click", this.handlePois.bind(component), false);
    }

    handleRestos = function handleRestos() {
        // console.log("PoisControl:fuHandleRestos-Clicked-this", this);
        this.onSearchRestaurantsClick();
    };

    handleHotels = function handleHotels() {
        // console.log("PoisControl:handleHotelsClicked");
        this.onSearchHotelsClick();
    };

    handleSupermarkets = function handleSupermarkets() {
        // console.log("PoisControl:handleSupermarketsClicked");
        this.onSearchSupermarketsClick();
    };

    handlePois = function handlePois() {
        // console.log("PoisControl:handlePoisClicked");
        this.onSearchPoisClick();
    };
}

export function getTransformedViewExtent(map: Map) {
    const view = map.getView();
    const extent = view.calculateExtent(map.getSize());
    // console.log("map2-utils-ol-map:onGeoCodeSearchObject-extent", extent);
    const coordLL = transform([extent[0], extent[1]], "EPSG:3857", "EPSG:4326");
    // console.log("map2-utils-ol-map:onGeoCodeSearchObject-coordLL", coordLL);
    const coordUR = transform([extent[2], extent[3]], "EPSG:3857", "EPSG:4326");
    // console.log("map2-utils-ol-map:onGeoCodeSearchObject-coordUR", coordUR);
    extent[0] = coordLL[0];
    extent[1] = coordLL[1];
    extent[2] = coordUR[0];
    extent[3] = coordUR[1];
    return extent;
}

export function convertMapPLaceToCoord(place: MapPlaceL) {
    const coord = {} as LngLat;
    coord.lng = place.coordLon;
    coord.lat = place.coordLat;
    return coord;
}
export function convertMapPlaceToUrlString(place: MapPlaceL) {
    // console.log("Maps:convertMapPlaceToUrlString-place", place);
    const x = Math.round(place.coordLon * 1000000) / 1000000;
    const y = Math.round(place.coordLat * 1000000) / 1000000;
    let urlString = place.type + ":" + place.cat + ":" + place.idOSM + "|" + x.toString() + "|" + y.toString() + "|" + place.name;
    if (place.label) { urlString += "|" + place.label; }
    return urlString;
}
export function parseUrlMapPlace(pointString: string) {
    // console.log("map1-utils-ol-map:parseUrlPoints-pointString", pointString);
    const items = pointString.split("|");
    if (items.length < 2) { return undefined; }
    // console.log("Map1:parseUrlPoint-items", items);
    const point = {} as MapPlaceL;
    point.type = "?";
    point.coordLon = Number(items[0]);
    point.coordLat = Number(items[1]);
    if (items.length >= 3) { point.name = items[2]; }
    if (items.length >= 4) { point.label = items[3]; }
    // console.log("map-utils-ol-map:parseUrlPoint-point", point);
    return point;
}

export function convertPoiToUrlString(place: MapPlaceL) {
    const x = Math.round(place.coordLon * 1000000) / 1000000;
    const y = Math.round(place.coordLat * 1000000) / 1000000;
    const urlString = place.type + ":" + place.cat + ":" + place.idOSM + "|" + x.toString() + "|" + y.toString() + "|" + place.name;
    return urlString;
}
export function parseUrlPoi(poiString: string) {
    // console.log("map1-utils-ol-map:parseUrlPoi-poiString", poiString);
    const items = poiString.split("|");
    if (items.length < 3) { return undefined; }
    const poi = {} as MapPlaceL;
    const x = items[0];
    const items1 = x.split(":");
    // console.log("map1-utils-ol-map:parseUrlPoi-items1", items1);
    poi.type = items1[0];
    if (items1[1] !== "undefined") { poi.cat = items1[1]; }
    if (items1.length === 3) { poi.idOSM = items1[2]; }
    if (items1.length === 4) { poi.idOSM = items1[2] + ":" + items1[3]; }
    poi.coordLon = Number(items[1]);
    poi.coordLat = Number(items[2]);
    if (items.length >= 4) { poi.name = items[3]; }
    if (items.length >= 5) { poi.label = items[4]; }
    // console.log("map-utils-ol-map:parseUrlPoint-point", point);
    return poi;
}

export function createColorArray() {
    const colorArray = new Array<Color>();
    let color: Color;
    color = [255, 0, 255, 1.0];    // magenta
    colorArray.push(color);
    color = [0, 255, 0, 1.0];    // green
    colorArray.push(color);
    color = [255, 255, 0, 1.0];    // yellow
    colorArray.push(color);
    color = [255, 0, 0, 1.0];    // red
    colorArray.push(color);
    color = [0, 0, 255, 1.0];    // blue
    colorArray.push(color);
    return colorArray;
}
