
import { MapPlaceL } from "src/app/models/mapplace";


// clone mapPlaces
export function cloneMapPlaces(places: MapPlaceL[]) {
    const clonedWps = new Array<MapPlaceL>();
    if (!places) { return clonedWps; }
    for (const place of places) {
        const clonedWp = cloneMapPlace(place);
        clonedWps.push(clonedWp);
    }
    return clonedWps;
}
export function cloneMapPlace(place: MapPlaceL) {
    const nePlace = {} as MapPlaceL;
    nePlace.coordLon = place.coordLon;
    nePlace.coordLat = place.coordLat;
    nePlace.countryCode = place.countryCode;
    nePlace.distance = place.distance;
    nePlace.id = place.id;
    nePlace.idOSM = place.idOSM;
    nePlace.label = place.label;
    nePlace.name = place.name;
    nePlace.notice = place.notice;
    nePlace.tags = place.tags;
    nePlace.type = place.type;
    nePlace.cat = place.cat;
    return nePlace;
}

