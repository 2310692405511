// tour-data

import { TourData } from "src/app/models/tour-data";
import { TrackPoint } from "src/app/models/track";
import { MapTour } from "src/dto.generated/api";
import { cloneTrackPoints } from "./utils-trackpoints";

export function initTourData(tourData: TourData) {
    tourData.dbId = undefined;
    tourData.tourStartTime = undefined;
    tourData.tourEndTime = undefined;
    tourData.doneDuration = 0;
    tourData.doneDurationHours = 0;
    tourData.doneDurationMinutes = 0;
    tourData.timeInMotion = 0;
    tourData.timeInMotionHours = 0;
    tourData.timeInMotionMinutes = 0;
    tourData.doneDistance = 0;
    tourData.trackLength = 0;
    tourData.currentVelocity = 0;
    tourData.averageVelocity = 0;
    tourData.currentAltidute = 0;
    tourData.minAltidute = Number.MAX_VALUE;
    tourData.maxAltidute = 0;
    tourData.doneAscents = 0;
    tourData.doneDescents = 0;
    tourData.numGaps = 0;
    tourData.trackPoints = Array<TrackPoint>();
    tourData.trackLength = 0;
    tourData.showTrack = false;
    tourData.route = undefined;
    tourData.routeId = undefined;
    tourData.routeDbId = undefined;
}

export function convertTourDataToMapTour(tourData: TourData) {
    const tour = {} as MapTour;
    tour.id = tourData.dbId;
    tour.name = tourData.name;
    tour.averageVelocity = tourData.averageVelocity;
    tour.doneAscents = tourData.doneAscents;
    tour.doneDescents = tourData.doneDescents;
    tour.doneDistance = tourData.trackLength;
    tour.minAltidute = tourData.minAltidute;
    tour.maxAltidute = tourData.maxAltidute;
    tour.numGaps = tourData.numGaps;
    tour.routeId = tourData.routeId;
    tour.routeString = JSON.stringify(tourData.route);
    tour.timeInMotion = tourData.timeInMotion;
    tour.tourStartTime = tourData.tourStartTime;
    tour.tourEndTime = tourData.tourEndTime;
    const trackPoints = cloneTrackPoints(tourData.trackPoints);
    tour.trackPoints = JSON.stringify(trackPoints);
    return tour;
}

export function convertMapTourToTourData(tour: MapTour) {
    console.log("utils-tour:convertMapTourToTourData-tour", tour);
    const tourData = {} as TourData;
    tourData.dbId = tour.id;
    tourData.name = tour.name;
    tourData.averageVelocity = tour.averageVelocity;
    tourData.doneAscents = tour.doneAscents;
    tourData.doneDescents = tour.doneDescents;
    tourData.trackLength = tour.doneDistance;
    tourData.minAltidute = tour.minAltidute;
    tourData.maxAltidute = tour.maxAltidute;
    tourData.numGaps = tour.numGaps;
    tourData.routeId = tour.routeId;
    if (tour.routeString) { tourData.route = JSON.parse(tour.routeString); }
    tourData.timeInMotion = tour.timeInMotion;
    tourData.timeInMotionHours = Math.floor(tour.timeInMotion / 3600);
    tourData.timeInMotionMinutes = Math.floor(tour.timeInMotion / 60) - tourData.timeInMotionHours * 60;
    tourData.tourStartTime = tour.tourStartTime;
    tourData.tourEndTime = tour.tourEndTime;
    tourData.trackPoints = new Array<TrackPoint>();
    if (tour.trackPoints) { tourData.trackPoints = JSON.parse(tour.trackPoints); }
    for (const trkPnt of tourData.trackPoints) {
        trkPnt.timeStamp = new Date(trkPnt.timeStamp);
    }
    if (tourData.tourEndTime > tourData.tourStartTime) {
        tourData.doneDuration = Math.floor((tour.tourEndTime.getTime() - tour.tourStartTime.getTime()) / 1000 / 60);
    } else {
        tourData.doneDuration = 0;
        if (tourData.trackPoints.length > 0) {
            tourData.tourEndTime = tourData.trackPoints[tourData.trackPoints.length - 1].timeStamp;
        }
    }
    tourData.doneDurationHours = Math.floor(tourData.doneDuration / 60);
    tourData.doneDurationMinutes = tourData.doneDuration - tourData.doneDurationHours * 60;
    // estimate moveType
    if (tour.averageVelocity < 25.0) { tourData.moveType = "biking"; }
    if (tour.averageVelocity < 6.0) { tourData.moveType = "hiking"; }
    console.log("utils-tour:convertMapTourToTourData-tourData", tourData);
    return tourData;
}
