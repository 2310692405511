import { Track, TrackPoint } from "../models/track";

const strNL = "\r\n";
const strBL2 = "  ";
const strBL4 = "    ";
const strBL6 = "      ";

export function convertTrackToGPXData(track: Track) {
  // console.log("utilsTrack:convertTrackToGPXData-track", track);

  let trackName = "anyTrack";
  if (track.name) { trackName = track.name; }
  const author = "Trip4You maps - " + track.author;
  let data = createXMLbeginGPX(trackName, author);
  let trackPoints = track.trackPoints;
  if (typeof trackPoints === "string") { trackPoints = JSON.parse(trackPoints); }
  for (const trp of track.trackPoints) {
    const index = track.trackPoints.indexOf(trp);
    if (index === 0) { data += createXMLelementWP(trp, "start"); }
  }
  data += createXMLbeginTRK(trackName);

  data += createXMLbeginTRKSEG();
  for (const trp of track.trackPoints) {
    data += createXMLelementTRKPT(trp);
  }
  data += createXMLendTRKSEG();
  data += createXMLendTRK();

  data += createXMLendGPX();

  return data;
}

function createXMLbeginGPX(name: string, author: string) {
  const text = "<?xml version='1.0' encoding='UTF-8'?>" + strNL +
    "<gpx version='1.0'  creator='Trip4You-Maps - https://maps.trip4you.net'>" + strNL +
    "<metadata>" + strNL +
    strBL2 + "<name>" + name + "</name>" + strNL +
    strBL2 + "<author>" + author + "</author>" + strNL +
    "</metadata>" + strNL;
  return text;
}
function createXMLelementWP(trp: TrackPoint, name: string) {
  const data = strBL2 +
    "<wpt" + " lat='" + trp.lat + "' lon='" + trp.lng + "'>" + "<name>" + name + "</name>" + "</wpt>" + strNL;
  return data;
}
function createXMLbeginTRK(name: string) {
  const text = strBL2 + "<trk>" + "<name>" + name + "</name>" + strNL;
  return text;
}
function createXMLbeginTRKSEG() {
  const text = strBL4 + "<trkseg>" + strNL;
  return text;
}
function createXMLelementTRKPT(trp: TrackPoint) {
  let data = strBL6 +
    "<trkpt" + " lat='" + trp.lat + "' lon='" + trp.lng + "'>";
  if (trp.ele) {
    data += "<ele>" + trp.ele + "</ele>";
  }
  if (trp.timeStamp) {
    data += "<time>" + formatDate(trp.timeStamp) + "</time>";
    // "yyyy-MM-ddThh:mm:ssZ"
  }
  data += "</trkpt>" + strNL;
  return data;
}
function createXMLendTRKSEG() {
  const text = strBL4 + "</trkseg>" + strNL;
  return text;
}
function createXMLendTRK() {
  const text = strBL2 + "</trk>" + strNL;
  return text;
}
function createXMLendGPX() {
  const text = "</gpx>" + strNL;
  return text;
}

function formatDate(date: Date) {
  // const dateStr = date.getUTCFullYear() + "-" + date.getUTCMonth() + "-" + date.getUTCDate() + "T" +
  //   date.getUTCHours() + ":" + date.getUTCMinutes() + ":" + date.getUTCSeconds() + "Z";
  const dateStr = date.toISOString();
  return dateStr;
}
