import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});

// meta-description
// let category = window.location.pathname.slice(1);
// console.log("main.ts:category", category);
// const category = "maps";
// const snippets = {
//   maps: "Maps - Free outdoor app - Route planning, Navigation, Route sharing - no advertising",
// };
// const snippet = snippets[category];
// const metaDesc = document.querySelector("meta[name='description']");
// metaDesc.setAttribute("content", snippet);
// console.log("main.ts:end");
