import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";

import { MapPlaceL } from "src/app/models/mapplace";
import { SavedPlaceCategory } from "src/app/models/saved-item-categories";

@Component({
  selector: "app-saved-place-selection-component",
  templateUrl: "./saved-place-selection.component.html",
  styleUrls: ["./saved-place-selection.component.scss"]
})
export class SavedPlaceSelectionComponent implements OnInit, AfterViewInit {
  @Input() savedPlaces: SavedPlaceCategory[];

  @Output() selectedPlace: EventEmitter<MapPlaceL> = new EventEmitter();

  constructor(
  ) {
  }

  public async ngOnInit() {
    // console.log("SavedPlacesSelection:ngOnInit");

  }
  public ngAfterViewInit() {
    // console.log("SavedPlacesSelection:ngAfterViewInit");
  }

  public onSelectPlace(place: MapPlaceL) {
    // console.log("SavedPlacesSelection:onSelectPlace-place", place);
    this.selectedPlace.emit(place);
  }


}
