import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { Command, CommandResult } from "src/api/api";
import { RockItApiConfig } from "./rockit-api-config";
import { RockItAuthenticationService } from "./rockit-authentication.service";
import { EnumGlobalStatusCode } from "src/dto.generated/api";

@Injectable({
    providedIn: "root"
})
export class RockItCommandSenderService {

    private isOnline = true;
    private showLog = false;

    private testConnectionSubscription: Subscription;
    private testConnectionIntervalSource: Observable<number>;
    private isConnectionTestActive = false;
    private developerAuthenticationUser: string;

    constructor(private rockItApiConfig: RockItApiConfig, authenticationService: RockItAuthenticationService, private zone: NgZone) {
        authenticationService.developerAuthenticationUser$.subscribe(t => this.developerAuthenticationUser = t);
    }

    private isApiAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isApiAvailable$: Observable<boolean> = this.isApiAvailable.asObservable();

    private dateReviver(key, value) {
        // tslint:disable-next-line:max-line-length
        // console.log("#####CommandSenderService:dateReviver-value", value);
        const dateFormat = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;
        if (typeof value === "string" && dateFormat.test(value)) {
            // const matches = value.match(dateFormat);
            // console.log("#####CommandSenderService:dateReviver-matches", matches);
            const mayBeDate = new Date(value);
            const isDate = mayBeDate instanceof Date && !isNaN(mayBeDate.getTime());
            if (isDate) { return mayBeDate; }
        }

        return value;
    }

    // showLog
    public setShowLog(showLog: boolean) {
        this.showLog = showLog;
    }

    // isOnline
    public setIsOnline(isOnline: boolean) {
        this.isOnline = isOnline;
    }
    public getIsOnline() {
        return this.isOnline;
    }


    public async sendCommand<TApiCommandResult extends CommandResult>(command: Command): Promise<TApiCommandResult> {
        const commandName = (command as any).__CommandType;
        delete (command as any).__CommandType;

        if (this.showLog) { console.log("=====> Sending command", commandName, command); }
        // const commandString = JSON.stringify(command);
        // console.log("commandstring", commandString);

        try {
            const fetchResult = await fetch(`${this.rockItApiConfig.apiUri}/${commandName}`, {
                method: "post",
                mode: "cors", // no-cors, cors, *same-origin
                cache: "no-cache",
                credentials: "include", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    TravelPlannerDevLoginUser: this.developerAuthenticationUser
                },
                redirect: "follow", // manual, *follow, error
                referrer: "no-referrer", // no-referrer, *client
                body: JSON.stringify(command), // body data type must match "Content-Type" header
            });

            const jsonResultString = await fetchResult.text();
            // console.log("==========> jsonResultString", jsonResultString);
            let apiResult = undefined;
            if (jsonResultString) {
                apiResult = JSON.parse(jsonResultString, this.dateReviver) as TApiCommandResult;
                this.isApiAvailable.next(true);
                if (this.showLog) { console.log("==========> Sending command succeeded", commandName, { response: apiResult, command }); }
                this.isOnline = true;
            } else {
                // §todo error-handling if jsonResultString is undefined
                apiResult = {} as TApiCommandResult;
                apiResult.status = EnumGlobalStatusCode.HttpError;
                this.isOnline = false;
            }

            if (this.testConnectionSubscription) {
                this.testConnectionSubscription.unsubscribe();
            }

            return apiResult;
        } catch (error) {

            // if(error.statusCode === 401){
            //     // redirect to login page
            // 1) call /.auth/refresh
            // 2) send request again
            // 3) If still not works => go to login screen
            // }

            this.isOnline = false;
            if (this.isApiAvailable.value) {
                this.isApiAvailable.next(false);
                this.testConnectionIntervalSource = interval(this.rockItApiConfig.reconnectIntervalMs);
                this.testConnectionSubscription = this.testConnectionIntervalSource.subscribe(async () => {
                    if (this.isConnectionTestActive) {
                        return;
                    }
                    this.isConnectionTestActive = true;
                    try {
                        await this.sendCommand<CommandResult>({ __CommandType: "PingCommand" });
                    } finally {
                        this.isConnectionTestActive = false;
                    }
                });
            }

            if (this.showLog) { console.warn("xxxxxxxxx> Sending command failed", commandName, { error, command }); }
            this.zone.run(async () => { });
            throw error;
        }
    }
}
