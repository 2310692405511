/* tslint:disable */

// This code was generated by RockIt.PwaTools.Dto
// Module: api
// Commands: 55
// Command-Results: 55
// Dto-Interfaces: 39
// Enums: 31

import { Command, CommandResult } from "../api/api";

//#region Commands

export class AddLiveTrackingCommand implements Command {
    newLiveTracking: LiveTracking;
    static create(command : AddLiveTrackingCommand) : AddLiveTrackingCommand { 
        command["__CommandType"] = "AddLiveTrackingCommand"; 
        return command;
    }
}
export class AddLiveTrackingPositionCommand implements Command {
    newLiveTrackPosition: LiveTrackPosition;
    storeOnlyEndPosition: boolean;
    static create(command : AddLiveTrackingPositionCommand) : AddLiveTrackingPositionCommand { 
        command["__CommandType"] = "AddLiveTrackingPositionCommand"; 
        return command;
    }
}
export class AddPlaceCategoryCommand implements Command {
    newPlaceCategory: MapPlaceCategory;
    static create(command : AddPlaceCategoryCommand) : AddPlaceCategoryCommand { 
        command["__CommandType"] = "AddPlaceCategoryCommand"; 
        return command;
    }
}
export class AddPlaceCommand implements Command {
    newPlace: MapPlace;
    static create(command : AddPlaceCommand) : AddPlaceCommand { 
        command["__CommandType"] = "AddPlaceCommand"; 
        return command;
    }
}
export class AddPublicRouteCommand implements Command {
    newRoute: MapPublicRoute;
    static create(command : AddPublicRouteCommand) : AddPublicRouteCommand { 
        command["__CommandType"] = "AddPublicRouteCommand"; 
        return command;
    }
}
export class AddRouteCategoryCommand implements Command {
    newRouteCategory: MapRouteCategory;
    static create(command : AddRouteCategoryCommand) : AddRouteCategoryCommand { 
        command["__CommandType"] = "AddRouteCategoryCommand"; 
        return command;
    }
}
export class AddRouteCommand implements Command {
    newRoute: MapRoute;
    static create(command : AddRouteCommand) : AddRouteCommand { 
        command["__CommandType"] = "AddRouteCommand"; 
        return command;
    }
}
export class AddTourCategoryCommand implements Command {
    newTourCategory: MapTourCategory;
    static create(command : AddTourCategoryCommand) : AddTourCategoryCommand { 
        command["__CommandType"] = "AddTourCategoryCommand"; 
        return command;
    }
}
export class AddTourCommand implements Command {
    newTour: MapTour;
    static create(command : AddTourCommand) : AddTourCommand { 
        command["__CommandType"] = "AddTourCommand"; 
        return command;
    }
}
export class AddUsageLogCommand implements Command {
    newUsageLog: UsageLog;
    static create(command : AddUsageLogCommand) : AddUsageLogCommand { 
        command["__CommandType"] = "AddUsageLogCommand"; 
        return command;
    }
}
export class AddUsageLogMapsCommand implements Command {
    newUsageLog: UsageLogMaps;
    static create(command : AddUsageLogMapsCommand) : AddUsageLogMapsCommand { 
        command["__CommandType"] = "AddUsageLogMapsCommand"; 
        return command;
    }
}
export class AnalysePublicRoutesCommand implements Command {
    mode: number;
    static create(command : AnalysePublicRoutesCommand) : AnalysePublicRoutesCommand { 
        command["__CommandType"] = "AnalysePublicRoutesCommand"; 
        return command;
    }
}
export class DeletePlaceCategoryCommand implements Command {
    placeCategoryId: number;
    static create(command : DeletePlaceCategoryCommand) : DeletePlaceCategoryCommand { 
        command["__CommandType"] = "DeletePlaceCategoryCommand"; 
        return command;
    }
}
export class DeletePlaceCommand implements Command {
    placeId: number;
    static create(command : DeletePlaceCommand) : DeletePlaceCommand { 
        command["__CommandType"] = "DeletePlaceCommand"; 
        return command;
    }
}
export class DeletePublicRouteCommand implements Command {
    routeId: number;
    static create(command : DeletePublicRouteCommand) : DeletePublicRouteCommand { 
        command["__CommandType"] = "DeletePublicRouteCommand"; 
        return command;
    }
}
export class DeleteRouteCategoryCommand implements Command {
    routeCategoryId: number;
    static create(command : DeleteRouteCategoryCommand) : DeleteRouteCategoryCommand { 
        command["__CommandType"] = "DeleteRouteCategoryCommand"; 
        return command;
    }
}
export class DeleteRouteCommand implements Command {
    routeId: number;
    static create(command : DeleteRouteCommand) : DeleteRouteCommand { 
        command["__CommandType"] = "DeleteRouteCommand"; 
        return command;
    }
}
export class DeleteTourCategoryCommand implements Command {
    tourCategoryId: number;
    static create(command : DeleteTourCategoryCommand) : DeleteTourCategoryCommand { 
        command["__CommandType"] = "DeleteTourCategoryCommand"; 
        return command;
    }
}
export class DeleteTourCommand implements Command {
    tourId: number;
    static create(command : DeleteTourCommand) : DeleteTourCommand { 
        command["__CommandType"] = "DeleteTourCommand"; 
        return command;
    }
}
export class DoConvertXCommand implements Command {
    id: number;
    static create(command : DoConvertXCommand) : DoConvertXCommand { 
        command["__CommandType"] = "DoConvertXCommand"; 
        return command;
    }
}
export class DoRepairItemsXCommand implements Command {
    static create(command : DoRepairItemsXCommand) : DoRepairItemsXCommand { 
        command["__CommandType"] = "DoRepairItemsXCommand"; 
        return command;
    }
}
export class ExistsPublicRouteCommand implements Command {
    routeId: string;
    static create(command : ExistsPublicRouteCommand) : ExistsPublicRouteCommand { 
        command["__CommandType"] = "ExistsPublicRouteCommand"; 
        return command;
    }
}
export class GetLiveTrackingCommand implements Command {
    trackingId: string;
    static create(command : GetLiveTrackingCommand) : GetLiveTrackingCommand { 
        command["__CommandType"] = "GetLiveTrackingCommand"; 
        return command;
    }
}
export class GetLiveTrackingPositionsCommand implements Command {
    sinceTime: Date;
    trackingId: string;
    static create(command : GetLiveTrackingPositionsCommand) : GetLiveTrackingPositionsCommand { 
        command["__CommandType"] = "GetLiveTrackingPositionsCommand"; 
        return command;
    }
}
export class GetLoggedInUserCommand implements Command {
    static create(command : GetLoggedInUserCommand) : GetLoggedInUserCommand { 
        command["__CommandType"] = "GetLoggedInUserCommand"; 
        return command;
    }
}
export class GetPlaceCategoriesCommand implements Command {
    static create(command : GetPlaceCategoriesCommand) : GetPlaceCategoriesCommand { 
        command["__CommandType"] = "GetPlaceCategoriesCommand"; 
        return command;
    }
}
export class GetPlacesCommand implements Command {
    static create(command : GetPlacesCommand) : GetPlacesCommand { 
        command["__CommandType"] = "GetPlacesCommand"; 
        return command;
    }
}
export class GetPublicRouteByRouteIdCommand implements Command {
    routeId: string;
    static create(command : GetPublicRouteByRouteIdCommand) : GetPublicRouteByRouteIdCommand { 
        command["__CommandType"] = "GetPublicRouteByRouteIdCommand"; 
        return command;
    }
}
export class GetPublicRouteCommand implements Command {
    id: number;
    static create(command : GetPublicRouteCommand) : GetPublicRouteCommand { 
        command["__CommandType"] = "GetPublicRouteCommand"; 
        return command;
    }
}
export class GetRefImageCommand implements Command {
    refImageId: number;
    static create(command : GetRefImageCommand) : GetRefImageCommand { 
        command["__CommandType"] = "GetRefImageCommand"; 
        return command;
    }
}
export class GetRefRegionCommand implements Command {
    includeObjects: boolean;
    maxCities: number;
    refRegionId: number;
    static create(command : GetRefRegionCommand) : GetRefRegionCommand { 
        command["__CommandType"] = "GetRefRegionCommand"; 
        return command;
    }
}
export class GetRouteCategoriesCommand implements Command {
    static create(command : GetRouteCategoriesCommand) : GetRouteCategoriesCommand { 
        command["__CommandType"] = "GetRouteCategoriesCommand"; 
        return command;
    }
}
export class GetRoutesCommand implements Command {
    static create(command : GetRoutesCommand) : GetRoutesCommand { 
        command["__CommandType"] = "GetRoutesCommand"; 
        return command;
    }
}
export class GetTourCategoriesCommand implements Command {
    static create(command : GetTourCategoriesCommand) : GetTourCategoriesCommand { 
        command["__CommandType"] = "GetTourCategoriesCommand"; 
        return command;
    }
}
export class GetToursCommand implements Command {
    static create(command : GetToursCommand) : GetToursCommand { 
        command["__CommandType"] = "GetToursCommand"; 
        return command;
    }
}
export class GetUsageNrOfTotalCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    static create(command : GetUsageNrOfTotalCommand) : GetUsageNrOfTotalCommand { 
        command["__CommandType"] = "GetUsageNrOfTotalCommand"; 
        return command;
    }
}
export class GetUsageNrOfUserIPsCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    static create(command : GetUsageNrOfUserIPsCommand) : GetUsageNrOfUserIPsCommand { 
        command["__CommandType"] = "GetUsageNrOfUserIPsCommand"; 
        return command;
    }
}
export class GetUsageNrOfUsersCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    static create(command : GetUsageNrOfUsersCommand) : GetUsageNrOfUsersCommand { 
        command["__CommandType"] = "GetUsageNrOfUsersCommand"; 
        return command;
    }
}
export class GetUsageOfUserCommand implements Command {
    actionType: EnumActionType;
    appType: EnumAppType;
    fromDate: Date;
    toDate: Date;
    userId: number;
    static create(command : GetUsageOfUserCommand) : GetUsageOfUserCommand { 
        command["__CommandType"] = "GetUsageOfUserCommand"; 
        return command;
    }
}
export class GetUsersCommand implements Command {
    static create(command : GetUsersCommand) : GetUsersCommand { 
        command["__CommandType"] = "GetUsersCommand"; 
        return command;
    }
}
export class IncreaseDownloadedMapsCommand implements Command {
    static create(command : IncreaseDownloadedMapsCommand) : IncreaseDownloadedMapsCommand { 
        command["__CommandType"] = "IncreaseDownloadedMapsCommand"; 
        return command;
    }
}
export class IsApiAvailableCommand implements Command {
    static create(command : IsApiAvailableCommand) : IsApiAvailableCommand { 
        command["__CommandType"] = "IsApiAvailableCommand"; 
        return command;
    }
}
export class SearchPublicRoutesByFilterCommand implements Command {
    approved: number;
    onlyMainData: boolean;
    searchFilter: MapSearchRouteFilter;
    static create(command : SearchPublicRoutesByFilterCommand) : SearchPublicRoutesByFilterCommand { 
        command["__CommandType"] = "SearchPublicRoutesByFilterCommand"; 
        return command;
    }
}
export class SearchRefRegionListByNameCommand implements Command {
    searchString: string;
    static create(command : SearchRefRegionListByNameCommand) : SearchRefRegionListByNameCommand { 
        command["__CommandType"] = "SearchRefRegionListByNameCommand"; 
        return command;
    }
}
export class SendNotification implements Command {
    static create(command : SendNotification) : SendNotification { 
        command["__CommandType"] = "SendNotification"; 
        return command;
    }
}
export class SetNotificationSubscribtionCommand implements Command {
    subscription: string;
    static create(command : SetNotificationSubscribtionCommand) : SetNotificationSubscribtionCommand { 
        command["__CommandType"] = "SetNotificationSubscribtionCommand"; 
        return command;
    }
}
export class TestCommand implements Command {
    dateParameter: Date;
    parameter1: string;
    static create(command : TestCommand) : TestCommand { 
        command["__CommandType"] = "TestCommand"; 
        return command;
    }
}
export class UpdateLiveTrackingCommand implements Command {
    updateLiveTracking: LiveTracking;
    static create(command : UpdateLiveTrackingCommand) : UpdateLiveTrackingCommand { 
        command["__CommandType"] = "UpdateLiveTrackingCommand"; 
        return command;
    }
}
export class UpdatePlaceCategoryCommand implements Command {
    updatePlaceCategory: MapPlaceCategory;
    static create(command : UpdatePlaceCategoryCommand) : UpdatePlaceCategoryCommand { 
        command["__CommandType"] = "UpdatePlaceCategoryCommand"; 
        return command;
    }
}
export class UpdatePlaceCommand implements Command {
    updatePlace: MapPlace;
    static create(command : UpdatePlaceCommand) : UpdatePlaceCommand { 
        command["__CommandType"] = "UpdatePlaceCommand"; 
        return command;
    }
}
export class UpdatePublicRouteCommand implements Command {
    updatePublicRoute: MapPublicRoute;
    static create(command : UpdatePublicRouteCommand) : UpdatePublicRouteCommand { 
        command["__CommandType"] = "UpdatePublicRouteCommand"; 
        return command;
    }
}
export class UpdateRouteCategoryCommand implements Command {
    updateRouteCategory: MapRouteCategory;
    static create(command : UpdateRouteCategoryCommand) : UpdateRouteCategoryCommand { 
        command["__CommandType"] = "UpdateRouteCategoryCommand"; 
        return command;
    }
}
export class UpdateRouteCommand implements Command {
    updateRoute: MapRoute;
    static create(command : UpdateRouteCommand) : UpdateRouteCommand { 
        command["__CommandType"] = "UpdateRouteCommand"; 
        return command;
    }
}
export class UpdateTourCategoryCommand implements Command {
    updateTourCategory: MapTourCategory;
    static create(command : UpdateTourCategoryCommand) : UpdateTourCategoryCommand { 
        command["__CommandType"] = "UpdateTourCategoryCommand"; 
        return command;
    }
}
export class UpdateTourCommand implements Command {
    updateTour: MapTour;
    static create(command : UpdateTourCommand) : UpdateTourCommand { 
        command["__CommandType"] = "UpdateTourCommand"; 
        return command;
    }
}

//#endregion


//#region Command results

export interface AddLiveTrackingCommandResult extends CommandResult {
    addedLiveTracking: LiveTracking;
    status: EnumGlobalStatusCode;
}

export interface AddLiveTrackingPositionCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface AddPlaceCategoryCommandResult extends CommandResult {
    addedPlaceCategory: MapPlaceCategory;
    status: EnumGlobalStatusCode;
}

export interface AddPlaceCommandResult extends CommandResult {
    addedPlace: MapPlace;
    status: EnumGlobalStatusCode;
}

export interface AddPublicRouteCommandResult extends CommandResult {
    addedRoute: MapPublicRoute;
    status: EnumGlobalStatusCode;
}

export interface AddRouteCategoryCommandResult extends CommandResult {
    addedRouteCategory: MapRouteCategory;
    status: EnumGlobalStatusCode;
}

export interface AddRouteCommandResult extends CommandResult {
    addedRoute: MapRoute;
    status: EnumGlobalStatusCode;
}

export interface AddTourCategoryCommandResult extends CommandResult {
    addedTourCategory: MapTourCategory;
    status: EnumGlobalStatusCode;
}

export interface AddTourCommandResult extends CommandResult {
    addedTour: MapTour;
    status: EnumGlobalStatusCode;
}

export interface AddUsageLogCommandResult extends CommandResult {
    addedUsageLog: UsageLog;
}

export interface AddUsageLogMapsCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface AnalysePublicRoutesCommandResult extends CommandResult {
    reports: Array<string>;
    status: EnumGlobalStatusCode;
}

export interface DeletePlaceCategoryCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface DeletePlaceCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface DeletePublicRouteCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface DeleteRouteCategoryCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface DeleteRouteCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface DeleteTourCategoryCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface DeleteTourCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface DoConvertXCommandResult extends CommandResult {
    success: boolean;
}

export interface DoRepairItemsXCommandResult extends CommandResult {
    success: boolean;
}

export interface ExistsPublicRouteCommandResult extends CommandResult {
    existent: boolean;
    status: EnumGlobalStatusCode;
}

export interface GetLiveTrackingCommandResult extends CommandResult {
    liveTracking: LiveTracking;
    status: EnumGlobalStatusCode;
}

export interface GetLiveTrackingPositionsCommandResult extends CommandResult {
    liveTrackingPositions: Array<LiveTrackPosition>;
    status: EnumGlobalStatusCode;
}

export interface GetLoggedInUserCommandResult extends CommandResult {
    user: User;
}

export interface GetPlaceCategoriesCommandResult extends CommandResult {
    placeCategories: Array<MapPlaceCategory>;
    status: EnumGlobalStatusCode;
}

export interface GetPlacesCommandResult extends CommandResult {
    places: Array<MapPlace>;
    status: EnumGlobalStatusCode;
}

export interface GetPublicRouteByRouteIdCommandResult extends CommandResult {
    route: MapPublicRoute;
    status: EnumGlobalStatusCode;
}

export interface GetPublicRouteCommandResult extends CommandResult {
    route: MapPublicRoute;
    status: EnumGlobalStatusCode;
}

export interface GetRefImageCommandResult extends CommandResult {
    refImage: RefImage;
}

export interface GetRefRegionCommandResult extends CommandResult {
    refRegion: RefRegion;
    status: EnumGlobalStatusCode;
}

export interface GetRouteCategoriesCommandResult extends CommandResult {
    routeCategories: Array<MapRouteCategory>;
    status: EnumGlobalStatusCode;
}

export interface GetRoutesCommandResult extends CommandResult {
    routes: Array<MapRoute>;
    status: EnumGlobalStatusCode;
}

export interface GetTourCategoriesCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
    tourCategories: Array<MapTourCategory>;
}

export interface GetToursCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
    tours: Array<MapTour>;
}

export interface GetUsageNrOfTotalCommandResult extends CommandResult {
    nrOfTotal: number;
}

export interface GetUsageNrOfUserIPsCommandResult extends CommandResult {
    nrOfUserIPs: number;
}

export interface GetUsageNrOfUsersCommandResult extends CommandResult {
    nrOfUsers: number;
}

export interface GetUsageOfUserCommandResult extends CommandResult {
    nrOfUses: number;
}

export interface GetUsersCommandResult extends CommandResult {
    users: Array<User>;
}

export interface IncreaseDownloadedMapsCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface IsApiAvailableCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface SearchPublicRoutesByFilterCommandResult extends CommandResult {
    routes: Array<MapPublicRoute>;
    status: EnumGlobalStatusCode;
}

export interface SearchRefRegionListByNameCommandResult extends CommandResult {
    refRegions: Array<RefRegion>;
    status: EnumGlobalStatusCode;
}

export interface SendNotificationCommandResult extends CommandResult {
    sendNotification: boolean;
}

export interface SetNotificationSubscribtionCommandResult extends CommandResult {
    saved: boolean;
}

export interface TestCommandResult extends CommandResult {
    resultDateValue: Date;
    resultDateValue2: Date;
    resultValue1: string;
    userInfo: string;
}

export interface UpdateLiveTrackingCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface UpdatePlaceCategoryCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface UpdatePlaceCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface UpdatePublicRouteCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface UpdateRouteCategoryCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface UpdateRouteCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface UpdateTourCategoryCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}

export interface UpdateTourCommandResult extends CommandResult {
    status: EnumGlobalStatusCode;
}


//#endregion

//#region Data transfer objects

export interface Container {
    connectLocOnMap: boolean;
    containerId: number;
    containerItems: Array<SortObject>;
    containers: Array<Container>;
    containerType: EnumContainerType;
    duties: Array<Duty>;
    eventId: number;
    events: Array<Event>;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    places: Array<Place>;
    refContainerId: number;
    refPlaceId: number;
    refRegionId: number;
    restoId: number;
    restos: Array<Resto>;
    stayId: number;
    stays: Array<Stay>;
    title: string;
    transferId: number;
    transfers: Array<Transfer>;
    tripId: number;
    userId: number;
    visibleOnMap: boolean;
    xdocs: Array<XDoc>;
}

export interface Duty {
    containerId: number;
    duration: number;
    dutyType: EnumDutyType;
    eventId: number;
    from: Date;
    id: number;
    order: number;
    placeId: number;
    priority: number;
    restoId: number;
    startAfterDuty: number;
    status: EnumDutyStatus;
    stayId: number;
    text: string;
    title: string;
    to: Date;
    transferId: number;
    tripId: number;
}

export interface Event {
    containerId: number;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    eventCategory?: EnumEventCategory;
    eventType?: EnumEventType;
    fixed: boolean;
    from: Date;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    rating: number;
    refEventId: number;
    to: Date;
    transferId: number;
    tripId: number;
    zoom: number;
}

export interface Image {
    containerId: number;
    description: string;
    eventId: number;
    id: number;
    noticeId: number;
    order: number;
    placeId: number;
    providerName: string;
    refImageId: number;
    restoId: number;
    stayId: number;
    title: string;
    transferId: number;
    tripId: number;
    url: string;
}

export interface Info {
    containerId: number;
    dataType: EnumInfoDataType;
    eventId: number;
    id: number;
    infoType: EnumThemeType;
    order: number;
    placeId: number;
    providerName: string;
    refInfoId: number;
    restoId: number;
    stayId: number;
    text: string;
    title: string;
    transferId: number;
    tripId: number;
}

export interface Link {
    containerId: number;
    description: string;
    eventId: number;
    id: number;
    linkType: EnumThemeType;
    order: number;
    placeId: number;
    providerName: string;
    refLinkId: number;
    restoId: number;
    stayId: number;
    title: string;
    transferId: number;
    tripId: number;
    url: string;
}

export interface LiveTracking {
    aborted: boolean;
    drawOnlyEndPoint: boolean;
    duration: number;
    endTime: Date;
    id: number;
    name: string;
    startTime: Date;
    storeOnlyEndPosition: boolean;
    trackingId: string;
    trackPositions: Array<LiveTrackPosition>;
    userId: number;
}

export interface LiveTrackPosition {
    at: Date;
    coordLat: number;
    coordLon: number;
    id: number;
    trackId: string;
}

export interface MapPlace {
    categoryId: number;
    coordLat: number;
    coordLon: number;
    description: string;
    id: number;
    name: string;
    order: number;
    userId: number;
}

export interface MapPlaceCategory {
    description: string;
    id: number;
    mapPlaceFormat: string;
    mapPlaceFormatVersion: number;
    name: string;
    order: number;
    type: string;
    userId: number;
    visible: boolean;
}

export interface MapPublicRoute {
    alternateRouteIds: number;
    approved: boolean;
    ascent: number;
    backToStart: boolean;
    bboxLatFrom: number;
    bboxLatTo: number;
    bboxLonFrom: number;
    bboxLonTo: number;
    categories: string;
    createdAt: Date;
    descent: number;
    description: string;
    descriptionDe: string;
    descriptionEn: string;
    difficultyLevel: EnumDifficultyLevel;
    duration: number;
    geojsonRoute: string;
    id: number;
    imageIds: string;
    months: string;
    name: string;
    nameDe: string;
    nameEn: string;
    networkType: string;
    order: number;
    parentRouteId: number;
    plannerOptions: string;
    pois: string;
    rating: number;
    regionIds: string;
    roundRoute: boolean;
    routeId: string;
    routeLength: number;
    routeType: EnumRouteType;
    startLat: number;
    startLon: number;
    updatedAt: Date;
    userId: number;
    wayPoints: string;
}

export interface MapRoute {
    backToStart: boolean;
    categoryId: number;
    description: string;
    geojsonRoute: string;
    id: number;
    name: string;
    order: number;
    plannerOptions: string;
    pois: string;
    roundRoute: boolean;
    routeId: string;
    userId: number;
    wayPoints: string;
}

export interface MapRouteCategory {
    description: string;
    id: number;
    name: string;
    order: number;
    routeFormat: string;
    routeFormatVersion: number;
    type: string;
    userId: number;
    visible: boolean;
}

export interface MapSearchRouteFilter {
    bboxLatFrom: number;
    bboxLatTo: number;
    bboxLonFrom: number;
    bboxLonTo: number;
    inBbox: boolean;
}

export interface MapTour {
    averageVelocity: number;
    categoryId: number;
    doneAscents: number;
    doneDescents: number;
    doneDistance: number;
    id: number;
    maxAltidute: number;
    minAltidute: number;
    name: string;
    notes: string;
    numGaps: number;
    order: number;
    routeId: string;
    routeString: string;
    timeInMotion: number;
    tourEndTime: Date;
    tourStartTime: Date;
    trackPoints: string;
    userId: number;
}

export interface MapTourCategory {
    description: string;
    id: number;
    mapTourFormat: string;
    mapTourFormatVersion: number;
    name: string;
    order: number;
    type: string;
    userId: number;
    visible: boolean;
}

export interface Notice {
    containerId: number;
    createdAt: Date;
    eventId: number;
    id: number;
    noticeType: EnumNoticeType;
    order: number;
    placeId: number;
    restoId: number;
    stayId: number;
    text: string;
    title: string;
    transferId: number;
    tripId: number;
}

export interface Place {
    containerId: number;
    containers: Array<Container>;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    events: Array<Event>;
    fixed: boolean;
    from: Date;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    places: Array<Place>;
    rating: number;
    refPlaceId: number;
    restos: Array<Resto>;
    stays: Array<Stay>;
    to: Date;
    tripId: number;
    zoom: number;
}

export interface RefContainer {
    containerItems: Array<SortObject>;
    containerType: EnumContainerType;
    id: number;
    marked: boolean;
    order: number;
    rating: number;
    refContainerId: number;
    refContainers: Array<RefContainer>;
    refEventId: number;
    refEvents: Array<RefEvent>;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    refPlaces: Array<RefPlace>;
    refRegionId: number;
    refRestoId: number;
    refRestos: Array<RefResto>;
    refStayId: number;
    refStays: Array<RefStay>;
    refTransferId: number;
    refTransfers: Array<RefTransfer>;
    searchName: string;
    themeType?: EnumThemeType;
    title: string;
}

export interface RefEvent {
    coordLat: number;
    coordLon: number;
    dataObectjId: number;
    description: string;
    descUrl: string;
    eventCategory?: EnumEventCategory;
    eventType?: EnumEventType;
    from: Date;
    id: number;
    marked: boolean;
    name: string;
    neededHours: number;
    rating: number;
    refContainerId: number;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    refRegionId: number;
    refTransferId: number;
    sourceType?: EnumDataSourceType;
    to: Date;
    wikiName: string;
    zoom: number;
}

export interface RefImage {
    attribution: string;
    coordLat: number;
    coordLon: number;
    description: string;
    height: number;
    id: number;
    marked: boolean;
    order: number;
    providerName: string;
    quality: number;
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    refTransferId: number;
    sourceType?: EnumImageSourceType;
    themeType?: EnumThemeType;
    title: string;
    type?: EnumImageType;
    url: string;
    width: number;
}

export interface RefInfo {
    dataType: EnumInfoDataType;
    id: number;
    infoType: EnumThemeType;
    langCode: string;
    marked: boolean;
    order: number;
    providerName: string;
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    refTransferId: number;
    text: string;
    title: string;
}

export interface RefLink {
    description: string;
    id: number;
    langCode: string;
    linkType: EnumThemeType;
    marked: boolean;
    order: number;
    providerName: string;
    refContainerId: number;
    refEventId: number;
    refPlaceId: number;
    refRegionId: number;
    refRestoId: number;
    refStayId: number;
    refTransferId: number;
    title: string;
    url: string;
}

export interface RefOLI {
    id: number;
    linkType?: EnumObjectType;
    locationName: string;
    marked: boolean;
    objectId: number;
    objectName: string;
    objectText: string;
    objectType: EnumObjectType;
    refContainer: RefContainer;
    refContainerId: number;
    refEvent: RefEvent;
    refEventId: number;
    refImage: RefImage;
    refImageId: number;
    refInfo: RefInfo;
    refInfoId: number;
    refLink: RefLink;
    refLinkId: number;
    refPlace: RefPlace;
    refPlaceId: number;
    refRegionId: number;
    refResto: RefResto;
    refRestoId: number;
    refStay: RefStay;
    refStayId: number;
    refTransferId: number;
}

export interface RefPlace {
    area: number;
    coordLat: number;
    coordLon: number;
    countryCode: string;
    dataObjectId: number;
    description: string;
    descUrl: string;
    id: number;
    localName: string;
    marked: boolean;
    name: string;
    namePath: string;
    placeCode: string;
    placeType?: EnumPlaceType;
    population: number;
    rating: number;
    ratingActivity: number;
    ratingCulture: number;
    ratingNature: number;
    refContainerId: number;
    refContainers: Array<RefContainer>;
    refEvents: Array<RefEvent>;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    refPlaces: Array<RefPlace>;
    refRegionId: number;
    refRestos: Array<RefResto>;
    refStays: Array<RefStay>;
    sourceType?: EnumDataSourceType;
    wikiName: string;
    zoom: number;
}

export interface RefRegion {
    area: number;
    bestVisit: string;
    coordLat: number;
    coordLon: number;
    countryCode: string;
    dataObjectId: number;
    description: string;
    id: number;
    localName: string;
    mapType?: EnumMapType;
    name: string;
    namePath: string;
    noTryLinks: boolean;
    parentId: number;
    population: number;
    prepairLevel: number;
    refContainers: Array<RefContainer>;
    refEvents: Array<RefEvent>;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaces: Array<RefPlace>;
    refTransfers: Array<RefTransfer>;
    refTrips: Array<Trip>;
    regionClass?: EnumRegionClass;
    regionCode: string;
    sourceName: string;
    sourceType?: EnumDataSourceType;
    subRegionType: string;
    wikiName: string;
    zoom: number;
}

export interface RefResto {
    address: string;
    coordLat: number;
    coordLon: number;
    description: string;
    descUrl: string;
    email: string;
    foodType?: EnumFoodType;
    id: number;
    marked: boolean;
    name: string;
    priceCategory: number;
    rating: number;
    refContainerId: number;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    restoType?: EnumRestoType;
    specials: string;
    tel: string;
    zoom: number;
}

export interface RefStay {
    address: string;
    bookingPlatform: number;
    bookingType: number;
    coordLat: number;
    coordLon: number;
    description: string;
    descUrl: string;
    email: string;
    id: number;
    marked: boolean;
    minimumStay: number;
    name: string;
    placeType?: EnumSituationType;
    priceCategory: number;
    rating: number;
    recommender: string;
    refContainerId: number;
    refImages: Array<RefImage>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    refOlis: Array<RefOLI>;
    refPlaceId: number;
    stayType?: EnumStayType;
    tel: string;
    zoom: number;
}

export interface RefTransfer {
    app: EnumApp;
    coordFromLat: number;
    coordFromLon: number;
    coordToLat: number;
    coordToLon: number;
    description: string;
    descUrl: string;
    duration: number;
    from: Date;
    fromRefPlace: RefPlace;
    fromRefPlaceId: number;
    id: number;
    marked: boolean;
    name: string;
    rating: number;
    refEvents: Array<RefEvent>;
    refInfos: Array<RefInfo>;
    refLinks: Array<RefLink>;
    routeParam: string;
    to: Date;
    toRefPlace: RefPlace;
    toRefPlaceId: number;
    transportId: number;
    transportType: EnumTransportType;
}

export interface Resto {
    address: string;
    booked: boolean;
    containerId: number;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    email: string;
    fixed: boolean;
    foodType?: EnumFoodType;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    priceCategory: number;
    rating: number;
    refRestoId: number;
    restoType?: EnumRestoType;
    specials: string;
    tel: string;
    tripId: number;
    visitAt: Date;
    zoom: number;
}

export interface SortObject {
    id: number;
    order: number;
}

export interface Stay {
    address: string;
    booked: boolean;
    containerId: number;
    coordLat: number;
    coordLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    email: string;
    fixed: boolean;
    from: Date;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    placeId: number;
    placeType?: EnumSituationType;
    priceCategory: number;
    rating: number;
    refStayId: number;
    stayType?: EnumStayType;
    tel: string;
    to: Date;
    tripId: number;
    xDocs: Array<XDoc>;
    zoom: number;
}

export interface Transfer {
    app: EnumApp;
    containerId: number;
    coordFromLat: number;
    coordFromLon: number;
    coordToLat: number;
    coordToLon: number;
    dateActive: number;
    description: string;
    descUrl: string;
    duties: Array<Duty>;
    events: Array<Event>;
    fixed: boolean;
    from: Date;
    fromPlaceName: string;
    fromRefPlaceId: number;
    id: number;
    images: Array<Image>;
    infos: Array<Info>;
    links: Array<Link>;
    marked: boolean;
    name: string;
    notices: Array<Notice>;
    order: number;
    refTransferId: number;
    routeParam: string;
    to: Date;
    toPlaceName: string;
    toRefPlaceId: number;
    transportType: EnumTransportType;
    tripId: number;
}

export interface Trip {
    bestTravelTime: string;
    category: string;
    connectLocOnMap: boolean;
    containers: Array<Container>;
    createdAt: Date;
    events: Array<Event>;
    from: Date;
    id: number;
    infos: Array<Info>;
    isRefTrip: boolean;
    links: Array<Link>;
    name: string;
    places: Array<Place>;
    refRegionId: number;
    regionName: string;
    restos: Array<Resto>;
    stays: Array<Stay>;
    to: Date;
    transfers: Array<Transfer>;
    tripPriv: number;
    tripShare: TripShare;
    userId: number;
    useTimeMode: boolean;
    viewMode: EnumTripViewMode;
    visible: boolean;
}

export interface TripShare {
    allowPrivateData: boolean;
    allowShare: boolean;
    category: string;
    changedAt: Date;
    connectLocOnMap: boolean;
    email: string;
    id: number;
    key: string;
    shareName: string;
    status: EnumShareStatus;
    tripId: number;
    tripPriv: EnumTripPriv;
    userId: number;
    useTimeMode: boolean;
    viewMode: number;
    visible: boolean;
}

export interface UsageLog {
    actionInfo: string;
    actionType: EnumActionType;
    appType: EnumAppType;
    appVer: string;
    at: Date;
    id: number;
    partnerId: string;
    referrer: string;
    sessionId: string;
    userId: number;
    userIP: string;
    userLanguage: string;
}

export interface UsageLogMaps {
    actionInfo: string;
    actionType: EnumActionType;
    appVer: string;
    at: Date;
    id: number;
    partnerId: string;
    referrer: string;
    sessionId: string;
    userId: number;
    userIP: string;
    userLanguage: string;
}

export interface User {
    accountId: string;
    adminPriv: EnumAdminPriv;
    email: string;
    firstLogin: Date;
    id: number;
    lastLogin: Date;
    loginProvider?: EnumLoginProvider;
    mapCount: number;
    mapPriv: number;
    maxDocSize: number;
    maxImageSize: number;
    maxItemsPerTrip: number;
    maxMapCount: number;
    maxTilesPerMap: number;
    maxTrips: number;
    name: string;
    roleID: number;
    tripCopyPriv: boolean;
}

export interface XDoc {
    containerId: number;
    eventId: number;
    id: number;
    order: number;
    oriFileName: string;
    placeId: number;
    providerName: string;
    restoId: number;
    stayId: number;
    title: string;
    transferId: number;
    url: string;
}


//#endregion

//#region Enums

export const enum EnumActionType {
    Undef = 0,
    init = 1,
    autostart = 2,
    search_click = 1011,
    planner_click = 1012,
    loadgpx_click = 1013,
    tracking_click = 1014,
    settings_click = 1015,
    selectmap_click = 1016,
    help_click = 1017,
    saved_click = 1018,
    search_route_click = 1019,
    search_restos_click = 1023,
    search_hotels_click = 1024,
    search_supermarkets_click = 1025,
    search_pois_click = 1026,
    start_navi = 1031,
    stop_navi = 1032,
    activate_saved_route = 1033,
    add_route_poi = 1034,
    add_marker = 1035,
    publish_route = 1036,
    activate_public_route = 1037,
    save_route_device = 1038,
    save_route_cloud = 1039,
    send_location = 1041,
    send_livetracker = 1042,
    search_route = 1043,
    draw_public_route = 1044,
    draw_all_public_routes = 1045,
    call_ors_direction = 1051,
    call_ors_geocode = 1052,
    call_ors_reversegeocode = 1053,
    call_ors_searchautocomplete = 1054,
    public_route_by_url = 1061,
    activate_live_tracking_by_url = 1062,
    feedback_bug = 1091,
    feedback_question = 1092,
}

export const enum EnumAdminPriv {
    PrivNone = 0,
    PrivGet = 1,
    PrivUpdate = 2,
    PrivAdd = 3,
    PrivDelete = 4,
    PrivLevel1 = 11,
    PrivLevel2 = 12,
}

export const enum EnumApp {
    Trip4YouMaps = 1,
    GoogleMaps = 101,
}

export const enum EnumAppType {
    Undef = 0,
    Maps = 1,
    Explore = 2,
    Trips = 3,
}

export const enum EnumContainerType {
    Undef = 0,
    Standard = 1,
    Hiking = 2001,
    Cycling = 2002,
    Diving = 2003,
    Beaches = 2004,
    NationalParks = 2005,
    NatureParks = 2006,
    HikingRoute = 4001,
    BikeRoute = 4002,
    NationalPark = 4005,
}

export const enum EnumDataSourceType {
    Undef = 0,
    geonames = 1,
    wikipedia = 11,
    wikitravel = 12,
    wikivoyage = 13,
}

export const enum EnumDifficultyLevel {
    Undefined = 0,
    Easy = 1,
    Moderate = 2,
    Difficult = 3,
}

export const enum EnumDutyStatus {
    Undef = 0,
    wait = 1,
    active = 2,
    critical = 3,
    finished = 11,
}

export const enum EnumDutyType {
    Undef = 0,
    call = 1,
    sendmail = 2,
    waitformail = 3,
    book = 4,
    buy = 5,
    organise = 6,
    todo = 7,
    other = 99,
}

export const enum EnumEventCategory {
    Undef = 0,
    Activity = 1,
    Culture = 2,
    Nature = 3,
    Mixed = 99,
}

export const enum EnumEventType {
    Undef = 0,
    Visit = 1,
    Do = 2,
    Participate = 3,
    See = 4,
    Learn = 5,
    Hike = 11,
    Bike = 12,
    Dive = 21,
    Snorkel = 22,
    DiveSnorkel = 23,
}

export const enum EnumFoodType {
    Undef = 0,
    traditional = 1,
    international = 2,
    homemade = 3,
    vegetarian = 11,
    vegan = 12,
    seafood = 13,
    westernFood = 14,
    italian = 21,
    indian = 22,
    indonesian = 23,
    chinese = 24,
    mediteranean = 25,
    greek = 26,
    southamerican = 27,
    thai = 28,
    asian = 29,
}

export const enum EnumGlobalStatusCode {
    UnknownError = 0,
    Success = 1,
    HttpError = 11,
    ORSError = 21,
    OverpassError = 22,
    UserNotLoggedin = 1001,
    NotEnoughPrivileges = 1002,
    ObjectNotInDb = 1003,
    AccessNotPermited = 1004,
    MissingLinkObject = 1005,
    NameConflict = 1006,
    NoTripsAvailable = 1007,
    NoItemsAvailable = 1008,
    TripIsNoDemoTrip = 1009,
    ShareKeyNotFound = 1010,
    ShareKeyAlreadyUsedByUser = 1011,
    ShareKeyAlreadyUsedByOtherUser = 1012,
    TripNotFound = 1013,
}

export const enum EnumImageSourceType {
    Undef = 0,
    BLOB = 1,
    Link = 2,
}

export const enum EnumImageType {
    Undef = 0,
    Banner = 1,
    Landscape = 2,
    Portrait = 3,
}

export const enum EnumInfoDataType {
    Undef = 0,
    Text = 1,
    HTML = 2,
    Data = 9,
}

export const enum EnumLoginProvider {
    GoogleLogin = 1,
    GitHubLogin = 2,
}

export const enum EnumMapType {
    Undef = 0,
    OSM = 1,
    Outdoor = 2,
    Topo = 3,
    Streets = 4,
    Satellite = 5,
    Hydrid = 6,
}

export const enum EnumNoticeType {
    Undef = 0,
    remember = 1,
    documentation = 2,
    booking = 3,
    diary = 11,
    other = 99,
}

export const enum EnumObjectType {
    Undef = 0,
    region = 1,
    container = 2,
    place = 3,
    xevent = 4,
    stay = 5,
    resto = 6,
    transfer = 7,
    info = 8,
    link = 9,
    image = 10,
    duty = 11,
    notice = 12,
    xdoc = 13,
}

export const enum EnumPlaceType {
    Undef = 0,
    city = 1,
    village = 2,
    capital = 3,
    island = 4,
    archipelago = 5,
    comune = 6,
    neighborhood = 7,
    beach = 11,
    lake = 12,
    mountain = 13,
    admin1capital = 31,
    admin2capital = 32,
    admin3capital = 33,
    region = 101,
}

export const enum EnumRegionClass {
    Undef = 0,
    world = 1,
    continent = 2,
    subcontinent = 3,
    region = 4,
    country = 11,
    admin1 = 12,
    admin2 = 13,
    admin3 = 14,
    island = 21,
    penuinsula = 22,
    archipelago = 23,
    container = 51,
}

export const enum EnumRestoType {
    Undef = 0,
    simplerest = 1,
    stdrest = 2,
    hotelrest = 3,
    specialrest = 8,
    stand = 9,
    fastfood = 21,
    bar = 51,
    cafe = 52,
}

export const enum EnumRouteType {
    Undefined = 0,
    OneWay = 1,
    BackToStart = 2,
    RoundRoute = 3,
}

export const enum EnumShareStatus {
    New = 0,
    Send = 1,
    Used = 2,
    Returned = 9,
}

export const enum EnumSituationType {
    Undef = 0,
    Center = 1,
    Suburb = 2,
    Village = 3,
    Countryside = 11,
    Riverside = 12,
    Lakeside = 13,
    Beachside = 14,
}

export const enum EnumStayType {
    Undef = 0,
    Smallhotel = 1,
    Bighotel = 2,
    Pension = 3,
    Resort = 4,
    Aparthotel = 5,
    Apartments = 6,
    Guesthouse = 11,
    HomeStay = 12,
    BAndB = 13,
    Agrotourism = 14,
}

export const enum EnumThemeType {
    Undef = 0,
    Basic = 1,
    Place = 2,
    ToSee = 3,
    ToDo = 4,
    GetIn = 5,
    GetAround = 6,
    Sleep = 7,
    Eat = 8,
    News = 9,
    Map = 10,
    Profile = 11,
    BestTravelTime = 51,
    Climate = 52,
    Politics = 53,
    Economy = 54,
    Health = 55,
    TravelInfo = 56,
    CardImage = 101,
    Booking = 201,
    BookingFlight = 202,
    BookingHotel = 203,
    BookingEvent = 204,
    SocialMedia = 211,
}

export const enum EnumTransportType {
    Undef = 0,
    Plane = 1,
    Train = 4,
    Bus = 5,
    Boat = 6,
    Taxi = 7,
    Private = 11,
    RentCar = 12,
    Bike = 21,
    Ebike = 22,
    MotorBike = 23,
    Other = 99,
}

export const enum EnumTripPriv {
    PrivNone = 0,
    PrivGet = 1,
    PrivAdd = 2,
    PrivUpdate = 3,
    PrivDelete = 4,
}

export const enum EnumTripViewMode {
    ListView = 1,
    DayByDayView = 2,
    NoticeView = 3,
    DutyView = 4,
    ImageView = 5,
}


//#endregion
