<!-- saved-items-component -->
<div *ngIf="true" style="padding:0.5rem;">

    <!-- saved-items-title -->
    <div style="display:flex; padding:0.5rem;">
        <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
            title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onBackClick()">
        <!-- title -->
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <span style="margin-left:0.5rem; font-size:x-large;">{{'MAPS.SAVED.DLG.TITLE' | translate}}</span>
        <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
        <!-- change-to-map -->
        <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
            alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onBackClick()">
    </div>
    <hr style="margin-top:0.2rem;margin-bottom:0.5rem;">

    <!-- tab-group -->
    <mat-tab-group (selectedIndexChange)="onTabChanged($event)" mat-stretch-tabs="true" mat-align-tabs="start">

        <!-- saved-routes -->
        <mat-tab label="{{'MAPS.SAVED.DLG.TAB-ROUTES_TITLE' | translate}}">
            <!-- localy or cloud -->
            <div *ngIf="loggedInUser && isOnline" style="margin-top: 1.0rem; padding:0.5rem;">
                <mat-radio-group name="saveType" [value]="saveType">
                    <mat-radio-button value="local"
                        (click)="onSaveTypeRoutesClick('local')">{{'MAPS.SAVED.DLG.DEVICE_RADIO_LBL' |
                        translate}}</mat-radio-button>
                    <mat-radio-button style="margin-left:1.0rem;" value="server"
                        (click)="onSaveTypeRoutesClick('server')">{{'MAPS.SAVED.DLG.CLOUD_RADIO_LBL' |
                        translate}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <!-- order route-categories -->
            <div style="margin-top:0.0rem; font-size:x-large; padding:0.5rem;">
                {{'MAPS.SAVED.DLG.TAB-ROUTES_TITLE' | translate}}
                <img width="20px" style="margin-left:0.5rem;" src="./assets/icons/order-bool-ascending.svg" alt="symbol"
                    title="{{'MAPS.SAVED.DLG.REORDER_CAT_ICON_T' | translate}}" (click)="onOrderRouteCategoriesClick()">
            </div>

            <!-- routes -->
            <ng-container *ngIf="true">
                <!-- manage route-categories -->
                <div *ngIf="!orderRouteCategories" cdkDropListGroup style="padding:0.5rem;">
                    <!--  loop route-categories -->
                    <div *ngFor="let cat of this.savedRoutes; let j=index" cdkDropList [id]="j" class="drop-list"
                        (cdkDropListDropped)="onDropRoute($event)" [cdkDropListData]="cat.routeItems"
                        style="margin-top:0.5rem; border:3px solid mediumslateblue; border-radius:15px; padding:0.5rem; font-size:large;">
                        <input type="checkbox" [(ngModel)]="cat.visible" (change)="onRouteCategoryVisiblilityChange(j)"
                            title="{{'MAPS.SAVED.DLG.LIST-VISIBILITY_TEXT' | translate}}">
                        <input type="color" id="routecatcolor" name="routecolor" [(ngModel)]="cat.lineStyle.lineColor"
                            style="margin:0.5rem;" (change)="onRouteCategoryColorChange(j)">
                        <span *ngIf="j!==selectedSavedRouteCategoryIndex || !isActiveRenameSavedRouteCategory"
                            style="cursor:pointer; margin-left:0.5rem;"
                            [matMenuTriggerFor]="favcatmenu">{{cat.name}}</span>
                        <input *ngIf="j===selectedSavedRouteCategoryIndex && isActiveRenameSavedRouteCategory"
                            #catrouteinput type="text" spellcheck="false" enterkeyhint="go"
                            style="margin-left:0.5rem; height:1.8rem; border-radius:5px;  font-size:large;"
                            [class.list-input-s]="uiType=='S'" [class.list-input-l]="uiType=='L'" [(ngModel)]="cat.name"
                            (keyup)="onRouteCategoryNameKeyup($event, j)" (blur)="onRouteCategoryNameBlur(j)">
                        <span *ngIf="cat.routeItems.length>0 && !cat.open" (click)="cat.open=!cat.open">
                            <img width="25px" src="./assets/icons/menu-down.svg"></span>
                        <span *ngIf="cat.routeItems.length>0 && cat.open" (click)="cat.open=!cat.open">
                            <img width="25px" src="./assets/icons/menu-up.svg"></span>
                        <mat-menu #favcatmenu="matMenu">
                            <button mat-menu-item (click)="onRenameRouteCategoryClick(j)">
                                <img width="20px" src="./assets/icons-new/rename-outline.svg">
                                {{'MAPS.SAVED.DLG.RENAME-LIST_TEXT' | translate}}
                            </button>
                            <button *ngIf="!cat.fixed" mat-menu-item (click)="onDeleteRouteCategoryClick(j)">
                                <img width="20px" src="./assets/icons/delete.svg">
                                {{'MAPS.SAVED.DLG.DEL-LIST_TEXT' | translate}}
                            </button>
                        </mat-menu>

                        <!-- routes -->
                        <ng-container *ngIf="cat.open">
                            <div *ngFor="let xFavRoute of cat.routeItems; let i=index" cdkDrag class="drag-box">
                                <div class="drag-handle" cdkDragHandle style="flex-grow:0; flex-shrink:0;">
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                        </path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </div>
                                <div *ngIf="true" style="margin-left:2.0rem; display:flex;">
                                    <div style="height:25px; flex-grow:0; flex-shrink:0;">
                                        <img *ngIf="xFavRoute.plannerOptions.moveType === 'bike'" width="20px"
                                            style="margin-top:5px;" src="./assets/icons/bike.svg">
                                        <img *ngIf="xFavRoute.plannerOptions.moveType === 'ebike'" width="20px"
                                            style="margin-top:5px;" src="./assets/icons/bicycle-electric.svg">
                                        <img *ngIf="xFavRoute.plannerOptions.moveType === 'bike-road'" width="20px"
                                            style="margin-top:5px;" src="./assets/icons/bike-road.svg">
                                        <img *ngIf="xFavRoute.plannerOptions.moveType === 'mtb'" width="20px"
                                            style="margin-top:5px;" src="./assets/icons/bike-mtb.svg">
                                        <img *ngIf="xFavRoute.plannerOptions.moveType === 'walking'" width="20px"
                                            style="margin-top:5px;" src="./assets/icons/walking.svg">
                                        <img *ngIf="xFavRoute.plannerOptions.moveType === 'hiking'" width="20px"
                                            style="margin-top:5px;" src="./assets/icons/hiking.svg">
                                        <img *ngIf="xFavRoute.plannerOptions.moveType === 'car'" width="20px"
                                            style="margin-top:5px;" src="./assets/icons/car-hatchback.svg">
                                    </div>
                                    <div
                                        style=" margin-top:0.5rem; margin-left:0.5rem; width:300px; flex-grow:1; flex-shrink:1;">
                                        <span *ngIf="i!==selectedRouteIndex || !isActiveRenameSavedRoute"
                                            [matMenuTriggerFor]="favmenu"
                                            style="cursor:pointer; font-size:medium">{{xFavRoute.name}}</span>
                                        <input
                                            *ngIf="i===selectedRouteIndex && j===selectedSavedRouteCategoryIndex && isActiveRenameSavedRoute"
                                            #routeinput type="text" spellcheck="false" enterkeyhint="go"
                                            style="margin-left:0.5rem; height:1.8rem; border-radius:5px; font-size:large;"
                                            [class.item-input-s]="uiType=='S'" [class.item-input-l]="uiType=='L'"
                                            [(ngModel)]="xFavRoute.name" (keyup)="onRouteNameKeyup($event, j, i)"
                                            (blur)="onRouteNameBlur(j, i)">
                                        <mat-menu #favmenu="matMenu">
                                            <button mat-menu-item (click)="onShowRouteDetailsClicked(j,i)">
                                                <img width="20px" src="./assets/icons/eye.svg">
                                                {{'MAPS.SAVED.DLG.SHOW-ROUTE-DETAILS_TEXT' | translate}}
                                            </button>
                                            <button mat-menu-item (click)="onActivateRouteClicked(i,j)">
                                                <img width="20px" src="./assets/icons-new/magic-staff.svg">
                                                {{'MAPS.SAVED.DLG.ACTIVATE-ROUTE_TEXT' | translate}}
                                            </button>
                                            <button mat-menu-item (click)="onZoomToRouteClick(i,j)">
                                                <img width="20px" src="./assets/icons/overscan.svg">
                                                {{'MAPS.SAVED.DLG.ZOOM-TO-ROUTE_TEXT' | translate}}
                                            </button>
                                            <button mat-menu-item (click)="onRenameRouteClick(i,j)">
                                                <img width="20px" src="./assets/icons-new/rename-outline.svg">
                                                {{'MAPS.SAVED.DLG.RENAME-ROUTE_TEXT' | translate}}
                                            </button>
                                            <button mat-menu-item (click)="onDeleteRouteClick(i,j)">
                                                <img width="20px" src="./assets/icons/delete.svg">
                                                {{'MAPS.SAVED.DLG.DEL-ROUTE_TEXT' | translate}}
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <!-- new route-category -->
                    <div style="margin-top:0.5rem; font-size:large;">
                        <input #catinput type="text" spellcheck="false" enterkeyhint="done"
                            style="height:1.8rem; border-radius:5px; font-size:large; padding-left:0.5rem;"
                            placeholder="{{'MAPS.SAVED.DLG.NEW-LIST_PLH' | translate}}" [(ngModel)]="newCategoryName"
                            (keyup)="onNewRouteCategoryNameKeyup($event)">
                        <button *ngIf="newCategoryName" mat-raised-button color="primary" style="margin-left:0.5rem;"
                            (click)="onCreateNewRouteCategory()">{{'MAPS.SAVED.DLG.CREATE-NEW-LIST_PLH' | translate}}
                        </button>
                    </div>

                    <!-- export routes & categories -->
                    <div *ngIf="isTestUser" style="margin-top:0.5rem; font-size:large;">
                        <a [href]="fileUrlRoutes" download="my-saved-routes.json">
                            <button mat-raised-button color="primary" (click)="onExportRoutesClick()">
                                <img width="20px" src="./assets/icons/download-outline.svg">
                                Export saved routes
                            </button>
                        </a>

                    </div>
                    <!-- import local routes & categories-->
                    <div *ngIf="saveType === 'local' && isTestUser" style="margin-top:0.5rem; font-size:large;">
                        <span>Select JSON-file with saved routes</span><br>
                        <input type="file" #routesFileInput (change)="onFileChangeRoutes($event)"
                            style="cursor:pointer; margin:0.5rem; font-size:large;" title="load JSON-file"
                            accept=".json">
                    </div>

                </div>

                <!-- reorder local route-categories -->
                <div *ngIf="orderRouteCategories" cdkDropListGroup style="padding:0.5rem;">
                    <div cdkDropList class="drop-list" (cdkDropListDropped)="onDropRouteCategory($event)"
                        [cdkDropListData]="savedRoutes">
                        <div *ngFor="let cat of this.savedRoutes; let j=index" cdkDrag class="drag-box">
                            <div *ngIf="true" style="margin-left:0.5rem;">
                                <div class="drag-handle" cdkDragHandle>
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                        </path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </div>
                            </div>
                            <div
                                style="padding-top:0.2rem; margin-left:2.5rem; width:300px; height:30px; font-size:large;">
                                <span>{{cat.name}}</span>
                            </div>
                        </div>
                        <div style="margin-top:0.5rem; margin-left:2.5rem;">
                            <button mat-raised-button color="primary" (click)="onOrderRouteCategoriesClick()">
                                {{'MAPS.SAVED.DLG.REORDER_END_BUTTON_TEXT' | translate}}</button>
                        </div>
                    </div>
                </div>
            </ng-container>

        </mat-tab>

        <!-- saved-places -->
        <mat-tab label="{{'MAPS.SAVED.DLG.TAB-PLACES_TITLE' | translate}}">

            <!-- localy or cloud -->
            <div *ngIf="loggedInUser && isOnline" style="margin-top: 1.0rem; padding:0.5rem;">
                <mat-radio-group name="savetypeplaces" [value]="saveType">
                    <mat-radio-button value="local"
                        (click)="onSaveTypePlacesClick('local')">{{'MAPS.SAVED.DLG.DEVICE_RADIO_LBL' |
                        translate}}</mat-radio-button>
                    <mat-radio-button style="margin-left:1.0rem;" value="server"
                        (click)="onSaveTypePlacesClick('server')">{{'MAPS.SAVED.DLG.CLOUD_RADIO_LBL' |
                        translate}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <!-- order route-categories -->
            <div style="margin-top:1.0rem; font-size:x-large; padding:0.5rem;">
                {{'MAPS.SAVED.DLG.TAB-PLACES_TITLE' | translate}}
                <img width="20px" style="margin-left:0.5rem;" src="./assets/icons/order-bool-ascending.svg" alt="symbol"
                    title="{{'MAPS.SAVED.DLG.REORDER_CAT_ICON_T' | translate}}" (click)="onOrderPlaceCategoriesClick()">
            </div>

            <!-- place-categories -->
            <!-- manage place-categories -->
            <div *ngIf="!orderPlaceCategories" cdkDropListGroup style="padding:0.5rem;">
                <!--  loop place-categories -->
                <div *ngFor="let cat of this.savedPlaces; let j=index" cdkDropList [id]="j" class="drop-list"
                    (cdkDropListDropped)="onDropPlace($event)" [cdkDropListData]="cat.placeItems"
                    style="margin-top:0.5rem; border:3px solid mediumslateblue; border-radius:15px; padding:0.5rem; font-size:large;">
                    <input type="checkbox" [(ngModel)]="cat.visible" (change)="onPlaceCategoryVisiblilityChange(j)"
                        title="{{'MAPS.SAVED.DLG.LIST-VISIBILITY_TEXT' | translate}}">
                    <input type="color" id="placecatcolor" name="placecolor" style="inline-size:10px;"
                        [(ngModel)]="cat.pointStyle.strokeColor" style="margin:0.5rem;"
                        (change)="onPlaceCategoryColorChange(j)">
                    <span *ngIf="j!==selectedSavedPlaceCategoryIndex || !isActiveRenameSavedPlaceCategory"
                        style="cursor:pointer; margin-left:0.5rem;" [matMenuTriggerFor]="favcatmenu">{{cat.name}}</span>
                    <input *ngIf="j===selectedSavedPlaceCategoryIndex && isActiveRenameSavedPlaceCategory"
                        #catplaceinput type="text" spellcheck="false" enterkeyhint="go"
                        style="margin-left:0.5rem; height:1.8rem; border-radius:5px;  font-size:large;"
                        [class.list-input-s]="uiType=='S'" [class.list-input-l]="uiType=='L'" [(ngModel)]="cat.name"
                        (keyup)="onPlaceCategoryNameKeyup($event, j)" (blur)="onPlaceCategoryNameBlur(j)">
                    <span *ngIf="cat.placeItems.length>0 && !cat.open" (click)="cat.open=!cat.open">
                        <img width="25px" src="./assets/icons/menu-down.svg"></span>
                    <span *ngIf="cat.placeItems.length>0 && cat.open" (click)="cat.open=!cat.open">
                        <img width="25px" src="./assets/icons/menu-up.svg"></span>
                    <mat-menu #favcatmenu="matMenu">
                        <button mat-menu-item (click)="onRenamePlaceCategoryClick(j)">
                            <img width="20px" src="./assets/icons-new/rename-outline.svg">
                            {{'MAPS.SAVED.DLG.RENAME-LIST_TEXT' | translate}}
                        </button>
                        <button *ngIf="!cat.fixed" mat-menu-item (click)="onDeletePlaceCategoryClick(j)">
                            <img width="20px" src="./assets/icons/delete.svg">
                            {{'MAPS.SAVED.DLG.DEL-LIST_TEXT' | translate}}
                        </button>
                    </mat-menu>
                    <!-- places -->
                    <ng-container *ngIf="cat.open">
                        <div *ngFor="let xFavPlace of cat.placeItems; let i=index" cdkDrag class="drag-box">
                            <div *ngIf="true" style="margin-left:2.0rem;">
                                <div class="drag-handle" cdkDragHandle>
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                        </path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </div>
                                <div style="width:300px; height:25px;">
                                    <img width="20px" src="./assets/icons/star-outline.svg">
                                    <span *ngIf="i!==selectedPlaceIndex || !isActiveRenameSavedPlace"
                                        [matMenuTriggerFor]="favmenu"
                                        style="cursor:pointer; margin-left:0.5rem; font-size:medium">{{xFavPlace.name}}</span>
                                    <input
                                        *ngIf="i===selectedPlaceIndex && j===selectedSavedPlaceCategoryIndex && isActiveRenameSavedPlace"
                                        #placeinput type="text" spellcheck="false" enterkeyhint="go"
                                        style="margin-left:0.5rem; height:1.8rem; border-radius:5px; font-size:large;"
                                        [class.item-input-s]="uiType=='S'" [class.item-input-l]="uiType=='L'"
                                        [(ngModel)]="xFavPlace.name" (keyup)="onPlaceNameKeyup($event, j, i)"
                                        (blur)="onPlaceNameBlur(j, i)">
                                    <mat-menu #favmenu="matMenu">
                                        <button mat-menu-item (click)="onZoomToPlaceClick(i,j)">
                                            <img width="20px" src="./assets/icons/overscan.svg">
                                            {{'MAPS.SAVED.DLG.ZOOM-TO-PLACE_TEXT' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="onRenamePlaceClick(i,j)">
                                            <img width="20px" src="./assets/icons-new/rename-outline.svg">
                                            {{'MAPS.SAVED.DLG.RENAME-PLACE_TEXT' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="onDeletePlaceClick(i,j)">
                                            <img width="20px" src="./assets/icons/delete.svg">
                                            {{'MAPS.SAVED.DLG.DEL-PLACE_TEXT' | translate}}
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <!-- new category -->
                <div style="margin-top:0.5rem; font-size:large;">
                    <input #catinput type="text" spellcheck="false" enterkeyhint="done"
                        style="height:1.8rem; border-radius:5px; font-size:large; padding-left:0.5rem;"
                        placeholder="{{'MAPS.SAVED.DLG.NEW-LIST_PLH' | translate}}" [(ngModel)]="newCategoryName"
                        (keyup)="onNewPlaceCategoryNameKeyup($event)">
                    <button *ngIf="newCategoryName" mat-raised-button color="primary" style="margin-left:0.5rem;"
                        (click)="onCreateNewPlaceCategory()">{{'MAPS.SAVED.DLG.CREATE-NEW-LIST_PLH' | translate}}
                    </button>
                </div>

                <!-- export places -->
                <div *ngIf="loggedInUser && loggedInUser.id === 1" style="margin-top:0.5rem; font-size:large;">
                    <a [href]="fileUrlPlaces" download="my-saved-places.json">
                        <button mat-raised-button color="primary" (click)="onExportPlacesClick()">
                            <img width="20px" src="./assets/icons/download-outline.svg">
                            Export saved places
                        </button>
                    </a>
                </div>
                <!-- import places -->
                <div *ngIf="loggedInUser && loggedInUser.id === 1" style="margin-top:0.5rem; font-size:large;">
                    <span>Select JSON-file with saved places</span><br>
                    <input type="file" #placesFileInput (change)="onFileChangePlaces($event)"
                        style="cursor:pointer; margin:0.5rem; font-size:large;" title="load JSON-file" accept=".json">
                </div>
            </div>

            <!-- reorder place categories -->
            <div *ngIf="orderPlaceCategories" cdkDropListGroup style="padding:0.5rem;">
                <div cdkDropList class="drop-list" (cdkDropListDropped)="onDropPlaceCategory($event)"
                    [cdkDropListData]="savedPlaces">
                    <div *ngFor="let cat of this.savedPlaces; let j=index" cdkDrag class="drag-box">
                        <div *ngIf="true" style="margin-left:0.5rem;">
                            <div class="drag-handle" cdkDragHandle>
                                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                    <path
                                        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                    </path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>
                        </div>
                        <div style="padding-top:0.2rem; margin-left:2.5rem; width:300px; height:30px; font-size:large;">
                            <span>{{cat.name}}</span>
                        </div>
                    </div>
                    <div style="margin-top:0.5rem; margin-left:2.5rem;">
                        <button mat-raised-button color="primary" (click)="onOrderPlaceCategoriesClick()">
                            {{'MAPS.SAVED.DLG.REORDER_END_BUTTON_TEXT' | translate}}</button>
                    </div>
                </div>
            </div>

        </mat-tab>

        <!-- saved-tours -->
        <mat-tab *ngIf="loggedInUser && isOnline" label="{{'MAPS.SAVED.DLG.TAB-TOURS_TITLE' | translate}}">

            <!-- order tour-categories -->
            <div style="margin-top:1.0rem; font-size:x-large; padding:0.5rem;">
                {{'MAPS.SAVED.DLG.TAB-TOURS_TITLE' | translate}}
                <img width="20px" style="margin-left:0.5rem;" src="./assets/icons/order-bool-ascending.svg" alt="symbol"
                    title="{{'MAPS.SAVED.DLG.REORDER_CAT_ICON_T' | translate}}" (click)="onOrderTourCategoriesClick()">
            </div>

            <!-- tour-categories -->
            <!-- manage tour-categories -->
            <div *ngIf="!orderTourCategories" cdkDropListGroup style="padding:0.5rem;">
                <!--  loop tour-categories -->
                <div *ngFor="let cat of this.savedToursServer; let j=index" cdkDropList [id]="j" class="drop-list"
                    (cdkDropListDropped)="onDropTour($event)" [cdkDropListData]="cat.tourItems"
                    style="margin-top:0.5rem; border:3px solid mediumslateblue; border-radius:15px; padding:0.5rem; font-size:large;">
                    <!-- <input type="checkbox" [(ngModel)]="cat.visible" (change)="onTourCategoryVisiblilityChange(j)"
                        title="{{'MAPS.SAVED.DLG.LIST-VISIBILITY_TEXT' | translate}}"> -->
                    <!-- <input type="color" id="tourcatcolor" name="tourcolor" style="inline-size:10px;"
                        [(ngModel)]="cat.pointStyle.strokeColor" style="margin:0.5rem;"
                        (change)="onTourCategoryColorChange(j)"> -->
                    <span *ngIf="j!==selectedSavedTourCategoryIndex || !isActiveRenameSavedTourCategory"
                        style="cursor:pointer; margin-left:0.5rem;" [matMenuTriggerFor]="favcatmenu">{{cat.name}}</span>
                    <input *ngIf="j===selectedSavedTourCategoryIndex && isActiveRenameSavedTourCategory" #cattourinput
                        type="text" spellcheck="false" enterkeyhint="go"
                        style="margin-left:0.5rem; height:1.8rem; border-radius:5px;  font-size:large;"
                        [class.list-input-s]="uiType=='S'" [class.list-input-l]="uiType=='L'" [(ngModel)]="cat.name"
                        (keyup)="onTourCategoryNameKeyup($event, j)" (blur)="onTourCategoryNameBlur(j)">
                    <span *ngIf="cat.tourItems.length>0 && !cat.open" (click)="cat.open=!cat.open">
                        <img width="25px" src="./assets/icons/menu-down.svg"></span>
                    <span *ngIf="cat.tourItems.length>0 && cat.open" (click)="cat.open=!cat.open">
                        <img width="25px" src="./assets/icons/menu-up.svg"></span>
                    <mat-menu #favcatmenu="matMenu">
                        <button mat-menu-item (click)="onRenameTourCategoryClick(j)">
                            <img width="20px" src="./assets/icons-new/rename-outline.svg">
                            {{'MAPS.SAVED.DLG.RENAME-LIST_TEXT' | translate}}
                        </button>
                        <button *ngIf="!cat.fixed" mat-menu-item (click)="onDeleteTourCategoryClick(j)">
                            <img width="20px" src="./assets/icons/delete.svg">
                            {{'MAPS.SAVED.DLG.DEL-LIST_TEXT' | translate}}
                        </button>
                    </mat-menu>
                    <!-- tours -->
                    <ng-container *ngIf="cat.open">
                        <div *ngFor="let xFavTour of cat.tourItems; let i=index" cdkDrag class="drag-box">
                            <div *ngIf="true" style="margin-left:2.0rem;">
                                <div class="drag-handle" cdkDragHandle>
                                    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                        </path>
                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                    </svg>
                                </div>
                                <div style="width:300px; height:25px;">
                                    <img width="20px" src="./assets/icons/star-outline.svg">
                                    <span *ngIf="i!==selectedTourIndex || !isActiveRenameSavedTour"
                                        [matMenuTriggerFor]="favmenu"
                                        style="cursor:pointer; margin-left:0.5rem; font-size:medium">{{xFavTour.name}}</span>
                                    <input
                                        *ngIf="i===selectedTourIndex && j===selectedSavedTourCategoryIndex && isActiveRenameSavedTour"
                                        #tourinput type="text" spellcheck="false" enterkeyhint="go"
                                        style="margin-left:0.5rem; height:1.8rem; border-radius:5px; font-size:large;"
                                        [class.item-input-s]="uiType=='S'" [class.item-input-l]="uiType=='L'"
                                        [(ngModel)]="xFavTour.name" (keyup)="onTourNameKeyup($event, j, i)"
                                        (blur)="onTourNameBlur(j, i)">
                                    <mat-menu #favmenu="matMenu">
                                        <button mat-menu-item (click)="onActivateTourClicked(i,j)">
                                            <img width="20px" src="./assets/icons-new/magic-staff.svg">
                                            {{'MAPS.SAVED.DLG.ACTIVATE-TOUR_TEXT' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="onRenameTourClick(i,j)">
                                            <img width="20px" src="./assets/icons-new/rename-outline.svg">
                                            {{'MAPS.SAVED.DLG.RENAME-TOUR_TEXT' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="onDeleteTourClick(i,j)">
                                            <img width="20px" src="./assets/icons/delete.svg">
                                            {{'MAPS.SAVED.DLG.DEL-TOUR_TEXT' | translate}}
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <!-- new category -->
                <div style="margin-top:0.5rem; font-size:large;">
                    <input #catinput type="text" spellcheck="false" enterkeyhint="done"
                        style="height:1.8rem; border-radius:5px; font-size:large; padding-left:0.5rem;"
                        placeholder="{{'MAPS.SAVED.DLG.NEW-LIST_PLH' | translate}}" [(ngModel)]="newCategoryName"
                        (keyup)="onNewTourCategoryNameKeyup($event)">
                    <button *ngIf="newCategoryName" mat-raised-button color="primary" style="margin-left:0.5rem;"
                        (click)="onCreateNewTourCategory()">{{'MAPS.SAVED.DLG.CREATE-NEW-LIST_PLH' | translate}}
                    </button>
                </div>
            </div>

            <!-- reorder tour categories -->
            <div *ngIf="orderTourCategories" cdkDropListGroup style="padding:0.5rem;">
                <div cdkDropList class="drop-list" (cdkDropListDropped)="onDropTourCategory($event)"
                    [cdkDropListData]="savedTours">
                    <div *ngFor="let cat of this.savedTours; let j=index" cdkDrag class="drag-box">
                        <div *ngIf="true" style="margin-left:0.5rem;">
                            <div class="drag-handle" cdkDragHandle>
                                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                    <path
                                        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                    </path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>
                        </div>
                        <div style="padding-top:0.2rem; margin-left:2.5rem; width:300px; height:30px; font-size:large;">
                            <span>{{cat.name}}</span>
                        </div>
                    </div>
                    <div style="margin-top:0.5rem; margin-left:2.5rem;">
                        <button mat-raised-button color="primary" (click)="onOrderTourCategoriesClick()">
                            {{'MAPS.SAVED.DLG.REORDER_END_BUTTON_TEXT' | translate}}</button>
                    </div>
                </div>
            </div>


            <div *ngIf="false">

                <div *ngFor="let tour of savedToursServer; let i=index"
                    style="margin-top:0.5rem; border:3px solid mediumslateblue; border-radius:15px; padding:0.5rem; font-size:large;">

                    <div style="padding:0.5rem;">
                        <!-- <span (click)="onTourClick(i)">{{tour.name}}</span> -->
                        <div *ngIf="i===selectedTourIndex"
                            style="font-size: medium; display:flex; justify-content:center;">
                            <div>
                                <div class="data-item">
                                    <span><img style="margin-right:0.5rem;" width=16px
                                            src="./assets/icons/clock-outline.svg"></span>
                                    <span class="large-data-text">{{tourData.doneDurationHours}}:</span>
                                    <span class="large-data-text">{{tourData.doneDurationMinutes | number:
                                        '2.0'}}</span>
                                    <br><span>{{'MAPS.TRACK.DLG.DURATION_LBL' | translate}}</span>
                                </div>
                                <div class="data-item">
                                    <span class="large-data-text">&#8596; {{tourData.trackLength/1000 |
                                        number:'1.3-3'}}</span>
                                    <span> km</span>
                                    <br><span>{{'MAPS.TRACK.DLG.DONE-DIST_LBL' | translate}}</span>
                                </div>
                                <div class="data-item">
                                    <span *ngIf="tourData.minAltidute<10000" class="large-data-text">&#9662;
                                        {{tour.minAltidute | number: '1.0-0'}}</span>
                                    <span *ngIf="tourData.minAltidute>10000" class="large-data-text">&#9662; --</span>
                                    <span> m</span>
                                    <br><span>{{'MAPS.TRACK.DLG.MIN-ALT_LBL' | translate}}</span>
                                </div>
                                <div class="data-item">
                                    <span class="large-data-text">&#10138; {{tourData.doneAscents | number:
                                        '1.0-0'}}</span>
                                    <span> m</span>
                                    <br><span>{{'MAPS.TRACK.DLG.ASCENTS_LBL' | translate}}</span>
                                </div>
                            </div>
                            <div>
                                <div class="data-item">
                                    <span><img style="margin-right:0.5rem;" width=16px
                                            src="./assets/icons/clock-outline.svg"></span>
                                    <span class="large-data-text">{{tourData.timeInMotionHours}}:</span>
                                    <span class="large-data-text">{{tourData.timeInMotionMinutes | number:
                                        '2.0'}}</span>
                                    <br><span>{{'MAPS.TRACK.DLG.IN-MOTION_LBL' | translate}}</span>
                                </div>
                                <div class="data-item">
                                    <span><img style="margin-right:0.5rem;" width=16px
                                            src="./assets/icons-new/diameter-variant.svg"></span>
                                    <span *ngIf="tourData.averageVelocity" class="large-data-text">
                                        {{tour.averageVelocity | number:'1.1-1'}}</span>
                                    <span *ngIf="!tourData.averageVelocity" class="large-data-text">0.0</span>
                                    <span> km/h</span>
                                    <br><span>{{'MAPS.TRACK.DLG.AVE-VELOCITY_LBL' | translate}}</span>
                                </div>
                                <div class="data-item">
                                    <span *ngIf="tourData.maxAltidute>0" class="large-data-text">&#9652;
                                        {{tour.maxAltidute | number: '1.0-0'}}</span>
                                    <span *ngIf="tourData.maxAltidute==0" class="large-data-text">&#9652; --</span>
                                    <span> m</span>
                                    <br><span>{{'MAPS.TRACK.DLG.MAX-ALT_LBL' | translate}}</span>
                                </div>
                                <div class="data-item">
                                    <span class="large-data-text">&#10136; {{tourData.doneDescents |
                                        number:'1.0-0'}}</span>
                                    <span> m</span>
                                    <br><span>{{'MAPS.TRACK.DLG.DESCENTS_LBL' | translate}}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

    </mat-tab-group>

</div>