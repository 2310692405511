<!-- about information-->
<div #impressumId style="margin:1.0rem;">

  <!-- language de -->
  <ng-container *ngIf="languageCode==='de'">
    <h1>Kontakt / Impressum</h1>

    <p>
      Gerhard Rock<br>
      8054 Seiersberg-Pirka<br>
      Austria
    </p>
    <p>
      E-Mail an: travelplanner4you@outlook.com
    </p>
  </ng-container>

  <!-- language en -->
  <ng-container *ngIf="languageCode!=='de'">
    <h1>Contact / Impressum</h1>
    <p>
      Gerhard Rock<br>
      8054 Seiersberg-Pirka<br>
      Austria
    </p>
    <p>
      Email to: travelplanner4you@outlook.com
    </p>

  </ng-container>

</div>