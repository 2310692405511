<h1 mat-dialog-title>{{data.dlgTitle}}</h1>
<hr>

<div mat-dialog-content>
  <div style="display: flex; flex-direction: column; text-align: center; margin: auto;">
    <mat-form-field>
      <!-- <input matInput style="width: 200px;" type="text" spellcheck="false" [placeholder]="data.inputPlaceholder"
        [(ngModel)]="data.place.name" /> -->
      <input matInput style="width: 200px;" type="text" spellcheck="false" [placeholder]="data.inputPlaceholder"
        [(ngModel)]="data.route.name" />
    </mat-form-field><br>
  </div>
</div>

<div mat-dialog-actions align="end">
  <div>
    <button *ngIf="data.buttonOk" mat-button [mat-dialog-close]="'ok'" cdkFocusInitial>Ok</button>
    <button *ngIf="data.buttonCancel" mat-button [mat-dialog-close]="'cancel'">Cancel</button>
  </div>
</div>