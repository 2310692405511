<!-- privacy-policy-info -->
<div #privpolId style="margin:1.0rem;">

  <!-- language de -->
  <ng-container *ngIf="languageCode==='de'">
    <h1>Datenschutz & Sicherheit</h1>
    <div style="font-size:large;">
      In diesem Dokument wird beschrieben, wie wir personenbezogene Daten im Zusammenhang mit unseren Dienstleistungen
      erheben, verarbeiten, speichern und offenlegen.
    </div>

    <h2>Login Daten</h2>
    <p>
      Du kannst dich ich mit deinem Google-Login bei Trip4YouMaps anmelden. Die meisten Funktionen kannst du auch ohne
      Anmeldung nutzen. Für Spezielle Funktionen, wie das Teilen deines Standortes, musst du aber eingeloggt sein.<br>
      Beim Login erhalten wir deine Google-E-Mail-Adresse und eine ID, die wir speichern, um dich als unseren Nutzer zu
      registrieren. Wenn du dich zum ersten Mal anmeldest, musst du mittels eines Dialogs Google bestätigen, dass du
      damit einverstanden
      bist, dass wir deine E-Mail-Adresse erhalten.<br>
      Bei jedem Login mit Google weiß Google, dass du Trip4You-Maps nutzt. Google erhält von uns jedoch keine weiteren
      Informationen über dich.<br>
    </p>

    <h2>Was passiert mit Deinen Daten?</h2>
    <p>
      Wir geben deine E-Mail-Adresse nicht an Dritte weiter.<br>
      Wir protokollieren, wie du Trip4You-Maps nutzt, um zu sehen, wie oft unsere Nutzer bestimmte Funktionen nutzen.
      Dadurch können wir die Benutzerfreundlichkeit von Trip4You-Maps erhöhen. Wir nutzen die Daten nicht für andere
      Zwecke und geben die Daten nicht an Dritte weiter.
    </p>

    <h2>Cookies/Local storage</h2>
    <p>
      Wir verwenden Cookies und lokale Speicherung nur aus folgenden, technischen Gründen.<br>
      Zum Speichern deiner Einstellungen und wenn du Orte und Routen speicherst, nutzen wir den lokalen Speicher deines
      Geräts.<br>
      Wir verwenden keine Cookies, um personalisierte Werbung anzuzeigen.
    </p>

    <h2>Was kann ich meine persönlichen Daten wieder löschen?</h2>
    <p>
      Deine selbst gespeicherten Daten kannst du jederzeit mittels der Löschfunktionen in Trip4YouMaps löschen.
      Wenn du möchtest, dass wir deine gesamten persöhnlichen Daten samt deiner Registrierung löschen, sende uns eine
      E-Mail an travelplanner4you@outlook.com<br>
    </p>

  </ng-container>

  <!-- language en -->
  <ng-container *ngIf="languageCode!=='de'">
    <h1>Privacy policy</h1>

    <div style="font-size:large;">
      This document describes how we collect, process, store and disclose any personal data in connection with our
      services.
    </div>

    <h2>Login data</h2>
    <p>
      You can login into our services with your Google login. Most of the functionality you can use without logging in.
      <br>
      We get your google email-address and an id that we store to identify you as our user. If you login first time you
      have to confirm to Google that you allow that we get your email-address.<br>
      If you want that we delete your your account including the login data please send an email to
      travelplanner4you@outlook.com<br>
    </p>

    <h2>What happens with your data</h2>
    <p>
      Each time you use the login with Google, Google knows that you use Trip4You-Maps. But Google get no further
      information about you from us.<br>
      We do'nt hand out your email-address to third parties.<br>
      We log how you use Trip4You-Maps for the reason to see how often the users use certain features. This allows us to
      increase the user-friendliness of Trip4You-Maps. We don't use the data for other purposes and we don't hand out
      the data to others.
    </p>

    <h2>Cookies/Local storage</h2>
    <p>
      We use cokies and local storage only for following technical reasons. <br>
      We use the local storage of your device for saving your settings and if you save places and routes.<br>
      We do not use cookies to display personalized advertising.
    </p>

    <h2>What can I do to delete my personal data?</h2>
    <p>
      You can delete your self-saved data at any time using the delete functions in Trip4YouMaps.
      If you would like us to delete all of your personal data including your registration, send us an email
      to travelplanner4you@outlook.com
    </p>


  </ng-container>

</div>