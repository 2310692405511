import { NgModule, LOCALE_ID } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { MatCommonModule, MatNativeDateModule, MAT_DATE_LOCALE, } from "@angular/material/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSliderModule } from "@angular/material/slider";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { ShellModule } from "../shell/shell.module";
import { InfosModule } from "../module-infos/infos.module";
import { LazyLoadStylesheetService } from "../services/lazy-load-stylesheet.service";

import { EditWayPointsComponent } from "./edit-waypoints/edit-waypoints.component";
import { MapsComponent } from "./maps/maps.component";
import { SearchPlace1Component } from "./search-place/search-place1.component";
import { MapsHelpComponent } from "./maps-help/maps-help.component";
import { GeneralPopupComponent } from "../global-components/popup-general/popup-general.component";
import { ShowRouteComponent } from "./show-details/show-route/show-route.component";
import { ElevationProfileChartComponent } from "./show-details/elevation-profile-chart/elevation-profile-chart.component";
import { SurfaceProfileChartComponent } from "./show-details/surface-profile-chart/surface-profile-chart.component";
import { WaytypeProfileChartComponent } from "./show-details/waytype-profile-chart/waytype-profile-chart.component";
import { SettingsComponent } from "./settings/settings.component";
import { ManageMapsComponent } from "./manage-maps/manage-maps.component";
import { ShowTrackComponent } from "./show-details/show-track/show-track.component";
import { SavedPlaceSelectionComponent } from "./saved-place-selection/saved-place-selection.component";
import { SavedItemsComponent } from "./saved-items/saved-items.component";
import { RouteNamePopupComponent } from "./popup-route-name/popup-route-name.component";
import { MapSelectionComponent } from "./map-selection/map-selection.component";
import { GpxRouteComponent } from "./gpx-route/gpx-route.component";
import { TrackingComponent } from "./tracking/tracking.component";
import { MissingLocatorPopupComponent } from "./popup-missing-locator/popup-missing-locator.component";
import { RoutePlannerComponent } from "./route-planner/route-planner.component";
import { SteepnessProfileChartComponent } from "./show-details/steepness-profile-chart/steepness-profile-chart.component";
import { SearchRouteComponent } from "./search-route/search-route.component";
import { PresentRouteComponent } from "./present-route/present-route.component";
import { PublishRouteComponent } from "./publish-route/publish-route.component";
import { MatSelectModule } from "@angular/material/select";
import { MapsAdminComponent } from "./maps-admin/maps-admin.component";

const routes: Routes = [
  { path: "", component: MapsComponent },
  // {
  //   path: "param", component: MapXComponent
  // },
  // {
  //   path: "showroute", component: ShowRouteComponent
  // },
  // {
  //   path: "showtrack", component: ShowTrackComponent
  // },
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    MapsComponent,
    EditWayPointsComponent,
    GpxRouteComponent,
    MapSelectionComponent,
    SearchPlace1Component,
    SettingsComponent,
    ManageMapsComponent,
    MapsHelpComponent,
    MapsAdminComponent,
    MissingLocatorPopupComponent,
    GeneralPopupComponent,
    RouteNamePopupComponent,
    RoutePlannerComponent,
    ShowRouteComponent,
    ShowTrackComponent,
    ElevationProfileChartComponent,
    SteepnessProfileChartComponent,
    SurfaceProfileChartComponent,
    SavedPlaceSelectionComponent,
    TrackingComponent,
    WaytypeProfileChartComponent,
    SavedItemsComponent,
    PublishRouteComponent,
    SearchRouteComponent,
    PresentRouteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    // BackButtonDisableModule.forRoot({
    //   preserveScrollPosition: true
    // }),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    // MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatTabsModule,
    MatSliderModule,
    DragDropModule,

    ShellModule,
    InfosModule,
  ],
  providers: [
    // { provide: LOCALE_ID, useValue: "en-GB" },
    // { provide: MAT_DATE_LOCALE, useValue: "en-GB" },

    LazyLoadStylesheetService,
  ],
  exports: [RouterModule]
})
export class MapsModule {

}
