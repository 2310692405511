
import { } from "src/dto.generated/api";

import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ.js";
import BingMaps from "ol/source/BingMaps.js";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import VectorTileSource from "ol/source/VectorTile";
import TileJSONSource from "ol/source/TileJSON";
import GeoJSON from "ol/format/GeoJSON";


// set map-layer-source-functions
// no-map
export function setNoMapLayer(mapLayer: TileLayer<any>) {
  // console.log("util-ol-map:setOSMMapLayer-tileLayer",tileLayer);
  mapLayer.setSource(undefined);
}

// OSM-map
export function getOsmMapSource() {
  // console.log("util-ol-map:getOSMMapSource");
  const tileSource = new OSM();
  return tileSource as any;
}
export function setOsmMapLayer(mapLayer: TileLayer<any>) {
  // console.log("util-ol-map:setOSMMapLayer-tileLayer",tileLayer);
  const tileSource = getOsmMapSource();
  mapLayer.setSource(tileSource);
}

// maptiler-maps
export function getMaptilerOutdoorSource() {
  const tileSource = new TileJSONSource({
    url: "https://api.maptiler.com/maps/outdoor/tiles.json?key=dnfyfROeSjSWplpfZV0I",
    // url: "https://api.maptiler.com/maps/basic/tiles.json?key=dnfyfROeSjSWplpfZV0I",
    tileSize: 512,
    crossOrigin: "anonymous"
  });
  return tileSource;
}
export function setOutdoorMapLayer(mapLayer: TileLayer<any>) {
  const tileSource = getMaptilerOutdoorSource();
  mapLayer.setSource(tileSource);
}
export function setTopoMapLayer(mapLayer: TileLayer<any>) {
  const tileSource = new TileJSONSource({
    url: "https://api.maptiler.com/maps/topo/tiles.json?key=dnfyfROeSjSWplpfZV0I",
    tileSize: 512,
    crossOrigin: "anonymous"
  });
  mapLayer.setSource(tileSource);
}
export function setStreetsMapLayer(mapLayer: TileLayer<any>) {
  const tileSource = new TileJSONSource({
    url: "https://api.maptiler.com/maps/streets/tiles.json?key=dnfyfROeSjSWplpfZV0I",
    tileSize: 512,
    crossOrigin: "anonymous"
  });
  mapLayer.setSource(tileSource);
}
export function setSatelliteMapLayer(mapLayer: TileLayer<any>) {
  const tileSource = new TileJSONSource({
    url: "https://api.maptiler.com/tiles/satellite/tiles.json?key=dnfyfROeSjSWplpfZV0I",
    tileSize: 512,
    crossOrigin: "anonymous"
  });
  mapLayer.setSource(tileSource);
}
export function setHybridMapLayer(mapLayer: TileLayer<any>) {
  const tileSource = new TileJSONSource({
    url: "https://api.maptiler.com/maps/hybrid/tiles.json?key=dnfyfROeSjSWplpfZV0I",
    tileSize: 512,
    crossOrigin: "anonymous"
  });
  mapLayer.setSource(tileSource);
}
export function getGoogleMapsSource() {
  const tileSource = new XYZ({
    url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    attributions: "<span>© Google Maps</span>",
    maxZoom: 19
  });
  return tileSource;
}
export function setGoogleMapsMapLayer(mapLayer: TileLayer<any>) {
  const tileSource = getGoogleMapsSource();
  mapLayer.setSource(tileSource);
}

// cyclo-map
export function getCycleOsmMapSource() {
  // console.log("test-Maps:onShowCyclOSMSourceClick");
  const tileSource = new XYZ({
    url: "https://dev.{a-c}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
    attributions: "<a href='https://github.com/cyclosm/cyclosm-cartocss-style/releases' title='CyclOSM - Open Bicycle render'>CyclOSM</a> v0.6 | Map data © <a href='https://www.openstreetmap.org/'>OpenStreetMap</a>",
    maxZoom: 19
  });
  return tileSource;
}
export function setCycleOsmMapLayer(mapLayer: TileLayer<any>) {
  const tileSource = getCycleOsmMapSource();
  mapLayer.setSource(tileSource);
}

// bing-maps
export function setBingmapsAerialMapLayer(mapLayer: TileLayer<any>) {
  // console.log("test-Maps:onShowBingMapSourceClick");
  // const styles = [
  //   "RoadOnDemand",
  //   "Aerial",
  //   "AerialWithLabelsOnDemand",
  //   "CanvasDark",
  //   "OrdnanceSurvey",
  // ];
  const tileSource = new BingMaps({
    key: "Av2LPYZlKjWc58xxwLvlIiI2pKCYvKoCAcevrlGoE3hwrwbkHdxnBr7BEY4ew3A6",
    imagerySet: "Aerial",
    maxZoom: 19
  });
  mapLayer.setSource(tileSource);
}
export function setBingmapsAerialLabelsMapLayer(mapLayer: TileLayer<any>) {
  // console.log("test-Maps:onShowBingmapsAerialLabelsSourceClick");
  const tileSource = new BingMaps({
    key: "Av2LPYZlKjWc58xxwLvlIiI2pKCYvKoCAcevrlGoE3hwrwbkHdxnBr7BEY4ew3A6",
    imagerySet: "AerialWithLabelsOnDemand",
    maxZoom: 19
  });
  mapLayer.setSource(tileSource);
}

// waymarked-trails-hiking overlay-layer
export function setWaymarkedTrailsHikingOverlayLayer(overlayLayer: TileLayer<any>) {
  // console.log("test-Maps:setWaymarkedTrailsHikingOverlayLayer");

  const tileSource = new XYZ({
    url: "https://tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png", // Wander-Wegnetz mit Beschriftungen - overlay-Map
    attributions: "<a href='https://waymarkedtrails.org/' target='_blank'> | © WaymarkedTrails</a>",
    maxZoom: 19
  });
  overlayLayer.setSource(tileSource);
}
// waymarked-trails-cycling overlay-layer
export function setWaymarkedTrailsCyclingOverlayLayer(overlayLayer: TileLayer<any>) {
  // console.log("test-Maps:setWaymarkedTrailsCyclingOverlayLayer");

  const tileSource = new XYZ({
    url: "https://tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png", // Rad-Wegnetz mit Beschriftungen - overlay-Map
    attributions: "<a href='https://waymarkedtrails.org/' target='_blank'> | © WaymarkedTrails</a>",
    maxZoom: 19
  });
  overlayLayer.setSource(tileSource);
}
// waymarked-trails-mtb overlay-layer
export function setWaymarkedTrailsMtbOverlayLayer(overlayLayer: TileLayer<any>) {
  // console.log("test-Maps:setWaymarkedTrailsMtbOverlayLayer");

  const tileSource = new XYZ({
    url: "https://tile.waymarkedtrails.org/mtb/{z}/{x}/{y}.png", // MTB-Wegnetz mit Beschriftungen - overlay-Map
    attributions: "<a href='https://waymarkedtrails.org/' target='_blank'> | © WaymarkedTrails</a>",
    maxZoom: 19
  });
  overlayLayer.setSource(tileSource);
}

// country-layer
export function createCountryLayer() {
  const countrySource = new VectorSource({
    url: "./assets/data/geojson/countries.geojson",
    format: new GeoJSON(),
  });
  // console.log("createCountryLayer-state", countrySource.getState());
  const countryLayer = new VectorLayer({
    source: countrySource,
  });
  countryLayer.set("name", "countries");
  return countryLayer;
}

