import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";


@Component({
  selector: "app-maps-help-component",
  templateUrl: "./maps-help.component.html",
  styleUrls: ["./maps-help.component.scss"]
})
export class MapsHelpComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;

  @Output() close: EventEmitter<void> = new EventEmitter();

  @ViewChild("helpDlg") helpDlgElement: ElementRef;

  constructor() { }

  public async ngOnInit() {
    console.log("Help:ngOnInit");
    // this.languageCode = "en";
  }
  public ngAfterViewInit() {
    setTimeout(() => { this.scrollToDlg(); });
  }
  public ngOnDestroy(): void {
  }

  private scrollToDlg() {
    this.helpDlgElement.nativeElement.scrollIntoView({ align: top, behavior: "smooth" });
  }

  public onHelpBackClick() {
    this.close.emit();
  }

}
