import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PageNotFoundComponent } from "./global-components/page-not-found/page-not-found.component";
import { MapsInfosComponent } from "./module-infos/maps-infos/maps-infos.component";
import { StartPageComponent } from "./shell/start-page/start-page.component";

const routes: Routes = [
  {
    path: "", component: StartPageComponent
  },
  // {
  //   path: "",
  //   loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  // },
  {
    path: "start",
    loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  },
  {
    path: "param",
    loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  },
  {
    path: "planner",
    loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  },
  {
    path: "open-gpx",
    // path: "open-gpx", redirectTo: "/param?load-gpx", pathMatch: "full"
    loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  },
  {
    path: "infos", component: MapsInfosComponent
  },
  {
    path: "infos/:languageCode", component: MapsInfosComponent
  },
  {
    path: "about", component: MapsInfosComponent
  },
  {
    path: "about/:languageCode", component: MapsInfosComponent
  },
  {
    path: "faq", component: MapsInfosComponent
  },
  {
    path: "faq/:languageCode", component: MapsInfosComponent
  },
  {
    path: "privacy-policy", component: MapsInfosComponent
  },
  {
    path: "privacy-policy/:languageCode", component: MapsInfosComponent
  },
  {
    path: "terms-of-use", component: MapsInfosComponent
  },
  {
    path: "terms-of-use/:languageCode", component: MapsInfosComponent
  },
  {
    path: "feedback", component: MapsInfosComponent
  },
  {
    path: "feedback/:languageCode", component: MapsInfosComponent
  },
  {
    path: "contact", component: MapsInfosComponent
  },
  {
    path: "contact/:languageCode", component: MapsInfosComponent
  },
  {
    path: "apps", component: MapsInfosComponent
  },
  {
    path: "apps/:languageCode", component: MapsInfosComponent
  },
  {
    path: "route/:languageCode/:routeId",
    loadChildren: () => import("./module-maps/maps.module").then(mod => mod.MapsModule),
  },
  {
    path: "test",
    loadChildren: () => import("./module-test/test.module").then(mod => mod.TestModule),
  },
  {
    path: "**",
    component: PageNotFoundComponent, // 404 page
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


