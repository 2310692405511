import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IScatterData, IScatterPoint } from "src/app/models/chart-types";
import { Track } from "src/app/models/track";


@Component({
  selector: "app-show-track-component",
  templateUrl: "./show-track.component.html",
  styleUrls: ["./show-track.component.scss"]
})
export class ShowTrackComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() currentTrack: Track;
  @Input() languageCode: string;
  @Input() trackType: string;

  @Output() close: EventEmitter<string> = new EventEmitter();

  @ViewChild("drawboard") drawBoardEl: ElementRef;
  public cx: CanvasRenderingContext2D;
  public percentX: number;
  public percentY: number;
  public drawingSubscription: Subscription;
  public canvasWidth = 1000;
  public canvasHeight = 500;

  // chart
  public point: number[];
  public elevationChartData: IScatterData;

  public routeDistance = 0.0;
  public routeDistanceKm = 0.0;
  public hasElevationData: boolean;
  public routeAscent: number;
  public routeDescent: number;
  public minElevation: number;
  public maxElevation: number;
  public minDateTime: Date;
  public maxDateTime: Date;
  public timeNeeded: number;
  public routeDurationNormalBike = 0.0;
  public routeDurationNormalBikeMin = 0.0;
  public routeDurationNormalBikeHours = 0.0;
  public routeDurationEBike = 0.0;
  public routeDurationEBikeMin = 0.0;
  public routeDurationEBikeHours = 0.0;
  public routeDurationHike = 0.0;
  public routeDurationHikeMin = 0.0;
  public routeDurationHikeHours = 0.0;

  constructor(
  ) {
  }

  public ngOnInit() {
    // console.log("ShowTrack:ngOnInit-currentTrack", this.currentTrack);

    if (!this.currentTrack) { return; }

    this.routeDistance = 0.0;
    this.routeDurationNormalBike = 0.0;
    this.routeDurationEBike = 0.0;
    this.calculateTrackData();

    this.generateElevationChartData();

  }
  public ngAfterViewInit() {
    // console.log("ShowTrack:ngAfterViewInit");
    if (!this.currentTrack) { return; }

    // get the context
    // const canvasEl: HTMLCanvasElement = this.drawBoardEl.nativeElement;
    // canvasEl.width = this.canvasWidth;
    // canvasEl.height = this.canvasHeight;
    // this.canvasWidth = canvasEl.width;
    // this.canvasHeight = canvasEl.height;
    // console.log("ShowRoute:ngOnInit-width", this.canvasWidth);
    // console.log("ShowRoute:ngOnInit-height", this.canvasHeight);
    // this.cx = canvasEl.getContext("2d");
    // console.log("ShowRoute:ngAfterViewInit-cx", this.cx);

    // this.drawElevationProfile();

  }
  public ngOnDestroy() {
  }

  private calculateTrackData() {
    this.hasElevationData = false;
    this.minElevation = Infinity;
    this.maxElevation = -Infinity;
    this.minDateTime = new Date("2100-01-01");
    this.maxDateTime = new Date("1900-01-01");
    this.timeNeeded = undefined;
    let tpLast = null;
    let ascent = 0.0;
    let descent = 0.0;
    // let sumDist = 0.0;
    for (const tp of this.currentTrack.trackPoints) {
      const elevation = tp.ele;
      if (elevation) {
        if (elevation < this.minElevation) { this.minElevation = elevation; }
        if (elevation > this.maxElevation) { this.maxElevation = elevation; }
        this.hasElevationData = true;
      }
      const time = tp.timeStamp;
      if (time) {
        if (time < this.minDateTime) { this.minDateTime = time; }
        if (time > this.maxDateTime) { this.maxDateTime = time; }
      }
      const index = this.currentTrack.trackPoints.indexOf(tp);
      if (index > 0) {
        if (tp.ele) {
          if (tp.ele > tpLast.ele) { ascent += tp.ele - tpLast.ele; }
          if (tp.ele < tpLast.ele) { descent += tpLast.ele - tp.ele; }
        }
        // const deltaX = tp.lng - tpLast.lng;
        // const deltaY = tp.lat - tpLast.lat;
        // const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        // sumDist += dist;
        // console.log("ShowRoute:drawProfile-deltaX", deltaX);
        // console.log("ShowRoute:drawProfile-deltaY", deltaY);
        // console.log("ShowRoute:drawProfile-dist", dist);
        // console.log("ShowRoute:drawProfile-sumDist", sumDist);
      }
      tpLast = tp;
    }
    // console.log("ShowTrack:calculateTrackData-minElevation", this.minElevation);
    // console.log("ShowTrack:calculateTrackData-maxElevation", this.maxElevation);
    // console.log("ShowTrack:calculateTrackData-minDateTime", this.minDateTime);
    // console.log("ShowTrack:calculateTrackData-maxDateTime", this.maxDateTime);
    // console.log("ShowRoute:drawProfile-sumDist", sumDist);
    // this.routeDistance = sumDist;
    this.routeDistance = this.currentTrack.length;
    this.routeDistanceKm = Math.round(this.routeDistance) * 0.001;
    // console.log("ShowTrack:calculateTrackData-minDateTime", this.minDateTime);
    // console.log("ShowTrack:calculateTrackData-minElevation", this.minElevation);
    if (this.minDateTime.getTime() !== new Date("2100-01-01").getTime()) {
      this.timeNeeded = (this.maxDateTime.getTime() - this.minDateTime.getTime()) / 1000 / 60;
    }
    this.routeAscent = ascent;
    this.routeDescent = descent;
    this.routeDurationNormalBike = this.routeDistance / 15000.0 * 60.0;  // 15kmh
    this.routeDurationNormalBike = Math.round(this.routeDurationNormalBike);
    this.routeDurationNormalBikeHours = Math.floor(this.routeDurationNormalBike / 60.0);
    this.routeDurationNormalBikeMin = this.routeDurationNormalBike - this.routeDurationNormalBikeHours * 60.0;
    this.routeDurationEBike = this.routeDistance / 18000.0 * 60.0;  // 18kmh
    this.routeDurationEBike = Math.round(this.routeDurationEBike);
    this.routeDurationEBikeHours = Math.floor(this.routeDurationEBike / 60.0);
    this.routeDurationEBikeMin = this.routeDurationEBike - this.routeDurationEBikeHours * 60.0;
    this.routeDurationHike = this.routeDistance / 3500.0 * 60.0;   // 3.5kmh
    this.routeDurationHike = Math.round(this.routeDurationHike);
    this.routeDurationHikeHours = Math.floor(this.routeDurationHike / 60.0);
    this.routeDurationHikeMin = this.routeDurationHike - this.routeDurationHikeHours * 60.0;
  }

  private generateElevationChartData() {
    // console.log("ShowRoute:generateElevationChartData");

    let trpLast = null;
    let sumDist = 0.0;
    this.elevationChartData = ({} as IScatterData);
    this.elevationChartData.points = new Array<IScatterPoint>();
    for (const trp of this.currentTrack.trackPoints) {
      if (trpLast) {
        const deltaX = trp.lng - trpLast.lng;
        const deltaY = trp.lat - trpLast.lat;
        const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        sumDist += dist;
        const elevation = trp.ele;
        const point = {} as IScatterPoint;
        point.x = sumDist;
        point.y = elevation;
        this.elevationChartData.points.push(point);
      }
      trpLast = trp;
    }
    // console.log("ShowRoute:generateChartData-sumDist", sumDist);
    const fact = this.routeDistanceKm / sumDist;
    // console.log("ShowRoute:generateChartData-fact", fact);
    for (const p of this.elevationChartData.points) {
      p.x = p.x * fact;
      p.x = Math.round(p.x * 1000) / 1000;
    }

    // console.log("ShowRoute:generateChartData-elevationData", this.elevationData);
  }

  private drawProfile_old() {
    console.log("ShowTrack:drawProfile");

    let tpLast = null;
    let sumDist = this.routeDistance;

    this.cx.strokeStyle = "#0000FF";
    this.cx.lineWidth = 1;

    const x0 = this.percentX * 10.0;
    const y0 = this.percentY * 10.0;
    const factX = this.percentX * 80.0 / sumDist;
    const factY = this.percentY * 70.0 / (this.maxElevation - this.minElevation);
    // console.log("ShowTrack:drawProfile-factX", factX);
    // console.log("ShowTrack:drawProfile-factY", factY);
    tpLast = null;
    sumDist = 0.0;
    for (const tp of this.currentTrack.trackPoints) {
      const index = this.currentTrack.trackPoints.indexOf(tp);
      if (index === 0) {
        let y = y0 + (tp.ele - this.minElevation) * factY;
        y = this.canvasHeight - y;
        // console.log("ShowTrack:drawProfile-wp1-x0", x0);
        // console.log("ShowTrack:drawProfile-wp1-y", y);
        this.cx.beginPath();
        this.cx.moveTo(x0, y);
      }
      if (index > 0) {
        const deltaX = tp.lng - tpLast.lng;
        const deltaY = tp.lat - tpLast.lat;
        const dist = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
        sumDist += dist;
        // console.log("ShowTrack:drawProfile-deltaX", deltaX);
        // console.log("ShowTrack:drawProfile-deltaY", deltaY);
        // console.log("ShowTrack:drawProfile-dist", dist);
        // console.log("ShowTrack:drawProfile-sumDist", sumDist);
        const x = x0 + sumDist * factX;
        let y = y0 + (tp.ele - this.minElevation) * factY;
        y = this.canvasHeight - y;
        // console.log("ShowTrack:drawProfile-x", x);
        // console.log("ShowTrack:drawProfile-y", y);
        this.cx.lineTo(x, y);
      }
      tpLast = tp;
    }
    this.cx.stroke();
  }

  public onBack() {
    this.close.emit();
  }
  public onBackToMap() {
    this.close.emit("toMap");
  }

}
