import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-popup-missing-locator-component",
  templateUrl: "./popup-missing-locator.component.html",
  styleUrls: ["./popup-missing-locator.component.scss"]
})
export class MissingLocatorPopupComponent implements OnInit {

  public title: string;
  public textClose: string;
  public showMore: boolean;

  constructor(
    public dialogRef: MatDialogRef<MissingLocatorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      language: string,
      errorMsg: string,
    },
  ) {
  }

  public async ngOnInit() {
    console.log("MissingLocatorPopupSimple:ngOnInit");

    // language
    const lang = this.data.language;

    // title
    this.title = "Location is currently not available!";
    if (lang === "de") { this.title = "Die Standortbestimmung ist nicht verfügbar!"; }

    // button-text
    this.textClose = "Close";
    if (lang === "de") {
      this.textClose = "Schließen";
    }
  }

  public showMoreClick() {
    window.location.href = "/faq";
  }

}
