<!--maps-->
<div #mapPageId style="margin:0">

  <!-- isOnline -->
  <!-- <div *ngIf="!isOnline" class="online-div">
    <span style="margin-top:2.0rem;">{{'MAPS.GEN.OFFLINE_MSG' | translate}}</span>
  </div> -->

  <!-- help -->
  <!-- <div *ngIf="isVisibleHelp && uiType==='S'" style="margin-bottom:0.5rem;">
    <app-maps-help-component [uiType]="uiType" [languageCode]="userLanguage"
      (close)="onHelpBack()"></app-maps-help-component>
  </div> -->

  <!-- infos -->
  <!-- <div *ngIf="isVisibleInfos" style="margin-bottom:0.5rem;">
    <app-maps-infos-component [uiType]="uiType" [languageCode]="userLanguage"
      (close)="onInfosBack()"></app-maps-infos-component>
  </div> -->

  <!-- left-dialogs + distance#bar + map-container -->
  <div style="height:100vh; display:flex; margin:0;">

    <!--dialogs -->
    <!-- search-Dialog -->
    <div *ngIf="isVisibleSearchDialog"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto; padding:0.5rem;"
      [class.dialog-m]="uiType=='L'">
      <app-search-place1-component [languageCode]="userLanguage" [uiType]="uiType" [searchParameter]="searchParameter"
        [selectType]="'main'" [currentLocation]="currentPositionLngLat" [map]="mapRef"
        [currentMapExtent]="currentMapExtent" [sessionId]="sessionId" [createPointOnMapActive]="createSearchPointOnMap"
        [savedPlaces]="mySavedPlacesLocaly" [(foundPlaces)]="foundRefPlaces" (back)="onSearchBack()"
        [selectedPlace]="selectedMapPoint" (selectedPoint)="onSelectSearchResult($event)"
        (routeToSelectedPoint)="onRouteToSearchResult($event)" (showError)="showApiError($event)"
        (showORSSearchError)="showORSSearchError($event)"
        (createPointOnMap)="onCreateSearchPointOnMap()"></app-search-place1-component>
    </div>

    <!-- route-planner-dialog -->
    <div *ngIf="isVisibleRoutePlanner && true"
      style="outline: 2px solid gray; background-color:aliceblue;width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-l]="uiType=='L'">
      <app-route-planner-component [loggedInUser]="loggedInUser" [languageCode]="userLanguage" [uiType]="uiType"
        [sessionId]="sessionId" [currentLocation]="currentPositionLngLat" [map]="mapRef" [currentRoute]="currentRoute"
        [geojsonRoute]="geojsonRoute" [steepnessExtra]="steepnessExtra" [searchParameter]="searchParameter"
        [(foundPlaces)]="foundRefPlaces" [savedPlaces]="mySavedPlacesLocaly"
        [wayPointPositionOnMap]="wayPointPositionOnMap" [tourData]="currentTourData"
        [isTourStarted]="currentTourData.isTourStarted" [isCurrentRoutePublic]="isCurrentRoutePublic"
        [isCurrentRouteIdPublished]="isCurrentRouteIdPublished" [selectedWayPointIndex]="selectedWayPointIndex"
        [orsPostErrorText]="orsPostErrorText" [debugLog]="debugLog" (startNavigation)="onNavigationClick()"
        (moveTypeChange)="onSetRoutePlannerMoveType($event)" (clearRoute)="onClearRouteClick($event)"
        (reverseRoute)="onReverseRouteClick()" (showRouteDetails)="onShowRouteDetailsClick()"
        (zoomToRoute)="onZoomToActiveRouteClick()" (saveRouteLocaly)="onSaveRouteLocalyClick()"
        (saveRouteCloud)="onSaveRouteCloudClick()" (publishRoute)="onPublishRouteClick()"
        (publicRouteUpdate)="onPublishRouteClick()" (shareRoute)="onShareRouteClick()"
        (backToStartChange)="onBackToStartChange()" [drawRouteStyle]="drawRouteStyle"
        (drawRouteStyleChange)="onDrawRouteStyleChanged($event)"
        (selectedWayPointForMapChange)="onSelectedWayPointForMapChange($event)" (redrawWayPoints)="redrawWayPoints()"
        (recalculateRoute)="onRecalculateRoute($event)" (updateRouteToUrl)="updateUrl()" (back)="onRoutePlannerBack()">
      </app-route-planner-component>
    </div>

    <!-- route-details -->
    <!-- <div *ngIf="isVisibleRouteDetails"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-l]="uiType=='S'" [class.dialog-m]="currentDeviceType=='H-L' || currentDeviceType=='T-P'"
      [class.dialog-xl]="uiType=='L' && currentDeviceType!='H-L' && currentDeviceType!='T-P'">
      <app-show-route-component [currentGeojsonRoute]="geojsonRoute" [steepnessExtra]="steepnessExtra"
        [routeName]="currentRoute.name" [languageCode]="userLanguage" (close)="onRouteDetailsBack($event)"
        (pointedIndexChanged)="onPointedIndexChanged($event)">
      </app-show-route-component>
    </div> -->
    <div *ngIf="isVisibleRouteDetails"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-show-route-component [currentGeojsonRoute]="geojsonRoute" [steepnessExtra]="steepnessExtra"
        [routeName]="currentRoute.name" [languageCode]="userLanguage" (close)="onRouteDetailsBack($event)"
        (pointedIndexChanged)="onPointedIndexChanged($event)">
      </app-show-route-component>
    </div>

    <!-- publish-route -->
    <div *ngIf="isVisiblePublishRouteDialog"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-publish-route-component [loggedInUser]="loggedInUser" [languageCode]="userLanguage" [pubRoute]="pubRoute"
        [isCurrentRoutePublic]="isCurrentRoutePublic" [isCurrentRouteIdPublished]="isCurrentRouteIdPublished"
        [languageCode]="userLanguage" [regions]="searchRegions" (showError)="showApiError($event)"
        (close)="onPublishRouteBack($event)" (saveRouteToServer)="onUploadRouteToServer()">
      </app-publish-route-component>
    </div>

    <!-- record/tracking -->
    <div *ngIf="isVisibleTrackingDialog"
      style="border: 1px solid gray; background-color:aliceblue; width:100%;  max-height:100vh; overflow-y:auto;"
      [class.dialog-m]="uiType=='L'">
      <app-tracking-component [loggedInUser]="loggedInUser" [isTestUser]="isTestUser" [languageCode]="userLanguage"
        [uiType]="uiType" [hasGeolocation]="mapCoordinates!==undefined" [tourData]="currentTourData"
        (back)="onTrackingBack()" (startTour)="onStartTour()" (continueTour)="onContinueTour()"
        (stopTour)="onStopTour()" (saveTour)="onSaveTour()" (showTrackChanged)="onShowTrackChanged($event)"
        (moveTypeChanged)="onTourMoveTypeChanged()" (zoomToTrack)="onZoomToTrackClick()">
      </app-tracking-component>
    </div>

    <!-- saved items -->
    <div *ngIf="isVisibleSavedItemsDialog"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-saved-items-component [loggedInUser]="loggedInUser" [languageCode]="userLanguage" [uiType]="uiType"
        [isOnline]="isOnline" [saveType]="saveType" [savedPlacesLocaly]="mySavedPlacesLocaly"
        [savedPlacesServer]="mySavedPlacesServer" [savedRoutesLocaly]="mySavedRoutesLocaly"
        [savedRoutesServer]="mySavedRoutesServer" (back)="onSavedItemsBack()"
        (favoritePlacesChange)="onSavedPlacesChange($event)" (redrawSavedPlacesLocaly)="redrawSavedPlacesLocaly()"
        (redrawSavedPlacesServer)="redrawSavedPlacesServer()" (redrawSavedRoutesLocaly)="redrawSavedRoutesLocaly()"
        (redrawSavedRoutesServer)="redrawSavedRoutesServer()" (showError)="showApiError($event)"
        (activateSavedRouteLocaly)="activateSavedRouteLocaly($event)"
        (activateSavedRouteServer)="activateSavedRouteServer($event)"
        (activateSavedTourServer)="activateSavedTourServer($event)" (zoomToPlace)="onZoomToPlaceClick($event)"
        (zoomToRoute)="onZoomToRouteClick($event)" (showRouteDetailsLocaly)="onShowSavedRouteDetailsLocaly($event)"
        (showRouteDetailsServer)="onShowSavedRouteDetailsServer($event)" (saveTypeChange)="onSaveTypeChange($event)">
      </app-saved-items-component>
    </div>

    <!-- saved-route-details -->
    <div *ngIf="isVisibleSavedRouteDetails"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-show-route-component [currentRoute]="currentRouteForDetails" [languageCode]="userLanguage"
        (close)="onSavedRouteDetailsBack($event)"></app-show-route-component>
    </div>

    <!-- search route -->
    <div *ngIf="isVisibleSearchRoutesDialog"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-l]="uiType=='L'">
      <app-search-route-component [uiType]="uiType" [languageCode]="userLanguage" [loggedInUser]="loggedInUser"
        [map]="mapRef" [sessionId]="sessionId" [foundRoutes]="publicRoutes" [isPresentedRoute]="presentedRoute"
        [scrolledRouteIndex]="scrolledRouteIndex" [regions]="searchRegions" [searchFilter]="searchRouteFilter"
        (back)="onSearchRouteBack()" (showError)="showApiError($event)"
        (foundPublicRoutes)="onFoundPublicRoutesChanged($event)" (activatePublicRoute)="onActivatePublicRoute($event)"
        (removePublicRoute)="onRemovePublicRoute($event)" (drawPublicRoutes)="onDrawPublicRoutes()"
        (drawPublicRoute)="onDrawPublicRoute($event)">
      </app-search-route-component>
    </div>

    <!-- gpx-route -->
    <div *ngIf="isVisibleGPXRouteDialog"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-m]="uiType=='L'">
      <app-gpx-route-component [uiType]="uiType" [gpxFileLoaded]="gpxFileLoaded" [activeGpxTrack]="activeGpxTrack"
        (back)="onGPXRouteBack()" (fileChange)="onFileChange($event)" (zoomToRoute)="onZoomToGpxRouteClick()"
        (showRouteDetails)="onShowGpxRouteDetailsClick()" (clearRoute)="onClearGpxRouteClick()">
      </app-gpx-route-component>
    </div>

    <!-- gpx-route-details -->
    <div *ngIf="isVisibleGpxRouteDetails"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-show-track-component [currentTrack]="activeGpxTrack" [languageCode]="userLanguage" [trackType]="'gpx-route'"
        (close)="onGpxRouteDetailsBack($event)">
      </app-show-track-component>
    </div>

    <!-- settings -->
    <div *ngIf="isVisibleSettings"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-l]="uiType=='L'">
      <app-settings-component [uiType]="uiType" [generalSettings]="generalSettings"
        [routePlannerSettings]="routePlannerSettings" [routeFormatSettings]="routeFormatSettings"
        (back)="onSettingsBack()" (generalSettingsChange)="onGeneralSettingsChange($event)"
        (routePlannerSettingsChange)="onRoutePlannerSettingsChange($event)"
        (routeFormatSettingsChange)="onRouteFormatSettingsChange($event)">
      </app-settings-component>
    </div>

    <!-- manage maps -->
    <div *ngIf="isVisibleManageMaps"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-manage-maps-component [uiType]="uiType" [loggedInUser]="loggedInUser" [userLanguage]="userLanguage"
        [isActiveOfflineMap]="isActiveOfflineMap" [indexedDb]="indexedDb" [serverMapSource]="serverMapSource"
        [map]="mapRef" [mapExtent]="mapExtent" (back)="onManageMapsBack()" (activateOfflineMap)="onActivateOfflineMap()"
        (zoomToMapExtent)="onZoomToExtentLonLat($event)" (activateOnlineMap)="onActivateOnlineMap()">
      </app-manage-maps-component>
    </div>

    <!-- map-selection -->
    <div *ngIf="isVisibleMapSelection"
      style="border: 1px solid gray; background-color:aliceblue; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-l]="uiType=='L'">
      <app-map-selection-component [uiType]="uiType" [languageCode]="userLanguage" [mapSource]="mapSource"
        [ovlmapSource]="ovlmapSource" [loggedInUser]="loggedInUser" (back)="onSelectMapBack()"
        (selectedMapChange)="selectMap($event)" (selectedOverlayMapChange)="selectOverlayMap($event)">
      </app-map-selection-component>
    </div>

    <!-- help -->
    <div *ngIf="isVisibleHelp"
      style="border: 1px solid gray; background-color:lemonchiffon; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-m]="uiType=='L'">
      <app-maps-help-component [uiType]="uiType" [languageCode]="userLanguage"
        (close)="onHelpBack()"></app-maps-help-component>
    </div>

    <!-- infos -->
    <div *ngIf="isVisibleInfos"
      style="border: 1px solid gray; background-color:lemonchiffon; margin:auto; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-maps-infos-component [uiType]="uiType" [languageCode]="userLanguage"
        (close)="onInfosBack()"></app-maps-infos-component>
    </div>

    <!-- admin -->
    <div *ngIf="isVisibleAdmin"
      style="border: 1px solid gray; background-color:lemonchiffon; width:100%; max-height:100vh; overflow-y:auto;"
      [class.dialog-xl]="uiType=='L'">
      <app-maps-admin-component [uiType]="uiType" [languageCode]="userLanguage"
        (close)="onAdminBack()"></app-maps-admin-component>
    </div>

    <!-- distance bar -->
    <div *ngIf="isActiveDialog && uiType=='L'" style="width:5px;"></div>
    <!-- <div *ngIf="isActiveDialog && uiType=='L'" style="cursor:col-resize;width:5px;"
      (click)="startMovingDistanceBar($event)">
    </div> -->


    <!--Map-Container-->
    <div id="mapContainer" style="flex-shrink:0; flex-grow:1; margin-left:0; margin-right:0;">

      <!-- map -->
      <div #map id="map" class="map-normal"></div>
      <!-- <div #map id="map" class="map-normal" [class.map-dummy]="!isOnline"></div> -->

      <!-- progress-spinner -->
      <div *ngIf="isVisibleProgressSpinner" style="position: absolute; top:80px; right: 80px;">
        <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
      </div>

      <!-- select on map-message -->
      <ng-container *ngIf="isVisibleMap">
        <!-- <div *ngIf="createSearchPointOnMap || createWayPointOnMap"
          style="background-color:aliceblue; padding:2px; font-size:large;">
          <span *ngIf="createSearchPointOnMap">{{'MAPS.MAP.PANEL.SEL-PLACE_MSG' | translate}}</span>
          <span *ngIf="createWayPointOnMap">{{'MAPS.MAP.PANEL.SEL-WAYPOINT_MSG' | translate}}</span>
        </div> -->

        <div *ngIf="isTestUser"
          style="background-color:aliceblue; padding:2px; padding-left:0.5rem; border-left:4px solid red; font-size:large;">

          <!-- ----------------- version ------------------ -->
          <span>Ver-{{appVersion}}-a2</span><br>
          <span>isOnline: {{isOnline}}</span><br>
          <div>
            <!-- <span *ngIf="currentLiveTrackingMe">TrackingMe: {{currentLiveTrackingMe.trackingId}}</span><br>
            <span *ngIf="lastLiveTrackingMePosition">Last sent pos at: {{lastLiveTrackingMePosition.at}}</span><br> -->
            <!-- <span *ngIf="liveTrackings && liveTrackings.length">Tracking: {{liveTrackings[0].trackingId}}</span><br>
            <span *ngIf="testTrackingPositions">Count positions: {{testTrackingPositions.length}}</span><br> -->
          </div>
          <!-- test-log -->
          <div>Test-Log:<br>

            <ng-container *ngFor="let row of debugLog">
              <span>{{row}}</span><br>
            </ng-container>
          </div>
          <!-- <div>
            <span>CurrentHeading[rad]: {{currentHeading}}</span><br>
            <span>CurrentCompassHeading[rad]: {{currentCompassHeading}}</span><br>
            <span>SpeedCheck[m/s]: {{speedCheck}}</span><br>
            <span>UpdateArrowCheck[bool]: {{updateArrowCheck}}</span><br>
            <span>OrientationAlpha[grad360]: {{orientationAlpha}}</span><br>
            <span>CompassHeading[grad360]: {{360.0 - compassHeading}}</span><br>
            </div> -->
          <!-- <div>Symbole:<br>
            <span>&#10138;</span><br>
            <span>&#10136;</span><br>
            <span>&#8596;</span><br>
            <span>&#216;</span><br>
            <span>&#9650;</span><br>
            <span>&#9660;</span><br>
            <span></span><br>
          </div> -->
        </div>
      </ng-container>

      <!-- debug-info -->
      <ng-container *ngIf="false">
        <div>
          <!-- <span>IsVisibleMap: {{isVisibleMap}}</span> -->
        </div>

        <!-- debug-info tracking -->
        <!-- <div *ngIf="trackingActive"
          style="background-color:aliceblue; padding:2px; padding-left:1.0rem; font-size:large;">
          <span *ngIf="trackingActive">Tracking {{activeTrack.trackPoints.length}}</span><br>
          <span *ngIf="trackingActive">Location: {{mapCoordinates[0] + '/' + mapCoordinates[1]}}</span>
          <span>TimeStamp: {{timeStampTracking | date: 'HH:mm:ss'}}</span><br>
          <span>TimeStampOld: {{timeStampTrackingOldSave | date: 'HH:mm:ss'}}</span><br>
          <span>TimeDiff: {{timeDiffTracking | number: '1.0-1'}} min</span><br>
          <span>TrackPointsCount: {{trackPointsCount}}</span><br>
          <span>Tracking-length: {{trackingLength | number: '1.0-1'}}</span><br>
          <span>Tracking-duration: {{trackingMoveDuration | number: '1.0-1'}}</span><br>
        </div> -->


      </ng-container>

      <!-- main-dialog -->
      <!-- <div *ngIf="isOnline && isVisibleMainDialog" id="mainDialog" class="dialog-main" -->
      <div *ngIf="isVisibleMainDialog" id="mainDialog" class="dialog-main"
        style="visibility:visible; position: absolute; z-index:0;">
        <button mat-icon-button [matMenuTriggerFor]="xmenu" style="margin-left:0.5rem;">
          <img style="height:30px;" src="./assets/icons/menu.svg" title="{{'MAPS.GEN.MENU_ICON_T' | translate}}"
            (click)="onMenuButtonClick()">
        </button>
        <mat-menu #xmenu="matMenu">
          <!-- route planner -->
          <button mat-menu-item class="menu-item" style="margin-left:0px;" (click)="onRoutePlannerClick()">
            <img class="menu-icon" src="./assets/icons/directions.svg" alt="icon">
            {{'MAPS.MENU.ROUTEPLANNER' | translate}}</button>
          <!-- tracking -->
          <button mat-menu-item class="menu-item" style="margin-left:0px;" (click)="onTrackingClick()">
            <img class="menu-icon" src="./assets/icons/go-kart-track.svg" alt="icon">
            {{'MAPS.MENU.TRACKING' | translate}}</button>
          <!-- saved items -->
          <button mat-menu-item class="menu-item" style="margin-left:0px;" (click)="onSavedItemsClick()">
            <img class="menu-icon" src="./assets/icons/bookmark-outline.svg" alt="icon">
            {{'MAPS.MENU.SAVED-ITEMS' | translate}}</button>
          <!-- search route -->
          <button mat-menu-item [disabled]="!isOnline" class="menu-item" style="margin-left:0px;"
            (click)="onSearchRoutesClick()">
            <img class="menu-icon" src="./assets/icons/search-web.svg" alt="icon">
            {{'MAPS.MENU.SEARCH-ROUTE' | translate}}</button>
          <!-- load GPX-route -->
          <button mat-menu-item class="menu-item" style="margin-left:0px;" (click)="onGPXRouteClick()">
            <img class="menu-icon" src="./assets/icons/upload-outline.svg" alt="icon">
            {{'MAPS.MENU.GPX-ROUTE' | translate}}</button>
          <!-- settings -->
          <button mat-menu-item class="menu-item" style="margin-left:0px" (click)="onSettingsClick()">
            <img class="menu-icon" src="./assets/icons/cog-outline.svg" alt="icon">
            {{'MAPS.MENU.SETTINGS' | translate}}</button>
          <!-- map-managemaent -->
          <button *ngIf="true" mat-menu-item class="menu-item" style="margin-left:0px" (click)="onManageMapsClick()">
            <img class="menu-icon" src="./assets/icons/download-box-outline.svg" alt="icon">
            {{'MAPS.MENU.MANAGE-MAPS' | translate}}</button>
          <!-- help -->
          <button mat-menu-item class="menu-item" style="margin-left:0px" (click)="onHelpClick()">
            <img class="menu-icon" src="./assets/icons/help-circle-outline.svg" alt="icon">
            {{'MAPS.MENU.HELP' | translate}}</button>
          <!-- infos -->
          <button mat-menu-item class="menu-item" style="margin-left:0px" (click)="onInfosClick()">
            <img class="menu-icon" src="./assets/icons/information-variant.svg" alt="icon">
            {{'MAPS.MENU.INFOS' | translate}}</button>
          <!-- admin -->
          <button *ngIf="isUserAdmin" mat-menu-item [disabled]="!isOnline" class="menu-item" (click)="onAdminClick()">
            <img class="menu-icon" src="./assets/icons/alert.svg" alt="icon"> Admin</button>
        </mat-menu>
        <span style="margin-top:0.8rem;">Trip4YouMaps</span>
        <!-- main-search -->
        <button *ngIf="isOnline" mat-icon-button
          style="margin-top:0.2rem; margin-left:0.5rem; padding-left:2px;padding-right:2px;"
          (click)="onMainSearchInputClick()">
          <img style="height:30px" src="./assets/icons/magnify.svg"
            title="{{'MAPS.MAIN-DLG.SEARCH_INPUT_PLH' | translate}}"></button>
        <button *ngIf="!isOnline" mat-icon-button disabled
          style="margin-top:0.2rem; margin-left:0.5rem; padding-left:2px;padding-right:2px;">
          <img style="height:30px" src="./assets/icons/magnify_gray.svg"
            title="{{'MAPS.MAIN-DLG.SEARCH_INPUT_PLH' | translate}}"></button>
        <!-- main-route-planner -->
        <button mat-icon-button style="margin-top:0.2rem;padding-left:2px;padding-right:2px;"
          (click)="onRoutePlannerClick()">
          <img style="height:30px" [src]="iconMoveTypeSource"
            title="{{'MAPS.MAIN-DLG.RPL_ICON_T' | translate}}"></button>
        <div *ngIf="isOnline"
          style="flex-grow: 0; flex-shrink: 1; flex-basis: content; text-overflow: ellipsis; overflow: hidden; padding-right: 0.2em;"
          class="topbar-user-container">
          <rockit-social-login [isUserLoggedIn]="this.loggedInUser" [showUserName]="false"></rockit-social-login>
        </div>
        <div *ngIf="!isOnline">
          <button mat-icon-button style="margin-top:0.2rem;padding-left:2px;padding-right:2px;">
            <img style="height:30px" src="./assets/icons/wifi-alert.svg"
              title="{{'MAPS.MAIN-DLG.OFFLINE_ICON_T' | translate}}" (click)="onOfflineSymbolClicked()"></button>
          <!-- <div style="color:red" *ngIf="errorMessageTitle">
            <span>{{this.errorMessageTitle}}</span><br>
            <span>{{this.errorMessage}}</span><br>
            <span>{{this.errorMessageHelp}}</span><br>
          </div> -->
        </div>
        <!-- live-tracking-me -->
        <div *ngIf="currentLiveTrackingMe">
          <button mat-icon-button [matMenuTriggerFor]="x2menu">
            <img style="height:30px" src="./assets/icons/access-point-check.svg"
              title="{{'MAPS.MAIN-DLG.LIVETRACKING_ICON_T' | translate}}">
          </button>
          <mat-menu #x2menu="matMenu">
            <!-- show live-tracking me -->
            <button mat-menu-item class="menu-item" style="margin-left:0px;" (click)="onShowLiveTrackingMeClick()">
              <img class="menu-icon" src="./assets/icons/eye.svg" alt="icon">
              {{'MAPS.LIVETRACKING-MENU.SHOW-ACTIVE-TRACKING' | translate}}</button>
            <!-- end live-tracking me -->
            <button mat-menu-item class="menu-item" style="margin-left:0px;" (click)="onAbortLiveTrackingMeClick()">
              <img class="menu-icon" src="./assets/icons/access-point-remove.svg" alt="icon">
              {{'MAPS.LIVETRACKING-MENU.REMOVE-ACTIVE-TRACKING' | translate}}</button>
          </mat-menu>
        </div>
      </div>

      <!-- navigation-instructions -->
      <div *ngIf="geojsonRoute && isNavigationStarted && isNaviInfoOnMapVisible" class="navigation-dialog pos-normal"
        [class.navigation-div-small]="uiType==='S'" [class.navigation-div-large]="uiType==='L'"
        style="font-size:medium; visibility:visible;">
        <a class="ol-popup-closer" (click)="onNavigationInfoCloseClick()"></a>
        <div *ngIf="true" style="font-size:x-large; min-width:150px;">
          <span *ngIf="currentRouteStepInstructionA">{{currentRouteStepInstructionA}}</span>
          <img style="margin-left:0.3em; margin-right:0.3em;" [src]="currentRouteStepIconSource">
          <span *ngIf="currentRouteStepInstructionB">{{currentRouteStepInstructionB}}</span><br>
          <span *ngIf="currentRouteStepInstructionC"
            style="margin-right:0.5rem;">{{currentRouteStepInstructionC}}<br></span>
        </div>
        <div *ngIf="isArrivalInfoOnMapVisible">
          <span *ngIf="isAtStartPoint" style="color:blue">{{'MAPS.NAV.AT-START-POINT_MSG' | translate}}<br></span>
          <span *ngIf="isAtEndPoint" style="color:blue">{{'MAPS.NAV.AT-END-POINT_MSG' | translate}}<br></span>
          <span *ngIf="isOffRoute" style="color:red">{{'MAPS.NAV.NOT-ON-ROUTE_MSG' | translate}}<br></span>
          <span>{{remainingDistance/1000 | number : '1.0-1'}} km</span>
          <span *ngIf="remainingDurationHour === 0">, {{remainingDurationMinute}} min</span>
          <span *ngIf="remainingDurationHour > 0">, {{remainingDurationHour}}h {{remainingDurationMinute}}
            min</span><br>
          <span *ngIf="estimatedArrivalTime">{{'MAPS.NAV.ARRIVAL-TIME_MSG' | translate}} {{estimatedArrivalTime |
            date: 'H:mm'}}<br></span>
        </div>
      </div>

      <!-- geolocation-info -->
      <div *ngIf="geolocation && isVisibleMap && showGeolocationInfo && !isVisibleInfos" id="geolocation-data"
        [class.locatorpos1]="!isActiveDialog" [class.locatorpos2]="isActiveDialog"
        style="padding: 1px; font-size:small; background:snow; visibility:visible; position:absolute;">
        <span *ngIf="geolocation.getAccuracy()">{{'MAPS.LOCATOR.ACCURACY_TEXT' | translate}}
          {{geolocation.getAccuracy()
          | number : '1.0-1' }} [m]</span>
        <span *ngIf="geolocation.getAltitude()" style="margin-left:0.5rem;">
          {{'MAPS.LOCATOR.ALTITUDE_TEXT' | translate}} {{geolocation.getAltitude() | number : '1.0-0'}} [m]</span>
        <span *ngIf="geolocation.getHeading()" style="margin-left:0.5rem;">
          {{'MAPS.LOCATOR.HEADING_TEXT' | translate}} {{geolocation.getHeading()*factorHeading | number : '1.0-1'}}
          [°]</span>
        <!-- <span *ngIf="geolocation.getHeading()"> XXXXX:{{geolocation.getHeading()*180/3.14}}°</span> -->
        <span *ngIf="geolocation && geolocation.getSpeed()" style="margin-left:0.5rem;">
          {{'MAPS.LOCATOR.SPEED_TEXT' | translate}} {{geolocation.getSpeed()*3.6 | number : '1.0-1'}} [km/h]</span>
      </div>


      <!-- mouse-postion below map -->
      <!-- <div id="mouse-position" style="margin-top:0.5rem; color:cornflowerblue; text-align:center;"></div> -->


      <!-- end map-container -->
    </div>


    <!-- feature-popup  -->
    <div *ngIf="false" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- map-places -->
      <div *ngIf="selectedMapPoint">
        <!-- <ng-container *ngIf="selectedMapPoint.layer==='live-tracking'">
          <span>{{'MAPS.F-POPUP.CATEGORY-TRACKING-ROUTE' | translate}}<br></span>
          <button *ngIf="selectedMapPoint.cat==='track-end' && selectedMapPoint.notice==='true'"
            style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShowWholeTrackingRouteClick()">
            <img width="20px" src="./assets/icons/eye.svg">
            {{'MAPS.F-POPUP.TRACK-ROUTE-SHOW-WHOLE-ROUTE' | translate}}</button>
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onRemoveTrackingClickAtPoint()">
            <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.TRACK-ROUTE-REMOVE_BUTT_MSG' |
            translate}}</button>
          <hr>
        </ng-container> -->
        <ng-container *ngIf="selectedMapPoint.layer!=='geo-location'">
          <!-- plan route to this place -->
          <span *ngIf=" selectedMapPoint.layer!=='way-points' || canDeleteSelectedWayPoint">
            {{'MAPS.N-POPUP.CATEGORY-RPL' | translate}}<br></span>
          <button *ngIf="selectedMapPoint.type!=='waypoint'" style="cursor:pointer;margin-top:0.5rem;"
            class="popup-button" (click)="onPlanRouteToSelectedPlaceClick()">
            <img width="20px" src="./assets/icons/directions.svg"> {{'MAPS.F-POPUP.PLAN-ROUTE-TO-PLACE_BUTT_MSG' |
            translate}}</button>
          <!-- route-pois -->
          <ng-container *ngIf="selectedMapPoint.layer!=='route-pois' && selectedMapPoint.layer!=='way-points'">
            <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
              (click)="onAddSelectedMapPlaceToCurrentRouteClick()">
              <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ADD-PLACE-TO-CURRENT-ROUTE_BUTT_MSG' |
              translate}}</button>
            <hr>
          </ng-container>
          <!-- way-point -->
          <!-- <ng-container *ngIf="selectedMapPoint.type==='wp'">
            <button *ngIf="canDeleteSelectedWayPoint" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
              (click)="onDeleteWayPoint(selectedMapPoint.id)">
              <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-WAYPOINT_BUTT_MSG' |
              translate}}</button>
          </ng-container> -->
          <ng-container *ngIf="selectedMapPoint.layer==='route-pois'">
            <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
              (click)="onRemoveMapPlaceFromCurrentRouteClick()">
              <img width="20px" src="./assets/icons/delete.svg">
              {{'MAPS.F-POPUP.REMOVE-PLACE-FROM-CURRENT-ROUTE_BUTT_MSG'| translate}}
            </button>
            <hr>
          </ng-container>
          <hr>
        </ng-container>

        <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
        <!-- share place -->
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
          <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
          translate}}</button>

        <!-- saved places -->
        <!-- <span>
          <hr>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br>
        </span>
        <button *ngIf="selectedMapPoint.type!=='savedplace-l'" style="cursor:pointer;margin-top:0.5rem;"
          class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
          <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
          translate}}</button>
        <button *ngIf="selectedMapPoint.type!=='savedplace-s'" style="cursor:pointer;margin-top:0.5rem;"
          class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
          <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
          translate}}</button> -->

        <!-- create marker from search-point-->
        <!-- <div *ngIf="selectedMapPoint.type!=='marker'">
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onSelectedPlaceToMarkerClick()">
            <img width="20px" src="./assets/icons-new/flag-variant-plus.svg">
            {{'MAPS.F-POPUP.CONVERT-PLACE-TO-MARKER_BUTT_MSG' | translate}}</button>
        </div> -->

        <!-- search-point -->
        <!-- <ng-container *ngIf="selectedMapPoint.type==='searchpoint'">
          <div *ngIf="selectedMapPoint.type==='searchpoint'">
            <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onDeleteSearchPoint()">
              <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-SEARCH-POINT-FROM-MAP_BUTT_MSG' |
              translate}}</button>
          </div>
        </ng-container> -->

        <!-- marker -->
        <!-- <ng-container *ngIf="selectedMapPoint.type==='marker'">
          <div *ngIf="selectedMapPoint.type==='marker'">
            <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
              (click)="onRemoveMarker(selectedMapPoint)">
              <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-MARKER-FROM-MAP_BUTT_MSG' |
              translate}}</button>
          </div>
        </ng-container> -->
        <!-- saved-place -->
        <ng-container *ngIf="selectedMapPoint.type==='savedplace'">
          <!-- set places category to invisible -->
          <!-- remove saved-place -->
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onDeleteSavedPlaceLocaly(selectedMapPoint)">
            <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-SAVED-PLACE-FROM-MAP_BUTT_MSG' |
            translate}}</button>
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onMakePlaceCategoryLocalInvisible(selectedMapPoint)">
            <img width="20px" src="./assets/icons/eye-off.svg"> {{'MAPS.F-POPUP.MAKE-PLACE-CATEGORY-INVISIBLE_BUTT_MSG'
            | translate}}</button>
        </ng-container>
        <!-- show tags -->
        <hr>
        <div
          *ngIf="!showTagsInPopups && selectedMapPoint && selectedMapPoint.idOSM && selectedMapPoint.idOSM!='undefined'"
          style="margin:auto;">
          <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(true)">
            Click to show OSM tags ...</span>
        </div>
        <div #contenttags id="popup-contenttag" style="margin-top:0.5rem;"></div>
        <div *ngIf="showTagsInPopups" style="margin:auto;">
          <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(false)">
            Click to hide OSM tags ...</span>
        </div>
      </div>

      <!-- other features -->
      <div *ngIf="!selectedMapPoint && selectedMapItemType">
        <!-- planned route -->
        <ng-container *ngIf="selectedMapItemType==='route'">
          <!-- insert waypoint -->
          <button *ngIf="!currentRoute.roundRoute" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onInsertWaypointOnRoutePopupClick()">
            <img width="20px" src="./assets/icons/map-marker-plus.svg">
            {{'MAPS.F-POPUP.ROUTE-INSERT-WAYPOINT_BUTT_MSG' | translate}}</button>
          <!-- remove all route-pois -->
          <button *ngIf="currentRoute.pois.length>0" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onRemoveAllRoutePoisClick()">
            <img width="20px" src="./assets/icons/delete.svg">
            {{'MAPS.F-POPUP.ROUTE-REMOVE-ALL-ROUTEPOIS_BUTT_MSG' | translate}}</button>
          <!-- show route-details -->
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onShowRouteDetailsPopupClick()">
            <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ROUTE-SHOW-DETAILS_BUTT_MSG' |
            translate}}</button>
        </ng-container>
        <!-- gpx route -->
        <ng-container *ngIf="selectedMapItemType==='gpxroute'">
          <!-- show route-details -->
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onShowGpxRouteDetailsPopupClick()">
            <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.GPXROUTE-SHOW-DETAILS_BUTT_MSG' |
            translate}}</button>
        </ng-container>
        <!-- saved-route -->
        <ng-container *ngIf="selectedMapItemType==='savedroute-l'">
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onShowSavedRouteDetailsClick()">
            <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-SHOW-DETAILS_BUTT_MSG' |
            translate}}</button>
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onActivateSavedRouteClick()">
            <img width="20px" src="./assets/icons-new/magic-staff.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-ACTIVATE_BUTT_MSG' |
            translate}}</button>
        </ng-container>
        <!-- saved-route -->
        <ng-container *ngIf="selectedMapItemType==='savedroute-s'">
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onShowSavedRouteDetailsClick()">
            <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-SHOW-DETAILS_BUTT_MSG' |
            translate}}</button>
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onActivateSavedRouteClick()">
            <img width="20px" src="./assets/icons-new/magic-staff.svg">
            {{'MAPS.F-POPUP.PUBLIC-ROUTE-ACTIVATE_BUTT_MSG' | translate}}</button>
        </ng-container>
        <!-- public-route -->
        <ng-container *ngIf="selectedMapItemType==='pubroute'">
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onShowSavedRouteDetailsClick()">
            <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-SHOW-DETAILS_BUTT_MSG' |
            translate}}</button>
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onActivatePublicRouteClick()">
            <img width="20px" src="./assets/icons-new/magic-staff.svg"> {{'MAPS.F-POPUP.PUBLIC-ROUTE-ACTIVATE_BUTT_MSG'
            | translate}}</button>
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onClearAllPublicRoutesClick()">
            <img width="20px" src="./assets/icons/close.svg"> {{'MAPS.F-POPUP.PUBLIC-ROUTE-CLEAR-ALL_BUTT_MSG'
            |
            translate}}</button>
        </ng-container>
        <!-- live-tracking -->
        <ng-container *ngIf="selectedMapItemType==='track-route'">
          <!-- show only end-position -->
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onShowOnlyTrackingEndPositionClick()">
            <img width="20px" src="./assets/icons/eye-off.svg">
            {{'MAPS.F-POPUP.TRACK-ROUTE-SHOW-ONLY_END-POS_BUTT_MSG' | translate}}
          </button>
          <!-- remove-tracking -->
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onRemoveTrackingClickAtRoute()">
            <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.TRACK-ROUTE-REMOVE_BUTT_MSG' |
            translate}}</button>
        </ng-container>
      </div>

    </div>

    <!-- feature-popup POI -->
    <div *ngIf="isFeaturePopupPOIVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- plan route to this place -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onPlanRouteToSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/directions.svg"> {{'MAPS.F-POPUP.PLAN-ROUTE-TO-PLACE_BUTT_MSG' |
        translate}}
      </button>
      <!-- route-pois -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedMapPlaceToCurrentRouteClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ADD-PLACE-TO-CURRENT-ROUTE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- share place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- save place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br></span>
      <button *ngIf="selectedMapPoint.type!=='savedplace-l'" style="cursor:pointer;margin-top:0.5rem;"
        class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
        translate}}</button>
      <button *ngIf="selectedMapPoint.type!=='savedplace-s'" style="cursor:pointer;margin-top:0.5rem;"
        class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
        translate}}</button>

      <!-- create marker from POI-->
      <div *ngIf="selectedMapPoint.type!=='marker'">
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onSelectedPlaceToMarkerClick()">
          <img width="20px" src="./assets/icons-new/flag-variant-plus.svg">
          {{'MAPS.F-POPUP.CONVERT-PLACE-TO-MARKER_BUTT_MSG' | translate}}</button>
      </div>

      <!-- show tags -->
      <hr>
      <div
        *ngIf="!showTagsInPopups && selectedMapPoint && selectedMapPoint.idOSM && selectedMapPoint.idOSM!='undefined'"
        style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(true)">
          Click to show OSM tags ...</span>
      </div>
      <div #contenttags id="popup-contenttag" style="margin-top:0.5rem;"></div>
      <div *ngIf="showTagsInPopups" style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(false)">
          Click to hide OSM tags ...</span>
      </div>
    </div>

    <!-- feature-popup search-point -->
    <div *ngIf="isFeaturePopupSearchPointVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- plan route to this place -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onPlanRouteToSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/directions.svg"> {{'MAPS.F-POPUP.PLAN-ROUTE-TO-PLACE_BUTT_MSG' |
        translate}}
      </button>
      <!-- route-pois -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedMapPlaceToCurrentRouteClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ADD-PLACE-TO-CURRENT-ROUTE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- share place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- save place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br></span>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
        translate}}</button>
      <button *ngIf="loggedInUser" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
        translate}}</button>

      <!-- create marker from search-point-->
      <div *ngIf="selectedMapPoint.type!=='marker'">
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onSelectedPlaceToMarkerClick()">
          <img width="20px" src="./assets/icons-new/flag-variant-plus.svg">
          {{'MAPS.F-POPUP.CONVERT-PLACE-TO-MARKER_BUTT_MSG' | translate}}</button>
      </div>
      <!-- remove search-point -->
      <div *ngIf="selectedMapPoint.type==='searchpoint'">
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onDeleteSearchPoint()">
          <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-SEARCH-POINT-FROM-MAP_BUTT_MSG'
          | translate}}</button>
      </div>

      <!-- show tags -->
      <hr>
      <div
        *ngIf="!showTagsInPopups && selectedMapPoint && selectedMapPoint.idOSM && selectedMapPoint.idOSM!='undefined'"
        style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(true)">
          Click to show OSM tags ...</span>
      </div>
      <div #contenttags id="popup-contenttag" style="margin-top:0.5rem;"></div>
      <div *ngIf="showTagsInPopups" style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(false)">
          Click to hide OSM tags ...</span>
      </div>

    </div>

    <!-- feature-popup marker -->
    <div *ngIf="isFeaturePopupMarkerVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- plan route to this place -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onPlanRouteToSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/directions.svg"> {{'MAPS.F-POPUP.PLAN-ROUTE-TO-PLACE_BUTT_MSG' |
        translate}}</button>
      <!-- route-pois -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedMapPlaceToCurrentRouteClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ADD-PLACE-TO-CURRENT-ROUTE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- share place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- save place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br></span>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
        translate}}</button>
      <button *ngIf="loggedInUser" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
        translate}}</button>

      <!-- remove marker -->
      <div>
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
          (click)="onRemoveMarker(selectedMapPoint)">
          <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-MARKER-FROM-MAP_BUTT_MSG' |
          translate}}</button>
      </div>

      <!-- show tags -->
      <hr>
      <div
        *ngIf="!showTagsInPopups && selectedMapPoint && selectedMapPoint.idOSM && selectedMapPoint.idOSM!='undefined'"
        style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(true)">
          Click to show OSM tags ...</span>
      </div>
      <div #contenttags id="popup-contenttag" style="margin-top:0.5rem;"></div>
      <div *ngIf="showTagsInPopups" style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(false)">
          Click to hide OSM tags ...</span>
      </div>

    </div>

    <!-- feature-popup saved-place -->
    <div *ngIf="isFeaturePopupSavedPlaceVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- plan route to this place -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" [disabled]="!isOnline"
        (click)="onPlanRouteToSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/directions.svg"> {{'MAPS.F-POPUP.PLAN-ROUTE-TO-PLACE_BUTT_MSG' |
        translate}}</button>
      <!-- route-pois -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedMapPlaceToCurrentRouteClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ADD-PLACE-TO-CURRENT-ROUTE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- share place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br></span>
      <ng-container *ngIf="selectedMapPoint.type==='savedplace-s'">
        <!-- save place local -->
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
          (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
          <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
          translate}}</button>
        <!-- remove saved-place server -->
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" [disabled]="!isOnline"
          (click)="onDeleteSavedPlaceServer(selectedMapPoint)">
          <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-SAVED-PLACE-FROM-MAP_BUTT_MSG' |
          translate}}</button>
        <!-- make saved-place category local invisible -->
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
          (click)="onMakePlaceCategoryServerInvisible(selectedMapPoint)">
          <img width="20px" src="./assets/icons/eye-off.svg"> {{'MAPS.F-POPUP.MAKE-PLACE-CATEGORY-INVISIBLE_BUTT_MSG' |
          translate}}</button>
      </ng-container>

      <ng-container *ngIf="selectedMapPoint.type==='savedplace-l'">
        <!-- save place on server -->
        <button *ngIf="loggedInUser" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
          [disabled]="!isOnline" (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
          <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
          translate}}</button>
        <!-- remove saved-place local -->
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
          (click)="onDeleteSavedPlaceLocaly(selectedMapPoint)">
          <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-SAVED-PLACE-FROM-MAP_BUTT_MSG' |
          translate}}</button>
        <!-- make saved-place category local invisible -->
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
          (click)="onMakePlaceCategoryLocalInvisible(selectedMapPoint)">
          <img width="20px" src="./assets/icons/eye-off.svg"> {{'MAPS.F-POPUP.MAKE-PLACE-CATEGORY-INVISIBLE_BUTT_MSG' |
          translate}}</button>
      </ng-container>

      <!-- show tags -->
      <hr>
      <div
        *ngIf="!showTagsInPopups && selectedMapPoint && selectedMapPoint.idOSM && selectedMapPoint.idOSM!='undefined'"
        style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(true)">
          Click to show OSM tags ...</span>
      </div>
      <div #contenttags id="popup-contenttag" style="margin-top:0.5rem;"></div>
      <div *ngIf="showTagsInPopups" style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(false)">
          Click to hide OSM tags ...</span>
      </div>

    </div>

    <!-- feature-popup waypoint -->
    <div *ngIf="isFeaturePopupWPVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- delete wayPoint -->
      <button *ngIf="canDeleteSelectedWayPoint" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onDeleteWayPoint(selectedMapPoint.id)">
        <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.REMOVE-WAYPOINT_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- share place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- save place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br></span>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
        translate}}</button>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
        translate}}</button>

      <!-- show tags -->
      <div
        *ngIf="!showTagsInPopups && selectedMapPoint && selectedMapPoint.idOSM && selectedMapPoint.idOSM!='undefined'"
        style="margin:auto;">
        <hr>
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(true)">
          Click to show OSM tags ...</span>
      </div>
      <div #contenttags id="popup-contenttag" style="margin-top:0.5rem;"></div>
      <div *ngIf="showTagsInPopups" style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(false)">
          Click to hide OSM tags ...</span>
      </div>

    </div>

    <!-- feature-popup routePOI -->
    <div *ngIf="isFeaturePopupRoutePOIVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- remove route-poi -->
      <span>{{'MAPS.N-POPUP.CATEGORY-RPL' | translate}}<br></span>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onRemoveMapPlaceFromCurrentRouteClick()">
        <img width="20px" src="./assets/icons/delete.svg">
        {{'MAPS.F-POPUP.REMOVE-PLACE-FROM-CURRENT-ROUTE_BUTT_MSG'| translate}}
      </button>
      <hr>

      <!-- share place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
        <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
        translate}}</button>
      <hr>

      <!-- save place -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br></span>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
        translate}}</button>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
        translate}}</button>

      <!-- show tags -->
      <div
        *ngIf="!showTagsInPopups && selectedMapPoint && selectedMapPoint.idOSM && selectedMapPoint.idOSM!='undefined'"
        style="margin:auto;">
        <hr>
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(true)">
          Click to show OSM tags ...</span>
      </div>
      <div #contenttags id="popup-contenttag" style="margin-top:0.5rem;"></div>
      <div *ngIf="showTagsInPopups" style="margin:auto;">
        <span style="margin-left:2.0rem; cursor:pointer;" (click)="onShowTagsInPopupClick(false)">
          Click to hide OSM tags ...</span>
      </div>

    </div>

    <!-- feature-popup GPX-point -->
    <div *ngIf="isFeaturePopupGPXPointVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>


    </div>

    <!-- route-feature-popup  -->
    <div *ngIf="isFeaturePopupRouteVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- insert waypoint -->
      <button *ngIf="!currentRoute.roundRoute" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onInsertWaypointOnRoutePopupClick()">
        <img width="20px" src="./assets/icons/map-marker-plus.svg">
        {{'MAPS.F-POPUP.ROUTE-INSERT-WAYPOINT_BUTT_MSG' | translate}}</button>
      <!-- remove all route-pois -->
      <button *ngIf="currentRoute.pois.length>0" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onRemoveAllRoutePoisClick()">
        <img width="20px" src="./assets/icons/delete.svg">
        {{'MAPS.F-POPUP.ROUTE-REMOVE-ALL-ROUTEPOIS_BUTT_MSG' | translate}}</button>
      <!-- show route-details -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShowRouteDetailsPopupClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ROUTE-SHOW-DETAILS_BUTT_MSG' |
        translate}}</button>
    </div>

    <!-- route-segment-feature-popup  -->
    <div *ngIf="isFeaturePopupRouteSegmentVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- insert waypoint -->
      <button *ngIf="!currentRoute.roundRoute" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onInsertWaypointOnRoutePopupClick()">
        <img width="20px" src="./assets/icons/map-marker-plus.svg">
        {{'MAPS.F-POPUP.ROUTE-INSERT-WAYPOINT_BUTT_MSG' | translate}}</button>
      <!-- remove all route-pois -->
      <button *ngIf="currentRoute.pois.length>0" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onRemoveAllRoutePoisClick()">
        <img width="20px" src="./assets/icons/delete.svg">
        {{'MAPS.F-POPUP.ROUTE-REMOVE-ALL-ROUTEPOIS_BUTT_MSG' | translate}}</button>
      <!-- show route-details -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShowRouteDetailsPopupClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.ROUTE-SHOW-DETAILS_BUTT_MSG' |
        translate}}</button>

    </div>

    <!-- saved-route-feature-popup  -->
    <div *ngIf="isFeaturePopupSavedRouteVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- saved-route local -->
      <ng-container *ngIf="selectedMapItemType==='savedroute-l'">
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShowSavedRouteDetailsClick()">
          <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-SHOW-DETAILS_BUTT_MSG' |
          translate}}</button>
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onActivateSavedRouteClick()">
          <img width="20px" src="./assets/icons-new/magic-staff.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-ACTIVATE_BUTT_MSG' |
          translate}}</button>
      </ng-container>
      <!-- saved-route server -->
      <ng-container *ngIf="selectedMapItemType==='savedroute-s'">
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShowSavedRouteDetailsClick()">
          <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-SHOW-DETAILS_BUTT_MSG' |
          translate}}</button>
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onActivateSavedRouteClick()">
          <img width="20px" src="./assets/icons-new/magic-staff.svg">
          {{'MAPS.F-POPUP.PUBLIC-ROUTE-ACTIVATE_BUTT_MSG' | translate}}</button>
      </ng-container>
    </div>

    <!-- public-route-feature-popup  -->
    <div *ngIf="isFeaturePopupPubRouteVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShowSavedRouteDetailsClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.SAVED-ROUTE-SHOW-DETAILS_BUTT_MSG' |
        translate}}</button>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onActivatePublicRouteClick()">
        <img width="20px" src="./assets/icons-new/magic-staff.svg"> {{'MAPS.F-POPUP.PUBLIC-ROUTE-ACTIVATE_BUTT_MSG'
        | translate}}</button>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onClearPublicRouteClick()">
        <img width="20px" src="./assets/icons/close.svg"> {{'MAPS.F-POPUP.PUBLIC-ROUTE-CLEAR_BUTT_MSG' |
        translate}}</button>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onClearAllPublicRoutesClick()">
        <img width="20px" src="./assets/icons/close.svg"> {{'MAPS.F-POPUP.PUBLIC-ROUTE-CLEAR-ALL_BUTT_MSG' |
        translate}}</button>

    </div>

    <!-- GPX-route-feature-popup  -->
    <div *ngIf="isFeaturePopupGPXRouteVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- show route-details -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        (click)="onShowGpxRouteDetailsPopupClick()">
        <img width="20px" src="./assets/icons/eye.svg"> {{'MAPS.F-POPUP.GPXROUTE-SHOW-DETAILS_BUTT_MSG' |
        translate}}</button>
    </div>

    <!-- feature-popup geolocation -->
    <div *ngIf="isFeaturePopupGeolocationVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- share geo-location -->
      <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
      <div *ngIf="!loggedInUser" style="color:red;">
        <span *ngIf="userLanguage !== 'de'">To use the "Share location" functions you have to be logged in.</span>
        <span *ngIf="userLanguage === 'de'">Für die Funktionen zum "Standort teilen" musst du eingelogged sein.</span>
      </div>
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" [disabled]="!loggedInUser"
        (click)="onShareLocationClick()">
        <img width="20px" src="./assets/icons/share.svg">{{'MAPS.F-POPUP.SHARE-LOCATION_BUTT_MSG' |
        translate}}</button>
      <button *ngIf="!currentLiveTrackingMe" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        [disabled]="!loggedInUser" (click)="onStartLiveTrackingMeClick()">
        <img width="20px" src="./assets/icons/share.svg">{{'MAPS.F-POPUP.LIVE-TRACKING_BUTT_MSG' |
        translate}}</button>
      <button *ngIf="currentLiveTrackingMe" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
        [disabled]="!loggedInUser" (click)="onShowLiveTrackingMeClick()">
        <img width="20px" src="./assets/icons/eye.svg">{{'MAPS.LIVETRACKING-MENU.SHOW-ACTIVE-TRACKING' |
        translate}}</button>

      <!-- save geolocation as place -->
      <hr>
      <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br></span>
      <button *ngIf="selectedMapPoint.type!=='savedplace-l'" style="cursor:pointer;margin-top:0.5rem;"
        class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
        translate}}</button>
      <button *ngIf="selectedMapPoint.type!=='savedplace-s'" style="cursor:pointer;margin-top:0.5rem;"
        class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
        translate}}</button>

      <!-- create marker from geolocation -->
      <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onSelectedPlaceToMarkerClick()">
        <img width="20px" src="./assets/icons-new/flag-variant-plus.svg">
        {{'MAPS.F-POPUP.CONVERT-PLACE-TO-MARKER_BUTT_MSG' | translate}}</button>
    </div>

    <!-- feature-popup live-tracking -->
    <div *ngIf="isFeaturePopupLiveTrackingVisible" #container hidden id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>

      <!-- map-places -->
      <div *ngIf="selectedMapPoint">
        <ng-container *ngIf="selectedMapPoint.layer==='live-tracking'">
          <span>{{'MAPS.F-POPUP.CATEGORY-TRACKING-ROUTE' | translate}}<br></span>
          <!-- show whole tracking-route -->
          <button *ngIf="selectedMapPoint.cat==='track-end' && selectedMapPoint.notice==='true'"
            style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShowWholeTrackingRouteClick()">
            <img width="20px" src="./assets/icons/eye.svg">
            {{'MAPS.F-POPUP.TRACK-ROUTE-SHOW-WHOLE-ROUTE' | translate}}</button>
          <!-- remove-tracking -->
          <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
            (click)="onRemoveTrackingClickAtPoint()">
            <img width="20px" src="./assets/icons/delete.svg"> {{'MAPS.F-POPUP.TRACK-ROUTE-REMOVE_BUTT_MSG' |
            translate}}</button>
          <hr>
        </ng-container>
        <!-- share place -->
        <span>{{'MAPS.N-POPUP.CATEGORY-SHARE' | translate}}</span><br>
        <button style="cursor:pointer;margin-top:0.5rem;" class="popup-button" (click)="onShareSelectedPlaceClick()">
          <img width="20px" src="./assets/icons/share.svg"> {{'MAPS.F-POPUP.SHARE-PLACE_BUTT_MSG' |
          translate}}</button>
      </div>
      <!-- save places -->
      <span>
        <hr>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}<br>
      </span>
      <button *ngIf="selectedMapPoint.type!=='savedplace-l'" style="cursor:pointer;margin-top:0.5rem;"
        class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('local')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' |
        translate}}</button>
      <button *ngIf="selectedMapPoint.type!=='savedplace-s'" style="cursor:pointer;margin-top:0.5rem;"
        class="popup-button" (click)="onAddSelectedPlaceToSavedPlacesClick('server')">
        <img width="20px" src="./assets/icons/star-plus-outline.svg"> {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' |
        translate}}</button>
    </div>

    <!-- new-point-popup  -->
    <div *ngIf="isNewPointPopupVisible && selectedWayPointForMapIndex===-1" #container hidden id="popup"
      class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>
      <!-- no wp defined -->
      <ng-container>
        <!-- plan route to this place -->
        <span>{{'MAPS.N-POPUP.CATEGORY-RPL' | translate}}</span><br>
        <!-- plan route to point -->
        <button *ngIf="currentPositionLngLat" style="cursor:pointer;margin-top:0.5rem;" class="popup-button"
          [disabled]="!isOnline" (click)="onPlanRouteToSelectedPointClick()">
          <img width="20px" src="./assets/icons/directions.svg"> {{'MAPS.F-POPUP.PLAN-ROUTE-TO-PLACE_BUTT_MSG' |
          translate}}</button>
        <!-- here is start-point -->
        <button *ngIf="!currentRoute.roundRoute && !isStartPointDefined" class="popup-button" [disabled]="!isOnline"
          (click)="setStartWayPointToMapPosition()">
          {{'MAPS.RPL.OVL.HERE-IS-START-POINT_BUTT_MSG' | translate}}</button>
        <!-- add this point to route -->
        <button *ngIf="!currentRoute.roundRoute && isStartPointDefined && !isEndPointDefined" class="popup-button"
          [disabled]="!isOnline" (click)="setAdditionalWayPointToMapPosition()">
          {{'MAPS.RPL.OVL.HERE-IS-ADD-WAY-POINT_BUTT_MSG' | translate}}</button>
        <button *ngIf="!currentRoute.roundRoute && isStartPointDefined && isEndPointDefined" class="popup-button"
          [disabled]="!isOnline" (click)="setAdditionalWayPointToMapPosition()">
          {{'MAPS.RPL.OVL.HERE-IS-ADDEND-WAY-POINT_BUTT_MSG' | translate}}</button>
        <!-- here is end-point -->
        <button *ngIf="!currentRoute.roundRoute && !isEndPointDefined" class="popup-button" [disabled]="!isOnline"
          (click)="setEndWayPointToMapPosition()">
          {{'MAPS.RPL.OVL.HERE-IS-END-POINT_BUTT_MSG' | translate}}</button>
        <!-- plan round-route at this point -->
        <button *ngIf="true" style="cursor:pointer;margin-top:0.5rem;" class="popup-button" [disabled]="!isOnline"
          (click)="onPlanRoundRouteFromSelectedPointClick()">
          <img width="20px" src="./assets/icons/directions.svg"> {{'MAPS.F-POPUP.PLAN-ROUND-ROUTE_BUTT_MSG' |
          translate}}</button>
        <hr>
        <!-- search here -->
        <span>{{'MAPS.N-POPUP.CATEGORY-SEARCH' | translate}}</span><br>
        <button class="popup-button" [disabled]="!isOnline" (click)="newSearchPointOnMap()">
          <img width="20px" src="./assets/icons/magnify.svg">
          {{'MAPS.RPL.OVL.SEARCH-HERE_BUTT_MSG' | translate}}</button>
        <!-- get elevation -->
        <button class="popup-button" [disabled]="!isOnline" (click)="newElevationOnMap()">
          &#9652; {{'MAPS.RPL.OVL.GET-ELEVATION_BUTT_MSG' | translate}}</button>
        <hr>
        <!-- save this place -->
        <span>{{'MAPS.N-POPUP.CATEGORY-SAVE' | translate}}</span><br>
        <!-- save place localy -->
        <button class="popup-button" (click)="newSavePointOnMap('local')">
          <img width="20px" src="./assets/icons/star-plus-outline.svg">
          {{'MAPS.F-POPUP.SAVE-PLACE-LOCALY_BUTT_MSG' | translate}}</button>
        <!-- save place server -->
        <button *ngIf="loggedInUser" class="popup-button" [disabled]="!isOnline" (click)="newSavePointOnMap('server')">
          <img width="20px" src="./assets/icons/star-plus-outline.svg">
          {{'MAPS.F-POPUP.SAVE-PLACE-CLOUD_BUTT_MSG' | translate}}</button>
        <!-- create marker -->
        <button class="popup-button" (click)="newMarkerPointOnMap()">
          <img width="20px" src="./assets/icons-new/flag-variant-plus.svg">
          {{'MAPS.RPL.OVL.CREATE-MARKER_BUTT_MSG' | translate}}</button>
      </ng-container>
    </div>
    <div *ngIf="isNewPointPopupVisible && selectedWayPointForMapIndex!==-1" #container id="popup" class="ol-popup"
      style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>
      <ng-container>
        <button *ngIf="isWayPointStartPoint" class="popup-button" (click)="setStartWayPointToMapPosition()">
          {{'MAPS.RPL.OVL.HERE-AS-START-POINT_BUTT_MSG' | translate}}</button>
        <button *ngIf="!isWayPointStartPoint && !isWayPointEndPoint" class="popup-button"
          (click)="setSelectedWayPointToMapPosition()">
          {{selectedWayPointForMapIndex+1}}: {{'MAPS.RPL.OVL.HERE-AS-WAY-POINT_BUTT_MSG' | translate}}</button>
        <button *ngIf="isWayPointEndPoint" class="popup-button" (click)="setEndWayPointToMapPosition()">
          {{'MAPS.RPL.OVL.HERE-AS-END-POINT_BUTT_MSG' | translate}}</button>
      </ng-container>
    </div>

    <!-- place-name-popup  -->
    <div *ngIf="isNamePopupVisible" #container id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>
      <ng-container *ngIf="objectType==='marker'">
        <mat-form-field>
          <input matInput style="width: 200px;" type="text" spellcheck="false"
            placeholder="{{'MAPS.N-POPUP.MARKER-NAME_PLH' | translate}}" [(ngModel)]="markerName" />
        </mat-form-field><br>
        <button mat-raised-button color="primary" style="float:right;" (click)="onConvertPlaceToMarker()">ok</button>
      </ng-container>
      <ng-container *ngIf="objectType==='favorite'">
        <mat-form-field>
          <input matInput style="width: 200px;" type="text" spellcheck="false"
            placeholder="{{'MAPS.N-POPUP.FAVORITE-NAME_PLH' | translate}}" [(ngModel)]="savedPlaceName" />
        </mat-form-field><br>
        <button mat-raised-button color="primary" style="float:right;"
          (click)="onAddSelectedMapPlaceToSavedPlaces()">ok</button>
      </ng-container>
    </div>

    <!-- live-tracking-me-popup  -->
    <div *ngIf="isLiveTrackingMePopupVisible" #container id="popup" class="ol-popup" style="cursor:auto;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.5rem;"></div>
      <div *ngIf="currentPopupMode !== 'new'">
        <span>{{'MAPS.LTR-POPUP.KEY_LBL' | translate}}</span><br>
        <span><b>{{newLiveTrackingMe.trackingId}}</b></span><br>
        <button mat-raised-button color="primary" style="margin-top:0.5rem;" (click)="onShareLiveTrackingMeClick()">
          {{'MAPS.LTR-POPUP.SHARE-LINK_BTN_T' | translate}}</button>
        <!-- <button mat-raised-button color="primary" style="margin-left:0.5rem;"
        (click)="onShareLiveTrackingMeKeyClick_old()">Share key</button> -->
        <button mat-raised-button color="primary" style="margin-left:0.5rem;"
          (click)="onAbortLiveTrackingMeClick()">{{'MAPS.LTR-POPUP.ABORT_BTN_T' | translate}}</button>
      </div>
      <ng-container *ngIf="!newLiveTrackingMePermanent && currentPopupMode==='new'">
        <div class="live-tracking-duration-label-container">
          <label>{{'MAPS.LTR-POPUP.DURATION_LBL' | translate}}</label>
          <label>{{newLiveTrackingMeDuration}}</label>
        </div>
        <div style="margin-top:0.5rem;">
          <span>{{'MAPS.LTR-POPUP.HOURS_LBL' | translate}}</span><br>
          <mat-slider style="width: 80%; padding:0;" min="0" max="24" step="1" thumbLabel="true"
            [(ngModel)]="newLiveTrackingMeHours" (change)="onLiveTrackingMeDurationChange()">
            <input matSliderThumb [(ngModel)]="newLiveTrackingMeHours">
          </mat-slider><br>
          <span>{{'MAPS.LTR-POPUP.MINUTES_LBL' | translate}}</span><br>
          <mat-slider style="width: 80%; padding:0;" min="0" max="59" step="1" thumbLabel="true"
            [(ngModel)]="newLiveTrackingMeMinutes" (change)="onLiveTrackingMeDurationChange()">
            <input matSliderThumb [(ngModel)]="newLiveTrackingMeMinutes">
          </mat-slider><br>
        </div>
      </ng-container>
      <ng-container *ngIf="currentPopupMode==='new'">
        <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="newLiveTrackingMePermanent">
          {{'MAPS.LTR-POPUP.PERMANENT_SLIDE_LBL' | translate}}
        </mat-slide-toggle><br>
      </ng-container>
      <hr>
      <mat-form-field>
        <input matInput style="width: 200px;" type="text" spellcheck="false"
          placeholder="{{'MAPS.LTR-POPUP.NAME_INPUT_PLH' | translate}}" [(ngModel)]="newLiveTrackingMeName"
          (change)="onLiveTrackingMePopupChanged()" />
      </mat-form-field><br>
      <ng-container>
        <mat-slide-toggle color="primary" style="margin-left:0.5rem;" [(ngModel)]="newLiveTrackingMeStoreWholeRoute"
          (change)="onLiveTrackingMePopupChanged()">{{'MAPS.LTR-POPUP.STORE-WHOLE_SLIDE_LBL' | translate}}
        </mat-slide-toggle><br>
      </ng-container>
      <ng-container>
        <hr>
        <button *ngIf="currentPopupMode === 'new'" mat-raised-button color="primary" style="float:right;"
          (click)="onLiveTrackingMePopupStartClick()">{{'MAPS.LTR-POPUP.START_BTN_T' | translate}}</button>
        <button *ngIf="currentPopupMode === 'show'" mat-raised-button color="primary"
          style="margin-left:0.5rem; float:right;" (click)="onLiveTrackingMePopupQuitClick()">
          {{'MAPS.LTR-POPUP.QUIT_BTN_T' | translate}}</button>
        <button *ngIf="currentPopupMode === 'show' && hasLiveTrackingMePopupChanged" mat-raised-button color="primary"
          style="float:right;" (click)="onLiveTrackingMePopupUpdateClick()">{{'MAPS.LTR-POPUP.UPDATE_BTN_T' |
          translate}}</button>
      </ng-container>
    </div>

    <!-- hover-route-popup  -->
    <div *ngIf="isHoverRoutePopupVisible" #hoverroutepopupcontainer hidden id="popup" class="ol-popup2"
      style="cursor:auto;">
      <!-- <div #content id="popup-content" style="margin-top:0.0rem;"></div> -->
      <div style="display:flex; flex-direction: row;">
        <div style="flex-grow:1; text-align:center; margin-right:0.4rem;">
          <span *ngIf="doneHoverRouteDistance<1000.0" style="margin:auto;"><b>{{doneHoverRouteDistance | number:
              '1.0-0'}}</b>
            m</span>
          <span *ngIf="doneHoverRouteDistance>=1000.0" style="margin:auto;"><b>{{doneHoverRouteDistance*0.001 |
              number:
              '1.0-1'}}</b> km</span>
          <br>
          <span *ngIf="userLanguage==='de'">vom Start</span>
          <span *ngIf="userLanguage==='en'">from start</span>
        </div>
        <div style="border-left:solid 1px">
          <span></span>
        </div>
        <div style="flex-grow:1; text-align:center; margin-left:0.4rem;">
          <span *ngIf="remainingHoverRouteDistance<1000.0"><b>{{remainingHoverRouteDistance |
              number: '1.0-0'}}</b> m</span>
          <span *ngIf="remainingHoverRouteDistance>=1000.0"><b>{{remainingHoverRouteDistance*0.001 |
              number: '1.0-1'}}</b> km</span>
          <br>
          <span *ngIf="userLanguage==='de'">zum Ziel</span>
          <span *ngIf="userLanguage==='en'">to finish</span>
        </div>
      </div>
    </div>

    <!-- pois-filter-popup  -->
    <div *ngIf="isPoisFilterPopupVisible" #container id="popup" class="ol-popup3"
      style="cursor:auto; min-width: 200px;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div id="popup-content" style="margin-top:0.0rem;">
        <span>{{'MAPS.POIS-POPUP.TITLE-TEXT' | translate}}</span>
      </div>
      <hr>
      <div *ngFor="let category of poisLayerConfig">
        <div style="background-color: blanchedalmond;">
          <span *ngIf="category.open" (click)="category.open=false">
            <img width="25px" src="./assets/icons/menu-up.svg"></span>
          <span *ngIf="!category.open" (click)="category.open=true">
            <img width="25px" src="./assets/icons/menu-down.svg"></span>
          <span *ngIf="userLanguage==='de'" (click)="category.open=!category.open">{{category.nameDe}}:</span>
          <span *ngIf="userLanguage!=='de'" (click)="category.open=!category.open">{{category.nameEn}}:</span>
        </div>
        <ng-container *ngIf="category.open">
          <div *ngFor="let layer of category.layers" style="cursor:pointer">
            <ng-container>
              <input type="checkbox" [(ngModel)]="layer.visible">
              <img *ngIf="layer.icon" style="padding-left:0.5rem; padding-right:0.5rem;" [src]="layer.icon"
                (click)="layer.visible = !layer.visible">
              <span *ngIf="!layer.icon" style="margin-left:1.0rem; margin-right:1.0rem;"></span>
              <span *ngIf="userLanguage==='de'" (click)="layer.visible = !layer.visible">{{layer.nameDe}}</span>
              <span *ngIf="userLanguage!=='de'" (click)="layer.visible = !layer.visible">{{layer.nameEn}}</span>
              <br>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <hr>
      <button mat-raised-button color="primary" (click)="searchPois()">
        {{'MAPS.POIS-POPUP.SHOW-FEATURE_BUTT-TITLE' | translate}}</button>
    </div>

    <!-- elevation-popup -->
    <div *ngIf="isElevationPopupVisible" #container id="popup" class="ol-popup3" style="cursor:auto; min-width: 200px;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.0rem;"></div>
      <div>
        <span>{{currentElevation}} m</span>
      </div>
      <div style="float:right">
        <button mat-raised-button color="accent" (click)="isElevationPopupVisible=false">Close</button>
      </div>
    </div>


    <!-- error-message-popup -->
    <div *ngIf="isErrorMessagePopupVisible" #container id="popup" class="ol-popup3"
      style="cursor:auto; min-width: 200px;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.0rem;"></div>
      <div style="float:right">
        <button mat-raised-button color="accent" (click)="isErrorMessagePopupVisible=false">Close</button>
      </div>
    </div>

    <!-- login-needed-popup -->
    <div *ngIf="isNeedToLoginPopupVisible" #container id="popup" class="ol-popup3"
      style="cursor:auto; min-width: 200px;">
      <a id="popup-closer" class="ol-popup-closer" (click)="closeAllPopups()"></a>
      <div #content id="popup-content" style="margin-top:0.0rem;"></div>
      <div>
        <div *ngIf="isOnline" class="topbar-user-container" style="display:flex; flex-direction:row;">
          <div style="flex-grow: 0; flex-shrink: 1; flex-basis: content;">
            <span style="padding:1,0rem; font-size:xx-large; margin-right:2.0rem;">Login</span>
          </div>
          <div style="flex-grow: 0; flex-shrink: 1; flex-basis: content;">
            <rockit-social-login [isUserLoggedIn]="this.loggedInUser" [showUserName]="false"></rockit-social-login>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>