import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-maps-infos-component",
  templateUrl: "./maps-infos.component.html",
  styleUrls: ["./maps-infos.component.scss"]
})
export class MapsInfosComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() languageCode: string;

  @Output() close: EventEmitter<void> = new EventEmitter();

  // private paramSubscription: any;
  // private paramSubscriptionRouteParams: any;
  public selectedTabIndex: number;

  public calledFromRouter: boolean;
  public activateFaq: string;

  @ViewChild("infoDlg") infoDlgElement: ElementRef;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private titleService: Title,
  ) { }

  public async ngOnInit() {
    // console.log("maps-infos:ngOnInit-route", this.route);
    if (!this.languageCode) {
      this.calledFromRouter = true;
      this.languageCode = this.translate.getBrowserLang();
      if (this.languageCode !== "de" && this.languageCode !== "en") {
        const languages = navigator.languages;
        // console.log("Maps:ngOnInit-languages", languages);
        if (languages) {
          let found = false;
          for (const language of languages) {
            const lang = language.substring(0, 2).toLowerCase();
            // console.log("Maps:ngOnInit-lang", lang);
            if (lang == "de" || lang == "en") {
              this.languageCode = lang;
              found = true;
              break;
            }
            if (!found) { this.languageCode = "en"; }
          }
        }
      }
    }
    // console.log("maps-infos:ngOnInit-languageCode", this.languageCode);

    // url-parameter
    this.selectedTabIndex = 0;
    const snapshotUrl = this.route.snapshot.url;
    if (snapshotUrl.length >= 1) {
      const segment0 = snapshotUrl[0];
      // console.log("maps-infos:ngOnInit-segment", segment0);
      if (segment0.path === "infos") { this.selectedTabIndex = 0; }
      if (segment0.path === "about") { this.selectedTabIndex = 0; }
      if (segment0.path === "faq") { this.selectedTabIndex = 1; }
      if (segment0.path === "privacy-policy") { this.selectedTabIndex = 2; }
      if (segment0.path === "terms-of-use") { this.selectedTabIndex = 3; }
      if (segment0.path === "feedback") { this.selectedTabIndex = 4; }
      if (segment0.path === "contact") { this.selectedTabIndex = 5; }
      if (segment0.path === "apps") { this.selectedTabIndex = 6; }
    }
    if (snapshotUrl.length >= 2) {
      const segment1 = snapshotUrl[1];
      if (segment1.path === "de") { this.languageCode = "de"; }
      if (segment1.path === "en") { this.languageCode = "en"; }
    }
    if (snapshotUrl.length >= 3) {
      const segment2 = snapshotUrl[2];
      if (segment2.path === "faq-loc") {
        this.activateFaq = segment2.path;
      }
    }
    // set language for i18n
    // this.languageCode = "en";  // for tests
    document.documentElement.lang = this.languageCode;
    this.translate.use(this.languageCode);
  }
  public ngAfterViewInit() {
    setTimeout(() => { this.scrollToDlg(); });
    // title
    // let title = "Information about route planner & outdoor app";
    // if (this.languageCode === "de") { title = "Informationen über Routenplaner & Outdoor-App"; }
    // title += " | Trip4You-Maps";
    // this.titleService.setTitle(title);
    // meta viewport
    let metaDesc = document.querySelector("meta[name='viewport']");
    metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    // let description = "Information about Trip4You-Maps - Overview, FAQ, Privacy Policy, Terms of Use, Contact, Other Apps";
    // if (this.languageCode === "de") {
    //   description = "Informationen über Trip4You-Maps - Überblick, FAQ, Datenschutz, Nutzungsbedingungen, Kontakt, Weitere Apps";
    // }
    // metaDesc = document.querySelector("meta[name='description']");
    // metaDesc.setAttribute("content", description);
    // set title & description for active tab
    this.setPageTitle(this.selectedTabIndex)
    this.setPageDescription(this.selectedTabIndex);

  }
  public ngOnDestroy(): void {
  }

  public async onRouteParamsChanged(paramMap: ParamMap) {
    console.log("maps-infos:onRouteParamsChanged-paramMap", paramMap);
  }

  public async onRouteQueryParamsChanged(paramMap: ParamMap) {
    console.log("maps-infos:onRouteQueryParamsChanged-paramMap", paramMap);
  }

  private scrollToDlg() {
    this.infoDlgElement.nativeElement.scrollIntoView({ align: top, behavior: "smooth" });
  }

  public onTabChange(event: MatTabChangeEvent) {
    console.log("maps-infos:onTabChange-event", event);
    this.setPageTitle(event.index)
    this.setPageDescription(event.index);
  }

  public onCloseClick() {
    if (this.calledFromRouter) {
      window.location.href = "/";
    }
    if (!this.calledFromRouter) {
      this.close.emit();
    }
  }

  private setPageTitle(tabIndex: number) {
    // let title = "Information about route planner & outdoor app";
    // if (this.languageCode === "de") { title = "Informationen über Routenplaner & Outdoor-App"; }
    // title += " | Trip4You-Maps";
    let title: string;
    // about
    if (tabIndex === 0) {
      title = "About route planner & outdoor app";
      if (this.languageCode === "de") { title = "Über die Routenplaner & Outdoor-App"; }
    }
    // faq
    if (tabIndex === 1) {
      title = "Frequently asked questions";
      if (this.languageCode === "de") { title = "Häufig gestellte Fragen"; }
    }
    // feedback
    if (tabIndex === 4) {
      title = "Feedback";
    }
    // contact
    if (tabIndex === 5) {
      title = "Contact/Impressum";
      if (this.languageCode === "de") { title = "Kontakt/Impressum"; }
    }
    // other apps
    if (tabIndex === 6) {
      title = "More apps of Trip4You";
      if (this.languageCode === "de") { title = "Weitere Apps von Trip4You"; }
    }

    if (title) {
      title += " | Trip4You-Maps";
    } else {
      title = "Trip4You-Maps"
    }
    this.titleService.setTitle(title);
  }

  private setPageDescription(tabIndex: number) {

    let description: string;
    // about
    if (tabIndex === 0) {
      description = "Basic information about the functionality of Trip4You-Maps - route planning, navigation, search, location sharing & live tracking";
      if (this.languageCode === "de") {
        description = "Grundlegegende Informationen über die Funktionalität von Trip4You-Maps - Routenplanung, Navigation, Suche, Standort teilen & Live-Tracking";
      }
    }
    // faq
    if (tabIndex === 1) {
      description = "Frequently asked questions about Trip4You-Maps - usage, data protection, installation tips, sharing location, embedding in your own websites";
      if (this.languageCode === "de") {
        description = "Häufig gestellte Fragen über Trip4You-Maps - Nutzung, Datenschutz, Tipps zur Installation, Standort freigeben, Einbinden in eigene Webseiten";
      }
    }
    // feedback
    if (tabIndex === 4) {
      description = "Give us feedback about bugs and problems or send a question";
      if (this.languageCode === "de") {
        description = "Melde uns Fehler und Probleme oder sende eine Anfrage";
      }
    }
    // contact
    if (tabIndex === 5) {
      description = "Contact information for Trip4You-Maps";
      if (this.languageCode === "de") {
        description = "Kontakt-Information von Trip4You-Maps";
      }
    }
    // other apps
    if (tabIndex === 6) {
      description = "Presentation of other apps of Trip4You";
      if (this.languageCode === "de") {
        description = "Vorstellung weiterer Apps von Trip4You";
      }
    }
    const metaDesc = document.querySelector("meta[name='description']");
    metaDesc.setAttribute("content", description);
  }
}

