import { MapPlaceL } from "src/app/models/mapplace";
import { PointStyle } from "src/app/models/point-style";
import { SavedPlaceCategory } from "src/app/models/saved-item-categories";
import { MapPlaceCategory } from "src/dto.generated/api";


export function createDefaultSavedPlaceCategoryRemember(userLanguage: string) {
    const newCat = {} as SavedPlaceCategory;
    newCat.name = "Places to remember";
    if (userLanguage === "de") { newCat.name = "Gemerkte Orte"; }
    newCat.type = "$remember";
    newCat.visible = true;
    newCat.fixed = true;
    newCat.open = true;
    newCat.pointStyle = {} as PointStyle;
    newCat.pointStyle.strokeColor = "#ff0000";
    newCat.placeItems = new Array<MapPlaceL>();
    return newCat;
}

export function createNewPlaceCategory(categoryName: string) {
    const newCategory = {} as SavedPlaceCategory;
    newCategory.name = categoryName;
    newCategory.type = "$user";
    newCategory.placeItems = new Array<MapPlaceL>();
    newCategory.pointStyle = {} as PointStyle;
    newCategory.pointStyle.strokeColor = "#808080";
    return newCategory;
}

export function convertLPlaceCatToSPlaceCat(localCat: SavedPlaceCategory) {
    // console.log("Maps:convertLPlaceCatToSPlaceCat-localCat", localCat);
    const sCat = {} as MapPlaceCategory;
    sCat.id = localCat.categoryId;
    sCat.type = localCat.type;
    sCat.name = localCat.name;
    sCat.description = localCat.description;
    sCat.mapPlaceFormatVersion = 1;
    sCat.mapPlaceFormat = JSON.stringify(localCat.pointStyle);
    sCat.order = localCat.order;
    sCat.visible = localCat.visible;
    return sCat;
}


export function convertSPlaceCatToLPlaceCat(serverCat: MapPlaceCategory) {
    // console.log("Maps:convertSPlaceCatToLPlaceCat-serverCat", serverCat);
    const lCat = {} as SavedPlaceCategory;
    lCat.categoryId = serverCat.id;
    lCat.type = serverCat.type;
    lCat.name = serverCat.name;
    if (!lCat.name) { lCat.name = lCat.type; }
    lCat.description = serverCat.description;
    lCat.placeItems = new Array<MapPlaceL>();
    if (serverCat.mapPlaceFormatVersion === 1) {
        lCat.pointStyle = JSON.parse(serverCat.mapPlaceFormat);
    }
    lCat.visible = false;
    lCat.open = false;
    if (lCat.type === "$remember") {
        lCat.open = true;
        lCat.fixed = true;
        lCat.pointStyle = {} as PointStyle;
        lCat.pointStyle.strokeColor = "#ff0000";
    }
    if (!lCat.pointStyle) {
        lCat.pointStyle = {} as PointStyle;
        lCat.pointStyle.strokeColor = "gray";
    }
    lCat.order = serverCat.order;
    lCat.visible = serverCat.visible;
    return lCat;
}
