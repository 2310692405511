<h1 mat-dialog-title>{{title}}</h1>
<hr>

<div *ngIf="data.errorMsg">
  <span>Error-message: </span><span style="color:red">{{data.errorMsg}}</span>
</div>

<div mat-dialog-content>
  <ng-container *ngIf="data.language != 'de'">
    <p>For route planning, navigation and tracking, location must be enabled.</p>
    <p>Location must be activated in the device settings and additionally in the browser.</p>
    <div *ngIf="!showMore" style="cursor:pointer; margin-top:2.0rem; text-align:right;" (click)="showMoreClick()">
      Find more information in FAQs ...
    </div>
  </ng-container>

  <ng-container *ngIf="data.language == 'de'">
    <p>Für Routenplannung, Navigation und Tracking muss die Standortbestimmung eingeschaltet werden.</p>
    <p>Die Standortbestimmung muss sowohl in den Einstellungen des Gerätes, als auch im
      verwendeten Browser zugelassen sein.</p>
    <div *ngIf="!showMore" style="cursor:pointer; margin-top:2.0rem; text-align:right;" (click)="showMoreClick()">
      Finde mehr Information in den FAQs ...
    </div>
  </ng-container>

  <div style="display: flex; flex-direction: column; text-align: center; margin: auto;">
  </div>
  <hr>
</div>

<div mat-dialog-actions align="end">
  <div>
    <button mat-button [mat-dialog-close]="'close'">{{textClose}}</button>
  </div>
</div>