import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-popup-general-component",
  templateUrl: "./popup-general.component.html",
  styleUrls: ["./popup-general.component.scss"]
})
export class GeneralPopupComponent implements OnInit {

  public textOk: string;
  public textCancel: string;
  public textYes: string;
  public textNo: string;
  public textDelete: string;
  public textAdd: string;
  public textReplace: string;

  constructor(
    public dialogRef: MatDialogRef<GeneralPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      language: string,
      dlgTitle: string,
      dlgText: string,
      dlgText2: string,
      buttonYes: boolean,
      buttonNo: boolean,
      buttonOk: boolean,
      buttonCancel: boolean,
      buttonDel: boolean,
      colorButtonDelete: string,
      buttonAdd: boolean;
      buttonReplace: boolean;
      defaultButton: string;
    },
  ) {
  }

  public async ngOnInit() {
    console.log("PopupSimple:ngOnInit");
    if (!this.data.colorButtonDelete) { this.data.colorButtonDelete = "red"; }

    // button-text
    const lang = this.data.language;
    // english
    this.textOk = "Ok";
    this.textCancel = "Cancel";
    this.textYes = "Yes";
    this.textNo = "No";
    this.textDelete = "Delete";
    this.textAdd = "Add";
    this.textReplace = "Replace";
    // german
    if (lang === "de") {
      this.textCancel = "Abbrechen";
      this.textYes = "Ja";
      this.textNo = "Nein";
      this.textDelete = "Löschen";
      this.textAdd = "Hinzufügen";
      this.textReplace = "Ersetzen";
    }
  }

}
