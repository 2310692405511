import { Style, Icon, Circle, Stroke, Fill } from "ol/style";


import { OsmLayerConfig, OsmLayerConfigCategory } from "src/app/models/pois-layers-config";

// get icon srource
export function getPoiIconSource(cat: string) {
    // cat1 - food & drink amenities
    let src;
    if (cat === "resto") { src = "./assets/icons-test/Restaurant-14.svg"; }
    if (cat === "food_court") { src = "./assets/icons-test/Restaurant-14.svg"; }
    if (cat === "fast_food") { src = "./assets/icons-test/Fast-food-16.svg"; }
    if (cat === "bar") { src = "./assets/icons-test/Bar-16.svg"; }
    if (cat === "cafe") { src = "./assets/icons-test/Cafe-16.svg"; }
    if (cat === "supermarket") { src = "./assets/icons-test/Supermarket-14.svg"; }
    if (cat === "marketplace") { src = "./assets/icons-test/Marketplace-14.svg"; }

    if (cat === "hotel") { src = "./assets/icons-test/Hotel-16.svg"; }
    if (cat === "guest_house") { src = "./assets/icons-test/Guest-house-14.svg"; }
    if (cat === "hostel") { src = "./assets/icons-test/Hostel-16.svg"; }
    if (cat === "motel") { src = "./assets/icons-test/Motel-16.svg"; }
    if (cat === "camp_site") { src = "./assets/icons-test/Camping-16.svg"; }
    if (cat === "caravan_site") { src = "./assets/icons-test/Caravan-16.svg"; }

    if (cat === "atm") { src = "./assets/icons-test/Atm-14.svg"; }
    if (cat === "bank") { src = "./assets/icons-test/Bank-16.svg"; }
    if (cat === "currencyexchange") { src = "./assets/icons-test/Bureau-de-change-14.svg"; }
    if (cat === "drinking_water") { src = "./assets/icons-test/Drinking-water-16.svg"; }
    if (cat === "water_point") { src = "./assets/icons-test/Drinking-water-16.svg"; }
    if (cat === "toilets") { src = "./assets/icons-test/Toilets-16.svg"; }
    if (cat === "public_bath") { src = "./assets/icons-test/Public-bath-14.svg"; }
    if (cat === "viewpoint") { src = "./assets/icons-test/Viewpoint-16.svg"; }
    if (cat === "attraction") { src = "./assets/icons-test/X_Camera-outline.svg"; }
    if (cat === "bus_station") { src = "./assets/icons-test/Bus-station-14.svg"; }
    if (cat === "fuel") { src = "./assets/icons-test/Fuel-16.svg"; }

    if (cat === "bicycle_parking") { src = "./assets/icons-test/Parking-bicycle-16.svg"; }
    if (cat === "bicycle_repair_station") { src = "./assets/icons-test/Bicycle-repair-station-14.svg"; }
    if (cat === "charging_station") { src = "./assets/icons-test/Charging-station-16.svg"; }
    if (cat === "bicycle_shop") { src = "./assets/icons-test/Bicycle-16.svg"; }
    if (cat === "bicycle_rental") { src = "./assets/icons-test/Bicycle-rental-16.svg"; }

    if (cat === "alpine_hut") { src = "./assets/icons-test/Alpine-hut-14.svg"; }
    if (cat === "wilderness_hut") { src = "./assets/icons-test/Wilderness-hut-14.svg"; }
    if (cat === "emergency_point") { src = "./assets/icons-test/Emergency-point-16.svg"; }

    return src;
}
// create pois-layer-config
export function createPoisLayerConfigBicycle(uiType: string) {
    const plConfig = new Array<OsmLayerConfigCategory>();
    // cat1 - food & drink amenities
    const catIdResto = "resto";
    const iconResto = getPoiIconSource(catIdResto);
    const catIdFoodcourt = "food_court";
    const iconFoodcourt = getPoiIconSource(catIdFoodcourt);
    const catIdFastfood = "fast_food";
    const iconFastfood = getPoiIconSource(catIdFastfood);
    const catIdBar = "bar";
    const iconBar = getPoiIconSource(catIdBar);
    const catIdCafe = "cafe";
    const iconCafe = getPoiIconSource(catIdCafe);
    const catIdSupermarket = "supermarket";
    const iconSupermarket = getPoiIconSource(catIdSupermarket);
    const catIdMarketplace = "marketplace";
    const iconMarketplace = getPoiIconSource(catIdMarketplace);
    // cat2 - accomodation
    const catIdHotel = "hotel";
    const iconHotel = getPoiIconSource(catIdHotel);
    const catIdGuesthouse = "guest_house";
    const iconGuesthouse = getPoiIconSource(catIdGuesthouse);
    const catIdHostel = "hostel";
    const iconHostel = getPoiIconSource(catIdHostel);
    const catIdMotel = "motel";
    const iconMotel = getPoiIconSource(catIdMotel);
    const catIdCampsite = "camp_site";
    const iconCampsite = getPoiIconSource(catIdCampsite);
    const catIdCaravansite = "caravan_site";
    const iconCaravansite = getPoiIconSource(catIdCaravansite);
    // cat3 - general amenities
    const catIdATM = "atm";
    const iconATM = getPoiIconSource(catIdATM);
    const catIdBank = "bank";
    const iconBank = getPoiIconSource(catIdBank);
    const catIdCurrencyexchange = "currencyexchange";
    const iconCurrencyexchange = getPoiIconSource(catIdCurrencyexchange);
    const catIdDrinkingwater = "drinking_water";
    const iconDrinkingwater = getPoiIconSource(catIdDrinkingwater);
    const catIdWaterpoint = "water_point";
    const iconWaterpoint = getPoiIconSource(catIdWaterpoint);
    const catIdToilets = "toilets";
    const iconToilets = getPoiIconSource(catIdToilets);
    const catIdPublicbath = "public_bath";
    const iconPublicbath = getPoiIconSource(catIdPublicbath);
    const catIdViewpoint = "viewpoint";
    const iconViewpoint = getPoiIconSource(catIdViewpoint);
    const catIdAttraction = "attraction";
    const iconAttraction = getPoiIconSource(catIdAttraction);
    const catIdBusstation = "bus_station";
    const iconBusstation = getPoiIconSource(catIdBusstation);
    const catIdFuel = "fuel";
    const iconFuel = getPoiIconSource(catIdFuel);
    // cat4 - bicycle amenities
    const catIdBicycleparking = "bicycle_parking";
    const iconParkingBicycle = getPoiIconSource(catIdBicycleparking);
    const catIdBicyclerepairstation = "bicycle_repair_station";
    const iconBicycleRepairStation = getPoiIconSource(catIdBicyclerepairstation);
    const catIdChargingstation = "charging_station";
    const iconChargingStation = getPoiIconSource(catIdChargingstation);
    const catIdBicycleshop = "bicycle_shop";
    const iconBicycleshop = getPoiIconSource(catIdBicycleshop);
    const catIdBicyclerental = "bicycle_rental";
    const iconBicycleRental = getPoiIconSource(catIdBicyclerental);

    // cat5 - hiking amenities
    const catIdAlpinehut = "alpine_hut";
    const iconAlpinehut = getPoiIconSource(catIdAlpinehut);
    const catIdWildernesshut = "wilderness_hut";
    const iconWildernesshut = getPoiIconSource(catIdWildernesshut);
    const catIdEmergencypoint = "emergency_point";
    const iconEmergencypoint = getPoiIconSource(catIdEmergencypoint);

    const colorOrange = "#F58008";
    const colorBrown = "#734a08";
    const colorBlue = "#1E90FF";
    const colorMagenta = "#ac39ac";

    // cat1 - food + drink
    let width = 1;
    if (uiType === "S") { width = 1; }
    const radius = 10;

    const cat1 = {
        id: "1",
        nameEn: "Food & drink",
        nameDe: "Essen & drinken",
        open: true,
        layers: [
            {
                id: catIdResto,
                nameEn: "Restaurant",
                nameDe: "Restaurant",
                types: "node;way",
                tags: "[amenity=restaurant]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconResto,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconResto,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorOrange,
                                width,
                            }),
                            fill: new Fill({
                                color: "#F5800820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdFoodcourt,
                nameEn: "Food court",
                nameDe: "Gastrobereich",
                types: "node;way",
                tags: "[amenity=food_court]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconFoodcourt,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconFoodcourt,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorOrange,
                                width,
                            }),
                            fill: new Fill({
                                color: "#F5800820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdFastfood,
                nameEn: "Fast food",
                nameDe: "Fast food",
                types: "node;way",
                tags: "[amenity=fast_food]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconFastfood,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconFastfood,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorOrange,
                                width,
                            }),
                            fill: new Fill({
                                color: "#F5800820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdBar,
                nameEn: "Bar",
                nameDe: "Bar",
                types: "node;way",
                tags: "[amenity=bar]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconBar,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconBar,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorOrange,
                                width,
                            }),
                            fill: new Fill({
                                color: "#F5800820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdCafe,
                nameEn: "Cafe",
                nameDe: "Cafe",
                types: "node;way",
                tags: "[amenity=cafe]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconCafe,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconCafe,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorOrange,
                                width,
                            }),
                            fill: new Fill({
                                color: "#F5800820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdSupermarket,
                nameEn: "Supermarket",
                nameDe: "Supermarkt",
                types: "node;way",
                tags: "[shop=supermarket]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconSupermarket,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconSupermarket,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorMagenta,
                                width,
                            }),
                            fill: new Fill({
                                color: "#ac39ac20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdMarketplace,
                nameEn: "Marketplace",
                nameDe: "Marktplatz",
                types: "node;way",
                tags: "[amenity=marketplace]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconMarketplace,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconMarketplace,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorMagenta,
                                width,
                            }),
                            fill: new Fill({
                                color: "#ac39ac20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
        ]
    } as OsmLayerConfigCategory;
    plConfig.push(cat1);

    // cat2 - accomodation
    const cat2 = {
        id: "2",
        nameEn: "Accomodation",
        nameDe: "Übernachten",
        open: false,
        layers: [
            {
                id: catIdHotel,
                nameEn: "Hotel",
                nameDe: "Hotel",
                types: "node;way",
                tags: "[tourism=hotel]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconHotel,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconHotel,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#1E90FF20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdGuesthouse,
                nameEn: "Guest house",
                nameDe: "Gästehaus",
                types: "node;way",
                tags: "[tourism=guest_house]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconGuesthouse,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconGuesthouse,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#1E90FF20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdHostel,
                nameEn: "Hostel",
                nameDe: "Herberge",
                types: "node;way",
                tags: "[tourism=hostel]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconHostel,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconHostel,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#1E90FF20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdMotel,
                nameEn: "Motel",
                nameDe: "Motel",
                types: "node;way",
                tags: "[tourism=motel]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconMotel,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconMotel,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#1E90FF20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdCampsite,
                nameEn: "Camp site",
                nameDe: "Campingplatz",
                types: "node;way",
                tags: "[tourism=camp_site]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconCampsite,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconCampsite,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdCaravansite,
                nameEn: "Caravan site",
                nameDe: "Stellplatz",
                types: "node;way",
                tags: "[tourism=caravan_site]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconCaravansite,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconCaravansite,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
        ]
    } as OsmLayerConfigCategory;
    plConfig.push(cat2);

    // cat3 - general amenities
    const cat3 = {
        id: "3",
        nameEn: "General amenities",
        nameDe: "Allgemein",
        open: false,
        layers: [
            {
                id: catIdATM,
                nameEn: "ATM",
                nameDe: "Bankomat",
                types: "node;way",
                tags: "[amenity=atm]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconATM,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconATM,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdBank,
                nameEn: "bank",
                nameDe: "Bank",
                types: "node;way",
                tags: "[amenity=bank]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconBank,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconBank,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdCurrencyexchange,
                nameEn: "Currency exchange",
                nameDe: "Geldwechsel",
                types: "node;way",
                tags: "[amenity=bureau_de_change]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconCurrencyexchange,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconCurrencyexchange,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdDrinkingwater,
                nameEn: "Drinking water",
                nameDe: "Trinkwasser",
                types: "node;way",
                tags: "[amenity=drinking_water]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconDrinkingwater,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconDrinkingwater,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdWaterpoint,
                nameEn: "Water point",
                nameDe: "Wasserstelle",
                types: "node;way",
                tags: "[amenity=water_point]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconWaterpoint,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconWaterpoint,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdToilets,
                nameEn: "Toilets",
                nameDe: "Toiletten",
                types: "node;way",
                tags: "[amenity=toilets]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconToilets,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconToilets,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdPublicbath,
                nameEn: "Public bath",
                nameDe: "Öffentliches Bad",
                types: "node;way",
                tags: "[amenity=public_bath]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconPublicbath,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconPublicbath,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdViewpoint,
                nameEn: "Viewpoint",
                nameDe: "Aussichtpunkt",
                types: "node;way",
                tags: "[tourism=viewpoint]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconViewpoint,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconViewpoint,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdAttraction,
                nameEn: "Attraction",
                nameDe: "Attraktion",
                types: "node;way",
                tags: "[tourism=attraction]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconAttraction,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconAttraction,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: "#ff0000",
                                width,
                            }),
                            fill: new Fill({
                                color: "#ff000020",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdBusstation,
                nameEn: "Bus station",
                nameDe: "Busstation",
                types: "node;way",
                tags: "[amenity=bus_station]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconBusstation,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconBusstation,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#1E90FF20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdFuel,
                nameEn: "Fuel",
                nameDe: "Tankstelle",
                types: "node;way",
                tags: "[amenity=fuel]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconFuel,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconFuel,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#1E90FF20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
        ]
    } as OsmLayerConfigCategory;
    plConfig.push(cat3);

    // cat3 - bicycle amenities
    const cat4 = {
        id: "4",
        nameEn: "Bicyle amenities",
        nameDe: "Für Radfahrer",
        layers: [
            {
                id: catIdBicycleparking,
                nameEn: "Bicycle parking",
                nameDe: "Radparkplätze",
                types: "node;way",
                tags: "[amenity=bicycle_parking]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconParkingBicycle,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconParkingBicycle,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#1E90FF20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdBicyclerepairstation,
                nameEn: "Bicycle repair station",
                nameDe: "Radwerkstätten",
                types: "node;way",
                tags: "[amenity=bicycle_repair_station]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconBicycleRepairStation,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconBicycleRepairStation,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdChargingstation,
                nameEn: "Charging station",
                nameDe: "Ladestation",
                types: "node;way",
                tags: "[amenity=charging_station]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconChargingStation,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconChargingStation,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                                width,
                            }),
                            fill: new Fill({
                                color: "#0092da20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdBicycleshop,
                nameEn: "Bicycle shop",
                nameDe: "Radgeschäft",
                types: "node;way",
                tags: "[shop=bicycle]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconBicycleshop,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconBicycleshop,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorMagenta,
                                width,
                            }),
                            // fill: new Fill({
                            //     color: "#ac39ac20",
                            // }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdBicyclerental,
                nameEn: "Bicycle rental",
                nameDe: "Fahrradverleih",
                types: "node;way",
                tags: "[shop=bicycle]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconBicycleRental,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconBicycleRental,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBlue,
                            }),
                            fill: new Fill({
                                color: "#0092da20",
                            }),
                            radius,
                        })
                    }),
                ]
            },
        ]
    } as OsmLayerConfigCategory;
    // console.log("utils-pois:createPoisLayerConfigBicycle-cat3", cat3);
    plConfig.push(cat4);

    // cat5 - hiking amenities
    const cat5 = {
        id: "5",
        nameEn: "Hiking amenities",
        nameDe: "Für Wanderer",
        layers: [
            {
                id: catIdAlpinehut,
                nameEn: "Alpine hut",
                nameDe: "Berghütte",
                types: "node;way",
                tags: "[tourism=alpine_hut]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconAlpinehut,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconAlpinehut,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdWildernesshut,
                nameEn: "Wilderness hut",
                nameDe: "Schutzhütte",
                types: "node;way",
                tags: "[tourism=wilderness_hut]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconWildernesshut,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconWildernesshut,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
            {
                id: catIdEmergencypoint,
                nameEn: "Emergency access point",
                nameDe: "Rettungspunkt",
                types: "node;way",
                tags: "[highway=emergency_access_point]",
                out: "",
                visible: false,
                symbolType: "Icon",
                icon: iconEmergencypoint,
                style: [
                    new Style({
                        image: new Icon({
                            src: iconEmergencypoint,
                            rotation: 0,
                        })
                    }),
                    new Style({
                        image: new Circle({
                            stroke: new Stroke({
                                color: colorBrown,
                                width,
                            }),
                            fill: new Fill({
                                color: "#734a0820",
                            }),
                            radius,
                        })
                    }),
                ]
            },
        ]
    } as OsmLayerConfigCategory;
    // console.log("utils-pois:createPoisLayerConfigBicycle-cat4", cat4);
    plConfig.push(cat5);


    return plConfig;
}
