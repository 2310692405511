import { Breakpoints } from "@angular/cdk/layout";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { EnumActionType, EnumAppType, UsageLog } from "src/dto.generated/api";
import { GlobalService } from "../services/global.service";
import { ApiError } from "../models/api-error";

export function getNavigatorLanguage() {
  // user-language
  const navigatorLanguage = navigator.language;
  const userLanguage = this.navigatorLanguage.substring(0, 2).toLowerCase();
  return userLanguage;
}
export function getUserLanguage() {

  // const availableLanguages = ["en", "de", "es"];
  const availableLanguages = ["en", "de"];
  const defaultLanguage = "en";

  let browserCultures: string[] = [];
  if (navigator.languages) {
    browserCultures = [...navigator.languages];
  } else {
    browserCultures = [navigator.language];
  }

  let browserLanguages = browserCultures;
  // console.log("utils:getNavigatorLanguage-browserLanguages", browserLanguages);
  browserLanguages = browserCultures.map(c1 => c1.split("-").find(c2 => true));
  const requestedLanguageCode = browserLanguages.find(bl => availableLanguages.find(al => al === bl) !== undefined);

  const languageCodeToUse = requestedLanguageCode ? requestedLanguageCode : defaultLanguage;
  return languageCodeToUse;
}

export function hasPointer() {
  if (window.matchMedia("(pointer:fine)").matches) {
    // console.log("utils:Mouse detected");
    return (true);
  } else {
    // console.log("utils:No mouse detected");
    return false;
  }

}
export function isMobileDevice() {
  let isMobile = false;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // console.log("Mobile device detected");
    isMobile = true;
  }
  return isMobile;
}

export function isTouchDevice(): boolean {
  // return ("ontouchstart" in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  return ("ontouchstart" in window) || (navigator.maxTouchPoints > 0);
}

export function calculateCompassHeading(alpha, beta, gamma) {
  const degtorad = Math.PI / 180; // Degree-to-Radian conversion
  const headingX = beta ? beta * degtorad : 0; // beta value
  const headingY = gamma ? gamma * degtorad : 0; // gamma value
  const headingZ = alpha ? alpha * degtorad : 0; // alpha value

  const cX = Math.cos(headingX);
  const cY = Math.cos(headingY);
  const cZ = Math.cos(headingZ);
  const sX = Math.sin(headingX);
  const sY = Math.sin(headingY);
  const sZ = Math.sin(headingZ);

  // Calculate Vx and Vy components
  const Vx = - cZ * sY - sZ * sX * cY;
  const Vy = - sZ * sY + cZ * sX * cY;

  // Calculate compass heading
  let compassHeading = Math.atan(Vx / Vy);

  // Convert compass heading to use whole unit circle
  if (Vy < 0) {
    compassHeading += Math.PI;
  } else if (Vx < 0) {
    compassHeading += 2 * Math.PI;
  }

  return compassHeading * (180 / Math.PI); // Compass Heading (in degrees)

}


export function calcDateDiffInDays(date1: Date, date2: Date) {
  const countDays = ((date1.valueOf() - date2.valueOf()) / 1000.0 / 86400.0);
  return countDays;
}

export function addHoursToDate(date: Date, hours: number) {

  const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + hours, date.getMinutes());
  return newDate;
}

export function convertDateForDisplay(localTime: Date) {
  // console.log("ConvertDateForDisplay-localTime", localTime);
  if (!localTime) { return undefined; }
  const utcTime = new Date(localTime.getUTCFullYear(), localTime.getUTCMonth(), localTime.getUTCDate());
  return utcTime;
}

export function convertDateToUTCDate(localTime: Date) {
  if (!localTime) { return undefined; }
  const utcTime = new Date(Date.UTC(localTime.getFullYear(), localTime.getMonth(), localTime.getDate()));
  return utcTime;
}

export function convertDateTimeToTimeStringForDisplay(date: Date) {
  // console.log("ConvertDateToTimeString", date)

  date = new Date(date);
  const hour: number = date.getUTCHours();
  const min: number = date.getUTCMinutes();
  // console.log("ConvertDateToTimeString-hour", hour);
  // console.log("ConvertDateToTimeString-min", min);
  let timeString = "";
  if (hour < 10) { timeString = "0" + hour.toString(); }
  if (hour >= 10) { timeString = hour.toString(); }
  // console.log("ConvertDateToTimeString-timeString1", timeString);
  timeString += ":";
  if (min < 10) { timeString += "0" + min.toString(); }
  if (min >= 10) { timeString += min.toString(); }
  // console.log("ConvertDateToTimeString-timeString2", timeString);
  return timeString;
}

export function setTimeOfDateByTimeString(utcDate: Date, timeString: string) {
  // console.log("addTimeOfDate", date);
  // console.log("addTimeOfDate", timeString);

  const hourString = timeString.slice(0, 2);
  const hour: number = parseInt(hourString, 10);
  const minString = timeString.slice(3, 5);
  const min: number = parseInt(minString, 10);
  utcDate = new Date(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate(), hour, min));
  // console.log("setTimeOfDateByTimeString-date", utcDate);

  return utcDate;
}

export function setShortUrlText(url: string, nchar: number) {

  if (!nchar) { nchar = 40; }
  if (url === null || url === undefined) { return null; }
  if (url.length > nchar) { return url.substring(0, nchar) + "..."; }
  return url;
}
export function setLongUrlText(url: string) {

  if (url === null || url === undefined) { return null; }
  if (url.length > 80) { return url.substring(0, 80) + "..."; }
  return url;
}

export function setShortInfoText(text: string) {

  if (text === null || text === undefined) { return null; }
  if (text.length > 80) { return text.substring(0, 80) + "..."; }
  return text;
}

export function isWhitespace(input) {

  // if (typeof input === 'undefined' || input == null) return true;

  return input.replace(/\s/g, "").length < 1;
}

export function getMonthName(month: number, languageCode: string) {

  let name = "Jan";
  if (month === 2) { name = "Feb"; }
  if (month === 3) { name = "Mar"; }
  if (month === 4) { name = "Apr"; }
  if (month === 5) { name = "May"; }
  if (month === 6) { name = "Jun"; }
  if (month === 7) { name = "Jul"; }
  if (month === 8) { name = "Aug"; }
  if (month === 9) { name = "Sep"; }
  if (month === 10) { name = "Okt"; }
  if (month === 11) { name = "Nov"; }
  if (month === 12) { name = "Dez"; }
  return name;
}

export interface IIP {
  ip: string;
}
export async function getIP(http: HttpClient) {
  let result = null;
  result = await http.get<IIP>("https://api.ipify.org/?format=json")
    .pipe(
      tap({
        error: (error) => {
          console.log("Error during getIP:", error);
        }
      })
    ).toPromise();

  // console.log("utils:getIP-result", result);
  return result;
}

export function getDeviceTypeName(query: any) {
  // Create a map to display breakpoint names for demonstration purposes.
  const displaySizeNameMap = new Map([
    [Breakpoints.HandsetPortrait, "H-P"],
    [Breakpoints.TabletPortrait, "T-P"],
    [Breakpoints.WebPortrait, "W-P"],
    [Breakpoints.HandsetLandscape, "H-L"],
    [Breakpoints.TabletLandscape, "T-L"],
    [Breakpoints.WebLandscape, "W-L"],
  ]);
  const deviceType = displaySizeNameMap.get(query);
  // console.log("utils:deviceType", deviceType);
  return deviceType;
}

export function createUid() {
  const date = new Date(Date.now());
  const uid = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate() + Math.round(Math.random() * 10000) / 10000;
  return uid;
}

export function roughSizeOfObject(value: object) {

  return sizeOf(value);
}

function sizeOf(value) {
  let bytes = 0;
  if (typeof value === "string") {
    bytes += (value as string).length * 2;
  } else if (typeof value === "number") {
    bytes += 8;
  } else if (typeof value === "boolean") {
    bytes += 4;
  } else if (typeof value === "object") {
    // console.log("utils:sizeof-object");
    const objClass = Object.prototype.toString.call(value).slice(8, -1);
    if (objClass === "Object") {
      for (const key in value) {
        if (!value.hasOwnProperty(key)) { continue; }
        bytes += sizeOf(value[key]);
      }
    } else if (objClass === "Array") {
      for (const arrayValue of value) {
        bytes += sizeOf(arrayValue);
      }
    } else if (objClass === "String") {
      bytes += (value as string).length * 2;
    } else if (objClass === "Number") {
      bytes += 8;
    } else if (objClass === "Boolean") {
      bytes += 4;
    }
  }
  return bytes;
}

export function createApiError(result: any, funcName: string) {
  const apiErr = {} as ApiError;
  apiErr.funcName = funcName;
  apiErr.status = result.status;
  apiErr.errorCode = result.errorCode;
  apiErr.errorMessage = result.errorMessage;
  return apiErr;
}


