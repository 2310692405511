import { Injectable, Inject } from "@angular/core";
import { ReplaySubject, Observable } from "rxjs";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class LazyLoadStylesheetService {
    loadedLibraries: { [url: string]: ReplaySubject<void> } = {};

    constructor(@Inject(DOCUMENT) private readonly document: Document) { }

    loadStylesheet(url: string): Observable<void> {
        // console.log("loadStyleSheet:url", url);

        if (this.loadedLibraries[url]) {
            return this.loadedLibraries[url].asObservable();
        }

        // console.log("loadStyleSheet:x1");
        this.loadedLibraries[url] = new ReplaySubject();

        const link = this.document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = url;
        link.onload = () => {
            this.loadedLibraries[url].next();
            this.loadedLibraries[url].complete();
        };
        // console.log("loadStyleSheet:x2-loadedLibraries", this.loadedLibraries);

        this.document.body.appendChild(link);

        return this.loadedLibraries[url].asObservable();
    }

    loadOlCSS(): Observable<void> {
        return (this.loadStylesheet("https://openlayers.org/en/v6.5.0/css/ol.css"));
        // return (this.loadStylesheet("/node_modules/ol/ol.css")); // testen???
        // return (this.loadStylesheet("https://openlayers.org/en/v7.3.0/css/ol.css")); // not available????
    }


}
