import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MapPlaceL } from "src/app/models/mapplace";
import { PRoute } from "src/app/models/p-route";

@Component({
  selector: "app-popup-route-name-component",
  templateUrl: "./popup-route-name.component.html",
  styleUrls: ["./popup-route-name.component.scss"]
})
export class RouteNamePopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<RouteNamePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dlgTitle: string,
      inputPlaceholder: string,
      // place: MapPlace,
      route: PRoute,
      buttonOk: boolean,
      buttonCancel: boolean,
    },
  ) {
    dialogRef.afterClosed().subscribe(popupResult => {
      // console.log("InputGeneralPopup:afterClosed-data", data);
      console.log(`Dialog result: ${popupResult}`);
    });
  }

  public async ngOnInit() {
    // console.log("InputGeneralPopup:ngOnInit");
  }

}
