import { EnumDifficultyLevel, EnumRouteType, MapPublicRoute, MapRoute } from "src/dto.generated/api";

import { createUid } from "src/app/utils/utils";
import { LngLat } from "src/app/models/lnglat";

import { MapPlaceL } from "src/app/models/mapplace";
import { PRoute } from "src/app/models/p-route";
import { RoutePlannerOptions } from "src/app/models/map-settings";
import { cloneMapPlaceL } from "./utils-place";
import { cloneMapPlaces } from "./utils-mapplace";


const wayPointType = "waypoint";

export function createPRoute(name?: string) {
    const route = {} as PRoute;
    // id
    route.routeId = createUid().toString();
    // name
    if (name) { this.name = name; }
    // way-points
    route.wayPoints = new Array<MapPlaceL>();
    // options
    route.plannerOptions = {} as RoutePlannerOptions;
    // viewPoints
    route.pois = new Array<MapPlaceL>();
    return route;
}

export function createDefaultWayPoints() {
    // console.log("utils-route:createDefaultWayPoints-currentLocation", currentLocation);
    const wayPoints = new Array<MapPlaceL>();
    if (wayPoints.length < 1) {
        const wp = {} as MapPlaceL;
        wayPoints.push(wp);
    }
    if (wayPoints.length < 2) {
        const wp = {} as MapPlaceL;
        wayPoints.push(wp);
    }
    // console.log("utils-route:createDefaultWayPoints-wayPoints", wayPoints);
    return wayPoints;
}
export function createWayPointAtCurrentLocation(currentLocation: LngLat, userLanguage: string) {
    const wpX = {} as MapPlaceL;
    if (wpX && !wpX.coordLon) {
        wpX.coordLon = currentLocation.lng;
        wpX.coordLat = currentLocation.lat;
        let name = "Current location";
        if (userLanguage === "de") { name = "Aktueller Standort"; }
        wpX.name = name;
        wpX.type = "$current";
    }
    return wpX
}

export function addNewWayPoint(wayPoints: MapPlaceL[]) {
    const newWp = {} as MapPlaceL;
    newWp.type = wayPointType;
    wayPoints.push(newWp);
}

export function insertNewWayPoint(wayPoints: MapPlaceL[], index: number) {
    const newWp = {} as MapPlaceL;
    wayPoints.splice(index + 1, 0, newWp);
}

export function getMovetypeIconSource(moveType: string) {
    let src = "";
    if (moveType === "bike") { src = "./assets/icons/bike.svg"; }
    if (moveType === "ebike") { src = "./assets/icons/bicycle-electric.svg"; }
    if (moveType === "bike-road") { src = "./assets/icons/bike-road.svg"; }
    if (moveType === "mtb") { src = "./assets/icons/bike-mtb.svg"; }
    if (moveType === "walking") { src = "./assets/icons/walking.svg"; }
    if (moveType === "hiking") { src = "./assets/icons/hiking.svg"; }
    if (moveType === "car") { src = "./assets/icons/car-hatchback.svg"; }
    if (moveType === "") { src = "./assets/icons/directions.svg"; }
    return src;
}

export function cloneLRoute(lRoute: PRoute) {
    const newRoute = {} as PRoute;
    newRoute.id = lRoute.id;
    newRoute.saveType = lRoute.saveType;
    newRoute.routeId = lRoute.routeId;
    newRoute.order = lRoute.order;
    newRoute.name = lRoute.name;
    newRoute.description = lRoute.description;
    newRoute.wayPoints = cloneMapPlaces(lRoute.wayPoints);
    newRoute.plannerOptions = this.clonePlannerOptions(lRoute.plannerOptions);
    newRoute.backToStart = lRoute.backToStart;
    newRoute.roundRoute = lRoute.roundRoute;
    newRoute.pois = this.cloneMapPlaces(lRoute.pois);
    newRoute.geojsonRoute = undefined;
    return newRoute;
}
export function convertLRouteToSRoute(pRoute: PRoute, catId: number) {
    const route = {} as MapRoute;
    route.id = pRoute.id;
    route.routeId = pRoute.routeId;
    route.categoryId = catId;
    route.order = pRoute.order;
    route.name = pRoute.name;
    route.description = pRoute.description;
    route.wayPoints = JSON.stringify(pRoute.wayPoints);
    route.plannerOptions = JSON.stringify(pRoute.plannerOptions);
    route.backToStart = pRoute.backToStart;
    route.roundRoute = pRoute.roundRoute;
    route.pois = JSON.stringify(pRoute.pois);
    // console.log("utils-route:convertLRouteToSRoute-geojsonRoute", pRoute.geojsonRoute);
    const xxx = pRoute.geojsonRoute;
    xxx.metadata.engine.build_date = "?";
    xxx.metadata.engine.graph_date = "?";
    route.geojsonRoute = JSON.stringify(xxx);
    // console.log("utils-route:convertLRouteToSRoute-geojsonRoute-length", route.geojsonRoute.length);
    return route;
}
export function convertSRouteToLRoute(route: MapRoute) {
    const pRoute = {} as PRoute;
    pRoute.id = route.id;
    pRoute.saveType = "server";
    pRoute.routeId = route.routeId;
    pRoute.order = route.order;
    pRoute.name = route.name;
    pRoute.name = route.name;
    pRoute.description = route.description;
    pRoute.wayPoints = JSON.parse(route.wayPoints);
    pRoute.plannerOptions = JSON.parse(route.plannerOptions);
    pRoute.backToStart = route.backToStart;
    pRoute.roundRoute = route.roundRoute;
    pRoute.pois = JSON.parse(route.pois);
    if (route.geojsonRoute) {
        // console.log("utils-route:convertSRouteToLRoute-geojsonRoute", route.geojsonRoute);
        pRoute.geojsonRoute = JSON.parse(route.geojsonRoute);
    }
    return pRoute;
}
export function convertLRouteToPubRoute(pRoute: PRoute) {
    const route = {} as MapPublicRoute;
    route.id = pRoute.id;
    route.routeId = pRoute.routeId;
    route.name = pRoute.name;
    route.nameEn = pRoute.nameEn;
    route.nameDe = pRoute.nameDe;
    route.description = pRoute.description;
    route.descriptionEn = pRoute.descriptionEn;
    route.descriptionDe = pRoute.descriptionDe;

    route.wayPoints = JSON.stringify(pRoute.wayPoints);
    route.plannerOptions = JSON.stringify(pRoute.plannerOptions);
    route.backToStart = pRoute.backToStart;
    route.roundRoute = pRoute.roundRoute;
    route.pois = JSON.stringify(pRoute.pois);
    const xxx = pRoute.geojsonRoute;
    xxx.metadata.engine.build_date = "?";
    xxx.metadata.engine.graph_date = "?";
    route.geojsonRoute = JSON.stringify(xxx);
    // console.log("utils-saved-route:convertLRouteToSRoute-geojsonRoute-length", route.geojsonRoute.length);

    const feature = xxx.features[0];
    const bbox = feature.bbox;
    route.bboxLonFrom = bbox[0];
    route.bboxLatFrom = bbox[1];
    route.bboxLonTo = bbox[3];
    route.bboxLatTo = bbox[4];
    route.startLon = pRoute.wayPoints[0].coordLon;
    route.startLat = pRoute.wayPoints[0].coordLat;
    const prop = feature.properties;
    route.routeLength = prop.summary.distance;
    route.duration = prop.summary.duration;
    route.ascent = prop.ascent;
    route.descent = prop.descent;

    let routeType = EnumRouteType.OneWay;
    if (pRoute.backToStart) { routeType = EnumRouteType.BackToStart; }
    if (pRoute.roundRoute) { routeType = EnumRouteType.RoundRoute; }
    route.routeType = routeType;

    route.rating = 0.0;

    return route;
}
export function combineLRouteAndPubRoute(pRoute: PRoute, pubRoute: MapPublicRoute) {
    console.log("utils-route:combineLRouteAndPubRoute-pRoute", pRoute);
    console.log("utils-route:combineLRouteAndPubRoute-pubRoute", pubRoute);
    const route = {} as MapPublicRoute;
    route.id = pubRoute.id;
    route.createdAt = pubRoute.createdAt;
    route.updatedAt = pubRoute.updatedAt;
    route.routeId = pRoute.routeId;
    // route.name = pRoute.name;
    route.nameEn = pRoute.nameEn;
    route.nameDe = pRoute.nameDe;
    route.description = pRoute.description;
    route.descriptionEn = pubRoute.descriptionEn;
    route.descriptionDe = pubRoute.descriptionDe;
    route.imageIds = pubRoute.imageIds;

    route.wayPoints = JSON.stringify(pRoute.wayPoints);
    route.plannerOptions = JSON.stringify(pRoute.plannerOptions);
    route.backToStart = pRoute.backToStart;
    route.roundRoute = pRoute.roundRoute;
    route.pois = JSON.stringify(pRoute.pois);

    const xxx = pRoute.geojsonRoute;
    xxx.metadata.engine.build_date = "?";
    xxx.metadata.engine.graph_date = "?";
    route.geojsonRoute = JSON.stringify(xxx);
    const feature = xxx.features[0];
    const bbox = feature.bbox;
    route.bboxLonFrom = bbox[0];
    route.bboxLatFrom = bbox[1];
    route.bboxLonTo = bbox[3];
    route.bboxLatTo = bbox[4];
    route.startLon = pRoute.wayPoints[0].coordLon;
    route.startLat = pRoute.wayPoints[0].coordLat;
    const prop = feature.properties;
    route.routeLength = prop.summary.distance;
    route.duration = prop.summary.duration;
    route.ascent = prop.ascent;
    route.descent = prop.descent;

    let routeType = EnumRouteType.OneWay;
    if (pRoute.backToStart) { routeType = EnumRouteType.BackToStart; }
    if (pRoute.roundRoute) { routeType = EnumRouteType.RoundRoute; }
    route.routeType = routeType;

    route.regionIds = pubRoute.regionIds;
    route.difficultyLevel = pubRoute.difficultyLevel;
    route.description = pubRoute.description;
    route.months = pubRoute.months;
    route.categories = pubRoute.categories;
    route.rating = pubRoute.rating;

    return route;
}

export function convertPubRouteToLRoute(pubRoute: MapPublicRoute) {
    const route = {} as PRoute;
    route.id = pubRoute.id;
    route.routeId = pubRoute.routeId;
    route.name = undefined;
    route.nameEn = pubRoute.nameEn;
    route.nameDe = pubRoute.nameDe;
    route.description = undefined;
    route.descriptionEn = pubRoute.descriptionEn;
    route.descriptionDe = pubRoute.descriptionDe;

    route.wayPoints = JSON.parse(pubRoute.wayPoints);
    route.plannerOptions = JSON.parse(pubRoute.plannerOptions);
    route.backToStart = pubRoute.backToStart;
    route.roundRoute = pubRoute.roundRoute;
    route.pois = JSON.parse(pubRoute.pois);
    route.geojsonRoute = JSON.parse(pubRoute.geojsonRoute);

    return route;
}

export function getRouteTypeName(routeType: EnumRouteType, languageCode: string) {
    let routeTypeName: string;
    if (languageCode === "de") {
        if (routeType === EnumRouteType.OneWay) { routeTypeName = "Von A nach B"; }
        if (routeType === EnumRouteType.BackToStart) { routeTypeName = "Rundroute"; }
        if (routeType === EnumRouteType.RoundRoute) { routeTypeName = "Rundroute"; }
    }
    if (languageCode !== "de") {
        if (routeType === EnumRouteType.OneWay) { routeTypeName = "One way"; }
        if (routeType === EnumRouteType.BackToStart) { routeTypeName = "Round route"; }
        if (routeType === EnumRouteType.RoundRoute) { routeTypeName = "Round route"; }
    }
    return routeTypeName;
}

export function getRouteMoveTypeText(moveType: string, languageCode: string) {
    let text = "";
    if (languageCode === "de") {
        if (moveType === "bike") { text = "Fahrradroute" }
        if (moveType === "ebike") { text = "E-Bike-Route"; }
        if (moveType === "bike-road") { text = "Rennradroute"; }
        if (moveType === "mtb") { text = "Mountainbike-Route"; }
        if (moveType === "walking") { text = "Spazierroute"; }
        if (moveType === "hiking") { text = "Wanderroute"; }
        if (moveType === "car") { text = "Auto-/Motorrad-route"; }
    }
    if (languageCode !== "de") {
        if (moveType === "bike") { text = "Bike route" }
        if (moveType === "ebike") { text = "E-bike route"; }
        if (moveType === "bike-road") { text = "Raising-bike route"; }
        if (moveType === "mtb") { text = "Mountainbike route"; }
        if (moveType === "walking") { text = "Walking route"; }
        if (moveType === "hiking") { text = "Hiking route"; }
        if (moveType === "car") { text = "Car-/Motorbike route"; }
    }
    return text;
}

export function getRouteCategoryName(id: number, languageCode: string) {
    let catName: string;
    if (languageCode === "de") {
        if (id === 1) { catName = "Wald"; }
        if (id === 2) { catName = "Fluss"; }
        if (id === 3) { catName = "See"; }
        if (id === 4) { catName = "Wasserfall"; }
        if (id === 5) { catName = "Höhle"; }
        if (id === 6) { catName = "Strand"; }
        if (id === 51) { catName = "Restaurant"; }
        if (id === 52) { catName = "Bar"; }
        if (id === 54) { catName = "Café"; }
        if (id === 53) { catName = "Supermarkt"; }
        if (id === 101) { catName = "Aussicht"; }
        if (id === 102) { catName = "Familienfreundlich"; }
        if (id === 103) { catName = "Kulturelle Attraktion"; }
        if (id === 104) { catName = "Sehenswerte Natur"; }
        if (id === 105) { catName = "Besondere Gastronomie"; }
        if (id === 151) { catName = "Beschildert"; }
        if (id === 1001) { catName = "Mit Öffis erreichbar"; }
    }
    if (languageCode !== "de") {
        if (id === 1) { catName = "Hood"; }
        if (id === 2) { catName = "River"; }
        if (id === 3) { catName = "Lake"; }
        if (id === 4) { catName = "Waterfall"; }
        if (id === 5) { catName = "Cave"; }
        if (id === 6) { catName = "Beach"; }
        if (id === 51) { catName = "Restaurant"; }
        if (id === 52) { catName = "Bar"; }
        if (id === 54) { catName = "Café"; }
        if (id === 53) { catName = "Supermarket"; }
        if (id === 101) { catName = "Scenic"; }
        if (id === 102) { catName = "Family friendly"; }
        if (id === 103) { catName = "Culturel attraction"; }
        if (id === 104) { catName = "Beautiful nature"; }
        if (id === 105) { catName = "Special gastronomy"; }
        if (id === 151) { catName = "Signed"; }
        if (id === 1001) { catName = "Accessible by public transport"; }
    }
    return catName;
}

export function getMonthName(id: number, languageCode: string) {
    let sName: string;
    if (languageCode === "de") {
        if (id === 1) { sName = "Jän"; }
        if (id === 2) { sName = "Feb"; }
        if (id === 3) { sName = "Mär"; }
        if (id === 4) { sName = "Apr"; }
        if (id === 5) { sName = "Mai"; }
        if (id === 6) { sName = "Jun"; }
        if (id === 7) { sName = "Jul"; }
        if (id === 8) { sName = "Aug"; }
        if (id === 9) { sName = "Sep"; }
        if (id === 10) { sName = "Okt"; }
        if (id === 11) { sName = "Nov"; }
        if (id === 12) { sName = "Dez"; }
    }
    if (languageCode !== "de") {
        if (id === 1) { sName = "Jan"; }
        if (id === 2) { sName = "Feb"; }
        if (id === 3) { sName = "Mar"; }
        if (id === 4) { sName = "Apr"; }
        if (id === 5) { sName = "May"; }
        if (id === 6) { sName = "Jun"; }
        if (id === 7) { sName = "Jul"; }
        if (id === 8) { sName = "Aug"; }
        if (id === 9) { sName = "Sep"; }
        if (id === 10) { sName = "Okt"; }
        if (id === 11) { sName = "Nov"; }
        if (id === 12) { sName = "Dec"; }
    }
    return sName;
}
export function getSeasonName(id: number, languageCode: string) {
    let sName: string;
    if (languageCode === "de") {
        if (id === 1) { sName = "Frühling"; }
        if (id === 2) { sName = "Sommer"; }
        if (id === 3) { sName = "Herbst"; }
        if (id === 4) { sName = "Winter"; }
    }
    if (languageCode !== "de") {
        if (id === 1) { sName = "Spring"; }
        if (id === 2) { sName = "Summer"; }
        if (id === 3) { sName = "Autumn"; }
        if (id === 4) { sName = "Winter"; }
    }
    return sName;
}

export function getDifficultyLevelText(level: EnumDifficultyLevel, languageCode: string) {
    let sName: string;
    if (languageCode === "de") {
        if (level === EnumDifficultyLevel.Easy) { sName = "Leicht"; }
        if (level === EnumDifficultyLevel.Moderate) { sName = "Mittel"; }
        if (level === EnumDifficultyLevel.Difficult) { sName = "Schwierig"; }
        if (level === EnumDifficultyLevel.Undefined) { sName = "Unbekannt"; }
    }
    if (languageCode !== "de") {
        if (level === EnumDifficultyLevel.Easy) { sName = "Easy"; }
        if (level === EnumDifficultyLevel.Moderate) { sName = "Moderate"; }
        if (level === EnumDifficultyLevel.Difficult) { sName = "Difficult"; }
        if (level === EnumDifficultyLevel.Undefined) { sName = "Unknown"; }
    }
    return sName;
}

export function convertORSMoveType(moveTypeORS: string) {
    let moveType = "car";
    if (moveTypeORS === "cycling-regular") { moveType = "bike" }
    if (moveTypeORS === "cycling-electric") { moveType = "ebike" }
    if (moveTypeORS === "cycling-road") { moveType = "bike-road" }
    if (moveTypeORS === "cycling-mountain") { moveType = "mtb" }
    if (moveTypeORS === "foot-walking") { moveType = "walking" }
    if (moveTypeORS === "foot-hiking") { moveType = "hiking" }
    return moveType;
}
export function getRouteDurationCorrectionFactor(moveType: string) {
    let corrFactor = 1.0;
    if (moveType === "bike") { corrFactor = 1.3; }
    if (moveType === "ebike") { corrFactor = 1.3; }
    if (moveType === "bike-road") { corrFactor = 1.2; }
    if (moveType === "mtb") { corrFactor = 1.3; }
    if (moveType === "walking") { corrFactor = 1.3; }
    if (moveType === "hiking") { corrFactor = 1.4; }
    return corrFactor;

}
export function getRouteNameForLanguage(route: PRoute, languageCode: string) {
    let name: string;
    // english
    if (languageCode === "en") { name = route.nameEn; }
    // german
    if (languageCode === "de") { name = route.nameDe; }
    if (!name) { name = route.nameEn; }
    if (!name) { name = route.nameDe; }
    return name;
}
export function getRouteDescriptionForLanguage(route: PRoute, languageCode: string) {
    let description: string;
    // english
    if (languageCode === "en") { description = route.descriptionEn; }
    // german
    if (languageCode === "de") { description = route.descriptionDe; }
    if (!description) { description = route.descriptionEn; }
    if (!description) { description = route.descriptionDe; }
    return description;
}

