// analytical geometry

import { RoPoint, RoLine, RoPolyline } from "../models/geometry";

export function calculateDistance(lng1: number, lat1: number, lng2: number, lat2: number) {
    const dx = lng2 - lng1;
    const dy = lat2 - lat1;
    const dist = Math.sqrt(dx * dx + dy * dy);
    return dist;
}
export function calculateLineLength(point1: RoPoint, point2: RoPoint) {
    const deltaX = point2.x - point1.x;
    const deltaY = point2.y - point1.y;
    const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    return length;
}
export function createLineBy2Points(p1: RoPoint, p2: RoPoint) {

    const deltaX = p2.x - p1.x;
    const deltaY = p2.y - p1.y;

    const line = {} as RoLine;
    line.p1 = p1;
    line.p2 = p2;
    if (Math.abs(deltaX) < 0.000001) { // vertical line
        line.m = Number.NaN;
        line.n = Number.NaN;
        // console.log("utils-geometry:createLineBy2Points-yline", line);
        return line;
    }
    if (Math.abs(deltaY) < 0.000001) { // horizontal line
        line.m = 0.0;
        line.n = p1.y;
        // console.log("utils-geometry:createLineBy2Points-xline", line);
        return line;
    }
    // sloping line
    line.m = deltaY / deltaX;
    if (p1.x === 0.0) {
        line.n = p1.y;
    } else {
        const deltaY1 = line.m * p1.x;
        line.n = p1.y - deltaY1;
    }
    // console.log("utils-geometry:createLineBy2Points-line", line);
    return line;

}

export function createNormalLine(line: RoLine, point: RoPoint) {
    const lineNormal = {} as RoLine;
    lineNormal.p1 = point;
    // console.log("utils-geometry:createNormalLine-line", line);
    // line is vertical - normal is horizontal
    if (Number.isNaN(line.m)) {
        lineNormal.m = 0.0;
        lineNormal.n = point.y;
        return lineNormal;
    }
    // line is horizontal - normal is vertical
    if (line.m === 0.0) {
        lineNormal.m = Number.NaN;
        lineNormal.n = Number.NaN;
        return lineNormal;
    }
    if (!lineNormal.m) {
        lineNormal.m = -1.0 / line.m;
        lineNormal.n = point.y - lineNormal.m * point.x;
        // console.log("utils-geometry:createNormalLine-lineNormal", lineNormal);
        return lineNormal;
    }
}
export function calculateLinePointIntersection(line1: RoLine, point: RoPoint) {

    // console.log("utils-geometry:calculateLinePointIntersection-line1", line1);
    // console.log("utils-geometry:calculateLinePointIntersection-point", point);
    const pointS = {} as RoPoint;
    const line2 = createNormalLine(line1, point);
    // console.log("utils-geometry:calculateLinePointIntersection-line2", line2);
    // line1 is vertical line
    if (Number.isNaN(line1.m)) {
        pointS.x = line1.p1.x;
        pointS.y = point.y;
        return pointS;
    }
    pointS.x = (line2.n - line1.n) / (line1.m - line2.m);
    pointS.y = line1.m * pointS.x + line1.n;
    // console.log("utils-geometry:calculateLinePointIntersection-pointS", pointS);
    // pointS not within line
    if (line1.p1.x < line1.p2.x) {
        if (pointS.x < line1.p1.x || pointS.x > line1.p2.x) { return undefined; }
    } else {
        if (pointS.x < line1.p2.x || pointS.x > line1.p1.x) { return undefined; }
    }
    if (line1.p1.y < line1.p2.y) {
        if (pointS.y < line1.p1.y || pointS.y > line1.p2.y) { return undefined; }
    } else {
        if (pointS.y < line1.p2.y || pointS.y > line1.p1.y) { return undefined; }
    }
    // pointS within line
    return pointS;
}

export function calculatePolylineLength(polyline: RoPolyline) {
    // console.log("utils-geometry:calculatePolylineLength-polyline", polyline);

    let length = 0.0;
    for (const p of polyline.points) {
        const index = polyline.points.indexOf(p);
        // console.log("utils-geometry:calculatePolylineLength-index", index);
        // console.log("utils-geometry:calculatePolylineLength-p", p);
        if (index === 0) { continue; }
        length += calculateLineLength(polyline.points[index - 1], p);
        // console.log("utils-geometry:calculatePolylineLength-length", length);
    }
    return length;
}
export function calculatePolylinePointIntersectionAt(polyline: RoPolyline, point: RoPoint, maxDistance: number) {
    // console.log("utils-geometry:calculatePolylinePointIntersectionAt-polyline", polyline);
    // console.log("utils-geometry:calculatePolylinePointIntersectionAt-point", point);

    if (polyline.points.length < 2) { return 0.0; }

    let minIndex = -1;
    let minDist = maxDistance;
    let pointSMin: RoPoint;

    // test, if point intersects the normal from point to the polyline
    for (const p of polyline.points) {
        //  console.log("utils-geometry:calculatePolylinePointIntersectionAt-p", p);
        const index = polyline.points.indexOf(p);
        if (index === 0) { continue; }
        const line = createLineBy2Points(polyline.points[index - 1], p);
        // console.log("utils-geometry:calculatePolylinePointIntersectionAt-line", line);
        const pointS = calculateLinePointIntersection(line, point);
        if (pointS) {
            // console.log("utils-geometry:calculatePolylinePointIntersectionAt-index", index);
            // console.log("utils-geometry:calculatePolylinePointIntersectionAt-pointS", pointS);
            const dist = calculateLineLength(pointS, point);
            // console.log("utils-geometry:calculatePolylinePointIntersectionAt-dist", dist);
            if (dist < minDist) {
                minDist = dist;
                minIndex = index;
                pointSMin = pointS;
            }
        }
    }
    if (minIndex !== -1) {
        // console.log("utils-geometry:calculatePolylinePointIntersectionAt-minIndex", minIndex);
        // console.log("utils-geometry:calculatePolylinePointIntersectionAt-minDist", minDist);
        let at = 0.0;
        for (const p of polyline.points) {
            const index = polyline.points.indexOf(p);
            if (index === 0) { continue; }
            if (index < minIndex) {
                at += calculateLineLength(polyline.points[index - 1], p);
            }
            if (index === minIndex) {
                at += calculateLineLength(polyline.points[index - 1], pointSMin);
                return at;
            }
            if (index > minIndex) { break; }
        }
        // console.log("utils-geometry:calculatePolylinePointIntersectionAt-at", at);
        return at;
    }
    // test, if point is close to a polyline-point
    for (const p of polyline.points) {
        const index = polyline.points.indexOf(p);
        const dist = calculateLineLength(p, point);
        if (dist < maxDistance) {
            minIndex = index;
            minDist = dist;
        }
    }
    if (minIndex !== -1) {
        let at = 0.0;
        if (minIndex === 0) { return 0.0; }
        for (const p of polyline.points) {
            const index = polyline.points.indexOf(p);
            if (index === 0) { continue; }
            if (index < minIndex) {
                at += calculateLineLength(polyline.points[index - 1], p);
            }
            if (index === minIndex) { break; }
        }
        // console.log("utils-geometry:calculatePolylinePointIntersectionAt-at", at);
        return at;
    }
    return Number.NaN;
}

export function calculatePolylinePointIntersectionAtIndex(polyline: RoPolyline, point: RoPoint, maxDistance: number) {
    // console.log("utils-geometry:calculatePolylinePointIntersectionAtIndex-polyline", polyline);
    // console.log("utils-geometry:calculatePolylinePointIntersectionAtIndex-point", point);

    if (polyline.points.length < 2) { return 0.0; }

    let minIndex = -1;
    let minDist = maxDistance;
    let pointSMin: RoPoint;

    // find closest segment
    for (const p of polyline.points) {
        // console.log("utils-geometry:calculatePolylinePointIntersectionAtIndex-p", p);
        const index = polyline.points.indexOf(p);
        if (index === 0) { continue; }
        const line = createLineBy2Points(polyline.points[index - 1], p);
        // console.log("utils-geometry:calculatePolylinePointIntersectionAtIndex-line", line);
        const pointS = calculateLinePointIntersection(line, point);
        if (pointS) {
            // console.log("utils-geometry:calculatePolylinePointIntersectionAtIndex-index", index);
            // console.log("utils-geometry:calculatePolylinePointIntersectionAtIndex-pointS", pointS);
            const dist = calculateLineLength(pointS, point);
            // console.log("utils-geometry:calculatePolylinePointIntersectionAtIndex-dist", dist);
            if (dist < minDist) {
                minDist = dist;
                minIndex = index;
                pointSMin = pointS;
            }
        }
    }
    return minIndex;
}
