import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { getNavigatorLanguage, getUserLanguage } from "src/app/utils/utils";

@Component({
  selector: "app-info-privacypolicy-component",
  templateUrl: "./info-privacypolicy.component.html",
  styleUrls: ["./info-privacypolicy.component.scss"]
})
export class FooterPrivacyPolicyComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;

  @ViewChild("privpolId") privpolElement: ElementRef;

  constructor(
    private titleService: Title,
  ) { }

  public async ngOnInit() {
    // this.languageCode = "en"; // until other versions available
    // console.log("PrivacyPolicy:ngOnInit", this.languageCode);
    // title
    // let title = "Privacy policy";
    // if (this.languageCode === "de") { title = "Datenschutz & Sicherheit"; }
    // title += " | Trip4You-Maps";
    // this.titleService.setTitle(title);
    // meta viewport
    // let metaDesc = document.querySelector("meta[name='viewport']");
    // metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    // let description = "Information about privacy policy of Trip4You-Maps";
    // if (this.languageCode === "de") {
    //   description = "Information über Datenschutz & Sicherheit von Trip4You-Maps";
    // }
    // metaDesc = document.querySelector("meta[name='description']");
    // metaDesc.setAttribute("content", description);
  }

  public async ngAfterViewInit() {
    // console.log("PrivacyPolicy:ngAfterViewInit", this.languageCode);
    this.privpolElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public ngOnDestroy(): void {
  }



}

