
export function decodeUrlParamRouteStyle(paramString: string) {
    const params = paramString.split("|");
    let routeStyle = "solid";
    if (params.length > 0) { routeStyle = params[0]; }
    if (routeStyle === "transparent") {
        return routeStyle;
    }
    if (routeStyle === "surface-type") {
        return routeStyle;
    }
    if (routeStyle === "way-type") {
        return routeStyle;
    }
    if (routeStyle === "steepness") {
        return routeStyle;
    }
    if (routeStyle === "style-g") {
        return routeStyle;
    }
    if (routeStyle === "style-t") {
        return routeStyle;
    }
    return routeStyle;
}

export function decodeUrlParamRouteColor(paramString: string) {
    const params = paramString.split("|");
    let routeColor = "cyan";
    if (params.length > 1) { routeColor = params[1]; }
    // console.log("Maps:decodeUrlParamRouteColor-routeColor", routeColor);
    return routeColor;
}

export function decodeUrlParamWaypointStyle(paramString: String) {
    const params = paramString.split("|");
    let waypointStyle = "std";
    if (params.length > 0) { waypointStyle = params[0]; }
    if (waypointStyle === "std") {
        return waypointStyle;
    }
    if (waypointStyle === "hide") {
        return waypointStyle;
    }
    if (waypointStyle === "start") {
        return waypointStyle;
    }
    if (waypointStyle === "end") {
        return waypointStyle;
    }
    return waypointStyle;
}

export function decodeUrlParamWaypointSize(paramString: String) {
    const params = paramString.split("|");
    let waypointSize = 12;
    if (params.length > 1) {
        const size = Number(params[1]);
        if (size) { waypointSize = size; }
    }
    return waypointSize;
}
