<div (click)="showLoginLogoutDialog()"
  style="flex-grow: 1; flex-shrink: 1; flex-basis: content; text-overflow: ellipsis; overflow: hidden;"
  class="user-container" [class.logged-in]="isUserLoggedIn" [class.logged-out]="!isUserLoggedIn">

  <div style="display: flex; flex-flow: row-reverse">
    <div style="flex-grow: 0; flex-shrink: 0; flex-basis: content;">
      <svg class="login-icon" style="width:36px; height:36px" viewBox="0 0 24 24">
        <path fill="currentColor"
          d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M16.5,16.25C16.5,14.75 13.5,14 12,14C10.5,14 7.5,14.75 7.5,16.25V17H16.5M12,12.25A2.25,2.25 0 0,0 14.25,10A2.25,2.25 0 0,0 12,7.75A2.25,2.25 0 0,0 9.75,10A2.25,2.25 0 0,0 12,12.25Z" />
      </svg>
    </div>

    <div *ngIf="showUserName"
      style="flex-grow: 0; flex-shrink: 1; flex-basis: content; text-overflow: ellipsis; overflow: hidden;">
      <ng-container>
        {{userDetails$ | async}}
      </ng-container>
      <ng-template #loginInfo>
        Login
      </ng-template>
    </div>

  </div>
</div>