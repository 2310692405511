// imports angular
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";

// imports-ol
import { fromLonLat, transform } from "ol/proj";
import { RoPoint } from "src/app/models/geometry";

// import-app
import { LngLat } from "src/app/models/lnglat";
import { MapPlaceL } from "src/app/models/mapplace";
import { RoutePlannerOptions } from "src/app/models/map-settings";
import { SearchFilter } from "src/app/models/search-parameter";

// ORS - geocode
// tslint:disable-next-line:max-line-length
export async function doORSGeocodeSearchAutocomplete(http: HttpClient, currentPositionLngLat: LngLat, searchParameter: SearchFilter) {
    const mapExtent = searchParameter.mapExtent;
    // console.log("utils_maps_ors:doGeoCodeSearchAutocomplete-extent", mapExtent);
    const placeNotFoundError = false;
    const text = searchParameter.text;
    let params = "";
    if (text) { params += "&text=" + text; }
    if (!params) {
        this.placeNotFoundError = true;
        return;
    }
    const focus = searchParameter.focusCurrentPosition;
    if (focus) { params += getFocusForCurrentPositionParam(currentPositionLngLat); }
    const inmap = searchParameter.onlyInMapExtent;
    if (inmap) { params += getOnlyInMapExtentParam(mapExtent); }

    const url = "https://api.openrouteservice.org/geocode/autocomplete?" +
        "api_key=5b3ce3597851110001cf62487c37d515ac6249bebf61de0e5e51cdf3" + params;
    // console.log("utils_maps_ors:onGeoCodeSearch-url", url);
    const result = await http.get<any>(url).toPromise().catch(event => {
        // console.log("utils_map_ors:doORSGeocodeSearchAutocomplete:handleORSPostError-event", event);
        let result = event
        if (result.status === 0) { result.type = "http-error"; }
        if (result.status !== 0) {
            result.type = "ors-error";
            result.errorMessage = "ORS-Error-Status=" + result.status;
        }
        // console.log("utils_map_ors:doORSGeocodeSearchAutocomplete:handleORSPostError-result", result);
        return result;
    });
    // console.log("utils_map_ors:onGeoCodeSearch-result", result);
    if (result.type === "http-error" || result.type === "ors-error") { return result; }
    const geojsonResult = result;
    // console.log("--------------------utils-maps-ors:doORSGeocodeSearchAutocomplete-geojsonResult", geojsonResult);
    const foundRefPlaces = new Array<MapPlaceL>();
    if (geojsonResult.features.length === 0) {
        return foundRefPlaces;
    }
    let index = -1;
    for (const feature of geojsonResult.features) {
        // console.log("utils-maps-ors:doORSGeocodeSearchAutocomplete-feature", feature);
        if (feature.geometry.type === "Point") {
            index++;
            const coord = {} as LngLat;
            coord.lng = feature.geometry.coordinates[0];
            coord.lat = feature.geometry.coordinates[1];
            const mapPlace = {} as MapPlaceL;
            mapPlace.id = index;
            mapPlace.idOSM = feature.properties.id;
            let name = "?";
            if (feature.properties.name) { name = feature.properties.name; }
            // console.log("utils-maps2-ors:onGeoCodeSearch-name", name);
            mapPlace.name = name;
            if (feature.properties.label) { mapPlace.label = feature.properties.label; }
            mapPlace.coordLon = coord.lng;
            mapPlace.coordLat = coord.lat;
            // refPlace.countryCode = feature.properties.country_a;
            if (feature.properties.distance) { mapPlace.distance = feature.properties.distance; }
            mapPlace.tags = feature.properties;
            foundRefPlaces.push(mapPlace);
        }
    }
    return foundRefPlaces;
}

// ORS - elevation
export async function doORSElevation(http: HttpClient, position: LngLat) {
    const params = getPositionGeometryParam(position);

    const url = "https://api.openrouteservice.org/elevation/point?" +
        "api_key=5b3ce3597851110001cf62487c37d515ac6249bebf61de0e5e51cdf3" + params;
    // console.log("utils_maps_ors:doORSElevation-url", url);
    const result = await http.get<any>(url).toPromise().catch(event => {
        // console.log("utils_map_ors:doORSElevation:handleORSPostError-event", event);
        let result = event
        if (result.status === 0) { result.type = "http-error"; }
        if (result.status !== 0) {
            result.type = "ors-error";
            result.errorMessage = "ORS-Error-Status=" + result.status;
        }
        // console.log("utils_map_ors:doORSElevation:handleORSPostError-result", result);
        return result;
    });
    // console.log("utils_map_ors:doORSElevation-result", result);
    if (result.type === "http-error" || result.type === "ors-error") { return result; }
    const geojsonResult = result;
    const geometry = geojsonResult.geometry;
    const coordinates = geometry.coordinates;
    const elevation = coordinates[2];
    // console.log("--------------------utils-maps-ors:doORSElevation-elevation", elevation);
    return elevation;
}

export async function doORSReverseGeocode(http: HttpClient, positionLngLat: LngLat) {
    // console.log("utils_map_ors:doORSInverseGeoCode-position", positionLngLat);
    let params = "";
    params += "&point.lat=" + positionLngLat.lat.toString();
    params += "&point.lon=" + positionLngLat.lng.toString();
    // params += "&layers=address";
    params += "&size=10";
    // console.log("utils-maps-ors:doORSReverseGeocode-params", params);

    const url = "https://api.openrouteservice.org/geocode/reverse?" +
        "api_key=5b3ce3597851110001cf62487c37d515ac6249bebf61de0e5e51cdf3" + params;
    // console.log("utils_map_ors:doORSReverseGeocode-url", url);
    const result = await http.get<any>(url).toPromise().catch(event => {
        // console.log("utils_map_ors:doORSReverseGeocode:handleORSGetError-event", event);
        let result = event;
        if (result.status === 0) { result.type = "http-error"; }
        if (result.status !== 0) {
            result.type = "ors-error";
            result.errorMessage = "ORS-Error-Status=" + result.status;
        }
        console.log("utils_map_ors:doORSReverseGeocode:handleORSPostError-result", result);
        return result;
    });
    if (result.type === "http-error" || result.type === "ors-error") { return result; }
    const geocodeResult = result;
    const foundRefPlaces = new Array<MapPlaceL>();
    let index = -1;
    for (const feature of geocodeResult.features) {
        // console.log("utils-maps-ors:doORSReverseGeocode-feature", feature);
        if (feature.geometry.type === "Point") {
            index++;
            const coord = {} as LngLat;
            coord.lng = feature.geometry.coordinates[0];
            coord.lat = feature.geometry.coordinates[1];
            const refPlace = {} as MapPlaceL;
            refPlace.id = index;
            refPlace.idOSM = feature.properties.id;
            let name = "?";
            if (feature.properties.name) { name = feature.properties.name; }
            // console.log("utils-maps2-ors:onGeoCodeSearch-name", name);
            refPlace.name = name;
            if (feature.properties.label) { refPlace.label = feature.properties.label; }
            refPlace.coordLon = coord.lng;
            refPlace.coordLat = coord.lat;
            // refPlace.countryCode = feature.properties.country_a;
            if (feature.properties.distance) { refPlace.distance = feature.properties.distance; }
            refPlace.tags = feature.properties;
            foundRefPlaces.push(refPlace);
        }
    }
    return foundRefPlaces;
}

// route-calculation ORS post-request ----------------------------------------------------------------
// tslint:disable-next-line:max-line-length
export async function doORSCalculateRoute(http: HttpClient, routeWayPoints: MapPlaceL[], isRoundTrip: boolean, routePlannerSettings: RoutePlannerOptions, languageCode: string) {
    // console.log("utils_map_ors:doORSCalculateRoute-languageCode", languageCode);
    // test, if minimum 2 waypoints with coord exist
    let countWayPoints = 0;
    for (const wp of routeWayPoints) {
        if (wp.coordLon) { countWayPoints++; }
    }
    if (countWayPoints < 2) { return; }
    // console.log("utils_map_ors:doORSCalculateRoute-countWayPoints", countWayPoints);

    const moveType = routePlannerSettings.moveType;
    let url = "https://api.openrouteservice.org/v2/directions/";
    if (moveType === "bike") { url = url + "cycling-regular"; }
    if (moveType === "ebike") { url = url + "cycling-electric"; }
    if (moveType === "bike-road") { url = url + "cycling-road"; }
    if (moveType === "mtb") { url = url + "cycling-mountain"; }
    if (moveType === "walking") { url = url + "foot-walking"; }
    if (moveType === "hiking") { url = url + "foot-hiking"; }
    if (moveType === "car") { url = url + "driving-car"; }
    url = url + "/geojson";
    // console.log("utils_map_ors:doORSCalculateRoute-url", url);

    let headers = new HttpHeaders({});
    headers = headers.set("Authorization", "5b3ce3597851110001cf62487c37d515ac6249bebf61de0e5e51cdf3");
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8");
    // console.log("utils_map_ors:doORSCalculateRoute-headers", headers);

    // body
    const body = {
        coordinates: [],
        // "language": "de",
        language: languageCode,
        elevation: true,
        // "roundabout_exits": true,
        extra_info: ["waytype", "surface", "steepness"],
        // extra_info: ["waytype", "surface"],
        // options: { avoid_features: ["tollways"] }
        options: {},
    };

    // moveType bike / avoids
    let isMoveTypeBike = false;
    if (moveType === "bike") { isMoveTypeBike = true; }
    if (moveType === "ebike") { isMoveTypeBike = true; }
    if (moveType === "bike-road") { isMoveTypeBike = true;; }
    if (moveType === "mtb") { isMoveTypeBike = true; }
    if (isMoveTypeBike) {
        const featureTypes = new Array<string>();
        // console.log("utils_map_ors:onRouteCalculate-avoidSteps", routePlannerSettings.avoidSteps);
        if (routePlannerSettings.avoidSteps) { featureTypes.push("steps"); }
        if (featureTypes.length > 0) {
            body.options = { avoid_features: featureTypes };
        }
    }
    // mpveType car / avoids
    if (moveType === "car") {
        const featureTypes = new Array<string>();
        if (routePlannerSettings.avoidTollways) { featureTypes.push("tollways"); }
        if (routePlannerSettings.avoidHighways) { featureTypes.push("highways"); }
        if (featureTypes.length > 0) {
            body.options = { avoid_features: featureTypes };
        }
    }
    // waypoints
    for (const wp of routeWayPoints) {
        if (wp.coordLon) { body.coordinates.push([wp.coordLon, wp.coordLat]); }
    }
    if (isRoundTrip) {
        const wp = routeWayPoints[0];
        body.coordinates.push([wp.coordLon, wp.coordLat]);
    }
    // console.log("utils_map_ors:onRouteCalculate-body", body);
    const result = await http.post<any>(url, body, { headers }).toPromise().catch(event => {
        // console.log("utils_map_ors:doORSCalculateRoute:handleORSPostError-event", event);
        let result = event
        if (result.status === 0) { result.type = "http-error"; }
        if (result.status !== 0) { result.type = "ors-error"; }
        console.log("utils_map_ors:doORSCalculateRoute:handleORSPostError-result", result);
        return result;
    });
    // console.log("utils_map_ors:doORSCalculateRoute-result", result);
    return result;
}

// tslint:disable-next-line:max-line-length
export async function doORSCalculateRoundRoute(http: HttpClient, routeWayPoints: MapPlaceL[], routePlannerSettings: RoutePlannerOptions, languageCode: string) {
    // console.log("utils_map_ors:doORSCalculateRoundRoute-languageCode", languageCode);
    // test, if minimum 2 waypoints with coord exist
    let countWayPoints = 0;
    for (const xWp of routeWayPoints) {
        if (xWp.coordLon) { countWayPoints++; }
    }
    if (countWayPoints < 1) { return; }
    // console.log("utils_map_ors:doORSCalculateRoundRoute-countWayPoints", countWayPoints);

    const moveType = routePlannerSettings.moveType;
    let url = "https://api.openrouteservice.org/v2/directions/";
    if (moveType === "bike") { url = url + "cycling-regular"; }
    if (moveType === "ebike") { url = url + "cycling-electric"; }
    if (moveType === "bike-road") { url = url + "cycling-road"; }
    if (moveType === "mtb") { url = url + "cycling-mountain"; }
    if (moveType === "walking") { url = url + "foot-walking"; }
    if (moveType === "hiking") { url = url + "foot-hiking"; }
    if (moveType === "car") { url = url + "driving-car"; }
    url = url + "/geojson";
    // console.log("utils_map_ors:doORSCalculateRoundRoute-url", url);

    let headers = new HttpHeaders({});
    headers = headers.set("Authorization", "5b3ce3597851110001cf62487c37d515ac6249bebf61de0e5e51cdf3");
    headers = headers.set("Content-Type", "application/json");
    headers = headers.set("Accept", "application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8");
    // console.log("utils_map_ors:doORSCalculateRoundRoute-headers", headers);

    // body
    const body = {
        coordinates: [],
        // "language": "de",
        language: languageCode,
        elevation: true,
        // "roundabout_exits": true,
        extra_info: ["waytype", "surface"],
        // options: { avoid_features: ["tollways"] },
        options: {}
    };

    // moveType bike / avoids
    let isMoveTypeBike = false;
    if (moveType === "bike") { isMoveTypeBike = true; }
    if (moveType === "ebike") { isMoveTypeBike = true; }
    if (moveType === "bike-road") { isMoveTypeBike = true;; }
    if (moveType === "mtb") { isMoveTypeBike = true; }
    if (isMoveTypeBike) {
        const featureTypes = new Array<string>();
        if (routePlannerSettings.avoidSteps) { featureTypes.push("steps"); }
        if (featureTypes.length > 0) {
            body.options = { avoid_features: featureTypes };
        }
    }
    // moveType car / avoids
    if (moveType === "car") {
        const featureTypes = new Array<string>();
        if (routePlannerSettings.avoidTollways) { featureTypes.push("tollways"); }
        if (routePlannerSettings.avoidHighways) { featureTypes.push("highways"); }
        if (featureTypes.length > 0) {
            body.options = { avoid_features: featureTypes };
        }
    }
    body.options = {
        round_trip: {
            length: routePlannerSettings.roundRouteLength,
            points: routePlannerSettings.roundRoutePoints,
            seed: routePlannerSettings.roundRouteSeed
        }
    };
    const wp = routeWayPoints[0];
    body.coordinates.push([wp.coordLon, wp.coordLat]);
    // console.log("utils_map_ors:doORSCalculateRoundRoute-body", body);
    const result = await http.post<any>(url, body, { headers }).toPromise().catch(event => {
        // console.log("utils_map_ors:doORSCalculateRoundRoute:handleORSPostError-event", event);
        let result = event
        if (result.status === 0) { result.type = "http-error"; }
        if (result.status !== 0) { result.type = "ors-error"; }
        console.log("utils_map_ors:doORSCalculateRoundRoute:handleORSPostError-result", result);
        return result;
    });
    // console.log("utils_map_ors:doORSCalculateRoundRoute:onRouteCalculate-result", result);
    return result;
}

export function getFocusForCurrentPositionParam(currentPositionLngLat: LngLat) {
    if (!currentPositionLngLat) { return ""; }
    let params = "";
    params += "&focus.point.lat=" + currentPositionLngLat.lat.toString();
    params += "&focus.point.lon=" + currentPositionLngLat.lng.toString();
    return params;
}
export function getOnlyInMapExtentParam(extent: number[]) {
    let params = "";
    params += "&boundary.rect.min_lon=" + extent[0].toString();
    params += "&boundary.rect.min_lat=" + extent[1].toString();
    params += "&boundary.rect.max_lon=" + extent[2].toString();
    params += "&boundary.rect.max_lat=" + extent[3].toString();
    return params;
}
export function getPositionGeometryParam(position: LngLat) {
    let params = "&geometry=" + position.lng.toString() + "," + position.lat.toString() + "&format_out=geojson";
    return params;
}





