import { Component, OnInit, OnDestroy, ViewChild, LOCALE_ID, AfterViewInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { getDeviceTypeName } from "src/app/utils/utils";
import { Track } from "src/app/models/track";


@Component({
  selector: "app-gpx-route-component",
  templateUrl: "./gpx-route.component.html",
  styleUrls: ["./gpx-route.component.scss"]
})
export class GpxRouteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uiType: string;
  @Input() gpxFileLoaded: boolean;
  @Input() activeGpxTrack: Track;

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Output() zoomToRoute: EventEmitter<void> = new EventEmitter();
  @Output() showRouteDetails: EventEmitter<void> = new EventEmitter();
  @Output() clearRoute: EventEmitter<void> = new EventEmitter();

  @ViewChild("gpxFileInput") gpxFileInputElement: ElementRef;
  constructor(
  ) {
  }

  public async ngOnInit() {
    // console.log("GpxRoute:ngOnInit");

  }
  public ngAfterViewInit() {
    // console.log("MapX:ngAfterViewInit");
  }
  public ngOnDestroy(): void {
  }

  public onGpxRouteBackClick() {
    this.back.emit();
  }

  public onFileChange(event: any) {
    // console.log("GpxRoute:onFileChange", event);
    this.fileChange.emit(event);
  }

  public onZoomToGpxRouteClick() {
    this.zoomToRoute.emit();
  }

  public onShowGpxRouteDetailsClick() {
    this.showRouteDetails.emit();
  }
  public onClearGpxRouteClick() {
    this.gpxFileInputElement.nativeElement.value = "";
    this.clearRoute.emit();
  }
}
