import { TrackPoint } from "src/app/models/track";

// clone trackPoints
export function cloneTrackPoints(trackpoints: TrackPoint[]) {
    const clonedTrackpoints = new Array<TrackPoint>();
    if (!trackpoints) { return clonedTrackpoints; }
    for (const trackPoint of trackpoints) {
        const clonedTrackPoint = cloneTrackPoint(trackPoint);
        clonedTrackpoints.push(clonedTrackPoint);
    }
    return clonedTrackpoints;
}
export function cloneTrackPoint(trackPoint: TrackPoint) {
    const newTrackPoint = {} as TrackPoint;
    newTrackPoint.lng = trackPoint.lng;
    newTrackPoint.lat = trackPoint.lat;
    newTrackPoint.ele = trackPoint.ele;
    newTrackPoint.timeStamp = trackPoint.timeStamp;
    return newTrackPoint;
}
