import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { getUserLanguage } from "src/app/utils/utils";

@Component({
  selector: "app-info-impressum-component",
  templateUrl: "./info-impressum.component.html",
  styleUrls: ["./info-impressum.component.scss"]
})
export class InfoImpressumComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() uiType: string;
  @Input() languageCode: string;


  @ViewChild("impressumId") newsElement: ElementRef;

  constructor(
    private titleService: Title,
  ) { }

  public async ngOnInit() {
    // title
    // let title = "Contact/Impressum";
    // if (this.languageCode === "de") { title = "Kontakt/Impressum"; }
    // title += " | Trip4You-Maps";
    // this.titleService.setTitle(title);
    // meta viewport
    // let metaDesc = document.querySelector("meta[name='viewport']");
    // metaDesc.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no");
    // meta description
    // let description = "Contact information for Trip4You-Maps";
    // if (this.languageCode === "de") {
    //   description = "Kontakt-Information von Trip4You-Maps";
    // }
    // metaDesc = document.querySelector("meta[name='description']");
    // metaDesc.setAttribute("content", description);
  }

  public async ngAfterViewInit() {
    // console.log("FooterImpressum:ngAfterViewInit", this.languageCode);
    // this.newsElement.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  public ngOnDestroy(): void {
  }



}
