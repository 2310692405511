<!-- page-not-found -->
<div style="text-align:center">

  <div style="margin:3.0rem;background-color: azure; padding:0.5rem;">
    <h1>Sorry, this page is not available</h1>
    <div style="margin-top:1.0rem; font-size:x-large;">
      <span>{{currentRoute}}</span>
    </div>
  </div>

  <div style="margin:3.0rem;background-color:aliceblue; padding:0.5rem;">
    <h1>Select what you like to do</h1>

    <!-- Trip4You-Maps -->
    <div style="background-color:antiquewhite; font-size:x-large; padding:1.0rem;">
      <span>Trip4You-Maps</span><br>
      <span>For route planning and navigation</span><br>
      <span style="font-size: smaller;"><i>Particularly suitable for cyclists and hikers</i></span><br>
      <a mat-raised-button color="primary" style="margin-top:1.0rem;" href="https://maps.trip4you.net">
        Go to Trip4You-Maps</a>
      <span style="margin-left:0.5rem; font-size: smaller;">https://maps.trip4you.net</span><br>
    </div>

    <!-- Trip4You-TravelInformation -->
    <div style="background-color:antiquewhite; font-size:x-large; padding:1.0rem;">
      <span>Trip4You - Travel explorer</span><br>
      <span>With travel information of several countries</span><br>
      <a mat-raised-button color="primary" style="margin-top:1.0rem;" href="https://trip4you.net/explore">
        Go to Trip4You - Travel information</a>
      <span style="margin-left:0.5rem; font-size: smaller;">https://trip4you.net/explore</span><br>
    </div>

    <!-- Trip4You-TravelPlanner -->
    <div style="background-color:antiquewhite; font-size:x-large; padding:1.0rem;">
      <span>Trip4You - Travel planner</span><br>
      <span>For individual trip planning</span><br>
      <a mat-raised-button color="primary" style="margin-top:1.0rem;" href="https://trip4you.net">
        Go to Trip4You - TripPlanner</a>
      <span style="margin-left:0.5rem; font-size: smaller;">https://trip4you.net</span><br>
    </div>

  </div>

  <!-- <app-trips-footer-component></app-trips-footer-component> -->

</div>