// functions for ol-map

// const colLightRed = "rgb(255, 120, 120)";


export function getSteepnessName(category: number, lang: string) {
    if (lang === "de") { return getSteepnessNameDe(category); }
    return getSteepnessNameEn(category);
}
export function getSteepnessNameDe(category: number) {
    let name = "unknown";
    if (category === -5) { name = ">16%"; }
    if (category === -4) { name = "12-15%"; }
    if (category === -3) { name = "7-11%"; }
    if (category === -2) { name = "4-6%"; }
    if (category === -1) { name = "1-3%"; }
    if (category === 0) { name = "0%"; }
    if (category === 1) { name = "1-3%"; }
    if (category === 2) { name = "4-6%"; }
    if (category === 3) { name = "7-11%"; }
    if (category === 4) { name = "12-15%"; }
    if (category === 5) { name = ">16%"; }
    return name;
}
export function getSteepnessNameEn(category: number) {
    let name = "unknown";
    if (category === -5) { name = ">16%"; }
    if (category === -4) { name = "12-15%"; }
    if (category === -3) { name = "7-11%"; }
    if (category === -2) { name = "4-6%"; }
    if (category === -1) { name = "1-3%"; }
    if (category === 0) { name = "0%"; }
    if (category === 1) { name = "1-3%"; }
    if (category === 2) { name = "4-6%"; }
    if (category === 3) { name = "7-11%"; }
    if (category === 4) { name = "12-15%"; }
    if (category === 5) { name = ">16%"; }
    return name;
}
export function getSteepnessColor(category: number) {
    let col = "rgba(128,128,128, 1)";
    if (category === -5) { col = "Plum"; }
    if (category === -4) { col = "Tomato"; }
    if (category === -3) { col = "Lightcoral"; }
    if (category === -2) { col = "Khaki"; }
    if (category === -1) { col = "Lightgreen"; }
    if (category === 0) { col = "Gray"; }
    if (category === 1) { col = "Green"; }
    if (category === 2) { col = "Yellow"; }
    if (category === 3) { col = "Darkorange"; }
    if (category === 4) { col = "Red"; }
    if (category === 5) { col = "Purple"; }
    return col;
}

export function getWaytypeName(category: number, lang: string) {
    if (lang === "de") { return getWaytypeNameDe(category); }
    return getWaytypeNameEn(category);
}
export function getWaytypeNameDe(category: number) {
    let name = "unknown";
    if (category === 1) { name = "Hauptstraße"; }
    if (category === 2) { name = "Nebenstraße"; }
    if (category === 3) { name = "Straße"; }
    if (category === 4) { name = "Pfad"; }
    if (category === 5) { name = "Spur"; }
    if (category === 6) { name = "Radweg"; }
    if (category === 7) { name = "Fussweg"; }
    if (category === 8) { name = "Stufen"; }
    if (category === 9) { name = "Fähre"; }
    if (category === 10) { name = "Bustelle"; }
    return name;
}
export function getWaytypeNameEn(category: number) {
    let name = "unknown";
    if (category === 1) { name = "State Road"; }
    if (category === 2) { name = "Road"; }
    if (category === 3) { name = "Street"; }
    if (category === 4) { name = "Path"; }
    if (category === 5) { name = "Track"; }
    if (category === 6) { name = "Cycleway"; }
    if (category === 7) { name = "Footway"; }
    if (category === 8) { name = "Steps"; }
    if (category === 9) { name = "Ferry"; }
    if (category === 10) { name = "Construction"; }
    return name;
}
export function getWaytypeColor(category: number) {
    let col = "rgba(128,128,128, 1)";
    if (category === 1) { col = "Red"; } // state-road
    if (category === 2) { col = "Orange"; } // road
    if (category === 3) { col = "Yellow"; } // street
    if (category === 4) { col = "Maroon"; } // path
    if (category === 5) { col = "Maroon"; } // track
    if (category === 6) { col = "Green"; } // cycleway
    if (category === 7) { col = "Chrimson"; } // footway
    if (category === 8) { col = "BlueViolet"; } // steps
    if (category === 9) { col = "Aquamarine"; } // ferry
    if (category === 10) { col = "Purple"; } // construction
    return col;
}

export function getSurfaceName(category: number, lang: string) {
    if (lang === "de") { return getSurfaceNameDe(category); }
    return getSurfaceNameEn(category);
}
export function getSurfaceNameDe(category: number) {
    let name = "Unbekannt";
    if (category === 1) { name = "Befestigt"; }
    if (category === 2) { name = "Unbefestigt"; }
    if (category === 3) { name = "Asphalt"; }
    if (category === 4) { name = "Beton"; }
    if (category === 5) { name = "Kopfsteinpflaster"; }
    if (category === 6) { name = "Metall"; }
    if (category === 7) { name = "Holz"; }
    if (category === 8) { name = "Kies dicht"; }
    if (category === 9) { name = "Kies fein"; }
    if (category === 10) { name = "Kies"; }
    if (category === 11) { name = "Schotterstraße"; }
    if (category === 12) { name = "Erdstraße"; }
    if (category === 13) { name = "Eis"; }
    if (category === 14) { name = "Pflastersteine"; }
    if (category === 15) { name = "Sand"; }
    if (category === 16) { name = "Hackschnitzel"; }
    if (category === 17) { name = "Gras"; }
    if (category === 18) { name = "Graspflasterung"; }
    return name;
}
export function getSurfaceNameEn(category: number) {
    let name = "Unknown";
    if (category === 1) { name = "Paved"; }
    if (category === 2) { name = "Unpaved"; }
    if (category === 3) { name = "Asphalt"; }
    if (category === 4) { name = "Concrete"; }
    if (category === 5) { name = "Cobblestone"; }
    if (category === 6) { name = "Metal"; }
    if (category === 7) { name = "Wood"; }
    if (category === 8) { name = "Compacted Gravel"; }
    if (category === 9) { name = "Fine Gravel"; }
    if (category === 10) { name = "Gravel"; }
    if (category === 11) { name = "Dirt"; }
    if (category === 12) { name = "Ground"; }
    if (category === 13) { name = "Ice"; }
    if (category === 14) { name = "Paving Stones"; }
    if (category === 15) { name = "Sand"; }
    if (category === 16) { name = "Woodchips"; }
    if (category === 17) { name = "Grass"; }
    if (category === 18) { name = "Grass Paver"; }
    return name;
}

export function getSurfaceColor(category: number) {
    let col = "grey"; // unknown
    if (category === 1) { col = "LimeGreen"; } // paved
    if (category === 2) { col = "DarkRed"; } // unpaved
    if (category === 3) { col = "Green"; } // asphalt
    if (category === 4) { col = "SlateBlue"; } // concrete
    if (category === 5) { col = "Crimson"; } // "Cobblestone"
    // if (category === 6) { col = "Metal"; }
    // if (category === 7) { col = "Wood"; }
    if (category === 8) { col = "DarkOrange"; } // compacted gravel
    if (category === 9) { col = "Orange"; } // Fine Gravel
    if (category === 10) { col = "OrangeRed"; } // gravel
    if (category === 11) { col = "Maroon"; } // Dirt
    if (category === 12) { col = "Red"; } // ground
    // if (category === 13) { col = "Ice"; }
    if (category === 14) { col = "IndianRed"; } // Paving Stones
    if (category === 15) { col = "LightCoral"; } // sand
    // if (category === 16) { col = "Woodchips"; }
    if (category === 17) { col = "Olive"; } // Grass
    // if (category === 18) { col = "Grass Paver"; }
    return col;
}
