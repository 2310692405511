import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { SwPush } from "@angular/service-worker";
import { fromEvent, Observable, Subscription } from "rxjs";
// import { NewsletterService } from "../common/service/newsletter.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {

  // readonly VAPID_PUBLIC_KEY = "BOtsBbm4aD_efgkdL03gmL-4i6q2Q0_ZdTMwlYNDrEMx5fPWSk9iI4IKPPl3JtoX4KaZICsMpcpqMgA1tmyTJpw";

  public releaseDate: string = environment.releaseDate;
  public isOnline: boolean;

  private offlineEvent: Observable<Event>;
  private offLineSubscription: Subscription;
  private onlineEvent: Observable<Event>;
  private onLineSubscription: Subscription;

  constructor(
    private router: Router,
    // private swPush: SwPush,
    // private newsletterService: NewsletterService
  ) {
    // router-events
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const targetUrl = event.url;
        // console.log("------------------------------->App:constructor-navigationStart-event", event);
      }
    });

  }

  // subscribeToNotifications() {
  //   this.swPush.requestSubscription({
  //     serverPublicKey: this.VAPID_PUBLIC_KEY
  //   })
  //     .then(sub => console.log("app:subscribeToNotifications-sub", sub));
  //   // .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
  //   // .catch(err => console.error("Could not subscribe to notifications", err));
  // }

  public ngOnInit() {
    this.isOnline = navigator.onLine;
    // console.log("App:ngOnInit-isOnline", this.isOnline);

    this.offlineEvent = fromEvent(window, "offline");
    this.offLineSubscription = this.offlineEvent.subscribe(e => {
      console.log("App:offlineEvent");
      this.isOnline = false;
    });

    this.onlineEvent = fromEvent(window, "online");
    this.onLineSubscription = this.onlineEvent.subscribe(e => {
      console.log("App:onlineEvent");
      this.isOnline = true;
    });
  }
  public ngOnDestroy() {
    this.offLineSubscription.unsubscribe();
    this.onLineSubscription.unsubscribe();
  }

}
