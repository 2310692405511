<!-- maps-help-component -->

<!-- help -->
<div #helpDlg *ngIf="true" style="padding:0.5rem;">

    <!-- help-de -->
    <div *ngIf="languageCode==='de'">
        <!-- helps-title -->
        <div style="display:flex; padding:0.5rem;">
            <img style="width:30px; height:30px; cursor:pointer;" src="./assets/icons/arrow-left-thin.svg" alt="symbol"
                title="{{'MAPS.GEN.BACK_ICON_T' | translate}}" (click)="onHelpBackClick()">
            <!-- title -->
            <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
            <span style="margin-left:0.5rem; font-size:x-large;">Hilfe</span>
            <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
            <!-- change-to-map -->
            <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
                alt="symbol" title="{{'MAPS.GEN.CHANGE-TO-MAP_ICON_T' | translate}}" (click)="onHelpBackClick()">
        </div>
        <hr style="margin-top:0.5rem;">

        <!-- map-symbols -->
        <div style="margin-top:1.0rem; font-size:small;">
            <span style="font-size:large;"><b>Karten-Symbole?</b></span>
            <div><img src="./assets/icons/crosshairs-gps.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Auf meine Position zoomen</span>
            </div>
            <div><img src="./assets/icons/navigation.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Navigation starten</span><br>
                <span style="margin-left:2.0rem;">(autom. schieben und drehen der Karte ein)</span>
            </div>
            <div><img src="./assets/icons/overscan.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Zoom auf Karteninhalte (Route, Markierungen ...)</span>
            </div>
            <div><img src="./assets/icons/layers-triple-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Kartenart wählen</span>
            </div>
            <div><img width="24px" src="./assets/icons/lock-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Klick auf Karte sperren</span>
            </div>
            <div><img width="24px" src="./assets/icons/lock-open-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Klick auf Karte erlauben</span>
            </div>

            <div><img src="./assets/osm-icons/restaurant-24x24.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Restaurants anzeigen</span>
            </div>
            <div><img src="./assets/osm-icons/hotel-24x24.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Hotels anzeigen</span>
            </div>
            <div><img width="24px" height="24px" src="./assets/osm-icons/supermarket-40x40.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Lebensmittelgeschäfte anzeigen</span>
            </div>
            <div><img width="24px" src="./assets/icons/dots-horizontal.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Weitere Elemente anzeigen</span>
            </div>
        </div>

        <!-- menu-symbols -->
        <div style="margin-top:1.0rem; font-size:small;">
            <span style="font-size:large;"><b>Menü-Symbole?</b></span>
            <div><img src="./assets/icons/menu.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Menü</span>
            </div>
            <div><img src="./assets/icons/directions.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Routenplaner (planen, teilen, speichern)</span>
            </div>
            <div><img src="./assets/icons/upload-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">GPX-Route aus Datei laden</span>
            </div>
            <div><img src="./assets/icons/go-kart-track.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Weg aufzeichnen (tracken, speichern)</span>
            </div>
            <div><img width="24px" src="./assets/icons/bookmark-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Gespeicherte Routen und Orte</span>
            </div>
            <div><img src="./assets/icons/cog-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Einstellungen</span>
            </div>
            <div><img src="./assets/icons/help-circle-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Hilfe</span>
            </div>
            <div><img src="./assets/icons/information-variant.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Infos</span><br>
                <span style="margin-left:2.0rem;">Datenschutz, Nutzungsbedingungen, FAQ ...</span><br>
            </div>
        </div>
        <!-- end help-de -->
    </div>

    <!-- help-en -->
    <div *ngIf="languageCode!=='de'">
        <!-- help-title -->
        <div style="display:flex; padding:0.5rem;">
            <img style="width:30px; height:30px;" src="./assets/icons/arrow-left-thin.svg" title="back"
                (click)="onHelpBackClick()">
            <!-- title -->
            <span style="margin-left:0.5rem; font-size:x-large;">Help</span>
            <span style="flex-grow:1; flex-shrink:1; flex-basis:content;"></span>
            <!-- change-to-map -->
            <img style="cursor:pointer; margin-right:0.5rem;" width=36px src="./assets/icons/home-map-marker.svg"
                alt="change to map" title="Change to map" (click)="onHelpBackClick()">
        </div>
        <hr style="margin-top:0.5rem;">

        <!-- map-symbols -->
        <div style="margin-top:1.0rem; font-size:small;">
            <span style="font-size:large;"><b>Map symbols?</b></span>
            <div><img src="./assets/icons/crosshairs-gps.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Zoom to my position</span>
            </div>
            <div><img src="./assets/icons/navigation.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Start navigation</span><br>
                <span style="margin-left:2.0rem;">(automatic map move and rotation)</span>
            </div>
            <div><img src="./assets/icons/overscan.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Zoom to content</span>
            </div>
            <div><img src="./assets/icons/layers-triple-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Select map type</span>
            </div>
            <div><img width="24px" src="./assets/icons/lock-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Prevent map clicks</span>
            </div>
            <div><img width="24px" src="./assets/icons/lock-open-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Allow map clicks</span>
            </div>

            <div><img src="./assets/osm-icons/restaurant-24x24.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Show restaurants</span>
            </div>
            <div><img src="./assets/osm-icons/hotel-24x24.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Show hotels</span>
            </div>
            <div><img width="24px" height="24px" src="./assets/osm-icons/supermarket-40x40.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Show groceries</span>
            </div>
            <div><img width="30px" src="./assets/icons/dots-horizontal.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Show more points of interest</span>
            </div>
        </div>

        <!-- menu-symbols -->
        <div style="margin-top:1.0rem; font-size:small;">
            <span style="font-size:large;"><b>Menu symbols?</b></span>
            <div><img src="./assets/icons/menu.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Menu</span>
            </div>
            <div><img src="./assets/icons/directions.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Route planner (plan, share, save)</span>
            </div>
            <div><img src="./assets/icons/upload-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Load GPX route from file</span>
            </div>
            <div><img src="./assets/icons/go-kart-track.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Record path (track, save)</span>
            </div>
            <div><img width="30px" src="./assets/icons/bookmark-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Saved routes and places</span>
            </div>
            <div><img src="./assets/icons/cog-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Settings</span>
            </div>
            <div><img src="./assets/icons/help-circle-outline.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Help</span>
            </div>
            <div><img src="./assets/icons/information-variant.svg" alt="symbol">
                <span style="margin-left:0.5rem;">Infos</span><br>
                <span style="margin-left:2.0rem;">Privacy policy, terms of use, FAQ ...</span><br>
            </div>
        </div>
        <!-- end help-en -->
    </div>

</div>