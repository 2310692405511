<!-- about information-->
<div #bugsId style="margin:1.0rem;">

  <!-- title + text-->
  <h1>{{'MAPS.FEEDBACK.FEEDBACK_TITLE' | translate}}</h1>
  <div style="padding:0.5rem;">{{'MAPS.FEEDBACK.FEEDBACK_TEXT' | translate}}</div>

  <!-- bug or question -->
  <div style="margin-top: 1.0rem; padding:0.5rem;">
    <span>{{'MAPS.FEEDBACK.FEEDBACK-TYPE_MSG' | translate}}<br></span>
  </div>
  <div style="padding:0.5rem;">
    <mat-radio-group name="selectType" [value]="selectType">
      <mat-radio-button value="bug" (click)="onSelectFeedbackClick('bug')">
        {{'MAPS.FEEDBACK.SELECT-BUG_RADIO_LBL' | translate}}</mat-radio-button><br>
      <mat-radio-button style="margin-top:0.5rem;" value="question" (click)="onSelectFeedbackClick('question')">
        {{'MAPS.FEEDBACK.SELECT-QUESTION_RADIO_LBL' | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- bugs & problems -->
  <div *ngIf="selectType==='bug'" style="margin-top:1.0rem; margin-right:1.0rem;">
    <span style="margin-top:1.0rem; font-size:large;">
      {{'MAPS.FEEDBACK.BUG_TITLE' | translate}}:</span><br>
    <textarea mat-input class="round-rectangle" style="width:100%;max-width:500px;" cdkTextareaAutosize
      cdkAutosizeMinRows="10" cdkAutosizeMaxRows="20" [(ngModel)]="bugText"
      (input)="onBugTextChange($event)"></textarea><br>
    <!-- client email -->
    <div style="margin-top:1.0rem;">
      <span style="font-size:medium;">{{'MAPS.FEEDBACK.CLIENT-EMAIL_BUG_MSG' | translate}}</span><br>
      <input type="e-mail" style="width:100%;max-width:500px;" [(ngModel)]="clientEmail" />
      <span *ngIf="missingClientEmail" style="color:red">{{'MAPS.FEEDBACK.MISSING-CLIENT-EMAIL_MSG' | translate}}</span>
      <span *ngIf="wrongClientEmail" style="color:red">{{'MAPS.FEEDBACK.WRONG-EMAIL_MSG' | translate}}</span>
    </div>
    <div style="margin-top:0.5rem;display:flex;justify-content:flex-end;max-width:500px;">
      <button mat-raised-button (click)="onSendBugClick()">{{'MAPS.FEEDBACK.BUG-SEND_TEXT' | translate}}</button>
    </div>
    <!-- sent feedback message -->
    <div *ngIf="sentBug">
      <span>{{'MAPS.FEEDBACK.SENT-BUG_MSG' | translate}}</span><br>
      <span>{{'MAPS.FEEDBACK.SENT-BUG2_MSG' | translate}}</span><br>
      <span>{{'MAPS.FEEDBACK.SENT-BUG3_MSG' | translate}}</span>
    </div>
  </div>

  <!-- questions -->
  <div *ngIf="selectType==='question'" style="margin-top:1.0rem;margin-right:1.0rem;">
    <span style="margin-top:1.0rem; font-size:large;">
      {{'MAPS.FEEDBACK.QUESTION_TITLE' | translate}}:</span><br>
    <textarea mat-input class="round-rectangle" style="width:100%;max-width:500px;" cdkTextareaAutosize
      cdkAutosizeMinRows="10" cdkAutosizeMaxRows="20" [(ngModel)]="questionText"
      (input)="onQuestionTextChange($event)"></textarea><br>
    <!-- client email -->
    <div style="margin-top:1.0rem;">
      <span style="font-size:medium;">{{'MAPS.FEEDBACK.CLIENT-EMAIL_QUESTION_MSG' | translate}}</span><br>
      <input type="e-mail" style="width:100%;max-width:500px;" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        [(ngModel)]="clientEmail" />
      <span *ngIf="missingClientEmail" style="color:red">{{'MAPS.FEEDBACK.MISSING-CLIENT-EMAIL_MSG' | translate}}</span>
      <span *ngIf="wrongClientEmail" style="color:red">{{'MAPS.FEEDBACK.WRONG-EMAIL_MSG' | translate}}</span>
    </div>
    <div style="margin-top:0.5rem;display:flex;justify-content:flex-end;max-width:500px;">
      <button mat-raised-button (click)="onSendQuestionClick()">{{'MAPS.FEEDBACK.QUESTION-SEND_TEXT' |
        translate}}</button>
    </div>
    <!-- sent feedback message -->
    <div *ngIf="sentQuestion">
      <span>{{'MAPS.FEEDBACK.SENT-QUESTION_MSG' | translate}}</span><br>
      <span>{{'MAPS.FEEDBACK.SENT-QUESTION2_MSG' | translate}}</span>
    </div>
  </div>

  <!-- e-mail -->
  <hr>
  <div style="margin-top:1.0rem; font-size:large;">
    <p>
      {{'MAPS.FEEDBACK.SEND-EMAIL_MSG' | translate}}<br>
      <a href="mailto:travelplanner4you@outlook.com">travelplanner4you&#64;outlook.com</a>
    </p>
  </div>

</div>